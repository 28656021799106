import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IntermediaryVariables } from './types';

interface IntermediaryState {
  id: string;
  intermediaryNotFound: boolean | null;
  isMfaIncomplete: boolean | null;
  isEmailNotVerified: boolean | null;
  [IntermediaryVariables.Name]: string;
  [IntermediaryVariables.Type]: string;
  [IntermediaryVariables.Email]: string;
  [IntermediaryVariables.Phone]: string;
  [IntermediaryVariables.Id]: string;
  [IntermediaryVariables.PrimaryContactFirstName]: string;
  [IntermediaryVariables.PrimaryContactLastName]: string;
  [IntermediaryVariables.IntermediaryFirstPaymentPercentage]: number;
  [IntermediaryVariables.IntermediarySecondPaymentPercentage]: number;
}

const initialState: IntermediaryState = {
  id: '',
  intermediaryNotFound: null,
  isMfaIncomplete: null,
  isEmailNotVerified: null,
  [IntermediaryVariables.Name]: '',
  [IntermediaryVariables.Type]: '',
  [IntermediaryVariables.Email]: '',
  [IntermediaryVariables.Phone]: '',
  [IntermediaryVariables.Id]: '',
  [IntermediaryVariables.PrimaryContactFirstName]: '',
  [IntermediaryVariables.PrimaryContactLastName]: '',
  [IntermediaryVariables.IntermediaryFirstPaymentPercentage]: 0.5,
  [IntermediaryVariables.IntermediarySecondPaymentPercentage]: 0.5,
};

const intermediary = createSlice({
  name: 'intermediary',
  initialState,
  reducers: {
    setIntermediaryData: (state: IntermediaryState, { payload }: PayloadAction<Partial<IntermediaryState>>) => {
      state = { ...state, ...payload };

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase('intermediaries/get/pending', (state: IntermediaryState) => {
      state.intermediaryNotFound = null;

      return state;
    });
    builder.addCase('intermediaries/get/rejected', (state: IntermediaryState) => {
      state.intermediaryNotFound = true;

      return state;
    });
  },
});

export const { setIntermediaryData } = intermediary.actions;

export default intermediary.reducer;
