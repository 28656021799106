import React from 'react';

import { useSelector } from 'react-redux';

import { BasicInformationSteps, Step } from 'handlers/steps/types';
import { WithProgressBar } from 'layouts';
import { getCurrentStep } from 'selectors';

import BorrowerInfo from './steps/BorrowerInfo';
import Contacts from './steps/Contacts';
import LoanAmount from './steps/LoanAmount';
import LoanPurpose from './steps/LoanPurpose';

const getPageByStep = (step: Step | null) => {
  switch (step) {
    case BasicInformationSteps.LoanPurpose:
      return <LoanPurpose />;
    case BasicInformationSteps.LoanAmount:
      return <LoanAmount />;
    case BasicInformationSteps.BorrowerInfo:
      return <BorrowerInfo />;
    case BasicInformationSteps.Contacts:
      return <Contacts />;
    default:
      return <LoanPurpose />;
  }
};

const BasicInformation = () => {
  const currentBasicInformationStep = useSelector(getCurrentStep);

  return <WithProgressBar title="Basic information">{getPageByStep(currentBasicInformationStep)}</WithProgressBar>;
};

export default BasicInformation;
