import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EmploymentDuration } from 'pages/Employment/types';
import { ApplicationStatus, HardPullStatus, SoftPullStatus } from 'types';
import { getFriendlyStatus } from 'utils/getFriendlyStatus';

import { ApplicationVariables, EmploymentType, OwnershipStatus } from './types';

export interface ApplicationState {
  applicationId: string;
  propertyAddress: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  status: ApplicationStatus | null;
  friendlyStatus: string;
  coborrowerId: string | null;
  [ApplicationVariables.PrimaryLoanPurpose]: string;
  [ApplicationVariables.SecondaryLoanPurpose]: string;
  [ApplicationVariables.LoanAmount]: number | null;
  [ApplicationVariables.InstallationStreetAddress]: string;
  [ApplicationVariables.BorrowerOwnership]: OwnershipStatus | null;
  [ApplicationVariables.BorrowerContactAddressSameAsInstall]: boolean;
  [ApplicationVariables.BorrowerContactStreetAddress]: string;
  [ApplicationVariables.InstallationZipCode]: string;
  [ApplicationVariables.InstallationState]: string;
  [ApplicationVariables.InstallationCity]: string;
  [ApplicationVariables.BorrowerContactZipCode]: string;
  [ApplicationVariables.BorrowerContactState]: string;
  [ApplicationVariables.BorrowerContactCity]: string;
  [ApplicationVariables.OwnershipTermLessOneYear]: boolean | null;
  [ApplicationVariables.BorrowersEqualsOwners]: boolean | null;
  [ApplicationVariables.CoborrowerApplication]: boolean | null;
  [ApplicationVariables.BorrowerPreviousGrossIncome]: number | null;
  [ApplicationVariables.BorrowerPreviousCompanyName]: string;
  [ApplicationVariables.BorrowerPreviousJobTitle]: string;
  [ApplicationVariables.BorrowerPreviousEmploymentType]: EmploymentType | null;
  [ApplicationVariables.BorrowerGrossIncome]: number | null;
  [ApplicationVariables.BorrowerLengthOfEmployment]: EmploymentDuration | null;
  [ApplicationVariables.BorrowerJobTitle]: string;
  [ApplicationVariables.BorrowerEmploymentType]: EmploymentType | null;
  [ApplicationVariables.BorrowerCompanyName]: string;
  [ApplicationVariables.CoborrowerPreviousGrossIncome]: number | null;
  [ApplicationVariables.CoborrowerPreviousCompanyName]: string;
  [ApplicationVariables.CoborrowerPreviousJobTitle]: string;
  [ApplicationVariables.CoborrowerPreviousEmploymentType]: EmploymentType | null;
  [ApplicationVariables.CoborrowerGrossIncome]: number | null;
  [ApplicationVariables.CoborrowerLengthOfEmployment]: EmploymentDuration | null;
  [ApplicationVariables.CoborrowerJobTitle]: string;
  [ApplicationVariables.CoborrowerEmploymentType]: EmploymentType | null;
  [ApplicationVariables.CoborrowerCompanyName]: string;
  [ApplicationVariables.ACHDiscount]: boolean;
  [ApplicationVariables.LoanTermList]: string | null;
  [ApplicationVariables.InterestRate]: number | null;
  [ApplicationVariables.MonthlyPayment]: number | null;
  [ApplicationVariables.IntermediaryId]: string | null;
  [ApplicationVariables.AdditionalSalespersonExists]: boolean | null;
  [ApplicationVariables.AdditionalSalespersonName]: string;
  [ApplicationVariables.AdditionalSalespersonPhone]: number | null;
  [ApplicationVariables.AdditionalSalespersonEmail]: string;
  [ApplicationVariables.ConfirmProjectDetails]: boolean | null;
  [ApplicationVariables.OwnerInformationCorrect]: boolean | null;
  [ApplicationVariables.CoborrowerAuthorization]: boolean | null;
  [ApplicationVariables.CoborrowerFicoScore]: number | null;
  [ApplicationVariables.BorrowerFicoScore]: number | null;
  [ApplicationVariables.ApplicationFicoScore]: number | null;
  [ApplicationVariables.UploadBorrowerVoiComplete]: boolean;
  [ApplicationVariables.UploadCoborrowerVoiComplete]: boolean;
  [ApplicationVariables.SoftPullResult]: SoftPullStatus | null;
  [ApplicationVariables.PropertyOwner1]: string;
  [ApplicationVariables.PropertyOwner2]: string;
  [ApplicationVariables.PropertyOwner3]: string;
  [ApplicationVariables.First50Requested]: boolean | null;
  [ApplicationVariables.Final50Requested]: boolean | null;
  [ApplicationVariables.FullAmountRequested]: boolean | null;
  [ApplicationVariables.First50Authorized]: boolean | null;
  [ApplicationVariables.Final50Authorized]: boolean | null;
  [ApplicationVariables.FullAmountAuthorized]: boolean | null;
  [ApplicationVariables.AutomatedBorrowerIdVerificationPassed]: boolean;
  [ApplicationVariables.AutomatedCoborrowerIdVerificationPassed]: boolean | null;
  [ApplicationVariables.ProjectCheckConfirmed]: boolean | null;
  [ApplicationVariables.ApplicationWithdrawn]: boolean | null;
  [ApplicationVariables.ContractManualReview]: boolean | null;
  [ApplicationVariables.MonthlyMortgagePayment]: number | null;
  [ApplicationVariables.VoiNeeded]: boolean | null;
  [ApplicationVariables.PvKilowatts]: number | null;
  [ApplicationVariables.BatterySize]: string | null;
  [ApplicationVariables.RoofingRepairsDescription]: string | null;
  [ApplicationVariables.RoofingRepairsCost]: number | null;
  [ApplicationVariables.MakeAndModel]: string | null;
  [ApplicationVariables.OtherEnergyEfficiencyDescription]: string | null;
  [ApplicationVariables.OtherEnergyEfficiencyCost]: number | null;
  [ApplicationVariables.SolarPanelsCost]: number | null;
  [ApplicationVariables.EnergyStorageCost]: number | null;
  [ApplicationVariables.HeatingAndCoolingCost]: number | null;
  [ApplicationVariables.OutsideAmount]: number | null;
  [ApplicationVariables.ProjectDetailsAndSignedContractComplete]: boolean | null;
  [ApplicationVariables.ProjectDetailsAndSignedContractCompleteDate]: string;
  [ApplicationVariables.Total]: number | null;
  [ApplicationVariables.TotalMinusOutsideAmount]: null | number;
  [ApplicationVariables.RequestFundsAgreement]: boolean;
  [ApplicationVariables.IntermediaryPrepaidEligible]: boolean | null;
  [ApplicationVariables.UploadBorrowerVoiCompleteDate]: string;
  [ApplicationVariables.UploadBorrowerIdComplete]: boolean | null;
  [ApplicationVariables.UploadBorrowerIdCompleteDate]: string;
  [ApplicationVariables.UploadAdditionalDocumentsComplete]: boolean | null;
  [ApplicationVariables.UploadAdditionalDocumentsCompleteDate]: string;
  [ApplicationVariables.UploadCoborrowerIdComplete]: boolean | null;
  [ApplicationVariables.UploadCoborrowerIdCompleteDate]: string;
  [ApplicationVariables.UploadCoborrowerVoiCompleteDate]: string;
  [ApplicationVariables.IntermediaryConfirmed]: boolean;
  [ApplicationVariables.ContractManualReviewConfirmed]: boolean | null;
  [ApplicationVariables.BorrowerCviScore]: number | null;
  [ApplicationVariables.CoborrowerCviScore]: number | null;
  [ApplicationVariables.BorrowerRiskIndicatorLn]: string;
  [ApplicationVariables.CoborrowerRiskIndicatorLn]: string;
  [ApplicationVariables.PotentialOffersDisplayed]: boolean | null;
  [ApplicationVariables.UploadVerificationOwnershipComplete]: boolean | null;
  [ApplicationVariables.UploadVerificationOwnershipCompleteDate]: boolean | null;
  [ApplicationVariables.ConfirmProjectDetailsDate]: string;
  [ApplicationVariables.AgreedRunHardPull]: boolean | null;
  [ApplicationVariables.RequestLoanDocuments]: boolean | null;
  [ApplicationVariables.JoinSunComplete]: boolean | null;
  [ApplicationVariables.HardPullResult]: HardPullStatus | null;
  [ApplicationVariables.BankName]: string;
  [ApplicationVariables.RoutingNumber]: string | null;
  [ApplicationVariables.AccountNumber]: string | null;
  [ApplicationVariables.JoinSunCompleteDate]: string;
  [ApplicationVariables.AdjustedApplicationMonthlyDebtPayments]: number | null;
  [ApplicationVariables.AdjustedApplicationMonthlyIncomeAmount]: number | null;
  [ApplicationVariables.BorrowerSigningDocumentsComplete]: boolean | null;
  [ApplicationVariables.CoborrowerSigningDocumentsComplete]: boolean | null;
  [ApplicationVariables.ProjectCheckConfirmedDate]: string;
  [ApplicationVariables.PvCostPerWatt]: number | null;
  [ApplicationVariables.RoofingPercentage]: number | null;
  [ApplicationVariables.EzsolarLoanFinal50Disbursed]: boolean | null;
  [ApplicationVariables.EzsolarLoanFullAmountDisbursed]: boolean | null;
  [ApplicationVariables.AccountType]: string;
  [ApplicationVariables.BorrowerSigningDocumentsCompleteDate]: string;
  [ApplicationVariables.ApplicationCreateDate]: string;
  [ApplicationVariables.ApplicationDisplayId]: string;
  [ApplicationVariables.ParcelNumber]: string;
  [ApplicationVariables.AbbreviatedLegalDescription]: string;
  [ApplicationVariables.PropertyCounty]: string;
  [ApplicationVariables.FipsCode]: string;
  [ApplicationVariables.UnqualifiedIncome]: string;
  [ApplicationVariables.UnsecuredDebtPercentage]: number | null;
  [ApplicationVariables.ApplicationMortgageAlert]: string;
  [ApplicationVariables.UCCFilingCost]: number | null;
  [ApplicationVariables.TransferToServicingSystemComplete]: boolean | null;
  [ApplicationVariables.PropertyType]: string;
  [ApplicationVariables.LandUseDescription]: string;
  [ApplicationVariables.MonthlyDebtPaymentsAdjustment1]: number | null;
  [ApplicationVariables.MonthlyDebtPaymentsAdjustment2]: number | null;
  [ApplicationVariables.MonthlyDebtPaymentsAdjustment3]: number | null;
  [ApplicationVariables.MonthlyDebtPaymentsAdjustment4]: number | null;
  [ApplicationVariables.MonthlyDebtPaymentsAdjustment5]: number | null;
  [ApplicationVariables.AvailableRevolving]: number | null;
  [ApplicationVariables.AddedCoborrowerThroughDashboard]: boolean | null;
  [ApplicationVariables.AuthorizedSignerAccount]: boolean | null;
  [ApplicationVariables.InstitutionName]: string;
  [ApplicationVariables.PropertyTypeCheckRequired]: boolean | null;
  [ApplicationVariables.BorrowerPreviousLengthOfEmployment]: EmploymentDuration | null;
  [ApplicationVariables.CoborrowerPreviousLengthOfEmployment]: EmploymentDuration | null;
  [ApplicationVariables.HardCreditPullOverride]: boolean | null;
  [ApplicationVariables.BorrowerRiskIndicatorCodesDescription]: string;
  [ApplicationVariables.CoborrowerRiskIndicatorCodesDescription]: string;
  [ApplicationVariables.IntermediaryInvited]: boolean | null;
  [ApplicationVariables.Borrower4LastDigitsSSN]: string;
  [ApplicationVariables.Coborrower4LastDigitsSSN]: string;
  [ApplicationVariables.DischargedBankruptcyAlert]: string;
  [ApplicationVariables.DischargedBankruptcyAlertCoborrower]: string;
  [ApplicationVariables.InterestRate5YearFixed]: string;
}

const initialState: ApplicationState = {
  applicationId: '',
  propertyAddress: {
    street: '',
    city: '',
    state: '',
    zipCode: '',
  },
  status: null,
  friendlyStatus: '',
  coborrowerId: null,
  [ApplicationVariables.PrimaryLoanPurpose]: '',
  [ApplicationVariables.SecondaryLoanPurpose]: '',
  [ApplicationVariables.LoanAmount]: null,
  [ApplicationVariables.InstallationStreetAddress]: '',
  [ApplicationVariables.BorrowerOwnership]: null,
  [ApplicationVariables.BorrowerContactAddressSameAsInstall]: false,
  [ApplicationVariables.BorrowerContactStreetAddress]: '',
  [ApplicationVariables.InstallationZipCode]: '',
  [ApplicationVariables.InstallationState]: '',
  [ApplicationVariables.InstallationCity]: '',
  [ApplicationVariables.BorrowerContactZipCode]: '',
  [ApplicationVariables.BorrowerContactState]: '',
  [ApplicationVariables.BorrowerContactCity]: '',
  [ApplicationVariables.OwnershipTermLessOneYear]: null,
  [ApplicationVariables.BorrowersEqualsOwners]: null,
  [ApplicationVariables.CoborrowerApplication]: null,
  [ApplicationVariables.BorrowerPreviousGrossIncome]: null,
  [ApplicationVariables.BorrowerPreviousCompanyName]: '',
  [ApplicationVariables.BorrowerPreviousJobTitle]: '',
  [ApplicationVariables.BorrowerPreviousEmploymentType]: null,
  [ApplicationVariables.BorrowerGrossIncome]: null,
  [ApplicationVariables.BorrowerLengthOfEmployment]: null,
  [ApplicationVariables.BorrowerJobTitle]: '',
  [ApplicationVariables.BorrowerEmploymentType]: null,
  [ApplicationVariables.BorrowerCompanyName]: '',
  [ApplicationVariables.CoborrowerPreviousGrossIncome]: null,
  [ApplicationVariables.CoborrowerPreviousCompanyName]: '',
  [ApplicationVariables.CoborrowerPreviousJobTitle]: '',
  [ApplicationVariables.CoborrowerPreviousEmploymentType]: null,
  [ApplicationVariables.CoborrowerGrossIncome]: null,
  [ApplicationVariables.CoborrowerLengthOfEmployment]: null,
  [ApplicationVariables.CoborrowerJobTitle]: '',
  [ApplicationVariables.CoborrowerEmploymentType]: null,
  [ApplicationVariables.CoborrowerCompanyName]: '',
  [ApplicationVariables.ACHDiscount]: true,
  [ApplicationVariables.LoanTermList]: '',
  [ApplicationVariables.InterestRate]: null,
  [ApplicationVariables.MonthlyPayment]: null,
  [ApplicationVariables.IntermediaryId]: null,
  [ApplicationVariables.AdditionalSalespersonExists]: null,
  [ApplicationVariables.AdditionalSalespersonName]: '',
  [ApplicationVariables.AdditionalSalespersonPhone]: null,
  [ApplicationVariables.AdditionalSalespersonEmail]: '',
  [ApplicationVariables.ConfirmProjectDetails]: null,
  [ApplicationVariables.OwnerInformationCorrect]: null,
  [ApplicationVariables.CoborrowerAuthorization]: null,
  [ApplicationVariables.CoborrowerFicoScore]: null,
  [ApplicationVariables.BorrowerFicoScore]: null,
  [ApplicationVariables.ApplicationFicoScore]: null,
  [ApplicationVariables.UploadBorrowerVoiComplete]: false,
  [ApplicationVariables.UploadCoborrowerVoiComplete]: false,
  [ApplicationVariables.SoftPullResult]: null,
  [ApplicationVariables.PropertyOwner1]: '',
  [ApplicationVariables.PropertyOwner2]: '',
  [ApplicationVariables.PropertyOwner3]: '',
  [ApplicationVariables.First50Requested]: null,
  [ApplicationVariables.Final50Requested]: null,
  [ApplicationVariables.FullAmountRequested]: null,
  [ApplicationVariables.First50Authorized]: null,
  [ApplicationVariables.Final50Authorized]: null,
  [ApplicationVariables.FullAmountAuthorized]: null,
  [ApplicationVariables.AutomatedCoborrowerIdVerificationPassed]: null,
  [ApplicationVariables.AutomatedBorrowerIdVerificationPassed]: false,
  [ApplicationVariables.ProjectCheckConfirmed]: null,
  [ApplicationVariables.ApplicationWithdrawn]: null,
  [ApplicationVariables.ContractManualReview]: null,
  [ApplicationVariables.MonthlyMortgagePayment]: null,
  [ApplicationVariables.VoiNeeded]: null,
  [ApplicationVariables.PvKilowatts]: null,
  [ApplicationVariables.BatterySize]: null,
  [ApplicationVariables.RoofingRepairsDescription]: null,
  [ApplicationVariables.RoofingRepairsCost]: null,
  [ApplicationVariables.MakeAndModel]: null,
  [ApplicationVariables.OtherEnergyEfficiencyDescription]: null,
  [ApplicationVariables.OtherEnergyEfficiencyCost]: null,
  [ApplicationVariables.SolarPanelsCost]: null,
  [ApplicationVariables.EnergyStorageCost]: null,
  [ApplicationVariables.HeatingAndCoolingCost]: null,
  [ApplicationVariables.OutsideAmount]: null,
  [ApplicationVariables.ProjectDetailsAndSignedContractComplete]: null,
  [ApplicationVariables.ProjectDetailsAndSignedContractCompleteDate]: '',
  [ApplicationVariables.Total]: null,
  [ApplicationVariables.TotalMinusOutsideAmount]: null,
  [ApplicationVariables.RequestFundsAgreement]: false,
  [ApplicationVariables.IntermediaryPrepaidEligible]: null,
  [ApplicationVariables.UploadBorrowerVoiCompleteDate]: '',
  [ApplicationVariables.UploadBorrowerIdComplete]: null,
  [ApplicationVariables.UploadBorrowerIdCompleteDate]: '',
  [ApplicationVariables.UploadAdditionalDocumentsComplete]: null,
  [ApplicationVariables.UploadAdditionalDocumentsCompleteDate]: '',
  [ApplicationVariables.UploadCoborrowerIdComplete]: null,
  [ApplicationVariables.UploadCoborrowerIdCompleteDate]: '',
  [ApplicationVariables.UploadCoborrowerVoiCompleteDate]: '',
  [ApplicationVariables.IntermediaryConfirmed]: false,
  [ApplicationVariables.ContractManualReviewConfirmed]: null,
  [ApplicationVariables.BorrowerCviScore]: null,
  [ApplicationVariables.CoborrowerCviScore]: null,
  [ApplicationVariables.BorrowerRiskIndicatorLn]: '',
  [ApplicationVariables.CoborrowerRiskIndicatorLn]: '',
  [ApplicationVariables.PotentialOffersDisplayed]: null,
  [ApplicationVariables.UploadVerificationOwnershipComplete]: null,
  [ApplicationVariables.UploadVerificationOwnershipCompleteDate]: null,
  [ApplicationVariables.ConfirmProjectDetailsDate]: '',
  [ApplicationVariables.AgreedRunHardPull]: null,
  [ApplicationVariables.RequestLoanDocuments]: null,
  [ApplicationVariables.JoinSunComplete]: null,
  [ApplicationVariables.HardPullResult]: null,
  [ApplicationVariables.BankName]: '',
  [ApplicationVariables.RoutingNumber]: null,
  [ApplicationVariables.AccountNumber]: null,
  [ApplicationVariables.JoinSunCompleteDate]: '',
  [ApplicationVariables.AdjustedApplicationMonthlyDebtPayments]: null,
  [ApplicationVariables.AdjustedApplicationMonthlyIncomeAmount]: null,
  [ApplicationVariables.BorrowerSigningDocumentsComplete]: null,
  [ApplicationVariables.CoborrowerSigningDocumentsComplete]: null,
  [ApplicationVariables.ProjectCheckConfirmedDate]: '',
  [ApplicationVariables.PvCostPerWatt]: null,
  [ApplicationVariables.RoofingPercentage]: null,
  [ApplicationVariables.EzsolarLoanFinal50Disbursed]: null,
  [ApplicationVariables.EzsolarLoanFullAmountDisbursed]: null,
  [ApplicationVariables.AccountType]: '',
  [ApplicationVariables.BorrowerSigningDocumentsCompleteDate]: '',
  [ApplicationVariables.ApplicationCreateDate]: '',
  [ApplicationVariables.ApplicationDisplayId]: '',
  [ApplicationVariables.ParcelNumber]: '',
  [ApplicationVariables.AbbreviatedLegalDescription]: '',
  [ApplicationVariables.PropertyCounty]: '',
  [ApplicationVariables.FipsCode]: '',
  [ApplicationVariables.UnqualifiedIncome]: '',
  [ApplicationVariables.UnsecuredDebtPercentage]: null,
  [ApplicationVariables.ApplicationMortgageAlert]: '',
  [ApplicationVariables.UCCFilingCost]: null,
  [ApplicationVariables.TransferToServicingSystemComplete]: null,
  [ApplicationVariables.PropertyType]: '',
  [ApplicationVariables.LandUseDescription]: '',
  [ApplicationVariables.MonthlyDebtPaymentsAdjustment1]: null,
  [ApplicationVariables.MonthlyDebtPaymentsAdjustment2]: null,
  [ApplicationVariables.MonthlyDebtPaymentsAdjustment3]: null,
  [ApplicationVariables.MonthlyDebtPaymentsAdjustment4]: null,
  [ApplicationVariables.MonthlyDebtPaymentsAdjustment5]: null,
  [ApplicationVariables.AvailableRevolving]: null,
  [ApplicationVariables.AddedCoborrowerThroughDashboard]: null,
  [ApplicationVariables.AuthorizedSignerAccount]: null,
  [ApplicationVariables.InstitutionName]: '',
  [ApplicationVariables.PropertyTypeCheckRequired]: null,
  [ApplicationVariables.CoborrowerPreviousLengthOfEmployment]: null,
  [ApplicationVariables.BorrowerPreviousLengthOfEmployment]: null,
  [ApplicationVariables.HardCreditPullOverride]: null,
  [ApplicationVariables.BorrowerRiskIndicatorCodesDescription]: '',
  [ApplicationVariables.CoborrowerRiskIndicatorCodesDescription]: '',
  [ApplicationVariables.IntermediaryInvited]: null,
  [ApplicationVariables.Borrower4LastDigitsSSN]: '',
  [ApplicationVariables.Coborrower4LastDigitsSSN]: '',
  [ApplicationVariables.DischargedBankruptcyAlert]: '',
  [ApplicationVariables.DischargedBankruptcyAlertCoborrower]: '',
  [ApplicationVariables.InterestRate5YearFixed]: '',
};

const application = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setApplicationData: (state: ApplicationState, { payload }: PayloadAction<Partial<ApplicationState>>) => {
      state = { ...state, ...payload };
      state.friendlyStatus = getFriendlyStatus(state);

      return state;
    },
    setCoborrowerExists: (state: ApplicationState, { payload }: PayloadAction<boolean | null>) => {
      state[ApplicationVariables.CoborrowerApplication] = payload;

      return state;
    },
  },
});

export const { setApplicationData, setCoborrowerExists } = application.actions;

export default application.reducer;
