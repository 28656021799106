export enum EmploymentPageType {
  Borrower = 'Borrower',
  Coborrower = 'Coborrower',
  PreviousBorrower = 'PreviousBorrower',
  PreviousCoborrower = 'PreviousCoborrower',
}

export enum EmploymentDuration {
  Less1 = '1 year or less',
  Between1And2 = 'Between 1 year and 2 years',
  More2 = '2 years or more',
}
