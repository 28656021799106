import React from 'react';

import { useSelector } from 'react-redux';

import { CoborrowerInfoSteps, Step } from 'handlers/steps/types';
import { WithProgressBar } from 'layouts';
import { getCurrentStep } from 'selectors';

import CoborrowerContactInfo from './steps/CoborrowerContactInfo';
import CoborrowerPersonalInfo from './steps/CoborrowerPersonalInfo';
import NumberOfBorrowers from './steps/NumberOfBorrowers';

const getPageByStep = (step: Step | null) => {
  switch (step) {
    case CoborrowerInfoSteps.NumberOfBorrowers:
      return <NumberOfBorrowers />;
    case CoborrowerInfoSteps.CoborrowerPersonalInfo:
      return <CoborrowerPersonalInfo />;
    case CoborrowerInfoSteps.CoborrowerContactInfo:
      return <CoborrowerContactInfo />;
    default:
      return <NumberOfBorrowers />;
  }
};

const CoborrowerInfo = () => {
  const currentStep = useSelector(getCurrentStep);

  return <WithProgressBar title="Co-borrower information">{getPageByStep(currentStep)}</WithProgressBar>;
};

export default CoborrowerInfo;
