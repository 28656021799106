export interface GeoAttrs {
  street: string;
  city: string;
  state: string;
  zip: string;
  country: string;
}

enum AddressPartsType {
  State = 'administrative_area_level_1',
  Locality = 'locality',
  City = 'city',
  PostalCode = 'postal_code',
  StreetNumber = 'street_number',
  Street = 'route',
  Country = 'country',
}

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

const getAddressParts = (addressComponents: AddressComponent[]) =>
  addressComponents.reduce(
    (acc, { types, long_name, short_name }) => {
      if (types.includes(AddressPartsType.State)) {
        acc.state = short_name;
      }

      if (types.includes(AddressPartsType.Locality)) {
        acc.city = long_name;
      }

      if (types.includes(AddressPartsType.PostalCode)) {
        acc.zip = short_name;
      }

      if (types.includes(AddressPartsType.Country)) {
        acc.country = long_name;
      }

      return acc;
    },
    { state: '', city: '', zip: '', country: '' } as GeoAttrs,
  );

export default getAddressParts;
