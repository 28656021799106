import { createAsyncThunk } from '@reduxjs/toolkit';
import { FileWithPath } from 'react-dropzone';

import ApplicationApi from 'api/ApplicationApi';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface UploadDocumentsProps {
  applicationId: string;
  files: FileWithPath[];
  isPdfFile?: boolean;
}

export const createUploadDocumentsThunk = (applicationApi: ApplicationApi) =>
  createAsyncThunk(
    'application/upload',
    async ({ applicationId, files, isPdfFile }: UploadDocumentsProps, { dispatch }) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        return await applicationApi.uploadDocuments(applicationId, files, isPdfFile);
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
