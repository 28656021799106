import React, { useEffect, useState } from 'react';

import classnames from 'classnames/bind';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import ViewContract from 'components/ViewContract';
import { MobileScreenWidth } from 'enums/MobileScreenWidth';
import { ApplicationVariables } from 'handlers/application/types';
import { UserInformationKeys } from 'handlers/customer/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { useAppDispatch } from 'store';
import { getApplicationByDisplayId } from 'thunks';
import { Application, Routes } from 'types';
import { convertMonetary } from 'utils/formats';

import styles from './ViewProjectDetails.module.scss';

const cx = classnames.bind(styles);

enum ProductValue {
  solarPanel = 'Solar system size in kilowatts',
  energyStorage = 'Energy storage in kilowatt hours',
  roofingRepairs = 'Roofing repairs',
  heatingAndCoolingUpgrades = 'Heating and cooling upgrades',
  otherEnergy = 'Other energy efficiency',
}

const projectCard = (product: string, details: string, cost: string) => (
  <div className={styles.cardContainer}>
    <div className={styles.card}>
      <div>
        <div className={styles.cardLabel}>Products</div>
        <div className={styles.cardValue}>{product}</div>
      </div>
      <div className={styles.cardBody}>
        <div>
          <div className={styles.cardLabel}>Details</div>
          <div className={styles.cardValue}>{details}</div>
        </div>
        <div>
          <div className={cx(styles.cardLabel, styles.textAlign)}>Cost $</div>
          <div className={styles.cardValue}>{cost}</div>
        </div>
      </div>
    </div>
  </div>
);

const ViewProjectDetails = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const [application, setApplication] = useState<Application | null>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobileScreen = screenWidth < MobileScreenWidth.Middle;

  const handleWindowSizeChange = () => setScreenWidth(window.innerWidth);

  const pvKilowatts = application?.variables[ApplicationVariables.PvKilowatts];
  const solarPanelsCost = convertMonetary(application?.variables[ApplicationVariables.SolarPanelsCost] ?? null);
  const batterySize = application?.variables[ApplicationVariables.BatterySize];
  const energyStorageCost = convertMonetary(application?.variables[ApplicationVariables.EnergyStorageCost] ?? null);
  const roofingRepairsDescription = application?.variables[ApplicationVariables.RoofingRepairsDescription];
  const roofingRepairsCost = convertMonetary(application?.variables[ApplicationVariables.RoofingRepairsCost] ?? null);
  const makeAndModel = application?.variables[ApplicationVariables.MakeAndModel];
  const heatingAndCoolingCost = convertMonetary(
    application?.variables[ApplicationVariables.HeatingAndCoolingCost] ?? null,
  );
  const otherEnergyEfficiencyDescription =
    application?.variables[ApplicationVariables.OtherEnergyEfficiencyDescription];
  const otherEnergyEfficiencyCost = convertMonetary(
    application?.variables[ApplicationVariables.OtherEnergyEfficiencyCost] ?? null,
  );

  const getApplication = async () => setApplication(await dispatch(getApplicationByDisplayId(id)).unwrap());

  const onBackClick = () => history.push(generatePath(Routes.ContractorManageDeal, { id }));

  useEffect(() => {
    getApplication();
    return () => setApplication(null);
  }, [id]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return (
    <>
      <p className={styles.subTitle}>
        Borrower: {application?.variables[UserInformationKeys.FirstName]}{' '}
        {application?.variables[UserInformationKeys.LastName]}
      </p>
      {!isMobileScreen && (
        <div className={styles.tableContainer}>
          <table className={styles.table}>
            <thead>
              <tr className={styles.headerRow}>
                <th className={styles.productCell}>Products</th>
                <th className={styles.detailsCell}>Details</th>
                <th className={styles.costCell}>Cost $</th>
              </tr>
            </thead>
            <tbody className={styles.tableBody}>
              {pvKilowatts ? (
                <tr className={styles.bodyRow}>
                  <td className={styles.productCell}>{ProductValue.solarPanel}</td>
                  <td className={styles.detailsCell}>{pvKilowatts}</td>
                  <td className={styles.costCell}>{solarPanelsCost}</td>
                </tr>
              ) : null}
              {batterySize ? (
                <tr className={styles.bodyRow}>
                  <td className={styles.productCell}>{ProductValue.energyStorage}</td>
                  <td className={styles.detailsCell}>{batterySize}</td>
                  <td className={styles.costCell}>{energyStorageCost}</td>
                </tr>
              ) : null}
              {roofingRepairsDescription ? (
                <tr className={styles.bodyRow}>
                  <td className={styles.productCell}>{ProductValue.roofingRepairs}</td>
                  <td className={styles.detailsCell}>{roofingRepairsDescription}</td>
                  <td className={styles.costCell}>{roofingRepairsCost}</td>
                </tr>
              ) : null}
              {makeAndModel ? (
                <tr className={styles.bodyRow}>
                  <td className={styles.productCell}>{ProductValue.heatingAndCoolingUpgrades}</td>
                  <td className={styles.detailsCell}>{makeAndModel}</td>
                  <td className={styles.costCell}>{heatingAndCoolingCost}</td>
                </tr>
              ) : null}
              {otherEnergyEfficiencyDescription ? (
                <tr className={styles.bodyRow}>
                  <td className={styles.productCell}>{ProductValue.otherEnergy}</td>
                  <td className={styles.detailsCell}>{otherEnergyEfficiencyDescription}</td>
                  <td className={styles.costCell}>{otherEnergyEfficiencyCost}</td>
                </tr>
              ) : null}
            </tbody>
          </table>
        </div>
      )}
      {isMobileScreen && (
        <>
          {pvKilowatts && <>{projectCard(ProductValue.solarPanel, pvKilowatts, solarPanelsCost)}</>}
          {batterySize && <>{projectCard(ProductValue.energyStorage, batterySize, energyStorageCost)}</>}
          {roofingRepairsDescription && (
            <>{projectCard(ProductValue.roofingRepairs, roofingRepairsDescription, roofingRepairsCost)}</>
          )}
          {makeAndModel && (
            <>{projectCard(ProductValue.heatingAndCoolingUpgrades, makeAndModel, heatingAndCoolingCost)}</>
          )}
          {otherEnergyEfficiencyDescription && (
            <>{projectCard(ProductValue.otherEnergy, otherEnergyEfficiencyDescription, otherEnergyEfficiencyCost)}</>
          )}
        </>
      )}
      <ViewContract
        className={cx({ [styles.viewContract]: isMobileScreen })}
        loanAmount={application?.variables[ApplicationVariables.LoanAmount]}
        totalProjectCost={application?.variables[ApplicationVariables.Total]}
        amountPaidOutside={application?.variables[ApplicationVariables.OutsideAmount]}
        viewTitle="signed contract"
        applicationId={application?.id as string}
      />
      <NavigationButtonWrapper>
        <BackButton onClick={onBackClick} className={commonStyles.portalBackButton} />
      </NavigationButtonWrapper>
    </>
  );
};

export default withPortalLayout(ViewProjectDetails);
