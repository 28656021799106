import React, { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import Checkbox from 'common/components/Checkbox';
import MonetaryInput from 'common/components/MonetaryInput/MonetaryInput';
import { SearchLocationInput } from 'common/components/SearchLocationInput';
import InputErrorMessage from 'common/components/TextInput/InputErrorMessage';
import useScript from 'common/hooks/useScript';
import DropDown from 'components/DropDown';
import { OptionType } from 'components/DropDown/DropDown';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import PageLoader from 'components/PageLoader';
import { getMessageForInvalidField, getRequiredErrorMessage } from 'errors/errors';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables, OwnershipStatus } from 'handlers/application/types';
import { UserInformationKeys } from 'handlers/customer/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { nextStep } from 'handlers/steps';
import { CoborrowerInfoSteps } from 'handlers/steps/types';
import styles from 'pages/pages.module.scss';
import {
  getBorrowerContactAddressSameAsIntall,
  getBorrowerContactCity,
  getBorrowerContactState,
  getBorrowerContactStreetAddress,
  getBorrowerContactZipCode,
  getBorrowerOwnershipStatus,
  getCoborrowerExists,
  getInstallationCity,
  getInstallationState,
  getInstallationStreetAddress,
  getInstallationZipCode,
  getIsLoading,
  getLoanAmount,
  getMortgagePayment,
} from 'selectors';
import { useAppDispatch } from 'store';
import { getPropertyOwners, runStrategy } from 'thunks';
import { StrategyName } from 'types';
import { checkPropertyType } from 'utils/checkPropertyType';
import { getOptionObject } from 'utils/getOptionObject';
import { getPatterns, isExistErrors } from 'utils/validationHelper';

const InstallationToContactAddress: Record<string, string> = {
  [ApplicationVariables.InstallationStreetAddress]: ApplicationVariables.BorrowerContactStreetAddress,
  [ApplicationVariables.InstallationCity]: ApplicationVariables.BorrowerContactCity,
  [ApplicationVariables.InstallationState]: ApplicationVariables.BorrowerContactState,
  [ApplicationVariables.InstallationZipCode]: ApplicationVariables.BorrowerContactZipCode,
};

const ownershipList = [
  { value: OwnershipStatus.OwnOutright, label: OwnershipStatus.OwnOutright },
  { value: OwnershipStatus.OwnWithMortgage, label: OwnershipStatus.OwnWithMortgage },
  { value: OwnershipStatus.ReverseMortage, label: OwnershipStatus.ReverseMortage },
  { value: OwnershipStatus.NotAnOwner, label: OwnershipStatus.NotAnOwner },
];

const cx = classNames.bind(styles);

const BorrowerAddressInfo = () => {
  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
  );
  const dispatch = useAppDispatch();

  const initialOwnershipStatus = useSelector(getBorrowerOwnershipStatus);
  const initialContactAddressTheSame = useSelector(getBorrowerContactAddressSameAsIntall);
  const loading = useSelector(getIsLoading);
  const coborrowerApplication = useSelector(getCoborrowerExists);
  const loanAmount = useSelector(getLoanAmount);

  const [mortgagePaymentNeeded, setMortgagePaymentNeeded] = useState(
    initialOwnershipStatus === OwnershipStatus.OwnWithMortgage,
  );

  const initialInstallationAddress = {
    zip: useSelector(getInstallationZipCode),
    state: useSelector(getInstallationState),
    city: useSelector(getInstallationCity),
    street_address: useSelector(getInstallationStreetAddress),
  };
  const initialContactAddress = {
    zip: useSelector(getBorrowerContactZipCode),
    state: useSelector(getBorrowerContactState),
    city: useSelector(getBorrowerContactCity),
    street_address: useSelector(getBorrowerContactStreetAddress),
  };

  const {
    handleSubmit,
    watch,
    setValue,
    register,
    trigger,
    control,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useForm<FieldValues>({
    defaultValues: {
      [ApplicationVariables.MonthlyMortgagePayment]: useSelector(getMortgagePayment),
      [ApplicationVariables.BorrowerOwnership]: getOptionObject(
        ownershipList,
        initialOwnershipStatus as OwnershipStatus,
      ),
      [ApplicationVariables.BorrowerContactAddressSameAsInstall]: initialContactAddressTheSame,
      [ApplicationVariables.BorrowerContactStreetAddress]: initialContactAddress.street_address,
      [ApplicationVariables.BorrowerContactCity]: initialContactAddress.city,
      [ApplicationVariables.BorrowerContactState]: initialContactAddress.state,
      [ApplicationVariables.BorrowerContactZipCode]: initialContactAddress.zip,
      [ApplicationVariables.InstallationStreetAddress]: initialInstallationAddress.street_address,
      [ApplicationVariables.InstallationZipCode]: initialInstallationAddress.zip,
      [ApplicationVariables.InstallationState]: initialInstallationAddress.state,
      [ApplicationVariables.InstallationCity]: initialInstallationAddress.city,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const watcher = watch();

  useEffect(() => {
    register(ApplicationVariables.BorrowerOwnership, {
      required: getRequiredErrorMessage('Ownership status'),
    });
    register(ApplicationVariables.InstallationStreetAddress, {
      required: getRequiredErrorMessage('Installation street address'),
    });
    register(ApplicationVariables.InstallationCity, { required: getRequiredErrorMessage('City') });
    register(ApplicationVariables.InstallationZipCode, {
      required: getRequiredErrorMessage('Zip code'),
      pattern: {
        value: getPatterns().zipcode,
        message: getMessageForInvalidField('Zip code'),
      },
    });
    register(ApplicationVariables.InstallationState, { required: getRequiredErrorMessage('State') });
    register(ApplicationVariables.BorrowerContactStreetAddress, {
      required: getRequiredErrorMessage('Contact street address'),
    });
    register(ApplicationVariables.BorrowerContactCity, { required: getRequiredErrorMessage('City') });
    register(ApplicationVariables.BorrowerContactZipCode, {
      required: getRequiredErrorMessage('Zip code'),
      pattern: {
        value: getPatterns().zipcode,
        message: getMessageForInvalidField('Zip code'),
      },
    });
    register(ApplicationVariables.BorrowerContactState, { required: getRequiredErrorMessage('State') });
    register(ApplicationVariables.MonthlyMortgagePayment, {
      validate: (value) =>
        mortgagePaymentNeeded && !value ? getRequiredErrorMessage('Monthly mortgage payment') : undefined,
    });
  }, [register, mortgagePaymentNeeded, watcher.mortgagePayment]);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    let propertyType;
    const uccFilingCost = await dispatch(
      runStrategy({
        strategyName: StrategyName.EstimatedUCCFilingCostCalculation,
        strategyInputVariables: {
          [UserInformationKeys.State]: data[ApplicationVariables.BorrowerContactState],
          [ApplicationVariables.LoanAmount]: loanAmount,
        },
      }),
    ).unwrap();

    dispatch(
      setApplicationData({
        [ApplicationVariables.BorrowerOwnership]: data[ApplicationVariables.BorrowerOwnership].value as OwnershipStatus,
        [ApplicationVariables.BorrowerContactAddressSameAsInstall]:
          data[ApplicationVariables.BorrowerContactAddressSameAsInstall],
        [ApplicationVariables.InstallationStreetAddress]: data[ApplicationVariables.InstallationStreetAddress],
        [ApplicationVariables.InstallationZipCode]: data[ApplicationVariables.InstallationZipCode],
        [ApplicationVariables.InstallationState]: data[ApplicationVariables.InstallationState],
        [ApplicationVariables.InstallationCity]: data[ApplicationVariables.InstallationCity],
        [ApplicationVariables.BorrowerContactStreetAddress]: data[ApplicationVariables.BorrowerContactStreetAddress],
        [ApplicationVariables.BorrowerContactZipCode]: data[ApplicationVariables.BorrowerContactZipCode],
        [ApplicationVariables.BorrowerContactState]: data[ApplicationVariables.BorrowerContactState],
        [ApplicationVariables.BorrowerContactCity]: data[ApplicationVariables.BorrowerContactCity],
        [ApplicationVariables.MonthlyMortgagePayment]: mortgagePaymentNeeded
          ? Number(data[ApplicationVariables.MonthlyMortgagePayment])
          : null,
        [ApplicationVariables.UCCFilingCost]: uccFilingCost.outputVariables[ApplicationVariables.UCCFilingCost],
      }),
    );

    if (
      initialInstallationAddress.street_address !== data[ApplicationVariables.InstallationStreetAddress] ||
      initialInstallationAddress.state !== data[ApplicationVariables.InstallationState] ||
      initialInstallationAddress.zip !== data[ApplicationVariables.InstallationZipCode]
    ) {
      propertyType = await dispatch(
        getPropertyOwners({
          street: data[ApplicationVariables.InstallationStreetAddress],
          state: data[ApplicationVariables.InstallationState],
          zipCode: data[ApplicationVariables.InstallationZipCode],
        }),
      ).unwrap();
    }

    if (propertyType) {
      await dispatch(
        setApplicationData({
          [ApplicationVariables.PropertyTypeCheckRequired]: checkPropertyType(propertyType.toLocaleLowerCase(), true),
        }),
      );

      if (checkPropertyType(propertyType.toLocaleLowerCase())) {
        dispatch(setCurrentStage({ stage: StagesType.Decline }));
        return;
      }
    } else {
      await dispatch(
        setApplicationData({
          [ApplicationVariables.OwnerInformationCorrect]: false,
          [ApplicationVariables.BorrowersEqualsOwners]: false,
          [ApplicationVariables.PropertyTypeCheckRequired]: false,
        }),
      );
    }

    if (!coborrowerApplication) {
      await dispatch(
        runStrategy({
          strategyName: StrategyName.EligibilityCheck2,
          strategyInputVariables: {
            [UserInformationKeys.OwnershipStatus]: data[ApplicationVariables.BorrowerOwnership]
              .value as OwnershipStatus,
            [ApplicationVariables.CoborrowerApplication]: false,
          },
        }),
      );
    }

    dispatch(nextStep());
  };

  const handleInstallationAddressChange = async (name: string, value: string) => {
    setValue(name, value);
    if (watcher[ApplicationVariables.BorrowerContactAddressSameAsInstall]) {
      handleContactAddressChange(InstallationToContactAddress[name], value);
    }
    if (errors[name]?.message) clearErrors(name);
    return trigger(name);
  };

  const handleContactAddressChange = async (name: string, value: string) => {
    setValue(name, value);
    if (errors[name]?.message) clearErrors(name);
    return trigger(name);
  };

  const handleContactAddressSame = () => {
    const newValue = !watcher[ApplicationVariables.BorrowerContactAddressSameAsInstall];
    setValue(ApplicationVariables.BorrowerContactAddressSameAsInstall, newValue);
    if (newValue) {
      handleContactAddressChange(
        ApplicationVariables.BorrowerContactStreetAddress,
        watcher[ApplicationVariables.InstallationStreetAddress],
      );
      handleContactAddressChange(
        ApplicationVariables.BorrowerContactCity,
        watcher[ApplicationVariables.InstallationCity],
      );
      handleContactAddressChange(
        ApplicationVariables.BorrowerContactState,
        watcher[ApplicationVariables.InstallationState],
      );
      handleContactAddressChange(
        ApplicationVariables.BorrowerContactZipCode,
        watcher[ApplicationVariables.InstallationZipCode],
      );
    }
  };

  const isSubmitInactive = () => {
    const fields =
      !watcher[ApplicationVariables.InstallationStreetAddress] ||
      !watcher[ApplicationVariables.InstallationCity] ||
      !watcher[ApplicationVariables.InstallationState] ||
      !watcher[ApplicationVariables.InstallationZipCode] ||
      !watcher[ApplicationVariables.BorrowerOwnership] ||
      !watcher[ApplicationVariables.BorrowerContactStreetAddress] ||
      !watcher[ApplicationVariables.BorrowerContactCity] ||
      !watcher[ApplicationVariables.BorrowerContactState] ||
      !watcher[ApplicationVariables.BorrowerContactZipCode] ||
      (isSubmitted && isExistErrors(errors));

    if (mortgagePaymentNeeded) {
      return fields || watcher[ApplicationVariables.MonthlyMortgagePayment] === null;
    }

    return fields;
  };

  const handleOwnershipChange = (value: OptionType) => {
    setValue(ApplicationVariables.BorrowerOwnership, value);
    setMortgagePaymentNeeded(value.value === OwnershipStatus.OwnWithMortgage);
  };

  const handleBackClick = () =>
    dispatch(
      setCurrentStage({
        stage: StagesType.CoborrowerInfo,
        step: coborrowerApplication ? CoborrowerInfoSteps.CoborrowerContactInfo : CoborrowerInfoSteps.NumberOfBorrowers,
      }),
    );

  return (
    <>
      {!loaded && <PageLoader />}
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.textInputLabel}>What is the address where the installation will take place?</div>
        <div className={styles.inputContainer}>
          {loaded && (
            <SearchLocationInput
              control={control}
              addressValue={{
                street_address: watcher[ApplicationVariables.InstallationStreetAddress],
                city: watcher[ApplicationVariables.InstallationCity],
                state: watcher[ApplicationVariables.InstallationState],
                zip: watcher[ApplicationVariables.InstallationZipCode],
              }}
              errors={isSubmitted ? errors : []}
              addressLabel="Installation street address"
              handleAddressChange={handleInstallationAddressChange}
              inputNames={{
                street_address: ApplicationVariables.InstallationStreetAddress,
                zip: ApplicationVariables.InstallationZipCode,
                city: ApplicationVariables.InstallationCity,
                state: ApplicationVariables.InstallationState,
              }}
            />
          )}
        </div>
        <div className={styles.textInputLabel}>What is your ownership status?</div>
        <div className={cx(styles.dropdownContainer, styles.inputContainer)}>
          <DropDown
            control={control}
            className={styles.inputChildContainer}
            name={ApplicationVariables.BorrowerOwnership}
            onValuePicked={handleOwnershipChange}
            selectedOption={watcher[ApplicationVariables.BorrowerOwnership]}
            options={ownershipList}
            placeholder="Ownership status"
            isSearchable={false}
            invalid={!!errors[ApplicationVariables.BorrowerOwnership]?.message}
            onChange={() => clearErrors(ApplicationVariables.BorrowerOwnership)}
          />
          {!!errors[ApplicationVariables.BorrowerOwnership]?.message && (
            <InputErrorMessage>{errors[ApplicationVariables.BorrowerOwnership]?.message}</InputErrorMessage>
          )}
        </div>

        {mortgagePaymentNeeded && (
          <>
            <div className={styles.textInputLabel}>
              What is your monthly mortgage payment? Please include any tax or insurance payments.
            </div>
            <div className={styles.inputContainer}>
              <MonetaryInput
                label="Monthly mortgage payment"
                control={control}
                name={ApplicationVariables.MonthlyMortgagePayment}
                value={watcher[ApplicationVariables.MonthlyMortgagePayment]?.toString()}
                errorMessage={errors[ApplicationVariables.MonthlyMortgagePayment]?.message}
                handleChange={() => clearErrors(ApplicationVariables.MonthlyMortgagePayment)}
              />
            </div>
          </>
        )}

        <div className={styles.inputContainer}>
          <Checkbox
            checked={watcher[ApplicationVariables.BorrowerContactAddressSameAsInstall]}
            onChange={handleContactAddressSame}
            label="Contact address same as install"
          />
        </div>
        {loaded && (
          <SearchLocationInput
            control={control}
            addressValue={{
              street_address: watcher[ApplicationVariables.BorrowerContactStreetAddress],
              city: watcher[ApplicationVariables.BorrowerContactCity],
              state: watcher[ApplicationVariables.BorrowerContactState],
              zip: watcher[ApplicationVariables.BorrowerContactZipCode],
            }}
            addressLabel="Contact street address"
            handleAddressChange={handleContactAddressChange}
            errors={isSubmitted ? errors : []}
            inputNames={{
              street_address: ApplicationVariables.BorrowerContactStreetAddress,
              zip: ApplicationVariables.BorrowerContactZipCode,
              city: ApplicationVariables.BorrowerContactCity,
              state: ApplicationVariables.BorrowerContactState,
            }}
            disabled={watcher[ApplicationVariables.BorrowerContactAddressSameAsInstall]}
          />
        )}
        <NavigationButtonWrapper>
          <BackButton onClick={handleBackClick} />
          <NextButton type="submit" inactive={isSubmitInactive()} loading={loading} />
        </NavigationButtonWrapper>
      </FormContainer>
    </>
  );
};

export default BorrowerAddressInfo;
