export enum StagesType {
  BasicInformation = 'BasicInformation',
  PhoneVerification = 'PhoneVerification',
  Installation = 'Installation',
  CoborrowerInfo = 'CoborrowerInfo',
  OwnersCheck = 'OwnersCheck',
  Employment = 'Employment',
  CoborrowerEmployment = 'CoborrowerEmployment',
  Offers = 'Offers',
  EmailVerification = 'EmailVerification',
  Decline = 'Decline',
  ContactUs = 'ContactUs',
  CheckRateAndPayments = 'CheckRateAndPayments',
  SSN = 'SSN',
  CreateAccount = 'CreateAccount',
  SCICode = 'SCICode',
  SalespersonInfo = 'SalespersonInfo',
  UploadDocuments = 'UploadDocuments',
  CreditApproval = 'CreditApproval',
}
