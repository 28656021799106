import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';
import { UserType } from 'enums/UserType';
import { setCustomerData } from 'handlers/customer';
import { LoadingType, setLoadingState } from 'handlers/loading';

export interface LoginProps {
  email: string;
  password: string;
}

export const createLoginBorrowerThunk = (accountApi: AccountApi) =>
  createAsyncThunk('account/borrowerLogin', async ({ email, password }: LoginProps, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
      const result = await accountApi.loginBorrower(email, password);
      sessionStorage.setItem('userType', UserType.Borrower);
      localStorage.setItem('borrowerToken', result!.accessToken);
      localStorage.setItem('borrowerRefreshToken', result!.refreshToken);
      const parsedJwt = JSON.parse(atob(result!.accessToken.split('.')[1]));
      dispatch(
        setCustomerData({
          customerId: parsedJwt.borrowerId as string,
          borrower_email: email,
          isMfaIncomplete: result.isMfaIncomplete,
          isEmailNotVerified: result.isEmailNotVerified,
        }),
      );
      return result;
    } catch (e) {
      rejectWithValue(null);
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
    }
  });
