import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import DropZone from 'components/DropZone';
import { IFileWithError } from 'components/DropZone/DropZone';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { ApplicationVariables } from 'handlers/application/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import styles from 'pages/BorrowerPortal/BorrowerDocuments.module.scss';
import { getIsLoading } from 'selectors';
import { useAppDispatch } from 'store';
import { getApplicationByDisplayId, updateApplication, uploadDocuments } from 'thunks';
import { Application, Routes } from 'types';
import { getCurrentDate } from 'utils/dateHelper';

import { Notify } from './types';

const ContractorDocuments = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const loading = useSelector(getIsLoading);
  const { id } = useParams<{ id: string }>();

  const [incomeFiles, setIncomeFiles] = useState<IFileWithError[]>([]);
  const [driverFiles, setDriverFiles] = useState<IFileWithError[]>([]);
  const [additionalFiles, setAdditionalFiles] = useState<IFileWithError[]>([]);
  const [application, setApplication] = useState<Application | null>(null);

  const applicationId = application?.id as string;
  const coborrowerApplication = application?.variables[ApplicationVariables.CoborrowerApplication];

  const getApplication = async () => {
    setApplication(await dispatch(getApplicationByDisplayId(id)).unwrap());
  };

  const checkFiles = () => [...incomeFiles, ...driverFiles, ...additionalFiles].filter((file) => file.error).length;

  const isValid = () => !(incomeFiles.length || driverFiles.length || additionalFiles.length) || !!checkFiles();

  const backClick = () => history.push(Routes.ContractorManageDeal.replace(':id', id));

  const getApplicationVariables = () => {
    const applicationVariables = {};
    if (incomeFiles.length) {
      Object.assign(applicationVariables, {
        [ApplicationVariables.UploadBorrowerVoiComplete]: true,
        [ApplicationVariables.UploadBorrowerVoiCompleteDate]: getCurrentDate(),
      });
      if (coborrowerApplication) {
        Object.assign(applicationVariables, {
          [ApplicationVariables.UploadCoborrowerVoiComplete]: true,
          [ApplicationVariables.UploadCoborrowerVoiCompleteDate]: getCurrentDate(),
        });
      }
    }
    if (driverFiles.length) {
      Object.assign(applicationVariables, {
        [ApplicationVariables.UploadBorrowerIdComplete]: true,
        [ApplicationVariables.UploadBorrowerIdCompleteDate]: getCurrentDate(),
      });
      if (coborrowerApplication) {
        Object.assign(applicationVariables, {
          [ApplicationVariables.UploadCoborrowerIdComplete]: true,
          [ApplicationVariables.UploadCoborrowerIdCompleteDate]: getCurrentDate(),
        });
      }
    }
    if (additionalFiles.length) {
      Object.assign(applicationVariables, {
        [ApplicationVariables.UploadAdditionalDocumentsComplete]: true,
        [ApplicationVariables.UploadAdditionalDocumentsCompleteDate]: getCurrentDate(),
      });
    }
    return applicationVariables;
  };

  const onSubmit = async () => {
    await dispatch(
      uploadDocuments({
        applicationId,
        files: [...incomeFiles, ...driverFiles, ...additionalFiles],
      }),
    );
    await dispatch(
      updateApplication({
        applicationId,
        applicationVariables: getApplicationVariables(),
      }),
    );
    history.push(Routes.ContractorManageDeal.replace(':id', id), { notify: Notify.DocumentsUploaded });
  };

  useEffect(() => {
    if (id) getApplication();
    return () => setApplication(null);
  }, [id]);

  return (
    <>
      <p>Please drag the required files to the designated area or just click ‘browse for files’</p>
      <DropZone
        files={incomeFiles}
        setFiles={setIncomeFiles}
        titleName="Verification of income"
        dropZoneStyle={styles.dropZoneMargin}
        fileName="VOI-from-Portal"
      />
      <DropZone
        files={driverFiles}
        setFiles={setDriverFiles}
        titleName="Photo ID"
        dropZoneStyle={styles.dropZoneMargin}
        fileName="ID-from-Portal"
      />
      <DropZone
        files={additionalFiles}
        setFiles={setAdditionalFiles}
        titleName="Additional documents (anything not listed above)"
        dropZoneStyle={styles.dropZoneMargin}
        fileName="ADD-from-Portal"
      />
      <NavigationButtonWrapper>
        <BackButton className={commonStyles.portalBackButton} onClick={backClick} />
        <NextButton title="Submit" onClick={onSubmit} disabled={isValid()} loading={loading} />
      </NavigationButtonWrapper>
    </>
  );
};
export default withPortalLayout(ContractorDocuments);
