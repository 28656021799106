import React, { FC } from 'react';

import classnames from 'classnames/bind';

import styles from './TextInput.module.scss';

const cx = classnames.bind(styles);

interface InputLabelProps {
  inputType: 'smallInputLabel' | 'defaultInputLabel';
  active?: boolean;
  disabled?: boolean;
}

enum InputType {
  disabledDefaultInputLabel = 'disabledDefaultInputLabel',
  disabledSmallInputLabel = 'disabledSmallInputLabel',
}

const defaultInputLabel = 'defaultInputLabel';

const InputLabel: FC<InputLabelProps> = ({ children, active, disabled, inputType }) => (
  <label
    className={cx(styles.label, styles[inputType], {
      [styles.activeInputLabel]: active,
      [styles[
        inputType !== defaultInputLabel ? InputType.disabledSmallInputLabel : InputType.disabledDefaultInputLabel
      ]]: disabled,
    })}
  >
    {children}
  </label>
);

export default InputLabel;
