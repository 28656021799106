import React, { useEffect, useState } from 'react';

import classnames from 'classnames/bind';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import MonetaryInput from 'common/components/MonetaryInput/MonetaryInput';
import NumberInput from 'common/components/NumberInput';
import TextInput from 'common/components/TextInput';
import DropZone from 'components/DropZone';
import { IFileWithError } from 'components/DropZone/DropZone';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import {
  getInvalidBatteryErrorMessage,
  getInvalidKilowattsErrorMessage,
  getInvalidTotalAmountErrorMessage,
  getInvalidTotalMinusAmountErrorMessage,
  getRequiredErrorMessage,
} from 'errors/errors';
import { ApplicationVariables } from 'handlers/application/types';
import { UserInformationKeys } from 'handlers/customer/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import pages from 'pages/pages.module.scss';
import { getApplicationId, getIsLoading } from 'selectors';
import { useAppDispatch } from 'store';
import { getApplicationByDisplayId, runStrategy, updateApplication, uploadDocuments } from 'thunks';
import { Routes, StrategyName } from 'types';
import { getCurrentDate } from 'utils/dateHelper';
import { isExistErrors } from 'utils/validationHelper';

import styles from './ProjectDetails.module.scss';
import { Notify } from './types';

const MAX_VALUE = 100;
const MIN_VALUE = 0;
const MAX_INPUT_LENGTH = 100;
const ALLOW_NEGATIVE = true;

const cx = classnames.bind(styles);

enum ProjectValues {
  pvKilowatts = 'pvKilowatts',
  solarPanelsCost = 'solarPanelsCost',
  batterySize = 'batterySize',
  energyStorageCost = 'energyStorageCost',
  roofingRepairs = 'roofingRepairs',
  roofingRepairsCost = 'roofingRepairsCost',
  makeAndModel = 'makeAndModel',
  heatingAndCoolingCost = 'heatingAndCoolingCost',
  otherEnergy = 'otherEnergy',
  otherEnergyCost = 'otherEnergyCost',
  total = 'total',
  outsideAmount = 'outsideAmount',
  totalMinus = 'totalMinus',
  requestAmount = 'requestAmount',
}

enum ValuesLabels {
  pvKilowatts = 'PV kilowatts',
  solarPanelsCost = 'Solar system cost',
  batterySize = 'Battery size in kilowatt hours',
  energyStorageCost = 'Energy storage cost',
  roofingRepairsCost = 'Roofing repairs cost',
  makeAndModel = 'Make and model',
  heatingAndCoolingCost = 'Heating and cooling cost',
  otherEnergyCost = 'Other energy efficiency cost',
  description = 'Description',
}

interface IApplicationState {
  applicationId: string;
  loanAmount: string;
  firstName: string;
  lastName: string;
}

const initialState: IApplicationState = {
  applicationId: '',
  loanAmount: '',
  firstName: '',
  lastName: '',
};

const ProjectDetails = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const [files, setFiles] = useState<IFileWithError[]>([]);
  const [total, setTotal] = useState('');
  const [totalMinus, setTotalMinus] = useState('');
  const [app, setApp] = useState(initialState);
  const applicationId = useSelector(getApplicationId);

  const loading = useSelector(getIsLoading);
  const {
    watch,
    register,
    clearErrors,
    setValue,
    trigger,
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<FieldValues>({
    defaultValues: {
      [ProjectValues.pvKilowatts]: '',
      [ProjectValues.solarPanelsCost]: '',
      [ProjectValues.batterySize]: '',
      [ProjectValues.energyStorageCost]: '',
      [ProjectValues.roofingRepairs]: '',
      [ProjectValues.roofingRepairsCost]: '',
      [ProjectValues.makeAndModel]: '',
      [ProjectValues.heatingAndCoolingCost]: '',
      [ProjectValues.otherEnergy]: '',
      [ProjectValues.otherEnergyCost]: '',
      [ProjectValues.outsideAmount]: '',
    },
    mode: 'onSubmit',
    reValidateMode: undefined,
  });

  const watcher = watch();

  const isNullField = (field: number | string) => (!field ? null : field);

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    if (!files.length) return;
    await dispatch(
      uploadDocuments({
        applicationId: app.applicationId,
        files,
      }),
    ).unwrap();
    await dispatch(
      updateApplication({
        applicationId: app.applicationId,
        applicationVariables: {
          [ApplicationVariables.PvKilowatts]: isNullField(Number(data[ProjectValues.pvKilowatts])),
          [ApplicationVariables.BatterySize]: isNullField(data[ProjectValues.batterySize]),
          [ApplicationVariables.RoofingRepairsCost]: isNullField(Number(data[ProjectValues.roofingRepairsCost])),
          [ApplicationVariables.RoofingRepairsDescription]: isNullField(data[ProjectValues.roofingRepairs]),
          [ApplicationVariables.MakeAndModel]: isNullField(data[ProjectValues.makeAndModel]),
          [ApplicationVariables.OtherEnergyEfficiencyDescription]: isNullField(data[ProjectValues.otherEnergy]),
          [ApplicationVariables.OtherEnergyEfficiencyCost]: isNullField(Number(data[ProjectValues.otherEnergyCost])),
          [ApplicationVariables.SolarPanelsCost]: isNullField(Number(data[ProjectValues.solarPanelsCost])),
          [ApplicationVariables.HeatingAndCoolingCost]: isNullField(Number(data[ProjectValues.heatingAndCoolingCost])),
          [ApplicationVariables.OutsideAmount]: isNullField(Number(data[ProjectValues.outsideAmount])),
          [ApplicationVariables.EnergyStorageCost]: isNullField(Number(data[ProjectValues.energyStorageCost])),
        },
      }),
    ).unwrap();

    const strategyResult = await dispatch(
      runStrategy({
        strategyName: StrategyName.AutomatedProjectCheck,
        applicationId,
        strategyInputVariables: {
          [ApplicationVariables.SolarPanelsCost]: isNullField(Number(data[ProjectValues.solarPanelsCost])),
          [ApplicationVariables.PvKilowatts]: isNullField(Number(data[ProjectValues.pvKilowatts])),
          [ApplicationVariables.RoofingRepairsCost]: isNullField(Number(data[ProjectValues.roofingRepairsCost])),
          [ApplicationVariables.TotalMinusOutsideAmount]: Number(totalMinus),
        },
      }),
    ).unwrap();

    const isConfirmProjectDetails = () => {
      if (strategyResult.declineReasons.length) return false;
      if (
        !Number(data[ProjectValues.solarPanelsCost]) &&
        !Number(data[ProjectValues.pvKilowatts]) &&
        !Number(data[ProjectValues.roofingRepairsCost])
      ) {
        return null;
      }
      return true;
    };

    await dispatch(
      updateApplication({
        applicationId: app.applicationId,
        applicationVariables: {
          [ApplicationVariables.ProjectDetailsAndSignedContractComplete]: true,
          [ApplicationVariables.ProjectDetailsAndSignedContractCompleteDate]: getCurrentDate(),
          [ApplicationVariables.ContractManualReviewConfirmed]: null,
          [ApplicationVariables.ConfirmProjectDetails]: null,
          [ApplicationVariables.ProjectCheckConfirmed]: isConfirmProjectDetails(),
          [ApplicationVariables.ProjectCheckConfirmedDate]: getCurrentDate(),
          [ApplicationVariables.PvCostPerWatt]: isNullField(
            strategyResult.outputVariables[ApplicationVariables.PvCostPerWatt],
          ),
          [ApplicationVariables.RoofingPercentage]: isNullField(
            strategyResult.outputVariables[ApplicationVariables.RoofingPercentage],
          ),
        },
      }),
    );
    history.push(generatePath(Routes.ContractorManageDeal, { id }), {
      notify: strategyResult.declined ? Notify.ProjectManualReview : Notify.ProjectSubmitted,
    });
  };

  const onBackClick = () => history.push(generatePath(Routes.ContractorManageDeal, { id }));

  const setDefaultValue = (name: ProjectValues, value: string | number) => {
    if (!value) return;
    setValue(name, String(value));
  };

  const getApplication = async () => {
    const application = await dispatch(getApplicationByDisplayId(id)).unwrap();
    setApp({
      applicationId: application.id,
      loanAmount: String(application.variables[ApplicationVariables.LoanAmount]),
      firstName: application.variables[UserInformationKeys.FirstName],
      lastName: application.variables[UserInformationKeys.LastName],
    });
    setDefaultValue(ProjectValues.pvKilowatts, application.variables[ApplicationVariables.PvKilowatts]);
    setDefaultValue(ProjectValues.solarPanelsCost, application.variables[ApplicationVariables.SolarPanelsCost]);
    setDefaultValue(ProjectValues.batterySize, application.variables[ApplicationVariables.BatterySize]);
    setDefaultValue(ProjectValues.energyStorageCost, application.variables[ApplicationVariables.EnergyStorageCost]);
    setDefaultValue(
      ProjectValues.roofingRepairs,
      application.variables[ApplicationVariables.RoofingRepairsDescription],
    );
    setDefaultValue(ProjectValues.roofingRepairsCost, application.variables[ApplicationVariables.RoofingRepairsCost]);
    setDefaultValue(ProjectValues.makeAndModel, application.variables[ApplicationVariables.MakeAndModel]);
    setDefaultValue(
      ProjectValues.heatingAndCoolingCost,
      application.variables[ApplicationVariables.HeatingAndCoolingCost],
    );
    setDefaultValue(
      ProjectValues.otherEnergy,
      application.variables[ApplicationVariables.OtherEnergyEfficiencyDescription],
    );
    setDefaultValue(
      ProjectValues.otherEnergyCost,
      application.variables[ApplicationVariables.OtherEnergyEfficiencyCost],
    );
    setDefaultValue(ProjectValues.outsideAmount, application.variables[ApplicationVariables.OutsideAmount]);
  };

  const isValidTotalSum = () => Number(total) !== MIN_VALUE;

  const isValidTotalMinusSum = () => Number(totalMinus) <= Number(app.loanAmount) && Number(totalMinus) >= MIN_VALUE;

  const isSubmitInactive = () =>
    !isValidTotalSum() ||
    !files.length ||
    Number(totalMinus) < MIN_VALUE ||
    !!files.filter((file) => file.error).length ||
    (isSubmitted && isExistErrors(errors));

  const isOneFieldEmpty = (currentSectionField: string, sectionField: string, errorMessage: string) => {
    if (!currentSectionField && !sectionField) return false;
    return getRequiredErrorMessage(errorMessage);
  };

  const isCorrectValueSize = (value: string) => Number(value) <= MAX_VALUE && Number(value) > MIN_VALUE;

  useEffect(() => {
    register(ProjectValues.pvKilowatts, {
      required: isOneFieldEmpty(
        watcher[ProjectValues.pvKilowatts],
        watcher[ProjectValues.solarPanelsCost],
        ValuesLabels.pvKilowatts,
      ),
      validate: (value: string) => {
        if (value.length) return !isCorrectValueSize(value) ? getInvalidKilowattsErrorMessage() : true;
      },
    });
    register(ProjectValues.outsideAmount, {
      required: false,
      validate: () => (!isValidTotalMinusSum() ? getInvalidTotalMinusAmountErrorMessage() : true),
    });
    register(ProjectValues.solarPanelsCost, {
      required: isOneFieldEmpty(
        watcher[ProjectValues.solarPanelsCost],
        watcher[ProjectValues.pvKilowatts],
        ValuesLabels.solarPanelsCost,
      ),
    });
    register(ProjectValues.energyStorageCost, {
      required: isOneFieldEmpty(
        watcher[ProjectValues.energyStorageCost],
        watcher[ProjectValues.batterySize],
        ValuesLabels.energyStorageCost,
      ),
    });
    register(ProjectValues.roofingRepairsCost, {
      required: isOneFieldEmpty(
        watcher[ProjectValues.roofingRepairsCost],
        watcher[ProjectValues.roofingRepairs],
        ValuesLabels.roofingRepairsCost,
      ),
    });
    register(ProjectValues.heatingAndCoolingCost, {
      required: isOneFieldEmpty(
        watcher[ProjectValues.heatingAndCoolingCost],
        watcher[ProjectValues.makeAndModel],
        ValuesLabels.heatingAndCoolingCost,
      ),
    });
    register(ProjectValues.otherEnergyCost, {
      required: isOneFieldEmpty(
        watcher[ProjectValues.otherEnergyCost],
        watcher[ProjectValues.otherEnergy],
        ValuesLabels.otherEnergyCost,
      ),
    });
    register(ProjectValues.total, {
      required: false,
      validate: () => (!isValidTotalSum() ? getInvalidTotalAmountErrorMessage() : true),
    });
    register(ProjectValues.batterySize, {
      required: isOneFieldEmpty(
        watcher[ProjectValues.batterySize],
        watcher[ProjectValues.energyStorageCost],
        ValuesLabels.batterySize,
      ),
      validate: (value: string) => {
        if (value.length) return !isCorrectValueSize(value) ? getInvalidBatteryErrorMessage() : true;
      },
    });
  }, [register, watcher, total, totalMinus]);

  useEffect(() => {
    getApplication();
    return () => setApp({} as IApplicationState);
  }, [id]);

  useEffect(() => {
    const isFieldsEmpty =
      watcher[ProjectValues.solarPanelsCost] ||
      watcher[ProjectValues.energyStorageCost] ||
      watcher[ProjectValues.roofingRepairsCost] ||
      watcher[ProjectValues.heatingAndCoolingCost] ||
      watcher[ProjectValues.otherEnergyCost];
    const totalResult = String(
      Number(watcher[ProjectValues.energyStorageCost]) +
        Number(watcher[ProjectValues.otherEnergyCost]) +
        Number(watcher[ProjectValues.roofingRepairsCost]) +
        Number(watcher[ProjectValues.solarPanelsCost]) +
        Number(watcher[ProjectValues.heatingAndCoolingCost]),
    );
    setTotal(isFieldsEmpty ? totalResult : '');
    setTotalMinus(isFieldsEmpty ? String(Number(total) - Number(watcher[ProjectValues.outsideAmount])) : '');
  }, [
    total,
    watcher[ProjectValues.solarPanelsCost],
    watcher[ProjectValues.energyStorageCost],
    watcher[ProjectValues.roofingRepairsCost],
    watcher[ProjectValues.heatingAndCoolingCost],
    watcher[ProjectValues.otherEnergyCost],
    watcher[ProjectValues.outsideAmount],
  ]);

  const checkField = (emptyField: ProjectValues, field: ProjectValues) => {
    trigger(emptyField);
    clearErrors(field);
  };

  const validateForm = () => {
    if (watcher[ProjectValues.pvKilowatts] && !watcher[ProjectValues.solarPanelsCost]) {
      if (isCorrectValueSize(watcher[ProjectValues.pvKilowatts])) {
        checkField(ProjectValues.solarPanelsCost, ProjectValues.pvKilowatts);
      } else {
        trigger(ProjectValues.pvKilowatts);
        trigger(ProjectValues.solarPanelsCost);
      }
    } else checkField(ProjectValues.pvKilowatts, ProjectValues.solarPanelsCost);

    if (watcher[ProjectValues.batterySize] && !watcher[ProjectValues.energyStorageCost]) {
      if (isCorrectValueSize(watcher[ProjectValues.batterySize])) {
        checkField(ProjectValues.energyStorageCost, ProjectValues.batterySize);
      } else {
        trigger(ProjectValues.energyStorageCost);
        trigger(ProjectValues.batterySize);
      }
    } else checkField(ProjectValues.batterySize, ProjectValues.energyStorageCost);

    if (watcher[ProjectValues.roofingRepairs] && !watcher[ProjectValues.roofingRepairsCost]) {
      checkField(ProjectValues.roofingRepairsCost, ProjectValues.roofingRepairs);
    } else checkField(ProjectValues.roofingRepairs, ProjectValues.roofingRepairsCost);

    if (watcher[ProjectValues.makeAndModel] && !watcher[ProjectValues.heatingAndCoolingCost]) {
      checkField(ProjectValues.heatingAndCoolingCost, ProjectValues.makeAndModel);
    } else checkField(ProjectValues.makeAndModel, ProjectValues.heatingAndCoolingCost);

    if (watcher[ProjectValues.otherEnergy] && !watcher[ProjectValues.otherEnergyCost]) {
      checkField(ProjectValues.otherEnergyCost, ProjectValues.otherEnergy);
    } else checkField(ProjectValues.otherEnergy, ProjectValues.otherEnergyCost);
  };

  useEffect(() => {
    if (isSubmitted) {
      if (!isValidTotalSum()) trigger(ProjectValues.total);
      else clearErrors(ProjectValues.total);
    }
  }, [total]);

  useEffect(() => {
    if (isSubmitted) {
      if (!isValidTotalMinusSum()) trigger(ProjectValues.outsideAmount);
      else clearErrors(ProjectValues.outsideAmount);
    }
  }, [totalMinus]);

  useEffect(() => {
    if (isSubmitted) validateForm();
  }, [
    watcher[ProjectValues.solarPanelsCost],
    watcher[ProjectValues.pvKilowatts],
    watcher[ProjectValues.batterySize],
    watcher[ProjectValues.energyStorageCost],
    watcher[ProjectValues.roofingRepairs],
    watcher[ProjectValues.roofingRepairsCost],
    watcher[ProjectValues.makeAndModel],
    watcher[ProjectValues.heatingAndCoolingCost],
    watcher[ProjectValues.otherEnergy],
    watcher[ProjectValues.otherEnergyCost],
  ]);

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <p className={styles.subTitle}>
        Borrower: {app.firstName} {app.lastName}
      </p>
      <div className={styles.inputContainer}>
        <div className={cx(styles.title, styles.projectTitle)}>Solar System</div>
        <NumberInput
          value={watcher[ProjectValues.pvKilowatts]}
          label={ValuesLabels.pvKilowatts}
          required={false}
          control={control}
          inputType="smallInput"
          name={ProjectValues.pvKilowatts}
          errorMessage={errors[ProjectValues.pvKilowatts]?.message}
          errorBackgroundType="portalErrorBackground"
        />
        <MonetaryInput
          value={watcher[ProjectValues.solarPanelsCost]}
          label={`${ValuesLabels.solarPanelsCost}, $`}
          control={control}
          inputType="smallInput"
          name={ProjectValues.solarPanelsCost}
          errorMessage={errors[ProjectValues.solarPanelsCost]?.message}
          errorBackgroundType="portalErrorBackground"
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={cx(styles.title, styles.projectTitle)}>Energy storage</div>
        <NumberInput
          inputContainerClassName={pages.inputChildContainer}
          value={watcher[ProjectValues.batterySize]}
          label={ValuesLabels.batterySize}
          control={control}
          inputType="smallInput"
          name={ProjectValues.batterySize}
          errorMessage={errors[ProjectValues.batterySize]?.message}
          errorBackgroundType="portalErrorBackground"
        />
        <MonetaryInput
          inputContainerClassName={pages.inputChildContainer}
          value={watcher[ProjectValues.energyStorageCost]}
          label={`${ValuesLabels.energyStorageCost}, $`}
          control={control}
          inputType="smallInput"
          errorMessage={errors[ProjectValues.energyStorageCost]?.message}
          name={ProjectValues.energyStorageCost}
          errorBackgroundType="portalErrorBackground"
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={cx(styles.title, styles.projectTitle)}>Roofing repairs</div>
        <TextInput
          {...register(ProjectValues.roofingRepairs, {
            required: isOneFieldEmpty(
              watcher[ProjectValues.roofingRepairs],
              watcher[ProjectValues.roofingRepairsCost],
              ValuesLabels.description,
            ),
          })}
          inputContainerClassName={pages.inputChildContainer}
          value={watcher[ProjectValues.roofingRepairs]}
          errorMessage={errors[ProjectValues.roofingRepairs]?.message}
          label={ValuesLabels.description}
          inputType="smallInput"
          maxLength={MAX_INPUT_LENGTH}
          name={ProjectValues.roofingRepairs}
          errorBackgroundType="portalErrorBackground"
        />
        <MonetaryInput
          inputContainerClassName={pages.inputChildContainer}
          value={watcher[ProjectValues.roofingRepairsCost]}
          errorMessage={errors[ProjectValues.roofingRepairsCost]?.message}
          label={`${ValuesLabels.roofingRepairsCost}, $`}
          control={control}
          name={ProjectValues.roofingRepairsCost}
          inputType="smallInput"
          errorBackgroundType="portalErrorBackground"
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={cx(styles.title, styles.projectTitle)}>Heating and cooling upgrades</div>
        <TextInput
          {...register(ProjectValues.makeAndModel, {
            required: isOneFieldEmpty(
              watcher[ProjectValues.makeAndModel],
              watcher[ProjectValues.heatingAndCoolingCost],
              ValuesLabels.makeAndModel,
            ),
          })}
          inputContainerClassName={pages.inputChildContainer}
          value={watcher.makeAndModel}
          errorMessage={errors[ProjectValues.makeAndModel]?.message}
          label={ValuesLabels.makeAndModel}
          inputType="smallInput"
          maxLength={MAX_INPUT_LENGTH}
          name={ProjectValues.makeAndModel}
          errorBackgroundType="portalErrorBackground"
        />
        <MonetaryInput
          inputContainerClassName={pages.inputChildContainer}
          value={watcher.heatingAndCoolingCost}
          errorMessage={errors[ProjectValues.heatingAndCoolingCost]?.message}
          label={`${ValuesLabels.heatingAndCoolingCost}, $`}
          control={control}
          name={ProjectValues.heatingAndCoolingCost}
          inputType="smallInput"
          errorBackgroundType="portalErrorBackground"
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={cx(styles.title, styles.projectTitle)}>Other energy efficiency</div>
        <TextInput
          {...register(ProjectValues.otherEnergy, {
            required: isOneFieldEmpty(
              watcher[ProjectValues.otherEnergy],
              watcher[ProjectValues.otherEnergyCost],
              ValuesLabels.description,
            ),
          })}
          value={watcher[ProjectValues.otherEnergy]}
          errorMessage={errors[ProjectValues.otherEnergy]?.message}
          label={ValuesLabels.description}
          name={ProjectValues.otherEnergy}
          inputType="smallInput"
          maxLength={MAX_INPUT_LENGTH}
          errorBackgroundType="portalErrorBackground"
        />
        <MonetaryInput
          inputContainerClassName={pages.inputChildContainer}
          value={watcher[ProjectValues.otherEnergyCost]}
          errorMessage={errors[ProjectValues.otherEnergyCost]?.message}
          label={`${ValuesLabels.otherEnergyCost}, $`}
          control={control}
          name={ProjectValues.otherEnergyCost}
          inputType="smallInput"
          errorBackgroundType="portalErrorBackground"
        />
      </div>
      <div className={cx(styles.inputContainer, styles.marginTop)}>
        <div className={styles.title}>Total</div>
        <MonetaryInput
          value={total}
          label="$"
          control={control}
          name={ProjectValues.total}
          inputType="smallInput"
          errorMessage={errors[ProjectValues.total]?.message}
          errorBackgroundType="portalErrorBackground"
          disabled
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>Amount to be paid outside of ezSolarLoan</div>
        <MonetaryInput
          value={watcher[ProjectValues.outsideAmount]}
          label="$"
          control={control}
          name={ProjectValues.outsideAmount}
          inputType="smallInput"
          errorBackgroundType="portalErrorBackground"
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>Total minus outside amount</div>
        <MonetaryInput
          value={totalMinus}
          label="$"
          errorMessage={errors[ProjectValues.outsideAmount]?.message}
          control={control}
          name={ProjectValues.totalMinus}
          inputType="smallInput"
          allowNegative={ALLOW_NEGATIVE}
          disabled
        />
      </div>
      <div className={styles.inputContainer}>
        <div className={styles.title}>ezSolarLoan application amount</div>
        <MonetaryInput
          value={app.loanAmount}
          label="$"
          control={control}
          name={ProjectValues.requestAmount}
          inputType="smallInput"
          disabled
        />
      </div>
      <DropZone
        files={files}
        setFiles={setFiles}
        titleName="Signed contract"
        titleStyle={styles.marginTop}
        fileName="Signed Contract"
      />
      <NavigationButtonWrapper>
        <BackButton onClick={onBackClick} className={commonStyles.portalBackButton} />
        <NextButton title="Submit" type="submit" inactive={isSubmitInactive()} loading={loading} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default withPortalLayout(ProjectDetails);
