import { createAsyncThunk } from '@reduxjs/toolkit';

import ApplicationApi from 'api/ApplicationApi';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface IDownloadDocument {
  applicationId: string;
  documentName: string;
}

export const createDownloadDocumentThunk = (applicationApi: ApplicationApi) =>
  createAsyncThunk(
    'application/download-documents',
    async ({ applicationId, documentName }: IDownloadDocument, { dispatch }) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: true }));
        return await applicationApi.downloadDocument(applicationId, documentName);
      } catch (e) {
        return undefined;
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: false }));
      }
    },
  );
