import React, { useEffect, useState } from 'react';

import cnBind from 'classnames/bind';
import { Control } from 'react-hook-form';

import { ReactComponent as ValidIcon } from 'assets/icons/CheckmarkSlim.svg';
import { ReactComponent as InvalidIcon } from 'assets/icons/Close.svg';
import { ReactComponent as ResendIcon } from 'assets/icons/ResendIcon.svg';
import NumberInput from 'common/components/NumberInput';
import styles from 'pages/pages.module.scss';
import { notify } from 'utils/notificationHelper';

const VERIFICATION_CODE_LENGTH = 6;
const RESEND_CODE_NOTIFICATION = 'The code was successfully sent';
const cx = cnBind.bind(styles);

interface ValidationMessageProps {
  value: string;
  icon: JSX.Element;
  className: string;
}

export const ValidationMessage = ({ value, icon, className }: ValidationMessageProps) => (
  <div className={cx(styles.codeValidationMessage, className)}>
    {icon}
    {value}
  </div>
);

interface CodeVerificationProps {
  control: Control<any>;
  onChange: (value: string) => void;
  resendCode: () => void;
  inputContainerClassname?: string;
  notificationWhiteBackground?: boolean;
  loading: boolean;
  isCodeValid: boolean;
  caption?: string;
}

const CodeVerification = ({
  onChange,
  resendCode,
  loading,
  isCodeValid,
  inputContainerClassname,
  notificationWhiteBackground = true,
  control,
  caption,
}: CodeVerificationProps) => {
  const [seconds, setSeconds] = useState(59);
  const [value, setValue] = useState('');
  const [codeEntered, setCodeEntered] = useState<boolean>(false);

  useEffect(() => {
    if (seconds !== 0) {
      const timer = setTimeout(() => setSeconds(seconds - 1), 1000);
      return () => clearTimeout(timer);
    }
  }, [seconds]);

  useEffect(() => {
    if (value.length === VERIFICATION_CODE_LENGTH) {
      setCodeEntered(true);
      onChange(value);
    } else {
      setCodeEntered(false);
    }
  }, [value]);

  useEffect(() => {
    if (isCodeValid) {
      setSeconds(0);
    }
  }, [isCodeValid]);

  const handleResendCode = async () => {
    await resendCode();
    notify({ notification: RESEND_CODE_NOTIFICATION, whiteBackground: notificationWhiteBackground });
    setSeconds(59);
  };

  return (
    <>
      <div className={cx(styles.codeVerificationContainer, inputContainerClassname)}>
        <NumberInput
          control={control}
          inputContainerClassName={styles.codeInput}
          name="Code"
          label="Code"
          value={value}
          disabled={!!isCodeValid}
          maxLength={VERIFICATION_CODE_LENGTH}
          handleChange={setValue}
          allowLeadingZeros
        />
        {!loading &&
          codeEntered &&
          (isCodeValid ? (
            <ValidationMessage className={styles.codeValid} value="Correct code" icon={<ValidIcon />} />
          ) : (
            <ValidationMessage className={styles.codeInvalid} value="Invalid code" icon={<InvalidIcon />} />
          ))}
      </div>
      {caption && <p className={styles.caption}>{caption}</p>}
      <div className={cx(styles.resendButtonWrapper, { [styles.resendButtonWrapperDisabled]: isCodeValid })}>
        <ResendIcon />
        {!seconds ? (
          <div className={styles.resendButton} onClick={handleResendCode}>
            Resend code
          </div>
        ) : (
          <div>
            Resend code in 00:{seconds < 10 && '0'}
            {seconds}
          </div>
        )}
      </div>
    </>
  );
};

export default CodeVerification;
