import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserInformationKeys } from './types';

interface CustomerState {
  customerId: string;
  isMfaIncomplete: boolean | null;
  isEmailNotVerified: boolean | null;
  isLocked: boolean | null;
  [UserInformationKeys.Email]: string;
  [UserInformationKeys.PhoneNumber]: string;
  [UserInformationKeys.FirstName]: string;
  [UserInformationKeys.LastName]: string;
  [UserInformationKeys.DateOfBirth]: string;
  [UserInformationKeys.CitizenshipStatus]: string;
  [UserInformationKeys.PrivacyAndTermsSigned]: boolean;
  [UserInformationKeys.PolicyAgreement]: boolean;
  [UserInformationKeys.SSN]: string;
  [UserInformationKeys.ZipOrPostalCode]: string;
  [UserInformationKeys.State]: string;
  [UserInformationKeys.City]: string;
  [UserInformationKeys.StreetAddress]: string;
}

const initialState: CustomerState = {
  customerId: '',
  isMfaIncomplete: null,
  isEmailNotVerified: null,
  isLocked: null,
  [UserInformationKeys.Email]: '',
  [UserInformationKeys.PhoneNumber]: '',
  [UserInformationKeys.FirstName]: '',
  [UserInformationKeys.LastName]: '',
  [UserInformationKeys.DateOfBirth]: '',
  [UserInformationKeys.CitizenshipStatus]: '',
  [UserInformationKeys.PrivacyAndTermsSigned]: false,
  [UserInformationKeys.PolicyAgreement]: false,
  [UserInformationKeys.SSN]: '',
  [UserInformationKeys.ZipOrPostalCode]: '',
  [UserInformationKeys.State]: '',
  [UserInformationKeys.City]: '',
  [UserInformationKeys.StreetAddress]: '',
};

const customer = createSlice({
  name: 'customer',
  initialState,
  reducers: {
    setCustomerData: (state: CustomerState, { payload }: PayloadAction<Partial<CustomerState>>) => {
      state = { ...state, ...payload };

      return state;
    },
  },
});

export const { setCustomerData } = customer.actions;

export default customer.reducer;
