import React from 'react';

import { FieldValues } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { getDifferentEmailRequiredErrorMessage } from 'errors/errors';
import { setCoborrowerData } from 'handlers/coborrower';
import { CoborrowerInfoKeys } from 'handlers/coborrower/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { ContactInfo } from 'layouts';
import { getBorrowerEmail, getCoborrowerEmail, getCoborrowerPhone } from 'selectors';

const CoborrowerContactInfo = () => {
  const dispatch = useDispatch();

  const borrowerEmail = useSelector(getBorrowerEmail);
  const coborrowerPhone = useSelector(getCoborrowerPhone);
  const coborrowerEmail = useSelector(getCoborrowerEmail);

  const onSubmit = (data: FieldValues) => {
    dispatch(
      setCoborrowerData({
        [CoborrowerInfoKeys.PhoneNumber]: data.phone,
        [CoborrowerInfoKeys.Email]: data.email,
      }),
    );

    dispatch(setCurrentStage({ stage: StagesType.Installation }));
  };

  return (
    <ContactInfo
      phone={coborrowerPhone}
      email={coborrowerEmail}
      emailValidation={{
        notEqualToBorrowerEmail: (value) => value !== borrowerEmail || getDifferentEmailRequiredErrorMessage(),
      }}
      phoneTitle="What is the co-borrower's mobile phone number?"
      emailTitle="What is the co-borrower's email address?"
      onSubmit={onSubmit}
    />
  );
};

export default CoborrowerContactInfo;
