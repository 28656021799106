import React, { useEffect, useState } from 'react';

import { generatePath, useHistory, useParams } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import OffersMobile from 'components/OffersTable/OffersMobile';
import OffersTable, { ITableRow } from 'components/OffersTable/OffersTable';
import { MobileScreenWidth } from 'enums/MobileScreenWidth';
import { ApplicationVariables } from 'handlers/application/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { OffersColumns } from 'pages/Offers/Offers';
import pages from 'pages/pages.module.scss';
import { useAppDispatch } from 'store';
import { getApplicationByDisplayId } from 'thunks';
import { Routes } from 'types';
import { loanOffersToViewModel } from 'utils/loanOffersToViewModel';

const CustomerOffers = () => {
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useAppDispatch();
  const [rows, setRows] = useState<ITableRow[]>([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobileScreen = screenWidth < MobileScreenWidth.Middle;

  const handleWindowSizeChange = () => setScreenWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const getApplication = async () => {
    const application = await dispatch(getApplicationByDisplayId(id)).unwrap();
    const loanOffers = loanOffersToViewModel({
      applicationVariables: application.variables,
      selectedOffer: application.variables[ApplicationVariables.LoanTermList],
      loanAmount: application.variables[ApplicationVariables.LoanAmount],
    });
    setRows(loanOffers);
  };

  useEffect(() => {
    if (id) {
      getApplication();
    }
  }, [id]);

  const onBackClick = () => history.push(generatePath(Routes.ContractorManageDeal, { id }));
  return (
    <>
      <div className={pages.tableContainer}>
        {isMobileScreen ? (
          <OffersMobile rows={rows} shownAmount={7} isCollapsible={false} disabled />
        ) : (
          <OffersTable columns={OffersColumns} rows={rows} shownAmount={7} isCollapsible={false} disabled />
        )}
      </div>
      <NavigationButtonWrapper>
        <BackButton className={commonStyles.portalBackButton} onClick={onBackClick} />
      </NavigationButtonWrapper>
    </>
  );
};

export default withPortalLayout(CustomerOffers);
