import { createAsyncThunk } from '@reduxjs/toolkit';
import { DateTime } from 'luxon';

import ApplicationApi from 'api/ApplicationApi';
import BorrowerApi from 'api/BorrowerApi';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { setCoborrowerData } from 'handlers/coborrower';
import { CoborrowerInfoKeys } from 'handlers/coborrower/types';
import { setCustomerData } from 'handlers/customer';
import { UserInformationKeys } from 'handlers/customer/types';
import { setIntermediaryData } from 'handlers/intermediary';
import { IntermediaryVariables } from 'handlers/intermediary/types';
import { LoadingType, setLoadingState } from 'handlers/loading';
import { ApplicationStatus } from 'types';

export const createFetchDataForBorrowerThunk = (borrowerApi: BorrowerApi, applicationApi: ApplicationApi) =>
  createAsyncThunk('borrowers/createCoborrower', async (_, { dispatch }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
      const application = await applicationApi.getApplicationByBorrowerId();
      if (!application) {
        return null;
      }
      dispatch(
        setApplicationData({
          applicationId: application.id,
          coborrowerId: application.coborrowerIds[0],
          status: application.status.name as ApplicationStatus,
          [ApplicationVariables.ApplicationCreateDate]: DateTime.fromISO(String(application.createdAt)).toFormat(
            'MM-dd-yyyy',
          ),
          ...application.variables,
        }),
      );
      dispatch(
        setCustomerData({
          [UserInformationKeys.CitizenshipStatus]: application.variables[UserInformationKeys.CitizenshipStatus],
        }),
      );
      dispatch(
        setCoborrowerData({
          [CoborrowerInfoKeys.Email]: application.variables[CoborrowerInfoKeys.Email],
          [CoborrowerInfoKeys.PhoneNumber]: application.variables[CoborrowerInfoKeys.PhoneNumber],
          [CoborrowerInfoKeys.FirstName]: application.variables[CoborrowerInfoKeys.FirstName],
          [CoborrowerInfoKeys.LastName]: application.variables[CoborrowerInfoKeys.LastName],
          [CoborrowerInfoKeys.DateOfBirth]: application.variables[CoborrowerInfoKeys.DateOfBirth],
          [CoborrowerInfoKeys.CitizenshipStatus]: application.variables[CoborrowerInfoKeys.CitizenshipStatus],
          [CoborrowerInfoKeys.CoborrowerLives]: application.variables[CoborrowerInfoKeys.CoborrowerLives],
          [CoborrowerInfoKeys.CoborrowerOwnership]: application.variables[CoborrowerInfoKeys.CoborrowerOwnership],
          [CoborrowerInfoKeys.SSN]: application.variables[CoborrowerInfoKeys.SSN],
          [CoborrowerInfoKeys.StreetAddress]: application.variables[CoborrowerInfoKeys.StreetAddress],
          [CoborrowerInfoKeys.State]: application.variables[CoborrowerInfoKeys.State],
          [CoborrowerInfoKeys.City]: application.variables[CoborrowerInfoKeys.City],
          [CoborrowerInfoKeys.ZipOrPostalCode]: application.variables[CoborrowerInfoKeys.ZipOrPostalCode],
        }),
      );
      dispatch(
        setIntermediaryData({
          [IntermediaryVariables.Name]: application.variables[IntermediaryVariables.Name],
          [IntermediaryVariables.Type]: application.variables[IntermediaryVariables.Type],
          [IntermediaryVariables.Email]: application.variables[IntermediaryVariables.Email],
          [IntermediaryVariables.Phone]: application.variables[IntermediaryVariables.Phone],
          [IntermediaryVariables.Id]: application.variables[IntermediaryVariables.Id],
          [IntermediaryVariables.PrimaryContactFirstName]:
            application.variables[IntermediaryVariables.PrimaryContactFirstName],
          [IntermediaryVariables.PrimaryContactLastName]:
            application.variables[IntermediaryVariables.PrimaryContactLastName],
          [IntermediaryVariables.IntermediaryFirstPaymentPercentage]:
            application.variables[IntermediaryVariables.IntermediaryFirstPaymentPercentage],
          [IntermediaryVariables.IntermediarySecondPaymentPercentage]:
            application.variables[IntermediaryVariables.IntermediarySecondPaymentPercentage],
        }),
      );
      return application.variables[ApplicationVariables.BorrowerEmail];
    } catch (err) {
      return null;
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
    }
  });
