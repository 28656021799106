import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeclineTypes } from './types';

export interface DeclineState {
  [DeclineTypes.Reasons]: string[];
  [DeclineTypes.BorrowerEmailAlreadyInUse]: boolean | null;
  [DeclineTypes.BorrowerInvalidEmailForSSN]: boolean | null;
  [DeclineTypes.CoborrowerInvalidEmailForSSN]: boolean | null;
  [DeclineTypes.CoborrowerEmailAlreadyInUse]: boolean | null;
  [DeclineTypes.IsPassedStrategy]: boolean | null;
}

const initialState: DeclineState = {
  [DeclineTypes.Reasons]: [],
  [DeclineTypes.BorrowerEmailAlreadyInUse]: null,
  [DeclineTypes.BorrowerInvalidEmailForSSN]: null,
  [DeclineTypes.CoborrowerInvalidEmailForSSN]: null,
  [DeclineTypes.CoborrowerEmailAlreadyInUse]: null,
  [DeclineTypes.IsPassedStrategy]: null,
};

const decline = createSlice({
  name: 'decline',
  initialState,
  reducers: {
    setDeclineState(state: DeclineState, { payload }: PayloadAction<Partial<DeclineState>>) {
      state = { ...state, ...payload };

      return state;
    },
  },
});

export const { setDeclineState } = decline.actions;

export default decline.reducer;
