import { HttpClient } from './Api';

export interface IDocusignApi {
  getSignUrl(applicationId: string): Promise<{ url: string }>;
}

export default class DocusignApi extends HttpClient implements IDocusignApi {
  public async getSignUrl(applicationId: string) {
    return this.instance
      .post<{ url: string }>('/api/docusign', {
        applicationId,
      })
      .then((res) => res.data);
  }
}
