import { ApplicationState } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { ApplicationStatus, SoftPullStatus } from 'types';

export const getFriendlyStatus = (application: ApplicationState) => {
  if (application[ApplicationVariables.ApplicationWithdrawn] && application.status === ApplicationStatus.Rejected) {
    return 'Withdrawn';
  }
  if (application.status === ApplicationStatus.Rejected) {
    return 'Declined';
  }
  if (
    application.status === ApplicationStatus.Approved &&
    (application[ApplicationVariables.EzsolarLoanFinal50Disbursed] ||
      application[ApplicationVariables.EzsolarLoanFullAmountDisbursed])
  ) {
    return 'Completed';
  }
  if (
    (application[ApplicationVariables.First50Authorized] || application[ApplicationVariables.FullAmountAuthorized]) &&
    application.status === ApplicationStatus.ContractorPayments
  ) {
    return 'Funding';
  }
  if (
    (application.status === ApplicationStatus.BookedToKeystone ||
      application.status === ApplicationStatus.ContractReview ||
      application.status === ApplicationStatus.ContractorPayments ||
      (application.status === ApplicationStatus.ExceptionProcessing &&
        application[ApplicationVariables.TransferToServicingSystemComplete] === false)) &&
    application[ApplicationVariables.BorrowerSigningDocumentsComplete] &&
    (application.coborrower_application ? application[ApplicationVariables.CoborrowerSigningDocumentsComplete] : true)
  ) {
    return 'Signed';
  }
  if (
    application.status === ApplicationStatus.FinishingApplication ||
    application.status === ApplicationStatus.SigningDocuments ||
    (application.status === ApplicationStatus.ExceptionProcessing &&
      application[ApplicationVariables.TransferToServicingSystemComplete] == null)
  ) {
    return 'Approved';
  }
  if (
    application[ApplicationVariables.SoftPullResult] === SoftPullStatus.Approved &&
    (application.status === ApplicationStatus.PreQualified ||
      application.status === ApplicationStatus.CompletingVerification ||
      application.status === ApplicationStatus.ManualProcessorReview ||
      application.status === ApplicationStatus.ManualUnderwritingReview)
  ) {
    return 'Pre-approved';
  }
  if (
    application[ApplicationVariables.SoftPullResult] === SoftPullStatus.Received &&
    (application.status === ApplicationStatus.PreQualified ||
      application.status === ApplicationStatus.CompletingVerification ||
      application.status === ApplicationStatus.ManualProcessorReview ||
      application.status === ApplicationStatus.ManualUnderwritingReview)
  ) {
    return 'Received';
  }
  return '';
};
