import React, { FC, useEffect } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as LeftArrow } from 'assets/icons/LeftArrow.svg';
import Button from 'common/components/Button';
import { AlignmentType, ButtonVariant } from 'common/components/Button/Button';
import Link from 'common/components/Link';
import Header from 'components/Header';
import { DeclineReasons } from 'enums/DeclineReasons';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { BasicInformationSteps, CoborrowerInfoSteps } from 'handlers/steps/types';
import pages from 'pages/pages.module.scss';
import {
  getDeclineReasons,
  getBorrowerInvalidEmailForSSN,
  getCoborrowerInvalidEmailForSSN,
  getSoftPullResult,
  getCoborrowerEmailAlreadyInUse,
  getBorrowerEmailAlreadyInUse,
  getIsPassedStrategy,
  getPropertyType,
} from 'selectors';
import { SoftPullStatus } from 'types';
import { checkPropertyType } from 'utils/checkPropertyType';

import ContactUs from './ContactUs';
import styles from './DeclinePage.module.scss';

const cx = classNames.bind(styles);

enum DeclineText {
  IncorrectData = 'We were unable to pull a credit report for you. Perhaps you entered some information incorrectly. Please click below to go back and try again or contact us at (800) 493-1310.',
  CreditDecline = 'We are unable to extend any offers for ezSolarLoan financing to you at this time. Your application has been automatically declined for the following reason:',
  BorrowerCreditFreeze = 'We were unable to pull a credit report for you. Please remove any credit freeze in place for Equifax and then enter your application again. Please ensure that the credit freeze is lifted until you receive your loan documents. Contact us at (800) 493-1310 if you have any questions.',
  CoborrowerCreditFreeze = 'We were unable to pull a credit report for your co-borrower. Please remove any credit freeze in place for Equifax and then enter your application again. Please ensure that the credit freeze is lifted until you receive your loan documents. Contact us at (800) 493-1310 if you have any questions.',
  JointCreditFreeze = 'We were unable to pull a credit report for you and your co-borrower. Please remove any credit freeze in place for Equifax and then enter your application again. Please ensure that the credit freeze is lifted until you receive your loan documents. Contact us at (800) 493-1310 if you have any questions.',
  BorrowerInvalidEmailForSSN = 'Have you applied for an ezSolarLoan before, but used a different email address? Please try again with the same email address that you used previously. Otherwise, to change the email address associated with your records, please contact us at (800) 493-1310.',
  CoborrowerInvalidEmailForSSN = 'We have a different email address in our records associated with the co-borrower. Please provide the correct email address. If you need to change the email address associated with the co-borrower’s records, please contact us at (800)493-1310.',
  BorrowerEmailAlreadyInUse = 'Your email address is already in use. Please click below to go back and try another one or contact us at (800) 493-1310.',
  CoborrowerEmailAlreadyInUse = 'Co-borrower’s email address is already in use. Please click below to go back and try another one or contact us at (800) 493-1310.',
  StrategyError = 'Please try again later or contact us at (800) 493-1310 if you have any questions.',
  InvalidPropertyType = 'An ezSolarLoan cannot be used for apartment, commercial or retail properties.',
}

interface DeclinePageProps {
  title: string;
  contactUs?: boolean;
}

interface ICreditDeclineBody {
  reasons: string[];
  isCreditDecline: boolean;
}

const STRATEGY_ERROR_PAGE_NAME = 'Something went wrong.';

const CreditDeclineBody: FC<ICreditDeclineBody> = ({ isCreditDecline, reasons }) => (
  <>
    {isCreditDecline && <p className={styles.creditDeclineText}>{DeclineText.CreditDecline}</p>}
    <div className={cx(styles.list, { [styles.messagePadding]: reasons.length > 1 })}>
      {reasons?.map((item, index) => (
        <li
          className={cx({
            [styles.listItem]: reasons.length > 1,
          })}
          key={index}
        >
          {item}
        </li>
      ))}
    </div>
    {!reasons.includes(DeclineReasons.BorrowerFicoScore) && (
      <p>
        If the circumstances above change at some point in the future, feel free to return and apply again. Thank you
        for your application to ezSolarLoan.
      </p>
    )}
  </>
);

const DeclineData: FC<{ text: string }> = ({ text }) => <p>{text}</p>;

const DeclinePage = ({ title, contactUs }: DeclinePageProps) => {
  const dispatch = useDispatch();
  const reasons = useSelector(getDeclineReasons);
  const isBorrowerInvalidEmailForSSN = useSelector(getBorrowerInvalidEmailForSSN);
  const isCoborrowerInvalidEmailForSSN = useSelector(getCoborrowerInvalidEmailForSSN);
  const isCoborrowerEmailAlreadyInUse = useSelector(getCoborrowerEmailAlreadyInUse);
  const isBorrowerEmailAlreadyInUse = useSelector(getBorrowerEmailAlreadyInUse);
  const isPassedStrategy = useSelector(getIsPassedStrategy);
  const isRejectedSoftPull = useSelector(getSoftPullResult) === SoftPullStatus.Rejected;
  const isIncorrectData = reasons.includes(DeclineReasons.SoftPullIncorrectData);
  const isBorrowerCreditFreeze = reasons.includes(DeclineReasons.BorrowerCreditReportIsFrozen);
  const isCoborrowerCreditFreeze = reasons.includes(DeclineReasons.CoborrowerCreditReportIsFrozen);
  const propertyType = useSelector(getPropertyType);

  const isDeclineLink =
    isBorrowerInvalidEmailForSSN ||
    isCoborrowerInvalidEmailForSSN ||
    isIncorrectData ||
    isCoborrowerEmailAlreadyInUse ||
    isBorrowerEmailAlreadyInUse;

  const isAdditionalMarginsNeeded =
    isRejectedSoftPull ||
    isDeclineLink ||
    contactUs ||
    isCoborrowerCreditFreeze ||
    isBorrowerCreditFreeze ||
    !isPassedStrategy ||
    propertyType;

  const isStrategyError = !reasons.length && isPassedStrategy === false;

  const getDeclineBody = (declineReasons: string[], isRejected: boolean) => {
    if (propertyType && checkPropertyType(propertyType.toLocaleLowerCase())) {
      return <DeclineData text={DeclineText.InvalidPropertyType} />;
    }
    if (isBorrowerCreditFreeze && isCoborrowerCreditFreeze) {
      return <DeclineData text={DeclineText.JointCreditFreeze} />;
    }
    if (isBorrowerCreditFreeze) {
      return <DeclineData text={DeclineText.BorrowerCreditFreeze} />;
    }
    if (isCoborrowerCreditFreeze) {
      return <DeclineData text={DeclineText.CoborrowerCreditFreeze} />;
    }
    if (isIncorrectData) {
      return <DeclineData text={DeclineText.IncorrectData} />;
    }
    if (isCoborrowerInvalidEmailForSSN || isBorrowerInvalidEmailForSSN) {
      return (
        <DeclineData
          text={
            isBorrowerInvalidEmailForSSN
              ? DeclineText.BorrowerInvalidEmailForSSN
              : DeclineText.CoborrowerInvalidEmailForSSN
          }
        />
      );
    }
    if (isCoborrowerEmailAlreadyInUse || isBorrowerEmailAlreadyInUse) {
      return (
        <DeclineData
          text={
            isBorrowerEmailAlreadyInUse
              ? DeclineText.BorrowerEmailAlreadyInUse
              : DeclineText.CoborrowerEmailAlreadyInUse
          }
        />
      );
    }
    if (isRejected || declineReasons.length) {
      return <CreditDeclineBody reasons={declineReasons} isCreditDecline={isRejected} />;
    }
    if (isStrategyError) {
      return <DeclineData text={DeclineText.StrategyError} />;
    }
  };

  const getStep = () => {
    if (isBorrowerInvalidEmailForSSN || isBorrowerEmailAlreadyInUse) {
      return BasicInformationSteps.Contacts;
    }
    if (isCoborrowerInvalidEmailForSSN || isCoborrowerEmailAlreadyInUse) {
      return CoborrowerInfoSteps.CoborrowerContactInfo;
    }
    return BasicInformationSteps.BorrowerInfo;
  };

  const getStage = () => {
    if (isCoborrowerInvalidEmailForSSN || isCoborrowerEmailAlreadyInUse) {
      return StagesType.CoborrowerInfo;
    }
    return StagesType.BasicInformation;
  };

  const onClickButton = () => {
    dispatch(setCurrentStage({ stage: getStage(), step: getStep() }));
  };

  const getDeclineLink = (isIncorrectSoftPullData: boolean) => (
    <div className={styles.returnLink}>
      {isIncorrectSoftPullData ? (
        <Button
          variant={ButtonVariant.Text}
          title="Try again"
          onClick={onClickButton}
          icon={<LeftArrow className={styles.returnHomeIcon} />}
          iconAlignment={AlignmentType.Left}
        />
      ) : (
        <Link href="https://ezsolarloan.com/" className={styles.returnHomeLink}>
          <LeftArrow className={styles.returnHomeIcon} />
          Return to home page
        </Link>
      )}
    </div>
  );

  useEffect(() => {
    document.body.classList.add('declinePageBody');

    return () => {
      document.body.classList.remove('declinePageBody');
    };
  });

  return (
    <div className={pages.externalPage}>
      <div className={pages.externalPageContent}>
        <Header external />
        <h1 className={cx(styles.declinePageTitle, { [styles.titleMarginBottom]: isAdditionalMarginsNeeded })}>
          {isStrategyError && !isDeclineLink ? STRATEGY_ERROR_PAGE_NAME : title}
        </h1>
        <div className={pages.externalPageMainContent}>
          <ul className={styles.messageContainer}>
            {contactUs ? <ContactUs /> : getDeclineBody(reasons, isRejectedSoftPull)}
          </ul>
        </div>
      </div>
      {getDeclineLink(!!isDeclineLink)}
    </div>
  );
};

export default DeclinePage;
