import React, { ForwardedRef, useEffect, useRef, useState } from 'react';

import { debounce } from '@mui/material/utils';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as LoupeIcon } from 'assets/icons/Loupe.svg';
import { DealFilters } from 'components/DealsFilters/DealsFilters';
import { setTableOptions, TableType } from 'handlers/table';
import {
  getFilterByProjectDetails,
  getFilterByStatusApplication,
  getFilterByVoi,
  getSearchValue,
  getSortDirection,
  getSortField,
} from 'selectors';

import styles from './SearchInput.module.scss';

const DEBOUNCE_TIME = 500;

interface SearchInputProps {
  name?: string;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  fetchData: (...args: any[]) => any;
}

const cx = classNames.bind(styles);

const SearchInput = React.forwardRef(
  ({ disabled, name, placeholder, className, fetchData }: SearchInputProps, ref: ForwardedRef<HTMLInputElement>) => {
    const dispatch = useDispatch();
    const sortDirection = useSelector(getSortDirection);
    const sortField = useSelector(getSortField);
    const searchValue = useSelector(getSearchValue);
    const activeFilters: DealFilters = {
      projectDetails: useSelector(getFilterByProjectDetails),
      statusApplication: useSelector(getFilterByStatusApplication),
      voi: useSelector(getFilterByVoi),
    };
    const debouncedChangeValue = useRef(debounce(fetchData, DEBOUNCE_TIME)).current;
    const [value, setValue] = useState(searchValue);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
      debouncedChangeValue({ search: e.target.value, filters: activeFilters, sortField, sortDirection });
    };

    useEffect(() => {
      dispatch(
        setTableOptions({
          [TableType.SearchValue]: value,
          [TableType.PageIndex]: 0,
        }),
      );
    }, [value]);

    return (
      <div className={cx(styles.searchInputContainer, className)}>
        <LoupeIcon className={styles.icon} />
        <input
          className={styles.input}
          ref={ref}
          placeholder={placeholder}
          type="text"
          value={value}
          onChange={handleChange}
          name={name}
          disabled={disabled}
          inputMode="search"
        />
      </div>
    );
  },
);

SearchInput.displayName = 'SearchInput';

export default SearchInput;
