import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import Checkbox from 'common/components/Checkbox';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { ApplicationVariables } from 'handlers/application/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import {
  getApplicationId,
  getFinal50Requested,
  getFirst50Requested,
  getFullAmountRequested,
  getIntermediaryFirstPaymentsPercentage,
  getIntermediaryName,
  getIntermediarySecondPaymentsPercentage,
  getIsLoading,
  getOutsideAmount,
  getTotalProjectCost,
  getTotalProjectCostMinusOutsideAmount,
} from 'selectors';
import { updateApplication } from 'thunks';
import { Routes, VariableValue } from 'types';
import { getCurrentDate } from 'utils/dateHelper';
import { convertMonetary } from 'utils/formats';
import { notify } from 'utils/notificationHelper';

import styles from './Payments.module.scss';

const Payments = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const applicationId = useSelector(getApplicationId);
  const intermediaryName = useSelector(getIntermediaryName);
  const loading = useSelector(getIsLoading);
  const first50Requested = useSelector(getFirst50Requested);
  const final50Requested = useSelector(getFinal50Requested);
  const fullAmountRequested = useSelector(getFullAmountRequested);
  const totalProjectConst = Number(useSelector(getTotalProjectCost));
  const totalProjectConstMinusOutsideAmount = Number(useSelector(getTotalProjectCostMinusOutsideAmount));
  const outsideAmount = Number(useSelector(getOutsideAmount));
  const firstPaymentsPercentage = useSelector(getIntermediaryFirstPaymentsPercentage) || 0.5;
  const secondPaymentsPercentage = useSelector(getIntermediarySecondPaymentsPercentage) || 0.5;
  const [agreed, setAgreed] = useState(false);

  const handleBackClick = () => {
    history.push(Routes.BorrowerDashboard);
  };

  const handleSubmit = async () => {
    const applicationVariables: Record<string, VariableValue> = {};
    if (fullAmountRequested) {
      applicationVariables[ApplicationVariables.FullAmountAuthorized] = agreed;
      applicationVariables[ApplicationVariables.FullAmountAuthorizedDate] = getCurrentDate();
      applicationVariables[ApplicationVariables.AgreedToFundingTermsStatement] = true;
      applicationVariables[ApplicationVariables.RequestFundsAgreementDate] = getCurrentDate();
    } else if (first50Requested && !final50Requested) {
      applicationVariables[ApplicationVariables.First50Authorized] = agreed;
      applicationVariables[ApplicationVariables.First50AuthorizedDate] = getCurrentDate();
      applicationVariables[ApplicationVariables.AgreedToFundingTermsStatement] = true;
      applicationVariables[ApplicationVariables.RequestFundsAgreementDate] = getCurrentDate();
    } else {
      applicationVariables[ApplicationVariables.Final50Authorized] = agreed;
      applicationVariables[ApplicationVariables.Final50AuthorizedDate] = getCurrentDate();
    }
    await dispatch(
      updateApplication({
        applicationId,
        applicationVariables,
      }),
    );
    notify({ notification: 'Payment has been successfully authorized' });
    history.push(Routes.BorrowerDashboard);
  };

  const renderPaymentDetails = () => {
    let requestLabel = '';
    let requestAmount = '';
    const fullSum = convertMonetary(totalProjectConst - outsideAmount);
    let progressDetails: string | JSX.Element = '';
    if (fullAmountRequested) {
      requestLabel = 'Full loan amount';
      requestAmount = `${fullSum}`;
      progressDetails = '100% requested';
    } else if (first50Requested && !final50Requested) {
      requestLabel = `First ${firstPaymentsPercentage * 100}% of ${fullSum}`;
      requestAmount = `${convertMonetary(totalProjectConstMinusOutsideAmount * firstPaymentsPercentage)}`;
      progressDetails = `First ${firstPaymentsPercentage * 100}% requested`;
    } else if (first50Requested && final50Requested) {
      requestAmount = `${convertMonetary(
        totalProjectConstMinusOutsideAmount - totalProjectConstMinusOutsideAmount * firstPaymentsPercentage,
      )}`;
      requestLabel = `Second ${secondPaymentsPercentage * 100}% of ${fullSum}`;
      progressDetails = (
        <>
          <div>First {firstPaymentsPercentage * 100}% paid</div>
          <div>Final {secondPaymentsPercentage * 100}% requested</div>
        </>
      );
    }

    return (
      <dl className={styles.paymentDetailsList}>
        <dt className={styles.paymentDetailLabel}>{requestLabel}</dt>
        <dd className={styles.paymentDetailValue}>{requestAmount}</dd>
        <dt className={styles.paymentDetailLabel}>Progress</dt>
        <dd className={styles.paymentDetailValue}>{progressDetails}</dd>
      </dl>
    );
  };

  const getCheckboxLabel = () =>
    fullAmountRequested || (first50Requested && final50Requested)
      ? 'Borrower authorizes and requests payment of all outstanding amounts owed on the project as requested by Contractor. By submitting this authorization, Borrower certifies that Borrower has reviewed and approved the invoice(s) uploaded to the ezSolarLoan system, and that Borrower is fully satisfied with the materials and services reflected in such invoice(s), that all conditions for payment of the amount requested to Contractor under Borrower’s agreement with Contractor have been satisfied, and that the project has been completed to Borrower’s satisfaction.'
      : `Borrower authorizes and requests payment of the first ${
          firstPaymentsPercentage * 100
        }% of the final loan amount as requested by Contractor. By submitting this authorization, Borrower certifies that Borrower has reviewed and approved the invoice(s) uploaded to the ezSolarLoan system, and that Borrower is fully satisfied with the materials and services reflected in such invoice(s) and that all conditions for payment of the amount requested to Contractor under Borrower’s agreement with Contractor have been satisfied.`;

  return (
    <>
      <div className={styles.paymentDescriptionContainer}>
        <div className={styles.paymentTitle}>
          {`Your Solar Company, ${intermediaryName}, has requested the following funds be released for payment to them:`}
        </div>
        {renderPaymentDetails()}
      </div>
      <Checkbox checked={agreed} onChange={() => setAgreed(!agreed)} label={getCheckboxLabel()} />
      <NavigationButtonWrapper>
        <BackButton className={commonStyles.portalBackButton} onClick={handleBackClick} />
        <NextButton
          onClick={handleSubmit}
          disabled={!agreed}
          loading={loading}
          title="Authorize payment"
          className={styles.nextButton}
        />
      </NavigationButtonWrapper>
    </>
  );
};

export default withPortalLayout(Payments);
