import React from 'react';

import { useSelector } from 'react-redux';

import { EmploymentSteps, Step } from 'handlers/steps/types';
import { WithProgressBar } from 'layouts';
import { getCurrentStep } from 'selectors';

import EmploymentInfo from './steps/EmploymentInfo';
import IncomeInfo from './steps/IncomeInfo';
import { EmploymentPageType } from './types';

interface EmploymentInfoProps {
  title: string;
}

const getPageByStep = (step: Step | null) => {
  switch (step) {
    case EmploymentSteps.BorrowerEmploymentInfo:
      return <EmploymentInfo type={EmploymentPageType.Borrower} />;
    case EmploymentSteps.BorrowerIncome:
      return <IncomeInfo type={EmploymentPageType.Borrower} />;
    case EmploymentSteps.BorrowerPreviousEmployment:
      return <EmploymentInfo type={EmploymentPageType.PreviousBorrower} />;
    case EmploymentSteps.BorrowerPreviousIncome:
      return <IncomeInfo type={EmploymentPageType.PreviousBorrower} />;
    case EmploymentSteps.CoborrowerEmploymentInfo:
      return <EmploymentInfo type={EmploymentPageType.Coborrower} />;
    case EmploymentSteps.CoborrowerIncome:
      return <IncomeInfo type={EmploymentPageType.Coborrower} />;
    case EmploymentSteps.CoborrowerPreviousEmployment:
      return <EmploymentInfo type={EmploymentPageType.PreviousCoborrower} />;
    case EmploymentSteps.CoborrowerPreviousIncome:
      return <IncomeInfo type={EmploymentPageType.PreviousCoborrower} />;
    default:
      return <EmploymentInfo type={EmploymentPageType.Borrower} />;
  }
};

const EmploymentPage = ({ title }: EmploymentInfoProps) => {
  const currentStep = useSelector(getCurrentStep);

  return <WithProgressBar title={title}>{getPageByStep(currentStep)}</WithProgressBar>;
};

export default EmploymentPage;
