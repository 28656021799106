import React from 'react';

import classNames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import PhoneInput from 'common/components/PhoneInput';
import TextInput from 'common/components/TextInput';
import {
  getDifferentEmailRequiredErrorMessage,
  getInvalidEmailErrorMessage,
  getRequiredErrorMessage,
} from 'errors/errors';
import styles from 'pages/pages.module.scss';
import { getBorrowerEmail } from 'selectors';
import { deleteSpaceInStart } from 'utils/formats';
import { specialCharactersiInputProhibition } from 'utils/inputProhibition';
import { getCorrectValueForEmail, getPatterns } from 'utils/validationHelper';

const cx = classNames.bind(styles);

interface ISalespersonProps {
  errorBackgroundType?: 'flowErrorBackground' | 'portalErrorBackground';
}

export const SalespersonForm = ({ errorBackgroundType }: ISalespersonProps) => {
  const {
    register,
    setValue,
    control,
    watch,
    formState: { errors },
  } = useFormContext();

  const watcher = watch();

  const borrowerEmail = useSelector(getBorrowerEmail);

  const handleChangeEmail = (value: string) => {
    setValue('email', getCorrectValueForEmail(value));
  };

  return (
    <div>
      <div className={styles.textInputLabel}>What is that person’s name?</div>
      <div className={cx(styles.inputContainer, styles.flexInputContainer)}>
        <TextInput
          inputContainerClassName={styles.inputChildContainer}
          {...register('name', { required: getRequiredErrorMessage('Name') })}
          label="Name"
          maxLength={100}
          value={deleteSpaceInStart(watcher.name)}
          errorMessage={errors.name?.message}
          errorBackgroundType={errorBackgroundType}
          onKeyDown={specialCharactersiInputProhibition}
        />
      </div>
      <div className={styles.textInputLabel}>What is that person’s mobile phone number?</div>
      <div className={styles.inputContainer}>
        <PhoneInput label="Phone" name="phone" control={control} errorBackgroundType={errorBackgroundType} />
      </div>
      <div className={styles.textInputLabel}>What is that person’s email address?</div>
      <div className={styles.inputContainer}>
        <TextInput
          {...register('email', {
            required: getRequiredErrorMessage('Email'),
            pattern: {
              value: getPatterns().email,
              message: getInvalidEmailErrorMessage(),
            },
            validate: {
              notEqualToBorrowerEmail: (value) => value !== borrowerEmail || getDifferentEmailRequiredErrorMessage(),
            },
          })}
          onChange={(event) => handleChangeEmail(event.target.value)}
          label="Email"
          value={deleteSpaceInStart(watcher.email)}
          errorMessage={errors.email?.message}
          maxLength={100}
          errorBackgroundType={errorBackgroundType}
          inputMode="email"
        />
      </div>
    </div>
  );
};
