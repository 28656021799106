import { createAsyncThunk } from '@reduxjs/toolkit';

import ApplicationApi from 'api/ApplicationApi';
import { setDeclineState } from 'handlers/decline';
import { DeclineTypes } from 'handlers/decline/types';
import { LoadingType, setLoadingState } from 'handlers/loading';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { StrategyName } from 'types';

interface RunApplicationDecisionProps {
  applicationId: string;
  strategyName: StrategyName;
  skipDecline?: boolean;
  skipDeclinePage?: boolean;
}

export const createRunApplicationDecisionThunk = (applicationsApi: ApplicationApi) =>
  createAsyncThunk(
    'application/run-application-decision',
    async (
      { applicationId, strategyName, skipDecline = false, skipDeclinePage = false }: RunApplicationDecisionProps,
      { dispatch },
    ) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        const { declineReasons, outputVariables, passed } = await applicationsApi.runDecision(
          applicationId,
          strategyName,
        );

        const declined = !passed;

        if (!passed && !declineReasons.length) {
          dispatch(
            setDeclineState({
              [DeclineTypes.IsPassedStrategy]: false,
            }),
          );
        }

        if (!skipDecline && declined) {
          dispatch(
            setDeclineState({
              [DeclineTypes.Reasons]: declineReasons as string[],
            }),
          );
          if (!skipDeclinePage) {
            dispatch(setCurrentStage({ stage: StagesType.Decline }));
          }
        }

        return { outputVariables, declined, declineReasons, passed };
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
