import React, { useEffect, useState } from 'react';

import classNames from 'classnames/bind';

import { ReactComponent as Logo } from 'assets/Logo.svg';
import { ReactComponent as LogoMobile } from 'assets/LogoMobile.svg';
import { MobileScreenWidth } from 'enums/MobileScreenWidth';

import styles from './Logo.module.scss';

export enum AlignmentType {
  Center = 'Center',
  Right = 'Rigth',
  Left = 'Left',
}

interface ILogoIconProps {
  alignment: AlignmentType;
}

const cx = classNames.bind(styles);

const LogoIcon = ({ alignment }: ILogoIconProps) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobileScreen = screenWidth < MobileScreenWidth.Middle;

  const handleWindowSizeChange = () => setScreenWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return (
    <div
      className={cx(styles.logo, {
        [styles.logoCenter]: alignment === AlignmentType.Center,
        [styles.logoLeft]: alignment === AlignmentType.Left,
        [styles.logoRight]: alignment === AlignmentType.Right,
      })}
    >
      <picture>{isMobileScreen ? <LogoMobile /> : <Logo />}</picture>
    </div>
  );
};

export default LogoIcon;
