export type Step =
  | BasicInformationSteps
  | PhoneVerificationSteps
  | InstallationSteps
  | CoborrowerInfoSteps
  | OwnersCheckSteps
  | EmploymentSteps
  | SSNSteps
  | UploadDocumentsSteps;

export enum BasicInformationSteps {
  LoanPurpose = 'LoanPurpose',
  LoanAmount = 'LoanAmount',
  BorrowerInfo = 'BorrowerInfo',
  Contacts = 'Contacts',
}

export enum PhoneVerificationSteps {
  PhoneVerification = 'PhoneVerification',
}

export enum InstallationSteps {
  AddressInfo = 'AddressInfo',
  OwnershipTerm = 'OwnershipTerm',
  CoborrowerLives = 'CoborrowerLives',
}

export enum CoborrowerInfoSteps {
  NumberOfBorrowers = 'NumberOfBorrowers',
  CoborrowerPersonalInfo = 'CoborrowerPersonalInfo',
  CoborrowerContactInfo = 'CoborrowerContactInfo',
}

export enum OwnersCheckSteps {
  OwnersCheck = 'OwnersCheck',
}

export enum EmploymentSteps {
  BorrowerEmploymentInfo = 'BorrowerEmploymentInfo',
  BorrowerIncome = 'BorrowerIncome',
  BorrowerPreviousEmployment = 'BorrowerPreviousEmployment',
  BorrowerPreviousIncome = 'BorrowerPreviousIncome',
  CoborrowerEmploymentInfo = 'CoborrowerEmploymentInfo',
  CoborrowerIncome = 'CoborrowerIncome',
  CoborrowerPreviousEmployment = 'CoborrowerPreviousEmployment',
  CoborrowerPreviousIncome = 'CoborrowerPreviousIncome',
}

export enum SSNSteps {
  SSN = 'SSN',
}

export enum UploadDocumentsSteps {
  BorrowerIdentity = 'BorrowerIdentity',
  CoborrowerIdentity = 'CoborrowerIdentity',
  BorrowerIncome = 'BorrowerIncome',
  CoborrowerIncome = 'CoborrowerIncome',
}
