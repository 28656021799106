import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';
import { setCustomerData } from 'handlers/customer';
import { LoadingType, setLoadingState } from 'handlers/loading';

export const createGetBorrowerByTokenThunk = (accountApi: AccountApi) =>
  createAsyncThunk('account/getBorrowerByToken', async (token: string, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: true }));
      const result = await accountApi.getBorrowerAccountByToken(token);
      dispatch(
        setCustomerData({
          isMfaIncomplete: result.isMfaIncomplete,
          isEmailNotVerified: result.isEmailNotVerified,
        }),
      );
      return result;
    } catch (e) {
      rejectWithValue(null);
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: false }));
    }
  });
