import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { generatePath, useHistory, useParams } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import Checkbox from 'common/components/Checkbox';
import RadioButton from 'common/components/RadioButton/RadioButton';
import DropZone from 'components/DropZone';
import { IFileWithError } from 'components/DropZone/DropZone';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import ViewDeal from 'components/ViewDeal';
import { ApplicationVariables } from 'handlers/application/types';
import { IntermediaryVariables } from 'handlers/intermediary/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { getIsLoading } from 'selectors';
import { useAppDispatch } from 'store';
import { getApplicationByDisplayId, updateApplication, uploadDocuments } from 'thunks';
import { Application, Routes, VariableValue } from 'types';
import { getCurrentDate } from 'utils/dateHelper';
import { convertMonetary } from 'utils/formats';

import styles from './RequestFunds.module.scss';
import { Notify } from './types';

enum Step {
  request = 'request',
  agreement = 'agreement',
}

enum RadioButtonValue {
  first50Requested = 'first50Requested',
  final50Requested = 'final50Requested',
  fullAmountRequested = 'fullAmountRequested',
}

const RADIO_BUTTON_NAME = 'requestAmount';

const RequestFunds = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const [application, setApplication] = useState<Application | null>(null);
  const [step, setStep] = useState<Step | null>(null);
  const [projectPhotosFiles, setProjectPhotosFiles] = useState<IFileWithError[]>([]);
  const [invoiceFiles, setInvoiceFiles] = useState<IFileWithError[]>([]);
  const [agree, setAgree] = useState(false);
  const [checked, setChecked] = useState<RadioButtonValue | null>(null);

  const firstPaymentsPercentage =
    application?.variables[ApplicationVariables.IntermediaryPrepaidEligible] === false
      ? 50
      : application?.variables[IntermediaryVariables.IntermediaryFirstPaymentPercentage] * 100;
  const secondPaymentsPercentage =
    application?.variables[ApplicationVariables.IntermediaryPrepaidEligible] === false
      ? 50
      : application?.variables[IntermediaryVariables.IntermediarySecondPaymentPercentage] * 100;

  const loading = useSelector(getIsLoading);

  const applicationId = application?.id as string;
  const requestStep = step === Step.request;
  const agreementStep = step === Step.agreement;
  const totalMinusOutsideAmount = application?.variables[ApplicationVariables.TotalMinusOutsideAmount];

  const getApplication = async () => {
    const app = await dispatch(getApplicationByDisplayId(id)).unwrap();
    setApplication(app);
    setStep(app.variables[ApplicationVariables.RequestFundsAgreement] ? Step.request : Step.agreement);
  };

  const getValues = (): Record<string, VariableValue> => {
    if (requestStep) {
      if (checked === RadioButtonValue.first50Requested)
        return {
          [ApplicationVariables.First50Requested]: true,
          [ApplicationVariables.First50RequestedDate]: getCurrentDate(),
        };
      if (checked === RadioButtonValue.final50Requested)
        return {
          [ApplicationVariables.Final50Requested]: true,
          [ApplicationVariables.Final50RequestedDate]: getCurrentDate(),
        };
      if (checked === RadioButtonValue.fullAmountRequested)
        return {
          [ApplicationVariables.FullAmountRequested]: true,
          [ApplicationVariables.FullAmountRequestedDate]: getCurrentDate(),
        };
    }
    return {
      [ApplicationVariables.RequestFundsAgreement]: true,
      [ApplicationVariables.InstallerRequestFundsAgreementDate]: getCurrentDate(),
    };
  };

  const onSubmit = async () => {
    if (requestStep) {
      await dispatch(
        uploadDocuments({
          applicationId,
          files: [...projectPhotosFiles, ...invoiceFiles],
        }),
      );
      await dispatch(
        updateApplication({
          applicationId,
          applicationVariables: getValues(),
        }),
      ).unwrap();

      return history.push(generatePath(Routes.ContractorManageDeal, { id }), { notify: Notify.DocumentsUploaded });
    }
    const result = await dispatch(
      updateApplication({
        applicationId,
        applicationVariables: getValues(),
      }),
    ).unwrap();

    if (result && agreementStep) setStep(Step.request);
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => setChecked(e.target.value as RadioButtonValue);

  const onBackClick = () => history.push(generatePath(Routes.ContractorManageDeal, { id }));

  const isSubmitDisabled = () =>
    step === Step.agreement
      ? !agree
      : !checked ||
        (checked !== RadioButtonValue.first50Requested &&
          (![...projectPhotosFiles].length || ![...invoiceFiles].length)) ||
        (checked === RadioButtonValue.first50Requested && ![...invoiceFiles].length) ||
        !![...projectPhotosFiles, ...invoiceFiles].filter((file) => file.error).length;

  const isFirstAndFullAmountDisabled = () =>
    !application?.variables[ApplicationVariables.IntermediaryPrepaidEligible] ||
    application?.variables[ApplicationVariables.First50Requested] ||
    application?.variables[ApplicationVariables.Final50Requested] ||
    application?.variables[ApplicationVariables.FullAmountRequested];

  const isFinalDisabled = () =>
    !application?.variables[ApplicationVariables.IntermediaryPrepaidEligible] ||
    !application?.variables[ApplicationVariables.First50Requested] ||
    application?.variables[ApplicationVariables.Final50Requested];

  const isFullDisabled = () =>
    (isFirstAndFullAmountDisabled() && application?.variables[ApplicationVariables.IntermediaryPrepaidEligible]) ||
    application?.variables[ApplicationVariables.FullAmountRequested];

  useEffect(() => {
    if (id) getApplication();
    return () => setApplication(null);
  }, [id]);

  return (
    <>
      <ViewDeal application={application} />
      <div className={styles.container}>
        {agreementStep && (
          <Checkbox
            label="I understand and agree that prior to authorizing the full loan amount, the project must be complete in accordance with the terms agreed upon by the borrower(s) and the Solar Company in the signed contract for service that has been uploaded through ezSolarLoan"
            checked={agree}
            onChange={() => setAgree(!agree)}
          />
        )}
        {requestStep && (
          <>
            <p className={styles.subTitle}>
              Based on the details of the project and the signed contract submitted to ezSolarLoan, the final loan
              amount is: {convertMonetary(totalMinusOutsideAmount)}
            </p>
            <RadioButton
              containerClassName={styles.radioButtonMargin}
              label={`Contractor requests payment of the first ${firstPaymentsPercentage}% of the final loan amount. By submitting this request, Contractor certifies that the invoice(s) uploaded to the ezSolarLoan system is true and accurate, and that all work and materials required as a condition for this payment under Contractor’s service contract with the Borrower has/have been performed or provided.`}
              onChange={handleOnChange}
              checked={checked === RadioButtonValue.first50Requested}
              id={RadioButtonValue.first50Requested}
              value={RadioButtonValue.first50Requested}
              name={RADIO_BUTTON_NAME}
              disabled={isFirstAndFullAmountDisabled()}
            />
            <RadioButton
              containerClassName={styles.radioButtonMargin}
              label={`Contractor requests payment of the second ${secondPaymentsPercentage}% the final loan amount. By submitting this request, Contractor certifies that the invoice(s) uploaded to the ezSolarLoan system is true and accurate, that the project has been completed in accordance with Contractor’s service contract with the Borrower, and that the Borrower has authorized payment.`}
              onChange={handleOnChange}
              checked={checked === RadioButtonValue.final50Requested}
              id={RadioButtonValue.final50Requested}
              value={RadioButtonValue.final50Requested}
              name={RADIO_BUTTON_NAME}
              disabled={isFinalDisabled()}
            />
            <RadioButton
              containerClassName={styles.radioButtonMargin}
              label="Contractor requests payment of all amounts owed on the project. By submitting this request, Contractor certifies that the invoice(s) uploaded to the ezSolarLoan system is true and accurate, that the project has been completed in accordance with Contractor’s service contract with the Borrower, and that the Borrower has authorized payment."
              onChange={handleOnChange}
              checked={checked === RadioButtonValue.fullAmountRequested}
              id={RadioButtonValue.fullAmountRequested}
              value={RadioButtonValue.fullAmountRequested}
              name={RADIO_BUTTON_NAME}
              disabled={isFullDisabled()}
            />
            <DropZone
              descriptionStyle={styles.dropZoneDescription}
              dropZoneStyle={styles.dropZone}
              files={projectPhotosFiles}
              setFiles={setProjectPhotosFiles}
              titleName="Project photos"
              description="Required when requesting full or final payment"
              fileName="Project Photos-from-Portal"
            />
            <DropZone
              dropZoneStyle={styles.dropZone}
              files={invoiceFiles}
              setFiles={setInvoiceFiles}
              titleName="Invoice"
              fileName="Invoice-from-Request funds"
            />
          </>
        )}
      </div>
      <NavigationButtonWrapper>
        <BackButton className={commonStyles.portalBackButton} onClick={onBackClick} />
        <NextButton onClick={onSubmit} disabled={isSubmitDisabled()} loading={loading} title="Submit" />
      </NavigationButtonWrapper>
    </>
  );
};

export default withPortalLayout(RequestFunds);
