import React, { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import Button from 'common/components/Button';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import Link from 'common/components/Link';
import MonetaryInput from 'common/components/MonetaryInput/MonetaryInput';
import NumberInput from 'common/components/NumberInput';
import PhoneInput from 'common/components/PhoneInput';
import RadioButton from 'common/components/RadioButton/RadioButton';
import { SearchLocationInput } from 'common/components/SearchLocationInput';
import TextInput from 'common/components/TextInput';
import InputErrorMessage from 'common/components/TextInput/InputErrorMessage';
import useScript from 'common/hooks/useScript';
import DatePicker from 'components/DatePicker';
import DropDown from 'components/DropDown';
import { OptionType } from 'components/DropDown/DropDown';
import DropZone from 'components/DropZone';
import { IFileWithError } from 'components/DropZone/DropZone';
import FormContainer from 'components/FormContainer';
import ModalWindow from 'components/ModalWindow';
import ModalActionsWrapper from 'components/ModalWindow/ModalActionsWrapper';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { DeclineReasons } from 'enums/DeclineReasons';
import {
  getDifferentEmailRequiredErrorMessage,
  getInvalidDateErrorMessage,
  getInvalidEmailErrorMessage,
  getInvalidSSNLength,
  getMessageForInvalidField,
  getRequiredErrorMessage,
} from 'errors/errors';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables, EmploymentType } from 'handlers/application/types';
import { setCoborrowerData } from 'handlers/coborrower';
import { CoborrowerInfoKeys } from 'handlers/coborrower/types';
import { UserInformationKeys } from 'handlers/customer/types';
import { DeclineTypes } from 'handlers/decline/types';
import { citizenshipData } from 'layouts/CitizenshipStatus';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { employmentDurations, employmentTypes } from 'pages/Employment/steps/EmploymentInfo';
import { EmploymentDuration } from 'pages/Employment/types';
import { ownershipList } from 'pages/Installation/steps/CoborrowerLives';
import pages from 'pages/pages.module.scss';
import { getIsLoading, getRootState } from 'selectors';
import { useAppDispatch } from 'store';
import { createOrUpdateCoborrower, runApplicationDecision, runStrategy, updateApplication, uploadDocuments } from 'thunks';
import { Routes, SoftPullStatus, StrategyName } from 'types';
import { checkOwners } from 'utils/checkOwners';
import { getCurrentDate } from 'utils/dateHelper';
import { deleteDoubleSpace, deleteSpaceInStart } from 'utils/formats';
import { specialCharactersiInputProhibition } from 'utils/inputProhibition';
import { notify } from 'utils/notificationHelper';
import { getCorrectValueForEmail, getPatterns, isExistErrors, isValidDate } from 'utils/validationHelper';

import styles from './AddCoborrower.module.scss';
import { NotifyTypes } from './types';

const cx = classNames.bind(pages);

const SSN_LENGTH = 4;

const ELIGIBILITY_CHECK_FAILED =
  'Unfortunately, this co-borrower is not eligible to apply for an ezSolarLoan at this time';

const CONTACT_US_MODAL = (
  <>
    Please contact us <Link href="mailto:applications@ezsolarloan.com">applications@ezsolarloan.com</Link> or phone at{' '}
    <a className={pages.noStyleLink} href="tel:+18004931310">
      (800) 493-1310
    </a>
  </>
);

const SOFT_PULL_INCORRECT_DATA_MODAL = (
  <p>
    We were unable to pull a credit report for your co-borrower. Perhaps you entered some information incorrectly.
    Please click below to go back and try again or contact us at 800-493-1310.
  </p>
);

const COBORROWER_CREDIT_FROZEN_MODAL = (
  <p>
    We were unable to pull a credit report for your co-borrower. Please remove any credit freeze in place for Equifax
    and then add co-borrower again. Please ensure that the credit freeze is lifted until you receive your loan
    documents. Contact us at (800) 493-1310 if you have any questions.
  </p>
);

const COBORROWER_EMAIL_ALREADY_IN_USE_MODAL = (
  <p>
    Co-borrower’s email address is already in use. Please click below to go back and try another one or contact us at
    (800) 493-1310.
  </p>
);

const COBORROWER_INVALID_EMAIL_MODAL = (
  <p>
    We have a different email address in our records associated with the co-borrower. Please provide the correct email
    address. If you need to change the email address associated with the co-borrower’s records, please contact us at
    (800) 493-1310.
  </p>
);

const COBORROWER_SOMETHING_WENT_WRONG_MODAL = (
  <p>Please try again later or contact us at (800) 493-1310 if you have any questions.</p>
);

interface EmploymentData {
  value: string;
  label: string;
}

const AddCoborrower = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const loading = useSelector(getIsLoading);
  const { application, customer } = useSelector(getRootState);

  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<IFileWithError[]>([]);
  const [modalContent, setContent] = useState(CONTACT_US_MODAL);
  const [modalTitle, setTitle] = useState('Unfortunately, your co-borrower can not be added at this time.');

  const {
    setValue,
    clearErrors,
    register,
    control,
    watch,
    trigger,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm<FieldValues>({
    defaultValues: {
      [CoborrowerInfoKeys.StreetAddress]: '',
      [CoborrowerInfoKeys.City]: '',
      [CoborrowerInfoKeys.State]: '',
      [CoborrowerInfoKeys.ZipOrPostalCode]: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
  );

  const handleOpenModal = () => setOpen(true);

  const handleCloseModal = () => setOpen(false);

  const watcher = watch();

  const isEmploymentRequireFields = (employment: EmploymentData) => {
    if (employment === undefined) return;
    const employmentTypeFields = [EmploymentType.Employed, EmploymentType.BusinessOwner, EmploymentType.SelfEmployed];
    const employmentDurationFields = [EmploymentDuration.Less1, EmploymentDuration.Between1And2];
    if (employmentTypeFields.includes(employment.value as EmploymentType)) return true;
    return employmentDurationFields.includes(employment.value as EmploymentDuration);
  };

  const getValidationObj = (employmentType: EmploymentData, inputLabel: string) => {
    if (isEmploymentRequireFields(employmentType)) return { required: getRequiredErrorMessage(inputLabel) };
    return { required: false };
  };

  const getAdjustedApplicationMonthlyDebtPayments = (applicationMonthlyDebtPayments: number) =>
    Number(application[ApplicationVariables.MonthlyDebtPaymentsAdjustment1]) +
    Number(application[ApplicationVariables.MonthlyDebtPaymentsAdjustment2]) +
    Number(application[ApplicationVariables.MonthlyDebtPaymentsAdjustment3]) +
    Number(application[ApplicationVariables.MonthlyDebtPaymentsAdjustment4]) +
    Number(application[ApplicationVariables.MonthlyDebtPaymentsAdjustment5]) +
    applicationMonthlyDebtPayments;

  useEffect(() => {
    register(CoborrowerInfoKeys.SSN, {
      required: getRequiredErrorMessage('Co-borrower SSN'),
      minLength: {
        value: SSN_LENGTH,
        message: getInvalidSSNLength('Co-borrower SSN'),
      },
    });
    if (watcher[CoborrowerInfoKeys.CoborrowerLives] === false) {
      register(CoborrowerInfoKeys.CoborrowerOwnership, {
        required: watcher[CoborrowerInfoKeys.CoborrowerLives] ? undefined : getRequiredErrorMessage('Ownership status'),
      });
      register(CoborrowerInfoKeys.StreetAddress, {
        required: getRequiredErrorMessage('Contact street address'),
      });
      register(CoborrowerInfoKeys.City, { required: getRequiredErrorMessage('City') });
      register(CoborrowerInfoKeys.ZipOrPostalCode, {
        required: getRequiredErrorMessage('Zip code'),
        pattern: {
          value: getPatterns().zipcode,
          message: getMessageForInvalidField('Zip code'),
        },
      });
      register(CoborrowerInfoKeys.State, { required: getRequiredErrorMessage('State') });
    }
    register(CoborrowerInfoKeys.DateOfBirth, {
      required: getRequiredErrorMessage('Date of birth'),
      validate: (value) => (isValidDate(value) ? true : getInvalidDateErrorMessage()),
    });
    register(CoborrowerInfoKeys.CitizenshipStatus, {
      required: getRequiredErrorMessage('Citizenship status'),
    });
    register(ApplicationVariables.CoborrowerEmploymentType, {
      required: getRequiredErrorMessage('Employment type'),
    });
    register(
      ApplicationVariables.CoborrowerLengthOfEmployment,
      getValidationObj(watcher[ApplicationVariables.CoborrowerEmploymentType], 'Employment duration'),
    );
    register(ApplicationVariables.CoborrowerGrossIncome, {
      validate: (value) => (value ? undefined : getRequiredErrorMessage('Gross income')),
    });
    register(
      ApplicationVariables.CoborrowerPreviousEmploymentType,
      getValidationObj(watcher[ApplicationVariables.CoborrowerLengthOfEmployment], 'Employment type'),
    );
    register(
      ApplicationVariables.CoborrowerPreviousGrossIncome,
      getValidationObj(watcher[ApplicationVariables.CoborrowerLengthOfEmployment], 'Gross income'),
    );
    register(
      ApplicationVariables.CoborrowerPreviousLengthOfEmployment,
      getValidationObj(watcher[ApplicationVariables.CoborrowerPreviousEmploymentType], 'Employment duration'),
    );
    register(CoborrowerInfoKeys.CoborrowerLives, {
      required:
        watcher[CoborrowerInfoKeys.CoborrowerLives] !== undefined ? undefined : getRequiredErrorMessage('One option'),
    });
  }, [
    register,
    watcher[CoborrowerInfoKeys.CoborrowerLives],
    watcher[ApplicationVariables.CoborrowerEmploymentType],
    watcher[ApplicationVariables.CoborrowerLengthOfEmployment],
    watcher[ApplicationVariables.CoborrowerPreviousEmploymentType],
    watcher[ApplicationVariables.CoborrowerPreviousLengthOfEmployment],
    watcher[ApplicationVariables.CoborrowerGrossIncome],
    watcher[ApplicationVariables.CoborrowerPreviousGrossIncome],
  ]);

  const onSubmit: SubmitHandler<FieldValues> = async (data: FieldValues) => {
    const eligibilityCheckResult = await dispatch(
      runStrategy({
        applicationId: application.applicationId,
        strategyName: StrategyName.EligibilityCheck1,
        strategyInputVariables: {
          [UserInformationKeys.CitizenshipStatus]: customer.borrower_citizenship_status,
          [UserInformationKeys.DateOfBirth]: customer.borrower_date_of_birth,
          [CoborrowerInfoKeys.DateOfBirth]: data[CoborrowerInfoKeys.DateOfBirth],
          [CoborrowerInfoKeys.CitizenshipStatus]: data[CoborrowerInfoKeys.CitizenshipStatus].value,
          [ApplicationVariables.CoborrowerApplication]: true,
        },
      }),
    ).unwrap();

    if (eligibilityCheckResult.declined) {
      notify({ notification: `${ELIGIBILITY_CHECK_FAILED}. ${eligibilityCheckResult.declineReasons}` });
      return history.push(Routes.BorrowerDashboard);
    }

    const eligibilityCheck2Result = await dispatch(
      runStrategy({
        applicationId: application.applicationId,
        strategyName: StrategyName.EligibilityCheck2,
        strategyInputVariables: {
          [CoborrowerInfoKeys.CoborrowerLives]: data[CoborrowerInfoKeys.CoborrowerLives],
          [CoborrowerInfoKeys.CoborrowerOwnership]: data[CoborrowerInfoKeys.CoborrowerOwnership]?.value || null,
          [CoborrowerInfoKeys.CitizenshipStatus]: data[CoborrowerInfoKeys.CitizenshipStatus].value,
          [CoborrowerInfoKeys.DateOfBirth]: data[CoborrowerInfoKeys.DateOfBirth],
          [ApplicationVariables.BorrowerOwnership]: application.borrower_ownership,
          [UserInformationKeys.CitizenshipStatus]: customer.borrower_citizenship_status,
          [ApplicationVariables.CoborrowerApplication]: true,
        },
      }),
    ).unwrap();

    if (eligibilityCheck2Result.declined) {
      notify({ notification: `${ELIGIBILITY_CHECK_FAILED}. ${eligibilityCheck2Result.declineReasons}` });
      return history.push(Routes.BorrowerDashboard);
    }

    const borrowersEqualsOwners = checkOwners({
      borrowerFirstName: customer.borrower_first_name,
      borrowerLastName: customer.borrower_last_name,
      coborrowerFirstName: deleteDoubleSpace(data[CoborrowerInfoKeys.FirstName] as string),
      coborrowerLastName: deleteDoubleSpace(data[CoborrowerInfoKeys.LastName] as string),
      propertyOwner1: application.property_owner_1,
      propertyOwner2: application.property_owner_2,
      propertyOwner3: application.property_owner_3,
    });

    const softPull = await dispatch(
      runStrategy({
        strategyName: StrategyName.SoftCreditPull,
        applicationId: application.applicationId,
        strategyInputVariables: {
          [UserInformationKeys.FirstName]: customer.borrower_first_name,
          [UserInformationKeys.LastName]: customer.borrower_last_name,
          [ApplicationVariables.Borrower4LastDigitsSSN]: application[ApplicationVariables.Borrower4LastDigitsSSN],
          [ApplicationVariables.Coborrower4LastDigitsSSN]: `00000${data[CoborrowerInfoKeys.SSN]}`,
          [CoborrowerInfoKeys.FirstName]: deleteDoubleSpace(data[CoborrowerInfoKeys.FirstName] as string),
          [CoborrowerInfoKeys.LastName]: deleteDoubleSpace(data[CoborrowerInfoKeys.LastName] as string),
          [ApplicationVariables.CoborrowerApplication]: true,
          [UserInformationKeys.DateOfBirth]: customer.borrower_date_of_birth,
          [CoborrowerInfoKeys.DateOfBirth]: data[CoborrowerInfoKeys.DateOfBirth],
          [ApplicationVariables.ApplicationCreateDate]: application.application_create_date,
          [ApplicationVariables.ApplicationDisplayId]: application.application_display_id,
          [ApplicationVariables.PrimaryLoanPurpose]: application.primary_loan_purpose,
          [ApplicationVariables.SecondaryLoanPurpose]: application.secondary_loan_purpose,
          [ApplicationVariables.InstallationStreetAddress]: application.install_street_address,
          [ApplicationVariables.InstallationState]: application.install_state_or_province,
          [ApplicationVariables.InstallationCity]: application.install_city,
          [ApplicationVariables.InstallationZipCode]: application.install_zip_or_postal_code,
          [ApplicationVariables.LoanAmount]: application.loan_amount,
          [UserInformationKeys.Email]: customer.borrower_email.toLocaleLowerCase(),
          [UserInformationKeys.PhoneNumber]: customer.borrower_phone,
          [UserInformationKeys.StreetAddress]: application.borrower_contact_street_address,
          [UserInformationKeys.State]: application.borrower_contact_state_or_province,
          [UserInformationKeys.City]: application.borrower_contact_city,
          [UserInformationKeys.ZipOrPostalCode]: application.borrower_contact_zip_or_postal_code,
          [ApplicationVariables.BorrowerContactAddressSameAsInstall]:
            application.borrower_contact_address_same_as_install,
          [UserInformationKeys.OwnershipStatus]: application.borrower_ownership,
          [UserInformationKeys.CitizenshipStatus]: customer.borrower_citizenship_status,
          [ApplicationVariables.OwnershipTermLessOneYear]: application.ownership_term_less_1_year,
          [ApplicationVariables.BorrowerLengthOfEmployment]: application.borrower_length_of_employment,
          [ApplicationVariables.BorrowerEmploymentType]: application.borrower_employment_type,
          [ApplicationVariables.BorrowerJobTitle]: application.borrower_job_title,
          [ApplicationVariables.BorrowerCompanyName]: application.borrower_employment_company_name,
          [ApplicationVariables.BorrowerGrossIncome]: application.borrower_gross_annual_income,
          [ApplicationVariables.BorrowerPreviousGrossIncome]: application.borrower_previous_gross_annual_income,
          [ApplicationVariables.BorrowerPreviousEmploymentType]: application.borrower_previous_employment_type,
          [ApplicationVariables.BorrowerPreviousJobTitle]: application.borrower_previous_job_title,
          [ApplicationVariables.BorrowerPreviousCompanyName]: application.borrower_previous_employment_company_name,
          [ApplicationVariables.MonthlyMortgagePayment]: application.monthly_mortgage_payment,
          [CoborrowerInfoKeys.Email]: data[CoborrowerInfoKeys.Email].toLocaleLowerCase(),
          [CoborrowerInfoKeys.PhoneNumber]: data[CoborrowerInfoKeys.PhoneNumber],
          [CoborrowerInfoKeys.StreetAddress]: data[CoborrowerInfoKeys.StreetAddress],
          [CoborrowerInfoKeys.State]: data[CoborrowerInfoKeys.State],
          [CoborrowerInfoKeys.City]: data[CoborrowerInfoKeys.City],
          [CoborrowerInfoKeys.ZipOrPostalCode]: data[CoborrowerInfoKeys.ZipOrPostalCode],
          [CoborrowerInfoKeys.CoborrowerLives]: data[CoborrowerInfoKeys.CoborrowerLives],
          [CoborrowerInfoKeys.CoborrowerOwnership]: data[CoborrowerInfoKeys.CoborrowerOwnership]?.value,
          [CoborrowerInfoKeys.CitizenshipStatus]: data[CoborrowerInfoKeys.CitizenshipStatus].value,
          [ApplicationVariables.CoborrowerLengthOfEmployment]:
            data[ApplicationVariables.CoborrowerLengthOfEmployment]?.value,
          [ApplicationVariables.CoborrowerEmploymentType]: data[ApplicationVariables.CoborrowerEmploymentType]
            .value as EmploymentType,
          [ApplicationVariables.CoborrowerJobTitle]: data[ApplicationVariables.CoborrowerJobTitle],
          [ApplicationVariables.CoborrowerCompanyName]: data[ApplicationVariables.CoborrowerCompanyName],
          [ApplicationVariables.CoborrowerGrossIncome]: Number(data[ApplicationVariables.CoborrowerGrossIncome]),
          [ApplicationVariables.CoborrowerPreviousGrossIncome]: Number(
            data[ApplicationVariables.CoborrowerPreviousGrossIncome],
          ),
          [ApplicationVariables.CoborrowerPreviousEmploymentType]: data[
            ApplicationVariables.CoborrowerPreviousEmploymentType
          ]?.value as EmploymentType,
          [ApplicationVariables.CoborrowerPreviousJobTitle]: data[ApplicationVariables.CoborrowerPreviousJobTitle],
          [ApplicationVariables.CoborrowerPreviousCompanyName]:
            data[ApplicationVariables.CoborrowerPreviousCompanyName],
        },
      }),
    ).unwrap();

    if (softPull.declineReasons.length) {
      if (softPull.declineReasons.includes(DeclineReasons.BorrowerFicoScore)) {
        setContent(CONTACT_US_MODAL);
        setTitle('Unfortunately, your co-borrower can not be added at this time.');
      }
      if (softPull.declineReasons.includes(DeclineReasons.SoftPullIncorrectData)) {
        setContent(SOFT_PULL_INCORRECT_DATA_MODAL);
        setTitle('We’re sorry.');
      }
      if (softPull.declineReasons.includes(DeclineReasons.CoborrowerCreditReportIsFrozen)) {
        setContent(COBORROWER_CREDIT_FROZEN_MODAL);
        setTitle('We’re sorry.');
      }
      return handleOpenModal();
    }

    if (!softPull.declined && !softPull.passed) {
      setContent(COBORROWER_SOMETHING_WENT_WRONG_MODAL);
      setTitle('Something went wrong.');
      return handleOpenModal();
    }

    if (softPull.outputVariables[ApplicationVariables.SoftPullResult] === SoftPullStatus.Rejected)
      return handleOpenModal();

    dispatch(
      setApplicationData({
        [ApplicationVariables.DischargedBankruptcyAlert]:
          softPull.outputVariables[ApplicationVariables.DischargedBankruptcyAlert],
        [ApplicationVariables.DischargedBankruptcyAlertCoborrower]:
          softPull.outputVariables[ApplicationVariables.DischargedBankruptcyAlertCoborrower] || '',
      }),
    );

    const createCoborrowerResult = await dispatch(
      createOrUpdateCoborrower({
        [UserInformationKeys.FirstName]: deleteDoubleSpace(data[CoborrowerInfoKeys.FirstName] as string),
        [UserInformationKeys.LastName]: deleteDoubleSpace(data[CoborrowerInfoKeys.LastName] as string),
        [UserInformationKeys.Email]: data[CoborrowerInfoKeys.Email].toLocaleLowerCase(),
        [UserInformationKeys.PhoneNumber]: data[CoborrowerInfoKeys.PhoneNumber],
        [UserInformationKeys.StreetAddress]: data[CoborrowerInfoKeys.StreetAddress],
        [UserInformationKeys.City]: data[CoborrowerInfoKeys.City],
        [UserInformationKeys.State]: data[CoborrowerInfoKeys.State],
        [UserInformationKeys.ZipOrPostalCode]: data[CoborrowerInfoKeys.ZipOrPostalCode],
        [UserInformationKeys.DateOfBirth]: data[CoborrowerInfoKeys.DateOfBirth],
        softPullDecisionId: softPull.decisionId,
      }),
    ).unwrap();

    if (!createCoborrowerResult || createCoborrowerResult.locked) {
      setContent(CONTACT_US_MODAL);
      setTitle('Unfortunately, your co-borrower can not be added at this time.');
      return handleOpenModal();
    }

    if (createCoborrowerResult[DeclineTypes.CoborrowerInvalidEmailForSSN]) {
      setTitle('We’re sorry.');
      setContent(COBORROWER_INVALID_EMAIL_MODAL);
      return handleOpenModal();
    }

    if (createCoborrowerResult[DeclineTypes.CoborrowerEmailAlreadyInUse]) {
      setTitle('We’re sorry.');
      setContent(COBORROWER_EMAIL_ALREADY_IN_USE_MODAL);
      return handleOpenModal();
    }

    await dispatch(
      uploadDocuments({
        applicationId: application.applicationId,
        files,
      }),
    );

    await dispatch(
      updateApplication({
        applicationId: application.applicationId,
        applicationVariables: {
          [ApplicationVariables.DischargedBankruptcyAlert]:
            softPull.outputVariables[ApplicationVariables.DischargedBankruptcyAlert],
          [ApplicationVariables.DischargedBankruptcyAlertCoborrower]:
          softPull.outputVariables[ApplicationVariables.DischargedBankruptcyAlertCoborrower] || '',
          [ApplicationVariables.CoborrowerLengthOfEmployment]:
            data[ApplicationVariables.CoborrowerLengthOfEmployment]?.value,
          [ApplicationVariables.CoborrowerGrossIncome]: Number(data[ApplicationVariables.CoborrowerGrossIncome]),
          [ApplicationVariables.CoborrowerApplication]: true,
          [ApplicationVariables.CoborrowerEmploymentType]: data[ApplicationVariables.CoborrowerEmploymentType]
            .value as EmploymentType,
          [ApplicationVariables.CoborrowerJobTitle]: data[ApplicationVariables.CoborrowerJobTitle],
          [ApplicationVariables.CoborrowerCompanyName]: data[ApplicationVariables.CoborrowerCompanyName],
          [ApplicationVariables.BorrowersEqualsOwners]: borrowersEqualsOwners,
          [CoborrowerInfoKeys.CoborrowerLives]: data[CoborrowerInfoKeys.CoborrowerLives],
          [CoborrowerInfoKeys.CitizenshipStatus]: data[CoborrowerInfoKeys.CitizenshipStatus].value,
          [ApplicationVariables.Coborrower4LastDigitsSSN]: `00000${data[CoborrowerInfoKeys.SSN]}`,
          [ApplicationVariables.CoborrowerPreviousGrossIncome]: Number(
            data[ApplicationVariables.CoborrowerPreviousGrossIncome],
          ),
          [ApplicationVariables.CoborrowerPreviousEmploymentType]: data[
            ApplicationVariables.CoborrowerPreviousEmploymentType
          ]?.value as EmploymentType,
          [ApplicationVariables.CoborrowerPreviousLengthOfEmployment]: data[
            ApplicationVariables.CoborrowerPreviousLengthOfEmployment
          ]?.value as EmploymentType,
          [CoborrowerInfoKeys.CoborrowerOwnership]: data[CoborrowerInfoKeys.CoborrowerOwnership]?.value,
          [ApplicationVariables.CoborrowerPreviousCompanyName]:
            data[ApplicationVariables.CoborrowerPreviousCompanyName],
          [ApplicationVariables.CoborrowerPreviousJobTitle]: data[ApplicationVariables.CoborrowerPreviousJobTitle],
          [ApplicationVariables.UploadCoborrowerIdComplete]: true,
          [ApplicationVariables.UploadCoborrowerIdCompleteDate]: getCurrentDate(),
          [ApplicationVariables.CoborrowerFicoScore]:
            softPull.outputVariables[ApplicationVariables.CoborrowerFicoScore],
          [ApplicationVariables.CoborrowerUnsecuredDebtPercentage]:
            softPull.outputVariables[ApplicationVariables.CoborrowerUnsecuredDebtPercentage],
          [ApplicationVariables.SoftPullResult]: softPull.outputVariables[ApplicationVariables.SoftPullResult],
          [ApplicationVariables.ApplicationDti]: softPull.outputVariables[ApplicationVariables.ApplicationDti],
          [ApplicationVariables.ApplicationMonthlyDebtPayments]:
            softPull.outputVariables[ApplicationVariables.ApplicationMonthlyDebtPayments],
          [ApplicationVariables.ApplicationMonthlyIncomeAmount]:
            softPull.outputVariables[ApplicationVariables.ApplicationMonthlyIncomeAmount],
          [ApplicationVariables.UnqualifiedIncome]: softPull.outputVariables[ApplicationVariables.UnqualifiedIncome],
          [ApplicationVariables.UnsecuredDebtPercentage]:
            softPull.outputVariables[ApplicationVariables.UnsecuredDebtPercentage],
          [ApplicationVariables.ApplicationMortgageAlert]:
            softPull.outputVariables[ApplicationVariables.ApplicationMortgageAlert],
          [ApplicationVariables.AvailableRevolving]: softPull.outputVariables[ApplicationVariables.AvailableRevolving],
          [ApplicationVariables.AddedCoborrowerThroughDashboard]: true,
          [ApplicationVariables.CoborrowerContactStreetAddress]: data[CoborrowerInfoKeys.StreetAddress],
          [ApplicationVariables.CoborrowerContactZipCode]: data[CoborrowerInfoKeys.ZipOrPostalCode],
          [ApplicationVariables.CoborrowerContactState]: data[CoborrowerInfoKeys.State],
          [ApplicationVariables.CoborrowerContactCity]: data[CoborrowerInfoKeys.City],
        },
        coborrowerId: createCoborrowerResult.id as string,
      }),
    );

    await dispatch(
      createOrUpdateCoborrower({
        [UserInformationKeys.Email]: data[CoborrowerInfoKeys.Email].toLocaleLowerCase(),
        locked: true,
        softPullDecisionId: softPull.decisionId,
      }),
    );

    await dispatch(
      runApplicationDecision({
        strategyName: StrategyName.CoborrowerIdVerification,
        applicationId: application.applicationId,
        skipDecline: true,
      }),
    );

    const strategyOffersCalculationResult = await dispatch(
      runStrategy({
        strategyName: StrategyName.OffersCalculation,
        applicationId: application.applicationId,
        strategyInputVariables: {
          [ApplicationVariables.LoanAmount]: application.loan_amount,
          [ApplicationVariables.ApplicationFicoScore]:
            softPull.outputVariables[ApplicationVariables.ApplicationFicoScore],
          [ApplicationVariables.AdjustedApplicationMonthlyDebtPayments]: getAdjustedApplicationMonthlyDebtPayments(
            softPull.outputVariables[ApplicationVariables.ApplicationMonthlyDebtPayments],
          ),
          [ApplicationVariables.AdjustedApplicationMonthlyIncomeAmount]:
            application.adjusted_application_monthly_income_amount,
          [ApplicationVariables.ACHDiscount]: application.ach_discount,
          [ApplicationVariables.DischargedBankruptcyAlert]:
            softPull.outputVariables[ApplicationVariables.DischargedBankruptcyAlert],
          [ApplicationVariables.DischargedBankruptcyAlertCoborrower]:
            softPull.outputVariables[ApplicationVariables.DischargedBankruptcyAlertCoborrower] || '',
          [ApplicationVariables.CoborrowerApplication]: true,
        },
      }),
    ).unwrap();

    const strategyVOINeededResult = await dispatch(
      runStrategy({
        strategyName: StrategyName.VOINeeded,
        applicationId: application.applicationId,
        strategyInputVariables: {
          [ApplicationVariables.BorrowerFicoScore]: application.borrower_fico_score,
          [ApplicationVariables.CoborrowerFicoScore]:
            softPull.outputVariables[ApplicationVariables.CoborrowerFicoScore],
          [ApplicationVariables.SoftPullResult]: softPull.outputVariables[ApplicationVariables.SoftPullResult],
          [ApplicationVariables.UploadBorrowerVoiComplete]: application.upload_borrower_voi_complete,
          [ApplicationVariables.CoborrowerApplication]: true,
          [ApplicationVariables.UploadCoborrowerVoiComplete]: application.upload_coborrower_voi_complete,
          [ApplicationVariables.LoanAmount]: application.loan_amount,
          [ApplicationVariables.PotentialOffersDisplayed]:
            strategyOffersCalculationResult.outputVariables[ApplicationVariables.PotentialOffersDisplayed],
        },
        skipDecline: true,
      }),
    ).unwrap();

    await dispatch(
      updateApplication({
        applicationId: application.applicationId,
        applicationVariables: {
          [ApplicationVariables.VoiNeeded]: !strategyVOINeededResult.declined,
          ...strategyOffersCalculationResult.outputVariables,
        },
      }),
    );

    await dispatch(
      setCoborrowerData({
        [CoborrowerInfoKeys.FirstName]: deleteDoubleSpace(data[CoborrowerInfoKeys.FirstName] as string),
        [CoborrowerInfoKeys.LastName]: deleteDoubleSpace(data[CoborrowerInfoKeys.LastName] as string),
        [CoborrowerInfoKeys.Email]: data[CoborrowerInfoKeys.Email].toLocaleLowerCase(),
        [CoborrowerInfoKeys.PhoneNumber]: data[CoborrowerInfoKeys.PhoneNumber],
        [CoborrowerInfoKeys.StreetAddress]: data[CoborrowerInfoKeys.StreetAddress],
        [CoborrowerInfoKeys.City]: data[CoborrowerInfoKeys.City],
        [CoborrowerInfoKeys.State]: data[CoborrowerInfoKeys.State],
        [CoborrowerInfoKeys.ZipOrPostalCode]: data[CoborrowerInfoKeys.ZipOrPostalCode],
        [CoborrowerInfoKeys.DateOfBirth]: data[CoborrowerInfoKeys.DateOfBirth],
        [CoborrowerInfoKeys.CoborrowerLives]: data[CoborrowerInfoKeys.CoborrowerLives],
        [CoborrowerInfoKeys.CoborrowerOwnership]: data[CoborrowerInfoKeys.CoborrowerOwnership]?.value,
        [CoborrowerInfoKeys.CitizenshipStatus]: data[CoborrowerInfoKeys.CitizenshipStatus].value,
      }),
    );

    if (!strategyOffersCalculationResult.outputVariables[ApplicationVariables.PotentialOffersDisplayed]) {
      return history.push(Routes.BorrowerPaymentRate, { coborrowerAdded: NotifyTypes.CoborrowerAdded });
    }

    notify({
      notification: NotifyTypes.CoborrowerAdded,
      additionalNotification:
        strategyOffersCalculationResult.outputVariables[ApplicationVariables.PotentialOffersDisplayed] ||
        !strategyVOINeededResult.declined
          ? NotifyTypes.VOINeeded
          : undefined,
    });
    history.push(Routes.BorrowerDetails);
  };

  const isSubmitInactive = () => {
    let isValid =
      loading ||
      !files.length ||
      !watcher[CoborrowerInfoKeys.FirstName] ||
      !watcher[CoborrowerInfoKeys.CitizenshipStatus] ||
      !watcher[CoborrowerInfoKeys.LastName] ||
      !watcher[CoborrowerInfoKeys.DateOfBirth] ||
      !watcher[CoborrowerInfoKeys.Email] ||
      !watcher[CoborrowerInfoKeys.PhoneNumber] ||
      !watcher[ApplicationVariables.CoborrowerEmploymentType] ||
      !watcher[ApplicationVariables.CoborrowerGrossIncome] ||
      !watcher[CoborrowerInfoKeys.SSN] ||
      !!files.filter((file) => file.error).length;
    const isValidAddressInputs =
      !watcher[CoborrowerInfoKeys.StreetAddress] ||
      !watcher[CoborrowerInfoKeys.City] ||
      !watcher[CoborrowerInfoKeys.State] ||
      !watcher[CoborrowerInfoKeys.ZipOrPostalCode] ||
      !watcher[CoborrowerInfoKeys.CoborrowerOwnership];
    const isValidWork =
      !watcher[ApplicationVariables.CoborrowerCompanyName] ||
      !watcher[ApplicationVariables.CoborrowerJobTitle] ||
      !watcher[ApplicationVariables.CoborrowerLengthOfEmployment];
    const isValidPreviousWork =
      !watcher[ApplicationVariables.CoborrowerPreviousGrossIncome] ||
      !watcher[ApplicationVariables.CoborrowerPreviousCompanyName] ||
      !watcher[ApplicationVariables.CoborrowerPreviousJobTitle] ||
      !watcher[ApplicationVariables.CoborrowerPreviousLengthOfEmployment];

    if (watcher[CoborrowerInfoKeys.CoborrowerLives] === false) {
      isValid = isValidAddressInputs || isValid || watcher[CoborrowerInfoKeys.CoborrowerLives];
    } else {
      isValid = isValid || !watcher[CoborrowerInfoKeys.CoborrowerLives];
    }
    if (isEmploymentRequireFields(watcher[ApplicationVariables.CoborrowerEmploymentType])) {
      isValid = isValidWork || isValid;
    }
    if (isEmploymentRequireFields(watcher[ApplicationVariables.CoborrowerLengthOfEmployment])) {
      isValid = !watcher[ApplicationVariables.CoborrowerPreviousEmploymentType] || isValid;
    }
    if (isEmploymentRequireFields(watcher[ApplicationVariables.CoborrowerPreviousEmploymentType])) {
      isValid = isValidPreviousWork || isValid;
    }
    return isValid || (isSubmitted && isExistErrors(errors));
  };

  const handleBackButtonClick = () => {
    history.push(Routes.BorrowerDetails);
  };

  const handleModalButtonClick = () => {
    if (
      modalContent === SOFT_PULL_INCORRECT_DATA_MODAL ||
      modalContent === COBORROWER_INVALID_EMAIL_MODAL ||
      modalContent === COBORROWER_EMAIL_ALREADY_IN_USE_MODAL
    ) {
      return handleCloseModal();
    }

    handleBackButtonClick();
  };

  const handleLivesAtInstallAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === 'true';
    setValue(CoborrowerInfoKeys.CoborrowerLives, newValue);
    clearErrors(CoborrowerInfoKeys.CoborrowerLives);
    if (newValue) {
      setValue(CoborrowerInfoKeys.StreetAddress, application.install_street_address);
      setValue(CoborrowerInfoKeys.State, application.install_state_or_province);
      setValue(CoborrowerInfoKeys.City, application.install_city);
      setValue(CoborrowerInfoKeys.ZipOrPostalCode, application.install_zip_or_postal_code);
    } else {
      setValue(CoborrowerInfoKeys.StreetAddress, '');
      setValue(CoborrowerInfoKeys.State, '');
      setValue(CoborrowerInfoKeys.City, '');
      setValue(CoborrowerInfoKeys.ZipOrPostalCode, '');
    }
  };

  const handleContactAddressChange = async (name: string, value: string) => {
    setValue(name, value);
    if (errors[name]?.message) clearErrors(name);
    return trigger(name);
  };

  const handleChange = async (name: string, value: string | OptionType) => {
    setValue(name, value);
    clearErrors(name);
  };

  const onChangeDate = (date: string) => {
    setValue(CoborrowerInfoKeys.DateOfBirth, date);
    if (errors[CoborrowerInfoKeys.DateOfBirth]?.message) clearErrors(CoborrowerInfoKeys.DateOfBirth);
    return trigger(CoborrowerInfoKeys.DateOfBirth);
  };

  const handleChangeEmail = (value: string) => {
    setValue(CoborrowerInfoKeys.Email, getCorrectValueForEmail(value));
  };

  return (
    <>
      <ModalWindow
        isOpen={open}
        title={modalTitle}
        content={modalContent}
        actions={
          <ModalActionsWrapper>
            <Button
              className={styles.modalButton}
              title={
                modalContent === CONTACT_US_MODAL ||
                modalContent === COBORROWER_CREDIT_FROZEN_MODAL ||
                modalContent === COBORROWER_SOMETHING_WENT_WRONG_MODAL
                  ? 'return to dashboard'
                  : 'Try again'
              }
              onClick={handleModalButtonClick}
            />
          </ModalActionsWrapper>
        }
      />
      <FormContainer onSubmit={handleSubmit(onSubmit)}>
        <div className={pages.textInputLabel}>What is the co-borrower’s citizenship status?</div>
        <div className={pages.inputContainer}>
          <DropDown
            control={control}
            selectedOption={watcher[CoborrowerInfoKeys.CitizenshipStatus]}
            onValuePicked={(value) => handleChange(CoborrowerInfoKeys.CitizenshipStatus, value)}
            options={citizenshipData}
            name={CoborrowerInfoKeys.CitizenshipStatus}
            placeholder="Citizenship status"
            isSearchable={false}
            invalid={
              !watcher[CoborrowerInfoKeys.CitizenshipStatus] && errors[CoborrowerInfoKeys.CitizenshipStatus]?.message
            }
          />
          {errors[CoborrowerInfoKeys.CitizenshipStatus]?.message && (
            <InputErrorMessage backgroundColor="portalErrorBackground">
              {errors[CoborrowerInfoKeys.CitizenshipStatus]?.message}
            </InputErrorMessage>
          )}
        </div>
        <div className={pages.textInputLabel}>What is the co-borrower’s name?</div>
        <div className={cx(pages.flexInputContainer, pages.inputContainer)}>
          <TextInput
            inputContainerClassName={pages.inputChildContainer}
            {...register(CoborrowerInfoKeys.FirstName, { required: getRequiredErrorMessage('First name') })}
            label="First name"
            maxLength={100}
            value={deleteSpaceInStart(watcher[CoborrowerInfoKeys.FirstName])}
            errorMessage={errors[CoborrowerInfoKeys.FirstName]?.message}
            errorBackgroundType="portalErrorBackground"
            onKeyDown={specialCharactersiInputProhibition}
          />
          <TextInput
            inputContainerClassName={pages.inputChildContainer}
            {...register(CoborrowerInfoKeys.LastName, { required: getRequiredErrorMessage('Last name') })}
            label="Last name"
            maxLength={100}
            value={deleteSpaceInStart(watcher[CoborrowerInfoKeys.LastName])}
            errorMessage={errors[CoborrowerInfoKeys.LastName]?.message}
            errorBackgroundType="portalErrorBackground"
            onKeyDown={specialCharactersiInputProhibition}
          />
        </div>
        <div className={pages.textInputLabel}>What is the co-borrower’s date of birth?</div>
        <div className={pages.inputContainer}>
          <DatePicker
            control={control}
            name={CoborrowerInfoKeys.DateOfBirth}
            label="Date of birth"
            value={watcher[CoborrowerInfoKeys.DateOfBirth]}
            errorMessage={isSubmitted ? errors[CoborrowerInfoKeys.DateOfBirth]?.message : ''}
            handleChange={onChangeDate}
            handleBlur={() => trigger(CoborrowerInfoKeys.DateOfBirth)}
            errorBackgroundType="portalErrorBackground"
          />
        </div>
        <div className={pages.textInputLabel}>What is the co-borrower’s phone and email?</div>
        <div className={cx(pages.flexInputContainer, pages.inputContainer)}>
          <PhoneInput
            className={pages.inputChildContainer}
            name={CoborrowerInfoKeys.PhoneNumber}
            label="Phone"
            control={control}
            errorBackgroundType="portalErrorBackground"
            autofocus
          />
          <TextInput
            inputContainerClassName={pages.inputChildContainer}
            {...register(CoborrowerInfoKeys.Email, {
              required: getRequiredErrorMessage('Email'),
              pattern: {
                value: getPatterns().email,
                message: getInvalidEmailErrorMessage(),
              },
              validate: {
                notEqualToBorrowerEmail: (value) =>
                  value !== customer.borrower_email || getDifferentEmailRequiredErrorMessage(),
              },
            })}
            onChange={(event) => handleChangeEmail(event.target.value)}
            label="Email"
            errorMessage={errors[CoborrowerInfoKeys.Email]?.message}
            value={deleteSpaceInStart(watcher[CoborrowerInfoKeys.Email])}
            maxLength={100}
            errorBackgroundType="portalErrorBackground"
            inputMode="email"
          />
        </div>
        <div className={pages.textInputLabel}>Does the co-borrower live at install address?</div>
        <div className={pages.inputContainer}>
          <RadioButton
            containerClassName={pages.radioButtonWithMargin}
            label="Yes"
            value="true"
            id="coborrowerLives"
            onChange={handleLivesAtInstallAddressChange}
            checked={!!watcher[CoborrowerInfoKeys.CoborrowerLives]}
          />
          <RadioButton
            label="No"
            value="false"
            id="coborrowerNotLives"
            onChange={handleLivesAtInstallAddressChange}
            checked={watcher[CoborrowerInfoKeys.CoborrowerLives] === false}
          />
          {errors[CoborrowerInfoKeys.CoborrowerLives]?.message && (
            <InputErrorMessage backgroundColor="portalErrorBackground">
              {errors[CoborrowerInfoKeys.CoborrowerLives]?.message}
            </InputErrorMessage>
          )}
        </div>
        {watcher[CoborrowerInfoKeys.CoborrowerLives] === false && (
          <>
            <div className={pages.textInputLabel}>What is the co-borrower's ownership status?</div>
            <div className={cx(pages.dropdownContainer, pages.inputContainer)}>
              <DropDown
                control={control}
                className={pages.inputChildContainer}
                selectedOption={watcher[CoborrowerInfoKeys.CoborrowerOwnership]}
                onValuePicked={(value) => handleChange(CoborrowerInfoKeys.CoborrowerOwnership, value)}
                name={CoborrowerInfoKeys.CoborrowerOwnership}
                invalid={
                  !watcher[CoborrowerInfoKeys.CoborrowerOwnership] &&
                  errors[CoborrowerInfoKeys.CoborrowerOwnership]?.message
                }
                options={ownershipList}
                placeholder="Ownership status"
                isSearchable={false}
              />
              {errors[CoborrowerInfoKeys.CoborrowerOwnership]?.message && (
                <InputErrorMessage backgroundColor="portalErrorBackground">
                  {errors[CoborrowerInfoKeys.CoborrowerOwnership]?.message}
                </InputErrorMessage>
              )}
            </div>
            <div className={pages.textInputLabel}>What is the co-borrower’s contact address?</div>
            <div className={pages.inputContainer}>
              {loaded && (
                <SearchLocationInput
                  control={control}
                  addressValue={{
                    street_address: watcher[CoborrowerInfoKeys.StreetAddress],
                    city: watcher[CoborrowerInfoKeys.City],
                    zip: watcher[CoborrowerInfoKeys.ZipOrPostalCode],
                    state: watcher[CoborrowerInfoKeys.State],
                  }}
                  addressLabel="Contact street address"
                  containerClassName={pages.inputChildContainer}
                  errors={isSubmitted ? errors : []}
                  inputNames={{
                    street_address: CoborrowerInfoKeys.StreetAddress,
                    zip: CoborrowerInfoKeys.ZipOrPostalCode,
                    city: CoborrowerInfoKeys.City,
                    state: CoborrowerInfoKeys.State,
                  }}
                  handleAddressChange={handleContactAddressChange}
                  errorBackgroundType="portalErrorBackground"
                />
              )}
            </div>
          </>
        )}
        <div className={pages.textInputLabel}>Which best describes the co-borrower's type of employment?</div>
        <div className={pages.inputContainer}>
          <DropDown
            control={control}
            selectedOption={watcher[ApplicationVariables.CoborrowerEmploymentType]}
            onValuePicked={(value) => handleChange(ApplicationVariables.CoborrowerEmploymentType, value)}
            invalid={
              !watcher[ApplicationVariables.CoborrowerEmploymentType] &&
              errors[ApplicationVariables.CoborrowerEmploymentType]?.message
            }
            options={employmentTypes}
            isSearchable={false}
            placeholder="Employment type"
            name={ApplicationVariables.CoborrowerEmploymentType}
          />
          {errors[ApplicationVariables.CoborrowerEmploymentType]?.message && (
            <InputErrorMessage backgroundColor="portalErrorBackground">
              {errors[ApplicationVariables.CoborrowerEmploymentType]?.message}
            </InputErrorMessage>
          )}
        </div>
        <div className={pages.textInputLabel}>What is the co-borrower's job title?</div>
        <div className={pages.inputContainer}>
          <TextInput
            {...register(
              ApplicationVariables.CoborrowerJobTitle,
              getValidationObj(watcher[ApplicationVariables.CoborrowerEmploymentType], 'Job title'),
            )}
            value={deleteSpaceInStart(watcher[ApplicationVariables.CoborrowerJobTitle])}
            label="Job title"
            errorMessage={errors[ApplicationVariables.CoborrowerJobTitle]?.message}
            maxLength={100}
            errorBackgroundType="portalErrorBackground"
            onKeyDown={specialCharactersiInputProhibition}
          />
        </div>
        <div className={pages.textInputLabel}>What is the name of the co-borrower's employer / company?</div>
        <div className={pages.inputContainer}>
          <TextInput
            {...register(
              ApplicationVariables.CoborrowerCompanyName,
              getValidationObj(watcher[ApplicationVariables.CoborrowerEmploymentType], 'Employer / company'),
            )}
            value={deleteSpaceInStart(watcher[ApplicationVariables.CoborrowerCompanyName])}
            label="Employer / company"
            errorMessage={errors[ApplicationVariables.CoborrowerCompanyName]?.message}
            maxLength={100}
            errorBackgroundType="portalErrorBackground"
            onKeyDown={specialCharactersiInputProhibition}
          />
        </div>
        <div className={pages.textInputLabel}>How long have you been with your current employer/company?</div>
        <div className={pages.inputContainer}>
          <DropDown
            control={control}
            selectedOption={watcher[ApplicationVariables.CoborrowerLengthOfEmployment]}
            onValuePicked={(value) => handleChange(ApplicationVariables.CoborrowerLengthOfEmployment, value)}
            options={employmentDurations}
            placeholder="Length of employment"
            name={ApplicationVariables.CoborrowerLengthOfEmployment}
            isSearchable={false}
            invalid={
              !watcher[ApplicationVariables.CoborrowerLengthOfEmployment] &&
              errors[ApplicationVariables.CoborrowerLengthOfEmployment]?.message
            }
          />
          {errors[ApplicationVariables.CoborrowerLengthOfEmployment]?.message && (
            <InputErrorMessage backgroundColor="portalErrorBackground">
              {errors[ApplicationVariables.CoborrowerLengthOfEmployment]?.message}
            </InputErrorMessage>
          )}
        </div>
        <div className={pages.textInputLabel}>
          What is the co-borrower’s individual annual gross income (before taxes)?
        </div>
        <p className={pages.inputLabelNote}>
          Please refer to their paystubs or other documents so that they can verify your income if asked to do so
        </p>
        <div className={pages.inputContainer}>
          <MonetaryInput
            control={control}
            name={ApplicationVariables.CoborrowerGrossIncome}
            label="Gross income"
            value={watcher[ApplicationVariables.CoborrowerGrossIncome]?.toString()}
            errorMessage={errors[ApplicationVariables.CoborrowerGrossIncome]?.message}
            referenceInfo="Please include wages, commissions, bonuses and all other income"
            handleChange={() => clearErrors(ApplicationVariables.CoborrowerGrossIncome)}
            errorBackgroundType="portalErrorBackground"
          />
        </div>
        {isEmploymentRequireFields(watcher[ApplicationVariables.CoborrowerLengthOfEmployment]) && (
          <>
            <div className={pages.textInputLabel}>
              Which best describes the co-borrower’s most recent previous type of employment?
            </div>
            <div className={pages.inputContainer}>
              <DropDown
                control={control}
                selectedOption={watcher[ApplicationVariables.CoborrowerPreviousEmploymentType]}
                onValuePicked={(value) => handleChange(ApplicationVariables.CoborrowerPreviousEmploymentType, value)}
                invalid={
                  !watcher[ApplicationVariables.CoborrowerPreviousEmploymentType] &&
                  errors[ApplicationVariables.CoborrowerPreviousEmploymentType]?.message
                }
                options={employmentTypes}
                isSearchable={false}
                placeholder="Employment type"
                name={ApplicationVariables.CoborrowerPreviousEmploymentType}
              />
              {errors[ApplicationVariables.CoborrowerPreviousEmploymentType]?.message && (
                <InputErrorMessage backgroundColor="portalErrorBackground">
                  {errors[ApplicationVariables.CoborrowerPreviousEmploymentType]?.message}
                </InputErrorMessage>
              )}
            </div>
            <div className={pages.textInputLabel}>What was the co-borrower’s most recent previous job title?</div>
            <div className={pages.inputContainer}>
              <TextInput
                {...register(
                  ApplicationVariables.CoborrowerPreviousJobTitle,
                  getValidationObj(watcher[ApplicationVariables.CoborrowerPreviousEmploymentType], 'Job title'),
                )}
                value={deleteSpaceInStart(watcher[ApplicationVariables.CoborrowerPreviousJobTitle])}
                label="Job title"
                errorMessage={errors[ApplicationVariables.CoborrowerPreviousJobTitle]?.message}
                maxLength={100}
                errorBackgroundType="portalErrorBackground"
                onKeyDown={specialCharactersiInputProhibition}
              />
            </div>
            <div className={pages.textInputLabel}>
              What was the co-borrower’s most recent previous employer / company?
            </div>
            <div className={pages.inputContainer}>
              <TextInput
                {...register(
                  ApplicationVariables.CoborrowerPreviousCompanyName,
                  getValidationObj(
                    watcher[ApplicationVariables.CoborrowerPreviousEmploymentType],
                    'Employer / company',
                  ),
                )}
                value={deleteSpaceInStart(watcher[ApplicationVariables.CoborrowerPreviousCompanyName])}
                label="Employer / company"
                errorMessage={errors[ApplicationVariables.CoborrowerPreviousCompanyName]?.message}
                maxLength={100}
                errorBackgroundType="portalErrorBackground"
                onKeyDown={specialCharactersiInputProhibition}
              />
            </div>
            <div className={pages.textInputLabel}>
              How long has the co-borrower been with their most recent previous employer/company?
            </div>
            <div className={pages.inputContainer}>
              <DropDown
                control={control}
                selectedOption={watcher[ApplicationVariables.CoborrowerPreviousLengthOfEmployment]}
                onValuePicked={(value) =>
                  handleChange(ApplicationVariables.CoborrowerPreviousLengthOfEmployment, value)
                }
                options={employmentDurations}
                isSearchable={false}
                placeholder="Length of employment"
                name={ApplicationVariables.CoborrowerPreviousLengthOfEmployment}
                invalid={
                  !watcher[ApplicationVariables.CoborrowerPreviousLengthOfEmployment] &&
                  errors[ApplicationVariables.CoborrowerPreviousLengthOfEmployment]?.message
                }
              />
              {errors[ApplicationVariables.CoborrowerPreviousLengthOfEmployment]?.message && (
                <InputErrorMessage backgroundColor="portalErrorBackground">
                  {errors[ApplicationVariables.CoborrowerPreviousLengthOfEmployment]?.message}
                </InputErrorMessage>
              )}
            </div>
            <div className={pages.textInputLabel}>
              In their previous position, what was the co-borrower’s individual annual gross income (before taxes)?
            </div>
            <p className={pages.inputLabelNote}>
              Please refer to their paystubs or other documents so that they can verify your income if asked to do so
            </p>
            <div className={pages.inputContainer}>
              <MonetaryInput
                control={control}
                name={ApplicationVariables.CoborrowerPreviousGrossIncome}
                label="Gross income"
                value={watcher[ApplicationVariables.CoborrowerPreviousGrossIncome]?.toString()}
                errorMessage={errors[ApplicationVariables.CoborrowerPreviousGrossIncome]?.message}
                referenceInfo="Please include wages, commissions, bonuses and all other income"
                handleChange={() => clearErrors(ApplicationVariables.CoborrowerPreviousGrossIncome)}
                errorBackgroundType="portalErrorBackground"
              />
            </div>
          </>
        )}
        <div className={pages.textInputLabel}>What is the co-borrower's last 4 digits SSN?</div>
        <NumberInput
          name={CoborrowerInfoKeys.SSN}
          control={control}
          inputContainerClassName={pages.inputChildContainer}
          label="Last 4 digits SSN"
          value={watcher[CoborrowerInfoKeys.SSN]}
          errorMessage={errors[CoborrowerInfoKeys.SSN]?.message}
          errorBackgroundType="portalErrorBackground"
          maxLength={SSN_LENGTH}
          allowLeadingZeros
        />
        <DropZone
          files={files}
          setFiles={setFiles}
          titleName="Co-borrower identity verification"
          description="To verify co-borrower’s identity, please upload a valid, non-expired government-issued photo ID."
          dropZoneStyle={styles.dropZone}
          titleStyle={styles.dropZoneTitle}
          fileName="ID-from-Add Co-borrower"
        />
        <NavigationButtonWrapper>
          <BackButton className={commonStyles.portalBackButton} onClick={handleBackButtonClick} />
          <NextButton title="Submit" type="submit" inactive={isSubmitInactive()} loading={loading} />
        </NavigationButtonWrapper>
      </FormContainer>
    </>
  );
};

export default withPortalLayout(AddCoborrower);
