import React from 'react';

export type BaseIconProps = React.SVGAttributes<HTMLOrSVGElement>;

const BaseIcon = ({ width, height, fill, viewBox, className, onClick, children }: BaseIconProps) => (
  <svg
    width={width}
    height={height}
    viewBox={viewBox || `0 0 ${width} ${height}`}
    fill={fill || 'none'}
    className={className}
    onClick={onClick}
  >
    {children}
  </svg>
);

export default BaseIcon;
