import React from 'react';

import classNames from 'classnames/bind';

import styles from './Checkbox.module.scss';

const cx = classNames.bind(styles);

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | JSX.Element;
  noInteraction?: boolean;
}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, noInteraction, disabled, className, ...restProps }, ref) => (
    <label className={cx(styles.checkboxContainer, className)}>
      <input type="checkbox" className={styles.checkboxInput} disabled={disabled} {...restProps} ref={ref} />
      <span className={styles.checkboxControl} />
      {label}
    </label>
  ),
);

export default Checkbox;
