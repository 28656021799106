import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';

interface InviteDetailsProps {
  token: string;
}

export const createGetInviteDetailsThunk = (accountApi: AccountApi) =>
  createAsyncThunk('invite/details', async ({ token }: InviteDetailsProps, { rejectWithValue }) => {
    try {
      const result = await accountApi.getInviteDetails(token);
      return result;
    } catch (e) {
      rejectWithValue(null);
    }
  });
