import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';

import { ResetPasswordProps } from './resetBorrowerPassword';

export const createResetContractorPasswordThunk = (accountApi: AccountApi) =>
  createAsyncThunk(
    'account/contractorReset',
    async ({ password, resetPasswordToken }: ResetPasswordProps, { rejectWithValue }) => {
      try {
        const result = await accountApi.resetContractorPassword(password, resetPasswordToken);
        if (result.success) {
          return true;
        }
        rejectWithValue(null);
      } catch (e) {
        rejectWithValue(null);
      }
    },
  );
