import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import DropZone from 'components/DropZone';
import { IFileWithError } from 'components/DropZone/DropZone';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { ApplicationVariables } from 'handlers/application/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { getApplicationId, getCoborrowerExists, getIsLoading } from 'selectors';
import { updateApplication, uploadDocuments } from 'thunks';
import { Routes } from 'types';
import { getCurrentDate } from 'utils/dateHelper';
import { notify } from 'utils/notificationHelper';

import styles from './BorrowerDocuments.module.scss';

const UPLOAD_SUCCESS = 'Documents have been successfully uploaded';

const BorrowerDocuments = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const loading = useSelector(getIsLoading);
  const applicationId = useSelector(getApplicationId);
  const coborrowerApplication = useSelector(getCoborrowerExists);

  const [incomeFiles, setIncomeFiles] = useState<IFileWithError[]>([]);
  const [driverFiles, setDriverFiles] = useState<IFileWithError[]>([]);
  const [ownershipFiles, setOwnershipFiles] = useState<IFileWithError[]>([]);
  const [additionalFiles, setAdditionalFiles] = useState<IFileWithError[]>([]);

  const checkFiles = () =>
    [...incomeFiles, ...driverFiles, ...ownershipFiles, ...additionalFiles].filter((file) => file.error).length;

  const isValid = () =>
    !(incomeFiles.length || driverFiles.length || ownershipFiles.length || additionalFiles.length) || !!checkFiles();

  const backClick = () => history.push(Routes.BorrowerDetails);

  const getApplicationVariables = () => {
    const applicationVariables = {};
    if (incomeFiles.length) {
      Object.assign(applicationVariables, {
        [ApplicationVariables.UploadBorrowerVoiComplete]: true,
        [ApplicationVariables.UploadBorrowerVoiCompleteDate]: getCurrentDate(),
      });
      if (coborrowerApplication) {
        Object.assign(applicationVariables, {
          [ApplicationVariables.UploadCoborrowerVoiComplete]: true,
          [ApplicationVariables.UploadCoborrowerVoiCompleteDate]: getCurrentDate(),
        });
      }
    }
    if (driverFiles.length) {
      Object.assign(applicationVariables, {
        [ApplicationVariables.UploadBorrowerIdComplete]: true,
        [ApplicationVariables.UploadBorrowerIdCompleteDate]: getCurrentDate(),
      });
      if (coborrowerApplication) {
        Object.assign(applicationVariables, {
          [ApplicationVariables.UploadCoborrowerIdComplete]: true,
          [ApplicationVariables.UploadCoborrowerIdCompleteDate]: getCurrentDate(),
        });
      }
    }
    if (additionalFiles.length)
      Object.assign(applicationVariables, {
        [ApplicationVariables.UploadAdditionalDocumentsComplete]: true,
        [ApplicationVariables.UploadAdditionalDocumentsCompleteDate]: getCurrentDate(),
      });
    if (ownershipFiles.length)
      Object.assign(applicationVariables, {
        [ApplicationVariables.UploadVerificationOwnershipComplete]: true,
        [ApplicationVariables.UploadVerificationOwnershipCompleteDate]: getCurrentDate(),
      });
    return applicationVariables;
  };

  const onSubmit = async () => {
    await dispatch(
      uploadDocuments({
        applicationId,
        files: [...incomeFiles, ...driverFiles, ...ownershipFiles, ...additionalFiles],
      }),
    );
    await dispatch(
      updateApplication({
        applicationId,
        applicationVariables: getApplicationVariables(),
      }),
    );
    history.push(Routes.BorrowerDashboard);
    notify({ notification: UPLOAD_SUCCESS });
  };

  return (
    <>
      <p>Drag files to the designated areas or just click ‘browse for files’.</p>
      <p>
        Please note: send only those documents requested by ezSolarLoan. If it’s not requested, please don’t send it.
      </p>
      <DropZone
        files={incomeFiles}
        setFiles={setIncomeFiles}
        titleName="Verification of income"
        dropZoneStyle={styles.dropZoneMargin}
        fileName="VOI-from-Dashboard"
      />
      <DropZone
        files={driverFiles}
        setFiles={setDriverFiles}
        titleName="Photo ID"
        dropZoneStyle={styles.dropZoneMargin}
        fileName="ID-from-Dashboard"
      />
      <DropZone
        files={ownershipFiles}
        setFiles={setOwnershipFiles}
        titleName="Verification of ownership"
        dropZoneStyle={styles.dropZoneMargin}
        fileName="OWNERSHIP-from-Dashboard"
      />
      <DropZone
        files={additionalFiles}
        setFiles={setAdditionalFiles}
        titleName="Additional documents (anything not listed above)"
        dropZoneStyle={styles.dropZoneMargin}
        fileName="ADD-from-Dashboard"
      />
      <NavigationButtonWrapper>
        <BackButton className={commonStyles.portalBackButton} onClick={backClick} />
        <NextButton title="Submit" onClick={onSubmit} disabled={isValid()} loading={loading} />
      </NavigationButtonWrapper>
    </>
  );
};
export default withPortalLayout(BorrowerDocuments);
