import React from 'react';

import Link from 'common/components/Link';
import styles from 'pages/pages.module.scss';

const ContactUs = () => (
  <>
    To learn more, please check you mailbox or contact us{' '}
    <Link href="mailto:applications@ezsolarloan.com">applications@ezsolarloan.com</Link> or phone at{' '}
    <a className={styles.noStyleLink} href="tel:+18004931310">
      (800) 493-1310
    </a>
  </>
);

export default ContactUs;
