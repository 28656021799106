import { RootState } from 'handlers';

export const getCurrentStageIndex = ({ stages }: RootState) =>
  stages.progressStages.map((stage) => stage.name).indexOf(stages.currentStage);

export const getCurrentStage = ({ stages }: RootState) => stages.currentStage;

export const getCurrentStep = ({ steps }: RootState) => steps.currentStep;

export const getLoanPurpose = ({ application }: RootState) => application.primary_loan_purpose;

export const getAdditionalLoanPurpose = ({ application }: RootState) => application.secondary_loan_purpose;

export const getProgressStages = ({ stages }: RootState) => stages.progressStages;

export const getLoanAmount = ({ application }: RootState) => application.loan_amount;

export const getBorrowerCitizenshipStatus = ({ customer }: RootState) => customer.borrower_citizenship_status;

export const getBorrowerFirstName = ({ customer }: RootState) => customer.borrower_first_name;

export const getBorrowerLastName = ({ customer }: RootState) => customer.borrower_last_name;

export const getBorrowerDateOfBirth = ({ customer }: RootState) => customer.borrower_date_of_birth;

export const getPrivacyAndTermsSigned = ({ customer }: RootState) => customer.privacy_and_terms_signed;

export const getBorrowerPhone = ({ customer }: RootState) => customer.borrower_phone;

export const getBorrowerEmail = ({ customer }: RootState) => customer.borrower_email;

export const getInstallationStreetAddress = ({ application }: RootState) => application.install_street_address;

export const getInstallationZipCode = ({ application }: RootState) => application.install_zip_or_postal_code;

export const getInstallationState = ({ application }: RootState) => application.install_state_or_province;

export const getInstallationCity = ({ application }: RootState) => application.install_city;

export const getBorrowerOwnershipStatus = ({ application }: RootState) => application.borrower_ownership;

export const getBorrowerContactAddressSameAsIntall = ({ application }: RootState) =>
  application.borrower_contact_address_same_as_install;

export const getBorrowerContactStreetAddress = ({ application }: RootState) =>
  application.borrower_contact_street_address;

export const getBorrowerContactZipCode = ({ application }: RootState) =>
  application.borrower_contact_zip_or_postal_code;

export const getBorrowerContactState = ({ application }: RootState) => application.borrower_contact_state_or_province;

export const getBorrowerContactCity = ({ application }: RootState) => application.borrower_contact_city;

export const getBorrowerOwnershipTerm = ({ application }: RootState) => application.ownership_term_less_1_year;

export const getCoborrowerExists = ({ application }: RootState) => application.coborrower_application;

export const getCoborrowerCitizenshipStatus = ({ coborrower }: RootState) => coborrower.coborrower_citizenship_status;

export const getCoborrowerFirstName = ({ coborrower }: RootState) => coborrower.coborrower_first_name;

export const getCoborrowerLastName = ({ coborrower }: RootState) => coborrower.coborrower_last_name;

export const getCoborrowerDateOfBirth = ({ coborrower }: RootState) => coborrower.coborrower_date_of_birth;

export const getCoborrowerPhone = ({ coborrower }: RootState) => coborrower.coborrower_phone;

export const getCoborrowerEmail = ({ coborrower }: RootState) => coborrower.coborrower_email;

export const getCoborrowerLives = ({ coborrower }: RootState) => coborrower.coborrower_lives_at_the_install_address;

export const getCoborrowerOwnership = ({ coborrower }: RootState) => coborrower.coborrower_ownership;

export const getCoborrowerStreetAddress = ({ coborrower }: RootState) => coborrower.coborrower_street_address;

export const getCoborrowerState = ({ coborrower }: RootState) => coborrower.coborrower_state_or_province;

export const getCoborrowerZipCode = ({ coborrower }: RootState) => coborrower.coborrower_zip_or_postal_code;

export const getCoborrowerCity = ({ coborrower }: RootState) => coborrower.coborrower_city;

export const getBorrowersEqualsOwners = ({ application }: RootState) => application.borrowers_equals_owners;

export const getIsLoading = ({ loading }: RootState) => loading.ApplicationUpdate;

export const getIsApplicationFetch = ({ loading }: RootState) => loading.ApplicationFetch;

export const getDeclineReasons = ({ decline }: RootState) => decline.reasons;

export const getBorrowerInvalidEmailForSSN = ({ decline }: RootState) => decline.borrower_invalid_email_for_ssn;

export const getCoborrowerInvalidEmailForSSN = ({ decline }: RootState) => decline.coborrower_invalid_email_for_ssn;

export const getCoborrowerEmailAlreadyInUse = ({ decline }: RootState) => decline.coborrower_email_already_in_use;

export const getBorrowerEmailAlreadyInUse = ({ decline }: RootState) => decline.borrower_email_already_in_use;

export const getIsPassedStrategy = ({ decline }: RootState) => decline.is_passed_strategy;

export const getPropertyOwner1 = ({ application }: RootState) => application.property_owner_1;

export const getPropertyOwner2 = ({ application }: RootState) => application.property_owner_2;

export const getPropertyOwner3 = ({ application }: RootState) => application.property_owner_3;

export const getPropertyType = ({ application }: RootState) => application.property_type;

export const getBorrowerEmploymentType = ({ application }: RootState) => application.borrower_employment_type;

export const getBorrowerJobTitle = ({ application }: RootState) => application.borrower_job_title;

export const getBorrowerCompanyName = ({ application }: RootState) => application.borrower_employment_company_name;

export const getBorrowerEmploymentDuration = ({ application }: RootState) => application.borrower_length_of_employment;

export const getBorrowerPreviousEmploymentType = ({ application }: RootState) =>
  application.borrower_previous_employment_type;

export const getBorrowerPreviousJobTitle = ({ application }: RootState) => application.borrower_previous_job_title;

export const getBorrowerPreviousCompanyName = ({ application }: RootState) =>
  application.borrower_previous_employment_company_name;

export const getCoborrowerEmploymentType = ({ application }: RootState) => application.coborrower_employment_type;

export const getCoborrowerJobTitle = ({ application }: RootState) => application.coborrower_job_title;

export const getCoborrowerCompanyName = ({ application }: RootState) => application.coborrower_employment_company_name;

export const getCoborrowerEmploymentDuration = ({ application }: RootState) =>
  application.coborrower_length_of_employment;

export const getCoborrowerPreviousEmploymentType = ({ application }: RootState) =>
  application.coborrower_previous_employment_type;

export const getCoborrowerPreviousJobTitle = ({ application }: RootState) => application.coborrower_previous_job_title;

export const getCoborrowerPreviousCompanyName = ({ application }: RootState) =>
  application.coborrower_previous_employment_company_name;

export const getBorrowerGrossIncome = ({ application }: RootState) => application.borrower_gross_annual_income;

export const getBorrowerPreviousGrossIncome = ({ application }: RootState) =>
  application.borrower_previous_gross_annual_income;

export const getCoborrowerGrossIncome = ({ application }: RootState) => application.coborrower_gross_annual_income;

export const getCoborrowerPreviousGrossIncome = ({ application }: RootState) =>
  application.coborrower_previous_gross_annual_income;

export const getAchDiscount = ({ application }: RootState) => application.ach_discount;

export const getApplicationId = ({ application }: RootState) => application.applicationId;

export const getApplicationStatus = ({ application }: RootState) => application.status;

export const getApplicationFriendlyStatus = ({ application }: RootState) => application.friendlyStatus;

export const getLoanTermList = ({ application }: RootState) => application.loan_term_list;

export const getIntermediaryNotFound = ({ intermediary }: RootState) => intermediary.intermediaryNotFound;

export const getIntermediaryId = ({ intermediary }: RootState) => intermediary.id;

export const getIntermediarySci = ({ intermediary }: RootState) => intermediary.intermediary_id_number;

export const getIntermediaryName = ({ intermediary }: RootState) => intermediary.intermediary_name;

export const getIntermediaryPrimaryContactFirstName = ({ intermediary }: RootState) =>
  intermediary.intermediary_primary_contact_first_name;

export const getIntermediaryPrimaryContactLastName = ({ intermediary }: RootState) =>
  intermediary.intermediary_primary_contact_last_name;

export const getIntermediaryEmail = ({ intermediary }: RootState) => intermediary.intermediary_email;

export const getAdditionalSalespersonExists = ({ application }: RootState) => application.additional_salesperson_exists;

export const getAdditionalSalespersonName = ({ application }: RootState) => application.additional_salesperson_name;

export const getAdditionalSalespersonPhone = ({ application }: RootState) => application.additional_salesperson_phone;

export const getAdditionalSalespersonEmail = ({ application }: RootState) => application.additional_salesperson_email;

export const getPropertyAddress = ({ application }: RootState) => application.propertyAddress;

export const getConfirmProjectDetails = ({ application }: RootState) => application.project_and_contract_confirmed;

export const getCoborrowerAuthorization = ({ application }: RootState) => application.coborrower_authorization;

export const getOwnerInformationCorrect = ({ application }: RootState) => application.owner_information_correct;

export const getBorrowerFicoScore = ({ application }: RootState) => application.borrower_fico_score;

export const getCoborrowerFicoScore = ({ application }: RootState) => application.coborrower_fico_score;

export const getSoftPullResult = ({ application }: RootState) => application.soft_pull_result;

export const getUploadBorrowerVoiComplete = ({ application }: RootState) => application.upload_borrower_voi_complete;

export const getFirst50Requested = ({ application }: RootState) => application.first_50_requested;

export const getFinal50Requested = ({ application }: RootState) => application.final_50_requested;

export const getFullAmountRequested = ({ application }: RootState) => application.full_amount_requested;

export const getFirst50Authorized = ({ application }: RootState) => application.first_50_authorized;

export const getFinal50Authorized = ({ application }: RootState) => application.final_50_authorized;

export const getFullAmountAuthorized = ({ application }: RootState) => application.full_amount_authorized;

export const getBorrowerMfaIncomplete = ({ customer }: RootState) => customer.isMfaIncomplete;

export const getBorrowerEmailNotVerified = ({ customer }: RootState) => customer.isEmailNotVerified;

export const getContractorEmailNotVerified = ({ intermediary }: RootState) => intermediary.isEmailNotVerified;

export const getMortgagePayment = ({ application }: RootState) => application.monthly_mortgage_payment;

export const getVoiNeeded = ({ application }: RootState) => application.voi_needed;

export const getPvKilowatts = ({ application }: RootState) => application.pv_kilowatts;

export const getRoofingRepairsDescription = ({ application }: RootState) => application.roofing_repairs_description;

export const getMakeAndModel = ({ application }: RootState) => application.heating_and_cooling_upgrades;

export const getOtherEnergyEfficiencyDescription = ({ application }: RootState) =>
  application.other_energy_efficiency_description;

export const getTotalProjectCost = ({ application }: RootState) => application.total_project_cost;

export const getTotalProjectCostMinusOutsideAmount = ({ application }: RootState) =>
  application.total_project_cost_minus_outside_amount;

export const getOutsideAmount = ({ application }: RootState) => application.outside_amount;

export const getBatterySize = ({ application }: RootState) => application.battery_size;

export const getProjectDetailsAndSignedContractComplete = ({ application }: RootState) =>
  application.project_details_and_signed_contract_complete;

export const getBorrowerId = ({ customer }: RootState) => customer.customerId;

export const getUploadCoborrowerVoiComplete = ({ application }: RootState) =>
  application.upload_coborrower_voi_complete;

export const getApplicationFicoScore = ({ application }: RootState) => application.application_fico_score;

export const getAdjustedApplicationMonthlyIncome = ({ application }: RootState) =>
  application.adjusted_application_monthly_income_amount;

export const getAdjustedApplicationMonthlyDebtPayments = ({ application }: RootState) =>
  application.adjusted_application_monthly_debt_payments;

export const getPotentialOffersDisplayed = ({ application }: RootState) => application.potential_offers_displayed;

export const getAutomatedBorrowerIdVerificationPassed = ({ application }: RootState) =>
  application.automated_borrower_id_verification_passed;

export const getAutomatedCoborrowerIdVerificationPassed = ({ application }: RootState) =>
  application.automated_coborrower_id_verification_passed;

export const getBorrowerIsLocked = ({ customer }: RootState) => customer.isLocked;

export const getSortField = ({ tableOptions }: RootState) => tableOptions.sortField;

export const getSortDirection = ({ tableOptions }: RootState) => tableOptions.sortDirection;

export const getSearchValue = ({ tableOptions }: RootState) => tableOptions.searchValue;

export const getFilterByProjectDetails = ({ tableOptions }: RootState) => tableOptions.projectDetails;

export const getFilterByStatusApplication = ({ tableOptions }: RootState) => tableOptions.statusApplication;

export const getFilterByVoi = ({ tableOptions }: RootState) => tableOptions.voi;

export const getPageIndex = ({ tableOptions }: RootState) => tableOptions.pageIndex;

export const getApplicationWithdrawn = ({ application }: RootState) => application.application_withdrawn;

export const getBorrowerSigningDocumentsComplete = ({ application }: RootState) =>
  application.borrower_signing_documents_complete;

export const getCoborrowerSigningDocumentsComplete = ({ application }: RootState) =>
  application.coborrower_signing_documents_complete;

export const getEzsolarLoanFinal50Disbursed = ({ application }: RootState) =>
  application.ezsolarloan_final_50_disbursed;

export const getEzsolarLoanFullAmountDisbursed = ({ application }: RootState) =>
  application.ezsolarloan_full_amount_disbursed;

export const getPreviousCoborrowerEmploymentDuration = ({ application }: RootState) =>
  application.coborrower_previous_length_of_employment;

export const getPreviousBorrowerEmploymentDuration = ({ application }: RootState) =>
  application.borrower_previous_length_of_employment;

export const getRootState = (state: RootState) => ({
  application: state.application,
  customer: state.customer,
  coborrower: state.coborrower,
  common: state.common,
});

export const getIntermediaryInvited = ({ application }: RootState) => application.intermediary_invited;

export const getIntermediaryFirstPaymentsPercentage = ({ intermediary }: RootState) =>
  intermediary.intermediary_first_payment_percentage;

export const getIntermediarySecondPaymentsPercentage = ({ intermediary }: RootState) =>
  intermediary.intermediary_second_payment_percentage;

export const getDischargedBankruptcyAlert = ({ application }: RootState) => application.discharged_bankruptcy_alert;
export const getDischargedBankruptcyAlertCoborrower = ({ application }: RootState) =>
  application.discharged_bankruptcy_alert_coborrower;
