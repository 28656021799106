import { PropertyType } from 'enums/DataTree';

const PROPERTIES: string[] = [
  PropertyType.Apartments,
  PropertyType.Commercial,
  PropertyType.Retail,
  PropertyType.Supermarket,
  PropertyType.SupermarketWithSpace,
  PropertyType.OfficeBuilding,
];

const VALID_PROPERTIES: string[] = [
  PropertyType.Condo,
  PropertyType.Townhouse,
  PropertyType.TownhouseRowhouse,
  PropertyType.Rowhouse,
  PropertyType.MultiFamily,
  PropertyType.MobileHome,
  PropertyType.ManufacturedHome,
];

export const checkPropertyType = (propertyType: string, checkValid = false) => {
  let result: boolean = false;
  let propertyValue: string = '';
  propertyType.split('').forEach((item) => {
    propertyValue = `${propertyValue}${item}`;
    if (checkValid && VALID_PROPERTIES.includes(propertyValue)) {
      result = true;
    }
    if (!checkValid && PROPERTIES.includes(propertyValue)) {
      result = true;
    }
  });
  return result;
};
