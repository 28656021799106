import React, { FC } from 'react';

import classnames from 'classnames/bind';

import styles from './FormContainer.module.scss';

const cx = classnames.bind(styles);

interface FormContainerProps {
  title?: string;
  className?: string;
  onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}

const FormContainer: FC<FormContainerProps> = ({ onSubmit, title, children, className }) => (
  <div className={cx(styles.formContainer, className)}>
    <form id="form" onSubmit={onSubmit} noValidate>
      {title && <div className={styles.formTitle}>{title}</div>}
      {children}
    </form>
  </div>
);

export default FormContainer;
