import { FileWithPath } from 'react-dropzone';

import { Application, VariableValue } from 'types';

import { HttpClient } from './Api';

export interface ApplicationParams {
  submitApplication(
    isRejected: boolean,
    borrowerVariables: Record<string, VariableValue>,
    applicationVariables: Record<string, VariableValue>,
    softPullDecisionId: string,
    coBorrowerVariables?: Record<string, VariableValue>,
    declineReasons?: string[],
    intermediaryId?: string,
  ): Promise<Record<string, VariableValue>>;
  updateApplication(
    applicationId: string,
    applicationVariables?: Record<string, VariableValue>,
    applicationStatus?: string,
    coborrowerId?: string,
  ): Promise<{ application: Record<string, VariableValue> }>;
}

export default class ApplicationApi extends HttpClient implements ApplicationParams {
  public async submitApplication(
    isRejected: boolean,
    borrowerVariables: Record<string, VariableValue>,
    applicationVariables: Record<string, VariableValue>,
    softPullDecisionId: string,
    coBorrowerVariables?: Record<string, VariableValue>,
    declineReasons?: string[],
    sci?: string,
  ) {
    return this.instance
      .post<Record<string, any>>('/api/submissions/application', {
        isRejected,
        borrowerVariables,
        applicationVariables,
        coBorrowerVariables,
        declineReasons,
        sci,
        softPullDecisionId,
      })
      .then((res) => res.data);
  }

  public async updateApplication(
    applicationId: string,
    applicationVariables?: Record<string, VariableValue>,
    applicationStatus?: string,
    coborrowerId?: string,
    intermediaryId?: string,
    skipAuth?: boolean,
  ) {
    const headers: any = {};
    if (skipAuth) {
      headers['x-skip-auth'] = skipAuth.toString();
    }
    return this.instance
      .put<{ application: Record<string, VariableValue> }>(
        '/api/applications',
        {
          applicationId,
          applicationVariables,
          applicationStatus,
          coborrowerId,
          intermediaryId,
        },
        {
          headers,
        },
      )
      .then((res) => res.data);
  }

  public async getApplicationByBorrowerId() {
    return this.instance.get<Application>(`/api/applications/borrower`).then((response) => response.data);
  }

  public async getApplicationByDisplayId(displayId: string) {
    return this.instance
      .get<Application>(`/api/applications/application/${displayId}`)
      .then((response) => response.data);
  }

  public async getApplications({
    search = '',
    onlyInProgress = false,
    sortField = 'createdAt',
    sortDirection = 'desc',
    offset = 0,
    count = 20,
    borrowerId = '',
    intermediaryId = '',
    voi = '',
    projectDetails = '',
    statusApplication = [''],
  }) {
    return this.instance
      .get<{ items: Application[]; total: number }>(
        `/api/applications?search=${encodeURIComponent(
          search,
        )}&sortField=${sortField}&sortDirection=${sortDirection}&offset=${offset}&count=${count}&borrowerId=${borrowerId}&intermediaryIds=${intermediaryId}&voi=${voi}&projectDetails=${projectDetails}&statusApplication=${statusApplication}&onlyInProgress=${onlyInProgress}`,
      )
      .then((res) => res.data);
  }

  public async getApplicationById(id: string) {
    return this.instance.get<Application>(`/api/applications/${id}`).then((res) => res.data);
  }

  public async uploadDocuments(applicationId: string, files: FileWithPath[], isPdfFile = false) {
    const formData = new FormData();

    files.forEach((file) => {
      formData.append('files', file);
    });

    formData.append('applicationId', applicationId);

    return this.instance
      .post(`/api/documents/${isPdfFile ? 'upload-pdf' : 'upload'}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => res.data);
  }

  public async downloadDocument(applicationId: string, documentName: string) {
    const response = await this.instance.get(
      `/api/documents?applicationId=${applicationId}&documentName=${documentName}`,
      {
        headers: {
          Accept: '*/*',
        },
        responseType: 'blob',
      },
    );
    const disposition = response.headers['content-disposition'];
    return { data: response.data, extension: disposition.slice(disposition.indexOf('=') + 1) };
  }

  public async sendInvite(
    firstName: string,
    lastName: string,
    phone: string,
    email: string,
    sci: string,
    intermediaryName: string,
  ) {
    return this.instance
      .post('/api/submissions/invitation', {
        firstName,
        lastName,
        phone,
        email,
        sci,
        intermediaryName,
      })
      .then((res) => res.data);
  }

  public async sendLockedNotifications(firstName: string, lastName: string, email: string, phone: string) {
    return this.instance
      .post('/api/submissions/locked', {
        firstName,
        lastName,
        email,
        phone,
      })
      .then((res) => res.data);
  }

  public async runDecision(applicationId: string, strategyName: string) {
    return this.instance
      .post('/api/applications/application-decision-processing', {
        applicationId,
        strategyName,
      })
      .then((res) => res.data);
  }
}
