import React from 'react';

import NavigationCards from 'components/NavigationCards';
import { NavigationCard } from 'components/NavigationCards/NavigationCards';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { Routes } from 'types';

const ContractorDashboardCards: NavigationCard[] = [
  {
    title: 'Invite customer to apply',
    description:
      'Click here to invite a customer via email to apply for an ezSolarLoan through a personalized link connected to your Solar company',
    url: Routes.ContractorInvite,
  },
  {
    title: 'View deals',
    description:
      'Click here to view the status of all your current deals, and select the one you need to enter project details, upload documents, request funds and more',
    url: Routes.ContractorDeals,
  },
];

const ContractorDashboard = () => <NavigationCards items={ContractorDashboardCards} />;

export default withPortalLayout(ContractorDashboard);
