import React, { FC, useState } from 'react';

import IconButton from 'common/components/IconButton/IconButton';

import styles from './SelectionPanel.module.scss';

interface IIconButton {
  title: string;
  type: string;
  icon: JSX.Element;
}

interface SelectionPanelProps {
  buttons: IIconButton[];
  selected: string[];
  disableButtons?: boolean | undefined;
  setCheckedOptions?: (options: string[]) => void;
  singleSelection?: boolean;
}

const IconButtonsCheckBox: FC<SelectionPanelProps> = ({
  buttons,
  disableButtons,
  setCheckedOptions,
  selected,
  singleSelection,
}) => {
  const [selectedOptions, setSelectedOption] = useState(selected);

  const toggleSelected = (type: string) => {
    if (singleSelection) {
      if (selectedOptions[0] === type) return [];
      return [type];
    }
    return selectedOptions.includes(type) ? selectedOptions.filter((opt) => opt !== type) : [...selectedOptions, type];
  };

  const handleOnClick = (type: string) => {
    const newSelectedOption = toggleSelected(type);
    setSelectedOption(newSelectedOption);
    setCheckedOptions?.(newSelectedOption);
  };

  return (
    <div className={styles.wrapper}>
      {buttons.map(({ icon, type, title }) => (
        <IconButton
          key={type}
          icon={icon}
          title={title}
          disabled={disableButtons}
          onClick={() => handleOnClick(type)}
          selected={selectedOptions.includes(type)}
        />
      ))}
    </div>
  );
};

export default IconButtonsCheckBox;
