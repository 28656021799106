import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CommonTypes } from './types';

interface CommonState {
  [CommonTypes.DataTreeLink]: string;
}

const initialState: CommonState = {
  [CommonTypes.DataTreeLink]: '',
};

const common = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCommonState(state: CommonState, { payload }: PayloadAction<Partial<CommonState>>) {
      state = { ...state, ...payload };

      return state;
    },
  },
});

export const { setCommonState } = common.actions;

export default common.reducer;
