import { createAsyncThunk } from '@reduxjs/toolkit';

import BorrowerApi from 'api/BorrowerApi';
import { LoadingType, setLoadingState } from 'handlers/loading';
import { VariableValue } from 'types';

export const createCreateOrUpdateCoborrowerThunk = (borrowerApi: BorrowerApi) =>
  createAsyncThunk(
    'borrowers/createCoborrower',
    async (CoborrowerVariables: Record<string, VariableValue>, { dispatch }) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        return await borrowerApi.createOrUpdateCoborrower(CoborrowerVariables);
      } catch (err) {
        return null;
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
