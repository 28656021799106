import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as BorrowerIcon } from 'assets/icons/Borrower.svg';
import { ReactComponent as CoborrowerIcon } from 'assets/icons/Coborrower.svg';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import Checkbox from 'common/components/Checkbox';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import SelectionPanel from 'components/SelectionPanel';
import { setApplicationData, setCoborrowerExists } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { UserInformationKeys } from 'handlers/customer/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { nextStep } from 'handlers/steps';
import { BasicInformationSteps } from 'handlers/steps/types';
import styles from 'pages/pages.module.scss';
import {
  getBorrowerCitizenshipStatus,
  getBorrowerDateOfBirth,
  getBorrowerMfaIncomplete,
  getCoborrowerAuthorization,
  getCoborrowerExists,
  getIsLoading,
} from 'selectors';
import { useAppDispatch } from 'store';
import { runStrategy } from 'thunks';
import { Routes, StrategyName } from 'types';

const convertYesNoToBoolean = (string: string | null) => {
  switch (string) {
    case 'yes':
      return true;
    case 'no':
      return false;
    default:
      return null;
  }
};

const convertBooleanToYesNo = (boolean: boolean | null) => {
  switch (boolean) {
    case true:
      return 'yes';
    case false:
      return 'no';
    default:
      return '';
  }
};

const coborrowerExistsOptions = [
  {
    title: 'No, just me',
    type: 'no',
    icon: <BorrowerIcon />,
  },
  {
    title: 'Yes, add a co-borrower',
    type: 'yes',
    icon: <CoborrowerIcon />,
  },
];

const NumberOfBorrowers = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const coborrowerExists = useSelector(getCoborrowerExists);
  const borrowerDateOfBirth = useSelector(getBorrowerDateOfBirth);
  const borrowerCitizenshipStatus = useSelector(getBorrowerCitizenshipStatus);
  const loading = useSelector(getIsLoading);
  const authToken = localStorage.getItem('borrowerToken');
  const isMfaIncomplete = useSelector(getBorrowerMfaIncomplete);

  const [exist, setExist] = useState(coborrowerExists === null ? [] : [convertBooleanToYesNo(coborrowerExists)]);
  const [checkedAgrement, setCheckedAgrement] = useState(useSelector(getCoborrowerAuthorization) || false);

  const isFormValid = () => !!exist.length && !loading && (exist[0] === 'no' || checkedAgrement);

  const handleBackClick = () => {
    dispatch(setCurrentStage({ stage: StagesType.BasicInformation, step: BasicInformationSteps.Contacts }));
  };

  const handleSubmit = async () => {
    const isCoborrowerExist = convertYesNoToBoolean(exist[0]);
    dispatch(setCoborrowerExists(isCoborrowerExist));
    if (isCoborrowerExist) {
      dispatch(
        setApplicationData({
          [ApplicationVariables.CoborrowerAuthorization]: checkedAgrement,
        }),
      );
      dispatch(nextStep());
    } else {
      const strategyResult = await dispatch(
        runStrategy({
          strategyName: StrategyName.EligibilityCheck1,
          strategyInputVariables: {
            [UserInformationKeys.DateOfBirth]: borrowerDateOfBirth,
            [UserInformationKeys.CitizenshipStatus]: borrowerCitizenshipStatus,
            [ApplicationVariables.CoborrowerApplication]: false,
          },
        }),
      ).unwrap();

      if (strategyResult.declined) return;

      dispatch(setCurrentStage({ stage: StagesType.Installation }));
    }
  };

  useEffect(() => {
    if (authToken && isMfaIncomplete) {
      history.push(Routes.BorrowerLogin);
    }
  }, [authToken, isMfaIncomplete]);

  return (
    <>
      <p>
        All owners of the property installation address are required to apply. An automated property lookup will be used
        in the next step to check ownership.
      </p>
      <FormContainer className={styles.paragraph} title="Is there a co-borrower?">
        <div className={styles.selectionPanelWrapper}>
          <SelectionPanel
            buttons={coborrowerExistsOptions}
            setCheckedOptions={(option) => setExist(option)}
            selected={exist}
            singleSelection
          />
        </div>
        {exist[0] === 'yes' && (
          <p className={styles.paragraph}>
            <Checkbox
              label="I have access and authorization to provide information on behalf of the co-borrower"
              checked={checkedAgrement}
              onChange={() => setCheckedAgrement(!checkedAgrement)}
            />
          </p>
        )}
        <NavigationButtonWrapper>
          <BackButton onClick={handleBackClick} />
          <NextButton disabled={!isFormValid()} loading={loading} onClick={handleSubmit} />
        </NavigationButtonWrapper>
      </FormContainer>
    </>
  );
};

export default NumberOfBorrowers;
