import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';

interface ResendEmailCodeProps {
  code?: string;
}

export const createRequestEmailVerificationForBorrowerThunk = (accountApi: AccountApi) =>
  // eslint-disable-next-line no-empty-pattern
  createAsyncThunk('account/borrower/mfaCode', async ({}: ResendEmailCodeProps, { rejectWithValue }) => {
    try {
      return await accountApi.sendEmailCode();
    } catch (e) {
      rejectWithValue(null);
    }
  });

export const createRequestEmailVerificationForContractorThunk = (accountApi: AccountApi) =>
  // eslint-disable-next-line no-empty-pattern
  createAsyncThunk('account/contractor/mfaCode', async ({}: ResendEmailCodeProps, { rejectWithValue }) => {
    try {
      return await accountApi.sendEmailCodeForContractor();
    } catch (e) {
      rejectWithValue(null);
    }
  });
