import { MarkProps } from 'components/PriceSlider/Slider';

import { convertMonetary } from './formats';

const getMarksNumArray = () => {
  const array = [];
  let mark = 4000;
  while (mark < 40000) {
    mark += 1000;
    array.push(mark);
  }
  while (mark < 80000) {
    mark += 5000;
    array.push(mark);
  }
  return array;
};

const getMarksObject = (array: number[]): MarkProps[] =>
  array.map((mark: number) =>
    mark === 80000 || mark === 5000 ? { value: mark, label: `${convertMonetary(mark)}` } : { value: mark },
  );

export const getMarks = () => getMarksObject(getMarksNumArray());
