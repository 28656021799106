export enum CoborrowerInfoKeys {
  Email = 'coborrower_email',
  PhoneNumber = 'coborrower_phone',
  FirstName = 'coborrower_first_name',
  LastName = 'coborrower_last_name',
  DateOfBirth = 'coborrower_date_of_birth',
  CitizenshipStatus = 'coborrower_citizenship_status',
  CoborrowerLives = 'coborrower_lives_at_the_install_address',
  CoborrowerOwnership = 'coborrower_ownership',
  SSN = 'coborrower_manual_ssn',
  StreetAddress = 'coborrower_street_address',
  State = 'coborrower_state_or_province',
  City = 'coborrower_city',
  ZipOrPostalCode = 'coborrower_zip_or_postal_code',
}
