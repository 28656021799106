import React, { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { FieldValues, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import Checkbox from 'common/components/Checkbox';
import NumberInput from 'common/components/NumberInput';
import RadioButton from 'common/components/RadioButton/RadioButton';
import TextInput from 'common/components/TextInput';
import InputErrorMessage from 'common/components/TextInput/InputErrorMessage';
import DropDown from 'components/DropDown';
import { OptionType } from 'components/DropDown/DropDown';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { DeclineReasons } from 'enums/DeclineReasons';
import {
  getAuthorizedSignerAccountErrorMessage,
  getNumberInputInvalidMessage,
  getRequiredErrorMessage,
} from 'errors/errors';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { CoborrowerInfoKeys } from 'handlers/coborrower/types';
import { UserInformationKeys } from 'handlers/customer/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import pages from 'pages/pages.module.scss';
import { getIsLoading, getRootState } from 'selectors';
import { useAppDispatch } from 'store';
import { createSun, runApplicationDecision, signLoanDocuments, updateApplication } from 'thunks';
import { ApplicationStatus, HardPullStatus, Routes, StrategyName, VariableValue } from 'types';
import { getCurrentDate } from 'utils/dateHelper';
import { deleteSpaceInStart } from 'utils/formats';
import { notify } from 'utils/notificationHelper';
import { isExistErrors } from 'utils/validationHelper';

import styles from './LoanDocuments.module.scss';

const cx = classNames.bind(pages);

const REQUEST_RECEIVED =
  'Your request has been received. The ezSolarLoan Team needs to review it prior to sending your loan documents and will be in touch again soon';
const WASHINGTON_STATE = 'WA';

enum PageType {
  Review = 'Review',
  RequestAndSign = 'RequestAndSign',
  RequestAndSignWithBank = 'RequestAndSignWithBank',
}

enum InputLabel {
  RoutingNumber = 'Routing number',
  AccountNumber = 'Account number',
  AccountType = 'Account type',
  InstitutionName = 'Institution name',
  AuthorizedSignerAccount = 'One option is required',
}

enum InputName {
  RoutingNumber = 'routingNumber',
  AccountNumber = 'accountNumber',
  AccountType = 'accountType',
  InstitutionName = 'institutionName',
  AuthorizedSignerAccount = 'authorizedSignerAccount',
}

enum InputLength {
  RoutingNumberMin = 9,
  RoutingNumberMax = 9,
  AccountNumberMin = 5,
  AccountNumberMax = 15,
}

enum AccountType {
  SavingsAccount = 'Savings Account',
  CheckingAccount = 'Checking Account',
}

const accountTypeList = [
  { value: AccountType.SavingsAccount, label: AccountType.SavingsAccount },
  { value: AccountType.CheckingAccount, label: AccountType.CheckingAccount },
];

const LoanDocuments = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {
    register,
    watch,
    setValue,
    clearErrors,
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
  } = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const [agreedAccountDisclosures, setAgreedAccountDisclosures] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [pageType, setPageType] = useState<null | PageType>(null);
  const { application, customer, coborrower } = useSelector(getRootState);

  const loading = useSelector(getIsLoading);
  const watcher = watch();

  const creditFreeze = async (isBorrowerCreditFreeze: boolean, isCoborrowerCreditFreeze: boolean) => {
    const borrowerName = `${customer[UserInformationKeys.FirstName]} ${customer[UserInformationKeys.LastName]}`;
    const coborrowerName = `${coborrower[CoborrowerInfoKeys.FirstName]} ${coborrower[CoborrowerInfoKeys.LastName]}`;

    const getNameByCreditFreeze = () => {
      if (isBorrowerCreditFreeze && isCoborrowerCreditFreeze) return `${borrowerName} ${coborrowerName}`;
      return isBorrowerCreditFreeze && !isCoborrowerCreditFreeze ? borrowerName : coborrowerName;
    };

    await dispatch(
      updateApplication({
        applicationId: application.applicationId,
        applicationStatus: ApplicationStatus.ExceptionProcessing,
      }),
    );

    notify({
      notification: `
        We were unable to pull a credit report for ${getNameByCreditFreeze()}. Please remove any credit freeze in place for Equifax and then contact us at (800) 493-1310 so that we can try again.`,
    });
    return history.push(Routes.BorrowerDashboard);
  };

  const isValid =
    !application[ApplicationVariables.RequestLoanDocuments] && !application[ApplicationVariables.AgreedRunHardPull];
  const isWashingtonState =
    application[ApplicationVariables.BorrowerContactState] === WASHINGTON_STATE ||
    coborrower[CoborrowerInfoKeys.State] === WASHINGTON_STATE;

  const isValidByStatusAndJoinSun = (app: Record<string, VariableValue>) =>
    (app[ApplicationVariables.JoinSunComplete] === true || app[ApplicationVariables.JoinSunComplete] == null) &&
    (app[ApplicationVariables.HardPullResult] === HardPullStatus.Approved ||
      app[ApplicationVariables.HardPullResult] === HardPullStatus.Received ||
      app[ApplicationVariables.HardCreditPullOverride]);

  const getPageType = () => {
    if (
      isValidByStatusAndJoinSun({
        [ApplicationVariables.JoinSunComplete]: application[ApplicationVariables.JoinSunComplete],
        [ApplicationVariables.HardPullResult]: application[ApplicationVariables.HardPullResult],
        [ApplicationVariables.HardCreditPullOverride]: application[ApplicationVariables.HardCreditPullOverride],
      })
    )
      return PageType.Review;
    if (isValid && !application[ApplicationVariables.ACHDiscount]) return PageType.RequestAndSign;
    return PageType.RequestAndSignWithBank;
  };

  const handleRadioButton = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(InputName.AuthorizedSignerAccount, e.target.value === 'true');
    clearErrors(InputName.AuthorizedSignerAccount);
  };

  const handleAccountTypeChange = (value: OptionType) => setValue(InputName.AccountType, value);
  const getPageMainContent = (type: PageType | null) => {
    const subTitle = (
      <p className={styles.subTitle}>Press the button below to request, review and sign your loan documents</p>
    );
    const checkBox = (
      <>
        <Checkbox
          className={styles.checkBox}
          label="I understand and agree that a hard credit inquiry will appear on my credit report."
          onClick={() => setAgreed(!agreed)}
          {...register('agreement', {
            required:
              type === PageType.RequestAndSign || type === PageType.RequestAndSignWithBank
                ? getRequiredErrorMessage('Agreement')
                : false,
          })}
        />
        {errors.agreement?.message && (
          <InputErrorMessage backgroundColor="portalErrorBackground">{errors.agreement.message}</InputErrorMessage>
        )}
        <Checkbox
          className={styles.secondCheckBox}
          label={
            <p>
              By checking this box, I acknowledge that I have read and agree to the terms and conditions of the{' '}
              <a
                className={styles.noStyleLink}
                href="https://ezsolarloan.com/account-disclosures"
                target="_blank"
                rel="noreferrer"
              >
                Account Disclosures
              </a>{' '}
              that govern the account(s) and services I requested, including the Membership and Account Agreement, Funds
              Availability Policy, Electronic Services Agreement, Privacy Policy, and Truth in Savings Disclosures.
            </p>
          }
          onClick={() => setAgreedAccountDisclosures(!agreedAccountDisclosures)}
          {...register('agreementAccountDisclosures', {
            required:
              type === PageType.RequestAndSign || type === PageType.RequestAndSignWithBank
                ? getRequiredErrorMessage('Agreement')
                : false,
          })}
        />
        {errors.agreementAccountDisclosures?.message && (
          <InputErrorMessage backgroundColor="portalErrorBackground">
            {errors.agreementAccountDisclosures.message}
          </InputErrorMessage>
        )}
      </>
    );
    switch (type) {
      case PageType.Review:
        return <p>You may now generate, review and sign your loan documents using DocuSign.</p>;
      case PageType.RequestAndSign:
        return (
          <>
            {subTitle}
            {checkBox}
          </>
        );
      case PageType.RequestAndSignWithBank:
        return (
          <>
            {subTitle}
            <p>
              In your application, you chose to benefit from auto-payments and the 0.25% discount on the interest rate.
              Please provide the following information for the account where payments will come from.
            </p>
            <div className={cx(pages.textInputLabel, styles.label)}>What is the institution name?</div>
            <div className={cx(pages.flexInputContainer, pages.inputContainer)}>
              <TextInput
                {...register(InputName.InstitutionName, {
                  required: getRequiredErrorMessage(InputLabel.InstitutionName),
                })}
                inputContainerClassName={pages.inputChildContainer}
                value={deleteSpaceInStart(watcher[InputName.InstitutionName])}
                errorMessage={errors[InputName.InstitutionName]?.message}
                label={InputLabel.InstitutionName}
                name={InputName.InstitutionName}
                maxLength={100}
                errorBackgroundType="portalErrorBackground"
              />
            </div>
            <div className={pages.textInputLabel}>What is the account type?</div>
            <div className={cx(pages.dropdownContainer, pages.inputContainer)}>
              <DropDown
                control={control}
                className={styles.inputChildContainer}
                selectedOption={watcher[InputName.AccountType]}
                onValuePicked={handleAccountTypeChange}
                options={accountTypeList}
                placeholder={InputLabel.AccountType}
                name={InputName.AccountType}
                invalid={!!errors[InputName.AccountType]?.message}
                isSearchable={false}
                onChange={() => clearErrors(InputName.AccountType)}
              />
              {!!errors[InputName.AccountType]?.message && (
                <InputErrorMessage backgroundColor="portalErrorBackground">
                  {errors[InputName.AccountType]?.message}
                </InputErrorMessage>
              )}
            </div>
            <div className={pages.textInputLabel}>What are the routing number and account number?</div>
            <div className={cx(pages.flexInputContainer, styles.inputContainer)}>
              <NumberInput
                inputContainerClassName={pages.inputChildContainer}
                value={deleteSpaceInStart(watcher[InputName.RoutingNumber])}
                errorMessage={errors.routingNumber?.message}
                label={InputLabel.RoutingNumber}
                name={InputName.RoutingNumber}
                minLength={InputLength.RoutingNumberMin}
                maxLength={InputLength.RoutingNumberMax}
                control={control}
                errorBackgroundType="portalErrorBackground"
                allowLeadingZeros
              />
              <NumberInput
                inputContainerClassName={pages.inputChildContainer}
                value={deleteSpaceInStart(watcher[InputName.AccountNumber])}
                errorMessage={errors.accountNumber?.message}
                label={InputLabel.AccountNumber}
                name={InputName.AccountNumber}
                minLength={InputLength.AccountNumberMin}
                maxLength={InputLength.AccountNumberMax}
                control={control}
                errorBackgroundType="portalErrorBackground"
                allowLeadingZeros
              />
            </div>
            <div className={pages.textInputLabel}>Are you an authorized signer on this account?</div>
            <div>
              <RadioButton
                containerClassName={pages.radioButtonWithMargin}
                label="Yes"
                onChange={handleRadioButton}
                id="authorizedSigner"
                value="true"
                checked={!!watcher[InputName.AuthorizedSignerAccount]}
              />
              <RadioButton
                label="No"
                onChange={handleRadioButton}
                id="notAuthorizedSigner"
                value="false"
                checked={watcher[InputName.AuthorizedSignerAccount] === false}
              />
              {errors[InputName.AuthorizedSignerAccount]?.message && (
                <InputErrorMessage backgroundColor="portalErrorBackground">
                  {errors[InputName.AuthorizedSignerAccount]?.message}
                </InputErrorMessage>
              )}
            </div>
            <p className={styles.note}>
              Please note that this information is being provided in order for us to process your loan payments
              automatically. Providing this information does not mean that payments are due now, and you understand and
              agree that automatic payments will begin according to your loan agreement. There will be additional
              disclosures in your loan document set that will include more information about automatic payments. BY
              CLICKING THE CONTINUE BUTTON BELOW, YOU CERTIFY THAT YOU (AND/OR THE CO-BORROWER IF ANY) ARE AN AUTHORIZED
              SIGNER ON THIS ACCOUNT WITH THE AUTHORITY TO SET UP AUTOMATIC DEBITS.
            </p>
            {checkBox}
          </>
        );
      default:
        break;
    }
  };

  const signingLoanDocuments = async () => {
    const signLoanDocsUrl = await dispatch(
      signLoanDocuments({
        applicationId: application.applicationId,
      }),
    ).unwrap();
    if (signLoanDocsUrl) window.location.href = signLoanDocsUrl.url;
  };

  const isDisabled = (type: PageType) => {
    switch (type) {
      case PageType.RequestAndSign:
        return !agreed || !agreedAccountDisclosures;
      case PageType.RequestAndSignWithBank:
        return (
          !agreed ||
          !agreedAccountDisclosures ||
          !watcher[InputName.AccountNumber] ||
          !watcher[InputName.RoutingNumber] ||
          watcher[InputName.AuthorizedSignerAccount] === undefined ||
          !watcher[InputName.InstitutionName] ||
          (isSubmitted && isExistErrors(errors))
        );
      case PageType.Review:
        return false;
      default:
        break;
    }
  };
  const onSubmit = async (data: FieldValues) => {
    let joinSunResult;
    const appVariables = {
      [ApplicationVariables.RequestLoanDocuments]: true,
      [ApplicationVariables.AgreedRunHardPull]: true,
      [ApplicationVariables.AgreedToAccountDisclosures]: true,
    };
    if (getPageType() === PageType.RequestAndSignWithBank) {
      Object.assign(appVariables, {
        [ApplicationVariables.RoutingNumber]: String(data[InputName.RoutingNumber]),
        [ApplicationVariables.AccountNumber]: String(data[InputName.AccountNumber]),
        [ApplicationVariables.AccountType]: data[InputName.AccountType].value,
        [ApplicationVariables.AuthorizedSignerAccount]: watcher[InputName.AuthorizedSignerAccount] as boolean,
        [ApplicationVariables.InstitutionName]: data[InputName.InstitutionName],
      });
    }
    await dispatch(
      updateApplication({
        applicationId: application.applicationId,
        applicationVariables: appVariables,
      }),
    );

    if (
      isValidByStatusAndJoinSun({
        [ApplicationVariables.JoinSunComplete]: application[ApplicationVariables.JoinSunComplete],
        [ApplicationVariables.HardPullResult]: application[ApplicationVariables.HardPullResult],
        [ApplicationVariables.HardCreditPullOverride]: application[ApplicationVariables.HardCreditPullOverride],
      })
    )
      return signingLoanDocuments();

    if (!isWashingtonState) {
      const createSunResult = await dispatch(
        createSun({
          email: customer[UserInformationKeys.Email],
          firstName: customer[UserInformationKeys.FirstName],
          lastName: customer[UserInformationKeys.LastName],
          mailingStreet: application[ApplicationVariables.BorrowerContactStreetAddress],
          mailingCity: application[ApplicationVariables.BorrowerContactCity],
          mailingState: application[ApplicationVariables.BorrowerContactState],
          mailingPostalCode: application[ApplicationVariables.BorrowerContactZipCode],
          phone: customer[UserInformationKeys.PhoneNumber],
        }),
      ).unwrap();

      joinSunResult = createSunResult.success;
    }
    const strategyResult = await dispatch(
      runApplicationDecision({
        strategyName: StrategyName.HardCreditPull,
        applicationId: application.applicationId,
      }),
    ).unwrap();
    const borrowerCreditFreeze = strategyResult?.declineReasons.includes(DeclineReasons.BorrowerCreditReportIsFrozen);
    const coborrowerCreditFreeze = strategyResult?.declineReasons.includes(
      DeclineReasons.CoborrowerCreditReportIsFrozen,
    );

    const updateResult: any = await dispatch(
      updateApplication({
        applicationId: application.applicationId,
        applicationVariables: {
          [ApplicationVariables.HardPullResult]: strategyResult?.outputVariables[ApplicationVariables.HardPullResult],
          [ApplicationVariables.JoinSunComplete]: !isWashingtonState ? !!joinSunResult : null,
          [ApplicationVariables.JoinSunCompleteDate]: !isWashingtonState ? getCurrentDate() : null,
        },
      }),
    ).unwrap();
    if (borrowerCreditFreeze || coborrowerCreditFreeze)
      return creditFreeze(borrowerCreditFreeze, coborrowerCreditFreeze);

    if (isValidByStatusAndJoinSun(updateResult.application.variables)) return signingLoanDocuments();

    await dispatch(
      setApplicationData({
        status: ApplicationStatus.ExceptionProcessing,
      }),
    );

    notify({ notification: REQUEST_RECEIVED });
    history.push(Routes.BorrowerDashboard);
  };

  const backClick = () => history.push(Routes.BorrowerDashboard);

  const getAuthorizedSignerAccountError = (state: boolean) => {
    if (state === false) {
      return getAuthorizedSignerAccountErrorMessage();
    }
    if (state === undefined) {
      return getRequiredErrorMessage(InputLabel.AuthorizedSignerAccount);
    }
    return undefined;
  };

  const zeroCheck = (value: number) =>
    String(value)
      .split('')
      .every((num) => Number(num) === 0);

  useEffect(() => {
    if (getPageType() === PageType.RequestAndSignWithBank) {
      register(InputName.RoutingNumber, {
        required: getRequiredErrorMessage(InputLabel.RoutingNumber),
        minLength: {
          value: InputLength.RoutingNumberMin,
          message: getNumberInputInvalidMessage(InputLength.RoutingNumberMin),
        },
        validate: (value) => (zeroCheck(value) ? 'Wrong number' : true),
      });
      register(InputName.AccountNumber, {
        required: getRequiredErrorMessage(InputLabel.AccountNumber),
        minLength: {
          value: InputLength.AccountNumberMin,
          message: getNumberInputInvalidMessage(InputLength.AccountNumberMin, InputLength.AccountNumberMax),
        },
        validate: (value) => (zeroCheck(value) ? 'Wrong number' : true),
      });
      register(InputName.AccountType, {
        required: getRequiredErrorMessage(InputLabel.AccountType),
      });
      register(InputName.AuthorizedSignerAccount, {
        required: getAuthorizedSignerAccountError(watcher[InputName.AuthorizedSignerAccount]),
      });
    }
  }, [register, watcher[InputName.AuthorizedSignerAccount]]);

  useEffect(() => {
    setPageType(pageType || getPageType());
  }, []);

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      {getPageMainContent(pageType)}
      <NavigationButtonWrapper>
        <BackButton onClick={backClick} className={commonStyles.portalBackButton} />
        <NextButton type="submit" inactive={isDisabled(getPageType())} loading={loading} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default withPortalLayout(LoanDocuments);
