import React, { FC } from 'react';

import classnames from 'classnames/bind';

import styles from './IconButton.module.scss';

interface IIconButtonProps extends React.HTMLProps<HTMLButtonElement> {
  icon: JSX.Element;
  title: string;
  onClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
  selected?: boolean;
}

const cx = classnames.bind(styles);

const IconButton: FC<IIconButtonProps> = ({ selected, disabled, icon, title, onClick }) => (
  <button
    className={cx({
      [styles.iconButton]: !selected,
      [styles.selectedIconButton]: selected,
    })}
    onClick={onClick}
    disabled={disabled}
    type="button"
  >
    <div className={styles.svgWrapper}>{icon}</div>
    <div className={styles.titleWrapper}>{title}</div>
  </button>
);

export default IconButton;
