import React from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import NavigationCards from 'components/NavigationCards';
import { NavigationCard } from 'components/NavigationCards/NavigationCards';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { getApplicationId, getApplicationStatus, getCoborrowerExists, getPotentialOffersDisplayed } from 'selectors';
import { ApplicationStatus, Routes } from 'types';

import styles from './BorrowerPortal.module.scss';

const resumeAndDocumentsRequiredStatuses = [ApplicationStatus.PreQualified, ApplicationStatus.CompletingVerification];
const coborrowerCardRequiredStatuses = [
  ApplicationStatus.ManualProcessorReview,
  ApplicationStatus.ManualUnderwritingReview,
  ApplicationStatus.PreQualified,
  ApplicationStatus.CompletingVerification,
];
const offerCardRequiredStatuses = [
  ApplicationStatus.ManualProcessorReview,
  ApplicationStatus.ManualUnderwritingReview,
  ApplicationStatus.PreQualified,
  ApplicationStatus.CompletingVerification,
  ApplicationStatus.FinishingApplication,
];

const BorrowerDetailsCards = (
  isResumeAndDocumentsDisabled: boolean,
  isCoborrowerDisabled: boolean,
  isOffersDisabled: boolean,
  applicationId: string,
): NavigationCard[] => [
  {
    title: 'Pick up where you left off',
    description: 'Click here to complete your ezSolarLoan application by picking up where you most recently left off',
    url: `/apply?applicationId=${applicationId}`,
    className: styles.resumeCard,
    disabled: !isResumeAndDocumentsDisabled,
  },
  {
    title: 'Add co-borrower',
    description: 'Click here to add a Co-Borrower to your ezSolarLoan application',
    url: Routes.BorrowerCoborrower,
    disabled: !isCoborrowerDisabled,
  },
  {
    title: 'Upload documents',
    description:
      'Click here to upload documents that are needed such as verification of income, identification, or letters of explanation',
    url: Routes.BorrowerDocuments,
    disabled: isResumeAndDocumentsDisabled,
  },
  {
    title: 'Update salesperson info',
    description:
      'Click here to update or change the salesperson or other representative of your Solar Company that you’d like to keep up to date',
    url: Routes.BorrowerSalesperson,
    disabled: isResumeAndDocumentsDisabled,
  },
  {
    title: 'Change rate and payment option',
    description:
      'Click here to select (or change) a rate and payment option based on the loan terms and products available',
    url: Routes.BorrowerPaymentRate,
    disabled: isOffersDisabled,
  },
];

const BorrowerDetails = () => {
  const history = useHistory();

  const applicationStatus = useSelector(getApplicationStatus);
  const coborrowerExist = useSelector(getCoborrowerExists);
  const applicationId = useSelector(getApplicationId);

  const isCoborrowerCardDisabledByStatus = coborrowerCardRequiredStatuses.includes(
    applicationStatus as ApplicationStatus,
  );

  const isResumeAndDocumentsDisabled = resumeAndDocumentsRequiredStatuses.includes(
    applicationStatus as ApplicationStatus,
  );

  const isOffersDisabled =
    useSelector(getPotentialOffersDisplayed) === true ||
    !offerCardRequiredStatuses.includes(applicationStatus as ApplicationStatus);

  const isCoborrowerDisabled = !coborrowerExist && isCoborrowerCardDisabledByStatus;

  const handleBackClick = () => history.push(Routes.BorrowerDashboard);

  return (
    <>
      <NavigationCards
        items={BorrowerDetailsCards(
          isResumeAndDocumentsDisabled,
          isCoborrowerDisabled,
          isOffersDisabled,
          applicationId,
        )}
      />
      <NavigationButtonWrapper>
        <BackButton className={commonStyles.portalBackButton} onClick={handleBackClick} />
      </NavigationButtonWrapper>
    </>
  );
};

export default withPortalLayout(BorrowerDetails);
