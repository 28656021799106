import React from 'react';

import { Routes } from 'types';

import styles from './TableNoResult.module.scss';

const TableNoResult = () => (
  <div className={styles.container}>
    <p className={styles.title}>Sorry, no results found</p>
    <ul>
      <li className={styles.listItem}>
        If you don't have any applications yet, feel free to{' '}
        <a href={Routes.ContractorInvite} className={styles.link}>
          invite customer to apply.
        </a>
      </li>
      <li className={styles.listItem}>If using 'Search', try changing your search terms.</li>
      <li className={styles.listItem}>If using 'Filters', try resetting or choosing another filter.</li>
    </ul>
  </div>
);

export default TableNoResult;
