import React from 'react';

import { withPortalLayout } from 'layouts/withPortalLayout';
import ContactUs from 'pages/DeclinePage/ContactUs';
import pages from 'pages/pages.module.scss';

import styles from './PortalContactUs.module.scss';

const PortalContactUs = () => (
  <>
    <div className={pages.externalPage}>
      <div className={pages.externalPageContent}>
        <div className={styles.title}>Unfortunately, you can't apply for an ezSolarLoan at this time.</div>
        <div className={pages.externalPageMainContent}>
          <ContactUs />
        </div>
      </div>
    </div>
  </>
);

export default withPortalLayout(PortalContactUs);
