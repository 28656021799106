import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import CodeVerification from 'components/CodeVerification';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import PageLayout from 'layouts';
import styles from 'pages/pages.module.scss';
import { getBorrowerPhone, getIsLoading } from 'selectors';
import { useAppDispatch } from 'store';
import { requestPhoneVerificationForBorrower, verifyPhoneForBorrower } from 'thunks';

const PhoneVerificationPage = () => {
  const dispatch = useAppDispatch();
  const loading = useSelector(getIsLoading);
  const phone = useSelector(getBorrowerPhone);
  const [isPhoneCodeValid, setIsPhoneCodeValid] = useState<boolean | null>(null);

  const { control } = useForm();

  useEffect(() => {
    dispatch(requestPhoneVerificationForBorrower({}));
  }, []);

  const handleResendCode = () =>
    dispatch(
      requestPhoneVerificationForBorrower({
        phone,
      }),
    );

  const handleCodeEntered = async (value: string) => {
    const result = await dispatch(
      verifyPhoneForBorrower({
        code: value,
      }),
    ).unwrap();
    setIsPhoneCodeValid(!!result);
  };

  useEffect(() => {
    if (isPhoneCodeValid && !loading) {
      dispatch(setCurrentStage({ stage: StagesType.UploadDocuments }));
    }
  }, [isPhoneCodeValid, loading]);

  return (
    <PageLayout title="Phone Verification">
      <div className={styles.codeVerificationLabel}>We will send you a 6-digit verification code to {phone}.</div>
      <CodeVerification
        notificationWhiteBackground={false}
        control={control}
        loading={loading}
        onChange={handleCodeEntered}
        isCodeValid={!!isPhoneCodeValid}
        resendCode={handleResendCode}
      />
    </PageLayout>
  );
};

export default PhoneVerificationPage;
