import React, { useEffect } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button from 'common/components/Button';
import { AlignmentType, ButtonVariant } from 'common/components/Button/Button';
import { ReactComponent as ForwardArrowIcon } from 'common/components/Button/icons/ForwardArrowIcon.svg';
import Header from 'components/Header';
import pages from 'pages/pages.module.scss';
import {
  getAutomatedBorrowerIdVerificationPassed,
  getAutomatedCoborrowerIdVerificationPassed,
  getBorrowersEqualsOwners,
  getCoborrowerExists,
  getOwnerInformationCorrect,
  getPotentialOffersDisplayed,
  getSoftPullResult,
} from 'selectors';
import { Routes, SoftPullStatus } from 'types';

import styles from './CreditApproval.module.scss';

const CreditApproval = () => {
  const history = useHistory();

  const borrowersEqualsOwners = useSelector(getBorrowersEqualsOwners);
  const coborrowerExists = useSelector(getCoborrowerExists);
  const borrowerIdVerificationPassed = useSelector(getAutomatedBorrowerIdVerificationPassed);
  const coborrowerIdVerificationPassed = useSelector(getAutomatedCoborrowerIdVerificationPassed);
  const potentialOffersDisplayed = useSelector(getPotentialOffersDisplayed);
  const softPullResult = useSelector(getSoftPullResult);
  const ownerInformationCorrect = useSelector(getOwnerInformationCorrect);

  const isSuccessfulVerification = () => {
    const isValid =
      softPullResult === SoftPullStatus.Approved &&
      borrowersEqualsOwners &&
      ownerInformationCorrect &&
      borrowerIdVerificationPassed &&
      !potentialOffersDisplayed;

    return coborrowerExists ? isValid && coborrowerIdVerificationPassed : isValid;
  };

  const getCreditApprovalMainContent = () => {
    if (softPullResult === SoftPullStatus.Received) {
      return (
        <>
          <h1 className={classNames(pages.externalPageTitle, styles.title)}>Got it!</h1>
          <p>
            Your application has been received. While your loan was not able to be instantly approved, your application
            is being carefully reviewed for approval.
          </p>
          <p className={pages.paragraph}>You can expect a loan decision or request for more information soon.</p>
        </>
      );
    }
    if (isSuccessfulVerification()) {
      return (
        <>
          <h1 className={classNames(pages.externalPageTitle, styles.title)}>
            Congratulations, your application to ezSolarLoan has been approved!
          </h1>
          <p>Click the link/button below to go to your personal dashboard to review and sign your loan documents!</p>
        </>
      );
    }
    if (!isSuccessfulVerification()) {
      return (
        <>
          <h1 className={classNames(pages.externalPageTitle, styles.title)}>
            Congratulations, your application to ezSolarLoan has been pre-approved!
          </h1>
          <p>
            Your application information is being verified. You will receive an email and text with further instructions
            soon.
          </p>
          <p className={pages.paragraph}>Thank you and have a great day!</p>
        </>
      );
    }
  };

  useEffect(() => {
    document.body.classList.add('creditApprovalPageBody');
    return () => document.body.classList.remove('creditApprovalPageBody');
  });

  const onClick = () => history.push(Routes.BorrowerDashboard);

  return (
    <div className={pages.externalPage}>
      <div className={pages.externalPageContent}>
        <Header external />
        <div className={pages.externalPageMainContent}>{getCreditApprovalMainContent()}</div>
      </div>
      <Button
        onClick={onClick}
        variant={ButtonVariant.Text}
        title="Go to dashboard"
        className={styles.closeButton}
        icon={<ForwardArrowIcon />}
        iconAlignment={AlignmentType.Right}
      />
    </div>
  );
};

export default CreditApproval;
