import React from 'react';

import { StyledEngineProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';
import RouteApp from 'routes';
import store from 'store';

import styles from './App.module.scss';

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://53b7d90677984565a6a32622bd98cdb5@o993832.ingest.sentry.io/6308775',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 0.1,
});

ReactDOM.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <div className={styles.appWrapper}>
        <Router history={history}>
          <React.StrictMode>
            <RouteApp />
          </React.StrictMode>
        </Router>
        <ToastContainer
          limit={2}
          autoClose={false}
          className={styles.toastContainer}
          toastClassName={styles.toastBody}
          closeButton={() => <CloseIcon className={styles.toastCloseButton} />}
        />
      </div>
    </StyledEngineProvider>
  </Provider>,
  document.getElementById('root'),
);
