import React, { useEffect, useState } from 'react';

import classnames from 'classnames/bind';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Control, FieldErrors, Controller } from 'react-hook-form';
import usePlacesAutocomplete, { getGeocode } from 'use-places-autocomplete';

import { ReactComponent as ArrowUp } from 'assets/icons/ArrowUp.svg';
import TextInput from 'common/components/TextInput';
import InputErrorMessage from 'common/components/TextInput/InputErrorMessage';
import { TextInputProps } from 'common/components/TextInput/TextInput';
import DropDown, { OptionType } from 'components/DropDown/DropDown';
import SearchIcon from 'components/DropDown/Icons/SearchIcon';
import pages from 'pages/pages.module.scss';
import { deleteSpaceInStart } from 'utils/formats';

import getAddressParts from './getAddressParts';
import styles from './SearchLocationInput.module.scss';
import states from './states';

const ZIP_CODE_MAX_LENGTH = 5;

const stateOptions = [
  ...states.map(({ abbreviation }) => ({
    value: abbreviation,
    label: abbreviation,
  })),
];

interface SearchLocationInputProps extends TextInputProps {
  control: Control<any>;
  containerClassName?: string;
  addressLabel: string;
  inputNames: AddressType;
  handleAddressChange?: (name: string, value: string) => void;
  handleAddressBlur?: (name: string) => void;
  handleAddressFocus?: (name: string) => void;
  addressValue: AddressType;
  errors: FieldErrors;
  disabled?: boolean;
}

export interface GeoCompletionResult {
  description: string;
}

export interface AddressType {
  street_address: string;
  city: string;
  state: string;
  zip: string;
}
const STATUS_OK = 'OK';

const cx = classnames.bind(styles);

const SearchLocationInput = ({
  containerClassName,
  addressLabel,
  handleAddressChange,
  handleAddressBlur,
  handleAddressFocus,
  addressValue,
  disabled,
  inputNames,
  errors,
  control,
  errorBackgroundType,
}: SearchLocationInputProps) => {
  const {
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: 'us' },
    },
    debounce: 300,
  });
  const hasSuggestions = status === STATUS_OK;

  const [address, setAddress] = useState<AddressType>({
    street_address: '',
    city: '',
    state: '',
    zip: '',
  });

  useEffect(() => {
    if (addressValue) {
      setAddress(addressValue);
    }
  }, [addressValue]);

  const onclickOutsideRef = useOnclickOutside(() => {
    clearSuggestions();
  });

  const handleStreetAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    setAddress({ ...address, street_address: e.target.value });
    handleAddressChange?.(e.target.name, e.target.value);
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress({ ...address, city: e.target.value });
    handleAddressChange?.(e.target.name, e.target.value);
  };

  const handleZipChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAddress({ ...address, zip: e.target.value });
    handleAddressChange?.(e.target.name, e.target.value);
  };

  const handleStateChange = (option: OptionType) => {
    if (!option) {
      return;
    }

    setAddress({ ...address, state: option.value });
    handleAddressChange?.(inputNames.state, option.value);
  };

  const handleSelect =
    ({ description }: GeoCompletionResult) =>
    async () => {
      setValue(description.split(', ')[0], false);
      clearSuggestions();

      const [addressData] = await getGeocode({ address: description });
      const addressParts = getAddressParts(addressData.address_components);
      handleAddressChange?.(inputNames.street_address, description.split(',')[0]);
      handleAddressChange?.(inputNames.city, addressParts.city);
      handleAddressChange?.(inputNames.state, addressParts.state);
      handleAddressChange?.(inputNames.zip, addressParts.zip);
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        place_id,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <div key={place_id} onClick={handleSelect(suggestion)} className={styles.suggestion}>
          <span className={styles['suggestion-bold']}>{main_text}</span> {secondary_text}
        </div>
      );
    });

  return (
    <>
      <Controller
        name={inputNames.street_address}
        control={control}
        render={({ field: { ref } }) => (
          <div
            ref={onclickOutsideRef}
            className={cx(styles.addressFormContainer, containerClassName)}
            aria-owns="ex-list-box"
            aria-haspopup="listbox"
            aria-expanded={hasSuggestions}
          >
            <TextInput
              errorBackgroundType={errorBackgroundType}
              ref={ref}
              inputContainerClassName={styles.input_with_dropdown}
              label={addressLabel}
              name={inputNames.street_address}
              errorMessage={errors[inputNames.street_address]?.message}
              onBlur={() => handleAddressBlur?.(inputNames.street_address)}
              onFocus={() => handleAddressFocus?.(inputNames.street_address)}
              onChange={handleStreetAddressChange}
              endAdornment={() => (hasSuggestions ? <ArrowUp /> : <SearchIcon />)}
              value={deleteSpaceInStart(address.street_address)}
              disabled={disabled}
            />
            {hasSuggestions && (
              <ul id="ex-list-box" role="listbox" className={styles.suggestionsContainer}>
                {renderSuggestions()}
              </ul>
            )}
          </div>
        )}
      />
      <div className={cx(pages.flexInputContainer, styles.addressFormRow)}>
        <Controller
          control={control}
          name={inputNames.city}
          render={({ field: { ref } }) => (
            <TextInput
              errorBackgroundType={errorBackgroundType}
              ref={ref}
              label="City"
              onBlur={() => handleAddressBlur?.(inputNames.city)}
              onFocus={() => handleAddressFocus?.(inputNames.city)}
              name={inputNames.city}
              errorMessage={errors[inputNames.city]?.message}
              onChange={handleCityChange}
              value={deleteSpaceInStart(address.city)}
              disabled={disabled}
            />
          )}
        />
        <div className={cx(pages.dropdownContainer, styles.state)}>
          <DropDown
            control={control}
            name={inputNames.state}
            onValuePicked={handleStateChange}
            selectedOption={stateOptions.find((x) => x.value === address.state) || null}
            options={stateOptions}
            placeholder="State"
            onBlur={() => handleAddressBlur?.(inputNames.state)}
            onFocus={() => handleAddressFocus?.(inputNames.state)}
            isSearchable={false}
            invalid={!address.state && !!errors[inputNames.state]?.message}
            disabled={disabled}
          />
          {!address.state && errors[inputNames.state]?.message && (
            <InputErrorMessage backgroundColor={errorBackgroundType}>
              {errors[inputNames.state]?.message}
            </InputErrorMessage>
          )}
        </div>
        <Controller
          control={control}
          name={inputNames.zip}
          render={({ field: { ref } }) => (
            <TextInput
              errorBackgroundType={errorBackgroundType}
              ref={ref}
              label="Zip Code"
              onBlur={() => handleAddressBlur?.(inputNames.zip)}
              onFocus={() => handleAddressFocus?.(inputNames.zip)}
              name={inputNames.zip}
              errorMessage={errors[inputNames.zip]?.message}
              onChange={handleZipChange}
              value={address.zip}
              maxLength={ZIP_CODE_MAX_LENGTH}
              disabled={disabled}
            />
          )}
        />
      </div>
    </>
  );
};

export default SearchLocationInput;
