export enum UserInformationKeys {
  Email = 'borrower_email',
  PhoneNumber = 'borrower_phone',
  FirstName = 'borrower_first_name',
  LastName = 'borrower_last_name',
  DateOfBirth = 'borrower_date_of_birth',
  StreetAddress = 'borrower_street_address',
  State = 'borrower_state_or_province',
  City = 'borrower_city',
  ZipOrPostalCode = 'borrower_zip_or_postal_code',
  CitizenshipStatus = 'borrower_citizenship_status',
  OwnershipStatus = 'borrower_ownership',
  PrivacyAndTermsSigned = 'privacy_and_terms_signed',
  PolicyAgreement = 'policy_agreement',
  SSN = 'borrower_manual_ssn',
}
