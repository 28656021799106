interface ICodeDescription {
  [key: string]: string;
}

const CodeDescription: ICodeDescription = {
  ZI: 'Unable to verify zip code',
  WL: 'The input name matches one or more of the non-OFAC global screening list(s)',
  VA: 'The input address is a vacant address',
  SR: 'Address mismatch on secondary address range',
  SD: 'The input address State is different than the LN best address State for the input identity',
  RS: 'The input SSN was possibly randomly issued by the SSA',
  PR: 'The input phone appears as high risk in the Digital Identity Network.',
  PO: 'The primary input address is a PO Box',
  PA: 'Potential address discrepancy - the Input address may be previous address',
  NF: 'The input first name and last name may have been flipped',
  NB: 'No date-of-birth reported for the input identity',
  MS: 'Multiple SSNs reported with applicant',
  MO: 'The input zip code is a military only zip code',
  MI: 'Multiple identities associated with the input SSN',
  IT: 'The input SSN is an ITIN',
  IS: 'Input SSN possibly randomly issued by SSA, but invalid when first associated with the input identity',
  EU: 'The input email address is not verified.',
  ER: 'The input email address appears as high risk in the Digital Identity Network.',
  DV: "Unable to verify driver's license number",
  DM: "The input driver's license number may have been miskeyed",
  DI: 'The input identity is reported as deceased',
  DF: 'The input driver’s license number is valid and is not on record',
  DD: "A different driver's license number has been found for the input applicant",
  CZ: 'Address mismatch between city/state and zip code',
  CO: 'The input zip code is a corporate only zip code',
  CL: 'The input SSN is not the primary SSN for the input identity',
  CA: 'The primary input address is a Commercial Mail Receiving Agency',
  '90': 'The input SSN was issued after age five (post-1990)',
  '89': 'The input SSN was issued within the last three years',
  '85': 'The input SSN was issued to a non-US citizen',
  '83': 'The input date-of-birth may have been miskeyed',
  '82': 'The input name and address return a different phone number',
  '81': 'The input date-of-birth was missing or incomplete',
  '80': 'The input phone was missing or incomplete',
  '79': 'The input SSN/TIN was missing or incomplete',
  '78': 'The input address was missing',
  '77': 'The input name was missing',
  '76': 'The input name may have been miskeyed',
  '75': 'The input name and address are associated with an unlisted/non-published phone number',
  '74': 'The input phone number is associated with a different name and address',
  '72': 'The input SSN is associated with a different name and address',
  '71': 'The input SSN is not found in the public record',
  '66': 'The input SSN is associated with a different last name, same first name',
  '64': 'The input address returns a different phone number',
  '57': 'The input work phone is a mobile number',
  '56': 'The input work phone is potentially disconnected',
  '55': 'The input work phone is potentially invalid',
  '53': 'The input home phone and work phone are geographically distant (>100 miles)',
  '52': 'The input first name is not associated with the input SSN',
  '51': 'The input last name is not associated with the input SSN',
  '50': 'The input address matches a prison address',
  '49': 'The input phone and address are geographically distant (>10 miles)',
  '48': 'Unable to verify first name',
  '46': 'The input phone area code is changing',
  '44': 'The input phone area code is changing',
  '41': "The input driver's license number is invalid for the input DL State",
  '39': 'The input SSN is recently issued',
  '38': 'The input SSN is associated with multiple last names',
  '37': 'Unable to verify name',
  '32': 'The input name matches the OFAC file',
  '31': 'The input phone number may have been miskeyed',
  '30': 'The input address may have been miskeyed',
  '29': 'The input SSN/TIN may have been miskeyed',
  '28': 'Unable to verify date-of-birth',
  '27': 'Unable to verify phone number',
  '26': 'Unable to verify SSN/TIN',
  '25': 'Unable to verify address',
  '19': 'Unable to verify name, address, SSN/TIN and phone',
  '16': 'The input phone number and input zip code combination is invalid',
  '15': 'The input phone number matches a transient commercial or institutional address',
  '14': 'The input address is a transient commercial or institutional address',
  '12': 'The input zip code belongs to a post office box',
  '11': 'The input address may be invalid according to postal specifications',
  '10': 'The input phone number is a mobile number',
  '09': 'The input phone number is a pager number',
  '08': 'The input phone number is potentially invalid',
  '07': 'The input phone number may be disconnected',
  '06': 'The input SSN is invalid',
  '04': 'The input last name and SSN are verified, but not with the input address and phone',
  '03': 'The input SSN was issued prior to the input date-of-birth',
  '02': 'The input SSN is reported as deceased',
};

export const decodeRiskIndicators = (codes: string) =>
  codes
    .split(' ')
    .map((value) =>
      CodeDescription[value as keyof ICodeDescription] !== undefined
        ? CodeDescription[value as keyof ICodeDescription]
        : value,
    )
    .join();
