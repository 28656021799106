import React from 'react';

import { FieldValues, useForm, Validate } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import PhoneInput from 'common/components/PhoneInput';
import TextInput from 'common/components/TextInput';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { getInvalidEmailErrorMessage, getRequiredErrorMessage } from 'errors/errors';
import { backStep } from 'handlers/actions';
import styles from 'pages/pages.module.scss';
import { getIsLoading } from 'selectors';
import { deleteSpaceInStart } from 'utils/formats';
import { getCorrectValueForEmail, getPatterns, isExistErrors } from 'utils/validationHelper';

interface ContactInfoProps {
  phone: string;
  email: string;
  phoneTitle: string;
  emailTitle: string;
  emailValidation?: Record<string, Validate<any>>;
  onSubmit: (data: FieldValues) => void;
}

export const ContactInfo = ({ phone, email, phoneTitle, emailTitle, emailValidation, onSubmit }: ContactInfoProps) => {
  const dispatch = useDispatch();

  const loading = useSelector(getIsLoading);

  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted },
  } = useForm<FieldValues>({
    defaultValues: {
      phone,
      email,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const watcher = watch();

  const isSubmitInactive = () => !watcher.phone || !watcher.email || (isSubmitted && isExistErrors(errors));

  const handleBackClick = () => {
    dispatch(backStep());
  };

  const handleChangeEmail = (value: string) => {
    setValue('email', getCorrectValueForEmail(value));
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.textInputLabel}>{phoneTitle}</div>
      <div className={styles.inputContainer}>
        <PhoneInput label="Phone" control={control} name="phone" />
      </div>
      <div className={styles.textInputLabel}>{emailTitle}</div>
      <div className={styles.inputContainer}>
        <TextInput
          {...register('email', {
            required: getRequiredErrorMessage('Email'),
            pattern: {
              value: getPatterns().email,
              message: getInvalidEmailErrorMessage(),
            },
            validate: emailValidation,
          })}
          onChange={(event) => handleChangeEmail(event.target.value)}
          label="Email"
          value={deleteSpaceInStart(watcher.email)}
          errorMessage={errors.email?.message}
          inputMode="email"
        />
      </div>
      <NavigationButtonWrapper>
        <BackButton onClick={handleBackClick} />
        <NextButton type="submit" inactive={isSubmitInactive()} loading={loading} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};
