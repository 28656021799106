import React, { useEffect, useState } from 'react';

import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import Button, { ButtonVariant } from 'common/components/Button/Button';
import NextButton from 'common/components/Button/NextButton';
import NumberInput from 'common/components/NumberInput';
import FormContainer from 'components/FormContainer';
import ModalWindow from 'components/ModalWindow';
import ModalActionsWrapper from 'components/ModalWindow/ModalActionsWrapper';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { getInvalidSciCodeMessage, getRequiredErrorMessage } from 'errors/errors';
import { ApplicationVariables } from 'handlers/application/types';
import { IntermediaryVariables } from 'handlers/intermediary/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import PageLayout from 'layouts';
import pages from 'pages/pages.module.scss';
import {
  getApplicationId,
  getIntermediaryId,
  getIntermediaryInvited,
  getIntermediaryName,
  getIntermediaryNotFound,
  getIntermediarySci,
  getIsLoading,
} from 'selectors';
import { useAppDispatch } from 'store';
import { getIntermediaryBySciCode, updateApplication } from 'thunks';
import { IntermediaryShort } from 'types';
import { deleteSpaceInStart } from 'utils/formats';

import styles from './SCIPage.module.scss';

type FormValues = {
  sci: string;
};

const SCIPage = () => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const intermediaryNotFound = useSelector(getIntermediaryNotFound);
  const intermediaryId = useSelector(getIntermediaryId);
  const intermediaryName = useSelector(getIntermediaryName);
  console.log('🚀 ~ SCIPage ~ intermediaryName:', intermediaryName);
  const [modalTitle, setModalTitle] = useState('');
  const [modalContent, setModalContent] = useState('');
  const loading = useSelector(getIsLoading);
  const intermediaryIdNumber = useSelector(getIntermediarySci);
  const intermediaryInvited = useSelector(getIntermediaryInvited);
  const applicationId = useSelector(getApplicationId);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const {
    register,
    watch,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: {
      sci: intermediaryIdNumber || '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const sci = watch('sci');

  const getIntermediary = async (code: string) => {
    const intermediary = await dispatch(
      getIntermediaryBySciCode({
        sci: code,
      }),
    ).unwrap();
      
    handleOpenModal(intermediary);
  };

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    await getIntermediary(data.sci);
  };

  const handleBack = () => {
    dispatch(setCurrentStage({ stage: StagesType.Offers }));
  };

  const handleNext = async () => {
    const variables = {
      applicationId,
      applicationVariables: {
        [ApplicationVariables.IntermediaryConfirmed]: true,
      },
    };

    if (!intermediaryInvited) {
      Object.assign(variables, {
        intermediaryId,
      });
      Object.assign(variables.applicationVariables, {
        [ApplicationVariables.IntermediaryId]: sci,
      });
    }

    handleClose();
    await dispatch(
      updateApplication({
        ...variables,
        skipAuth: true,
      }),
    );

    dispatch(setCurrentStage({ stage: StagesType.SalespersonInfo }));
  };

  const handleOpenModal = (intermediary: IntermediaryShort | null) => {
    if (intermediary) {
      setModalTitle(`Is ${intermediary[IntermediaryVariables.Name]} your solar company?`);
      handleOpen();
    } else {
      setModalContent(
        `We did not find the SCI Code ${sci} in our system. Please confirm that you have entered it correctly. Would you like to try again?`,
      );
      setModalTitle("We're sorry.");
      handleOpen();
    }
  };

  useEffect(() => {
    if (loading) return;
    if (intermediaryIdNumber) {
      getIntermediary(sci);
    }
  }, [intermediaryIdNumber]);

  useEffect(() => {
    register('sci', {
      required: getRequiredErrorMessage('SCI'),
      pattern: { value: /^\d{4}$/, message: getInvalidSciCodeMessage() },
    });
  }, [register]);

  return (
    <>
      {open && (
        <ModalWindow
          isOpen={open}
          title={modalTitle}
          content={intermediaryNotFound || !intermediaryName ? modalContent : ''}
          actions={
            <ModalActionsWrapper>
              {!intermediaryNotFound && <Button variant={ButtonVariant.Secondary} title="No" onClick={handleClose} />}
              {!intermediaryNotFound && <Button title="Yes" onClick={handleNext} />}
              {intermediaryNotFound && (
                <Button title="Re-enter code" onClick={handleClose} className={styles.reEnterButton} />
              )}
            </ModalActionsWrapper>
          }
        />
      )}
      <PageLayout title="Solar company">
        <FormContainer onSubmit={handleSubmit(onSubmit)}>
          <div className={pages.textInputLabel}>What is the SCI code* for your solar company?</div>
          <div className={pages.inputContainer}>
            <NumberInput
              control={control}
              name="sci"
              label="SCI code"
              inputMode="decimal"
              maxLength={4}
              value={deleteSpaceInStart(sci)}
              errorMessage={errors.sci?.message}
              referenceInfo="*The SCI code - or Solar Company Identifier code - is a 4-digit number that is assigned to solar companies that are authorized to use the ezSolarLoan system. Please get in touch with your solar company to ask them for their SCI code if you don't have it handy."
            />
          </div>
          <NavigationButtonWrapper>
            <BackButton onClick={handleBack} />
            <NextButton
              type="submit"
              inactive={!sci || loading || sci.length < 4}
              disabled={!!errors.sci?.message}
              loading={loading}
            />
          </NavigationButtonWrapper>
        </FormContainer>
      </PageLayout>
    </>
  );
};

export default SCIPage;
