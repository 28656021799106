import React, { useEffect, useState } from 'react';

import { generatePath, useHistory, useLocation, useParams } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import NavigationCards, { NavigationCard } from 'components/NavigationCards/NavigationCards';
import ViewDeal from 'components/ViewDeal';
import { ApplicationVariables } from 'handlers/application/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { useAppDispatch } from 'store';
import { getApplicationByDisplayId } from 'thunks';
import { Application, ApplicationStatus, Routes } from 'types';
import { notify } from 'utils/notificationHelper';

import { Notify } from './types';

const manageDealCards = (
  isFundsDisabled: boolean,
  id: string,
  isProjectDetailsComplete: boolean,
  isLoading: boolean,
  isShowingViewProject: boolean,
  isCardsDisabled?: boolean,
  isLoanTermListExists?: boolean,
): NavigationCard[] => [
  {
    title: isShowingViewProject
      ? 'View project details and signed contract'
      : 'Enter project details and upload signed contract',
    description: isShowingViewProject
      ? 'Click here to view project details and signed contract with the customer.'
      : 'Click here to enter the project details (specifically the budget breakdown for solar system, storage, roofing and other upgrades) and to upload the signed contract with the customer',
    url: generatePath(isProjectDetailsComplete ? Routes.ContractorViewProject : Routes.ContractorProject, { id }),
    disabled: isLoading || (!isProjectDetailsComplete && isCardsDisabled),
  },
  {
    title: 'Upload customer documents',
    description:
      'Click here to upload documents that may be needed for the customer such as verification of income, ' +
      'identification or letters of explanation',
    url: generatePath(Routes.ContractorDocuments, { id }),
    disabled: isCardsDisabled || isLoading,
  },
  {
    title: 'View rate and payment option',
    description: 'Click here to view the rate and payment options and see which one your customer has selected',
    url: generatePath(Routes.ContractorPaymentRate, { id }),
    disabled: isLoanTermListExists,
  },
  {
    title: 'Request funds and upload invoices',
    description:
      'Click here to request partial or full payment for projects, which will prompt your customer to authorize the release of funds',
    url: generatePath(Routes.ContractorRequestFunds, { id }),
    disabled: isFundsDisabled || isCardsDisabled,
  },
];

const ManageDeal = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation<{ notify: Notify }>();
  const { id } = useParams<{ id: string }>();

  const [application, setApplication] = useState<Application | null>(null);

  const getApplication = async () => {
    const app = await dispatch(getApplicationByDisplayId(id)).unwrap();
    setApplication(app);
    if (app && location.state) {
      notify({ notification: location.state.notify });
      return history.replace(location.pathname);
    }
  };

  const isFundsDisabled = (() => {
    if (!application?.variables[ApplicationVariables.ProjectDetailsAndSignedContractComplete]) return true;
    if (application?.variables[ApplicationVariables.FullAmountRequested]) return true;
    if (
      application?.variables[ApplicationVariables.First50Requested] &&
      !application?.variables[ApplicationVariables.Final50Requested]
    )
      return !application?.variables[ApplicationVariables.First50Authorized];
    return !!(
      application?.variables[ApplicationVariables.First50Requested] &&
      application?.variables[ApplicationVariables.Final50Requested]
    );
  })();

  const isCardsDisabled = (() => {
    if (
      application?.status.name === ApplicationStatus.Rejected ||
      application?.status.name === ApplicationStatus.Approved
    )
      return true;
  })();

  const isLoanTermListExists = !application?.variables[ApplicationVariables.LoanTermList];

  const backClick = () => history.push(Routes.ContractorDeals);

  useEffect(() => {
    if (id) {
      getApplication();
    }
    return () => setApplication(null);
  }, [id]);

  const isShowingViewProject = () => {
    const isProjectDetailsComplete =
      application?.variables[ApplicationVariables.ProjectDetailsAndSignedContractComplete];

    if (isProjectDetailsComplete) {
      return true;
    }

    if (isCardsDisabled) {
      return !!isProjectDetailsComplete;
    }

    return false;
  };

  return (
    <>
      <ViewDeal application={application} />
      <NavigationCards
        items={manageDealCards(
          isFundsDisabled,
          id,
          application?.variables[ApplicationVariables.ProjectDetailsAndSignedContractComplete],
          !application,
          isShowingViewProject(),
          isCardsDisabled,
          isLoanTermListExists,
        )}
      />
      <NavigationButtonWrapper>
        <BackButton className={commonStyles.portalBackButton} onClick={backClick} />
      </NavigationButtonWrapper>
    </>
  );
};

export default withPortalLayout(ManageDeal);
