import React, { useState } from 'react';

import { FieldValues, SubmitHandler } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Button from 'common/components/Button';
import { ButtonVariant } from 'common/components/Button/Button';
import ModalWindow from 'components/ModalWindow';
import ModalActionsWrapper from 'components/ModalWindow/ModalActionsWrapper';
import { setCustomerData } from 'handlers/customer';
import { UserInformationKeys } from 'handlers/customer/types';
import { setIntermediaryData } from 'handlers/intermediary';
import { IntermediaryVariables } from 'handlers/intermediary/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { ContactInfo } from 'layouts';
import { getBorrowerEmail, getBorrowerPhone } from 'selectors';
import { useAppDispatch } from 'store';
import { getBorrowerAccount } from 'thunks';
import { Routes } from 'types';
import { convertPhone } from 'utils/formats';

import styles from './Contacts.module.scss';

enum ModalTitle {
  DoubleCheck = 'Please double-check!',
  IncorrectEmail = 'Please use the same email address that you have used previously.',
}

enum ModalType {
  DoubleCheck = 'doubleCheck',
  IncorrectEmail = 'incorrectEmail',
}

const getModalTitle = (type: ModalType) => {
  switch (type) {
    case ModalType.DoubleCheck:
      return ModalTitle.DoubleCheck;
    case ModalType.IncorrectEmail:
      return ModalTitle.IncorrectEmail;
    default:
      break;
  }
};

const Contacts = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const storedPhone = useSelector(getBorrowerPhone);
  const storedEmail = useSelector(getBorrowerEmail);
  const authToken = localStorage.getItem('borrowerToken');
  const { search } = useLocation();

  const [isOpen, setIsOpen] = useState<boolean | null>(null);
  const [modalType, setModalType] = useState<ModalType>(ModalType.DoubleCheck);

  const getModalContent = (type: ModalType) => {
    switch (type) {
      case ModalType.DoubleCheck:
        return (
          <p>
            Is <span className={styles.contact}>{convertPhone(storedPhone)}</span> your correct phone number and{' '}
            <span className={styles.contact}>{storedEmail}</span> your correct email address?
          </p>
        );
      case ModalType.IncorrectEmail:
        return (
          <p>
            If you need to change the email address associated with your records, please contact us at (800) 493-1310.
          </p>
        );
      default:
        break;
    }
  };

  const getModalButtons = (type: ModalType) => {
    switch (type) {
      case ModalType.DoubleCheck:
        return (
          <>
            <Button variant={ButtonVariant.Secondary} onClick={handleClose} title="No" />
            <Button onClick={() => handleNext(storedEmail)} title="Yes" />
          </>
        );
      case ModalType.IncorrectEmail:
        return (
          <>
            <Button onClick={handleClose} title="Try again" />
          </>
        );
      default:
        break;
    }
  };

  const setIntermediarySci = (searchUrl: string) => {
    const sci = new URLSearchParams(searchUrl).get('sci');
    if (sci) {
      dispatch(
        setIntermediaryData({
          [IntermediaryVariables.Id]: sci,
        }),
      );
    }
  };

  const handleClose = () => setIsOpen(false);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (search) {
      setIntermediarySci(search);
    }
    dispatch(
      setCustomerData({
        [UserInformationKeys.PhoneNumber]: data.phone,
        [UserInformationKeys.Email]: data.email,
      }),
    );
    if (isOpen === null) {
      setModalType(ModalType.DoubleCheck);
      setIsOpen(true);
      return;
    }
    await handleNext(data.email);
  };

  const handleNext = async (email: string) => {
    handleClose();
    const account = await dispatch(
      getBorrowerAccount({
        email,
      }),
    ).unwrap();

    if (account) {
      if (authToken) {
        const parsedJwt = JSON.parse(atob(authToken.split('.')[1]));
        if (account.borrowerId !== parsedJwt.borrowerId) {
          return history.push(Routes.BorrowerLogin);
        }
      } else {
        return history.push(Routes.BorrowerLogin);
      }
    }
    if (!account && authToken) {
      setModalType(ModalType.IncorrectEmail);
      setIsOpen(true);
      return;
    }

    dispatch(setCurrentStage({ stage: StagesType.CoborrowerInfo }));
  };
  return (
    <>
      <ModalWindow
        isOpen={isOpen || false}
        title={getModalTitle(modalType)}
        content={getModalContent(modalType)}
        actions={<ModalActionsWrapper>{getModalButtons(modalType)}</ModalActionsWrapper>}
      />
      <ContactInfo
        phone={storedPhone}
        email={storedEmail}
        phoneTitle="What is your mobile phone number?"
        emailTitle="What is your email address?"
        onSubmit={onSubmit}
      />
    </>
  );
};

export default Contacts;
