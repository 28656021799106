import React, { useEffect, useLayoutEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router-dom';

import useScript from 'common/hooks/useScript';
import { MobileScreenWidth } from 'enums/MobileScreenWidth';
import { UserType } from 'enums/UserType';
import { logout } from 'handlers/actions';
import portalStyles from 'layouts/Portal.module.scss';
import { PortalType } from 'layouts/withPortalLayout';
import Stages from 'pages';
import CreateIntermediaryAccount from 'pages/Account/CreateIntermediaryAccount';
import AddCoborrower from 'pages/BorrowerPortal/AddCoborrower';
import BorrowerDashboard from 'pages/BorrowerPortal/BorrowerDashboard';
import BorrowerDetails from 'pages/BorrowerPortal/BorrowerDetails';
import BorrowerDocuments from 'pages/BorrowerPortal/BorrowerDocuments';
import ChangeOffer from 'pages/BorrowerPortal/ChangeOffer';
import ConfirmProject from 'pages/BorrowerPortal/ConfirmProject';
import LoanDocuments from 'pages/BorrowerPortal/LoanDocuments';
import NoActiveApplication from 'pages/BorrowerPortal/NoActiveApplication';
import Payments from 'pages/BorrowerPortal/Payments';
import PortalContactUs from 'pages/BorrowerPortal/PortalContactUs';
import UpdateSalesperson from 'pages/BorrowerPortal/UpdateSalesperson';
import ContractorDashboard from 'pages/ContractorPortal/ContractorDashboard';
import ContractorDocuments from 'pages/ContractorPortal/ContractorDocuments';
import CustomerOffers from 'pages/ContractorPortal/CustomerOffers';
import Deals from 'pages/ContractorPortal/Deals';
import Invite from 'pages/ContractorPortal/Invite';
import ManageDeal from 'pages/ContractorPortal/ManageDeal';
import ProjectDetails from 'pages/ContractorPortal/ProjectDetails';
import RequestFunds from 'pages/ContractorPortal/RequestFunds';
import ViewProjectDetails from 'pages/ContractorPortal/ViewProjectDetails';
import Login, { ForgotPassword, NewPassword } from 'pages/Login';
import { EmailConfirmation } from 'pages/Login/EmailConfirmation';
import { PhoneAuthentication } from 'pages/Login/PhoneAuthentication';
import {
  getApplicationId,
  getApplicationStatus,
  getBorrowerEmail,
  getBorrowerFirstName,
  getBorrowerId,
  getBorrowerIsLocked,
  getBorrowerLastName,
  getBorrowerMfaIncomplete,
  getBorrowerPhone,
  getIntermediaryEmail,
  getIsLoading,
} from 'selectors';
import { useAppDispatch } from 'store';
import { fetchDataForBorrower, getBorrower, getIntermediaryByEmail, sendLockedNotifications } from 'thunks';
import { Routes } from 'types';

const cx = classNames.bind(portalStyles);

const FetchBorrowerData = () => {
  const borrowerId = useSelector(getBorrowerId);
  const borrowerEmail = useSelector(getBorrowerEmail);
  const borrowerFirstName = useSelector(getBorrowerFirstName);
  const borrowerLastName = useSelector(getBorrowerLastName);
  const borrowerPhone = useSelector(getBorrowerPhone);
  const applicationStatus = useSelector(getApplicationStatus);
  const applicationId = useSelector(getApplicationId);
  const borrowerIsLocked = useSelector(getBorrowerIsLocked);
  const loading = useSelector(getIsLoading);
  const authToken = localStorage.getItem('borrowerToken');
  const refreshToken = localStorage.getItem('borrowerRefreshToken');
  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isMfaIncomplete = useSelector(getBorrowerMfaIncomplete);

  useEffect(() => {
    if (applicationId && pathname.includes('no-active-application') && !loading) {
      history.push(Routes.BorrowerDashboard);
    }
  }, [applicationId, loading]);

  useEffect(() => {
    if (pathname.includes('contact-us') && !loading) {
      if (applicationId) {
        history.push(Routes.BorrowerDashboard);
        return;
      }
      if (!applicationId && !borrowerIsLocked) {
        history.push(Routes.BorrowerNoActiveApplication);
      }
    }
  }, [applicationId, borrowerIsLocked, loading]);

  useEffect(() => {
    if (!borrowerId && refreshToken) {
      sessionStorage.setItem('userType', UserType.Borrower);
      dispatch(fetchDataForBorrower())
        .unwrap()
        .then(() => {
          dispatch(getBorrower());
        });
    }
  }, [borrowerId, refreshToken]);

  useEffect(() => {
    if (borrowerId && !isMfaIncomplete) {
      dispatch(fetchDataForBorrower());
      dispatch(getBorrower());
    }
  }, [borrowerId, isMfaIncomplete]);

  useEffect(() => {
    if (!applicationId && !pathname.includes('login') && !pathname.includes('confirm') && !loading) {
      if (borrowerIsLocked) {
        dispatch(
          sendLockedNotifications({
            firstName: borrowerFirstName,
            lastName: borrowerLastName,
            email: borrowerEmail,
            phone: borrowerPhone,
          }),
        );
        history.push(Routes.BorrowerContactUs);
      }
      if (!borrowerIsLocked) history.push(Routes.BorrowerNoActiveApplication);
    }
  }, [borrowerIsLocked, applicationStatus, loading, applicationId]);

  useEffect(() => {
    if (!pathname.includes('login') && !pathname.includes('confirm') && !authToken) {
      history.push(Routes.BorrowerLogin);
    }
    if (!pathname.includes('login') && !pathname.includes('confirm') && isMfaIncomplete) {
      dispatch(logout());
    }
  }, [authToken, pathname, isMfaIncomplete]);

  return <></>;
};
const FetchContractorData = () => {
  const intermediaryEmail = useSelector(getIntermediaryEmail);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const authToken = localStorage.getItem('contractorToken');
  const refreshToken = localStorage.getItem('contractorRefreshToken');

  useEffect(() => {
    if (refreshToken || intermediaryEmail) {
      if (!intermediaryEmail) {
        sessionStorage.setItem('userType', UserType.Contractor);
      }
      dispatch(getIntermediaryByEmail());
    }
  }, [intermediaryEmail, refreshToken]);

  useEffect(() => {
    if (!pathname.includes('login') && !pathname.includes('signup') && !authToken) {
      history.push(Routes.ContractorLogin);
    }
  }, [authToken, pathname]);

  return <></>;
};

export default () => {
  const { pathname } = useLocation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [loaded] = useScript(`${process.env.REACT_APP_GLIA_URL}`);
  const isMobileScreen = screenWidth < MobileScreenWidth.Middle;

  const handleWindowSizeChange = () => setScreenWidth(window.innerWidth);

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return (
    <Route path="/">
      <Route path="/dashboard" component={FetchBorrowerData} />
      <Route path="/portal" component={FetchContractorData} />
      <Switch>
        <Redirect from="/" to={Routes.Apply} exact />
        {loaded && (
          <>
            <Route path={Routes.Apply} component={Stages} />
            <Route path={Routes.ContractorSignUp} exact component={CreateIntermediaryAccount} />
            <Route path={Routes.BorrowerLogin} exact>
              <Login type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerForgotPassword} exact>
              <ForgotPassword type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerNewPassword} exact>
              <NewPassword type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerConfirmEmail} exact>
              <EmailConfirmation type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerConfirmPhone} exact>
              <PhoneAuthentication type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.ContractorLogin} exact>
              <Login type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorConfirmEmail} exact>
              <EmailConfirmation type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorConfirmPhone} exact>
              <PhoneAuthentication type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorForgotPassword} exact>
              <ForgotPassword type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorNewPassword} exact>
              <NewPassword type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.BorrowerDashboard}>
              <BorrowerDashboard title="Borrower dashboard" type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerNoActiveApplication}>
              <NoActiveApplication title="Borrower dashboard" type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerContactUs}>
              <PortalContactUs title="" type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerDetails}>
              <BorrowerDetails title="Update your information" type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.ContractorDashboard}>
              <ContractorDashboard title="Solar Company Portal" type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorDeals} exact>
              <Deals
                title="Deals"
                type={PortalType.Contractor}
                className={cx({ [portalStyles.dealsPageContent]: !isMobileScreen })}
              />
            </Route>
            <Route path={Routes.ContractorManageDeal} exact>
              <ManageDeal title="Manage Deal" type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorProject}>
              <ProjectDetails title="Project Details" type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorViewProject}>
              <ViewProjectDetails title="Project Details" type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorInvite}>
              <Invite title="Invite customer to apply" type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorDocuments}>
              <ContractorDocuments title="Upload customer documents" type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorPaymentRate}>
              <CustomerOffers title="Rate and payment option" type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.ContractorRequestFunds}>
              <RequestFunds title="Request funds" type={PortalType.Contractor} />
            </Route>
            <Route path={Routes.BorrowerProject}>
              <ConfirmProject title="Confirm solar project and contract" type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerSalesperson}>
              <UpdateSalesperson title="Salesperson info" type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerPaymentRate}>
              <ChangeOffer title="Change your rate and payment option" type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerCoborrower}>
              <AddCoborrower title="Add co-borrower" type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerPayments}>
              <Payments title="Authorize payments" type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerDocuments}>
              <BorrowerDocuments title="Upload documents" type={PortalType.Borrower} />
            </Route>
            <Route path={Routes.BorrowerSigning}>
              <LoanDocuments title="Request and sign your loan documents" type={PortalType.Borrower} />
            </Route>
          </>
        )}
      </Switch>
    </Route>
  );
};
