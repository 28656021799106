import React, { useState } from 'react';

import { Drawer } from '@mui/material';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as CloseIcon } from 'assets/icons/Close.svg';
import Button from 'common/components/Button';
import { ButtonVariant } from 'common/components/Button/Button';
import Chip from 'common/components/Chip';
import RadioButton from 'common/components/RadioButton/RadioButton';
import { setTableOptions, TableType } from 'handlers/table';
import {
  getFilterByProjectDetails,
  getFilterByStatusApplication,
  getFilterByVoi,
  getSearchValue,
  getSortDirection,
  getSortField,
} from 'selectors';

import styles from './DealsFilters.module.scss';

const ApplicationFriendlyStatuses = [
  {
    label: 'Pre-approved',
    id: 'preApproved',
    value: 'pre-approved',
  },
  {
    label: 'Received',
    id: 'received',
    value: 'received',
  },
  {
    label: 'Declined',
    id: 'declined',
    value: 'declined',
  },
  {
    label: 'Approved',
    id: 'approved',
    value: 'approved',
  },
  {
    label: 'Signed',
    id: 'signed',
    value: 'signed',
  },
  {
    label: 'Funding',
    id: 'funding',
    value: 'funding',
  },
  {
    label: 'Withdrawn',
    id: 'withdrawn',
    value: 'withdrawn',
  },
  {
    label: 'Completed',
    id: 'completed',
    value: 'completed',
  },
];

export interface DealFilters {
  projectDetails: null | string;
  statusApplication: string[];
  voi: null | string;
}

interface IDealsFiltersProps {
  open: boolean;
  onClose: () => void;
  fetchData: (...args: any[]) => any;
}

const cx = classNames.bind(styles);

const emptyFilters = {
  projectDetails: null,
  statusApplication: [],
  voi: null,
};

const DealsFilters = ({ fetchData, open, onClose }: IDealsFiltersProps) => {
  const dispatch = useDispatch();
  const searchValue = useSelector(getSearchValue);
  const sortDirection = useSelector(getSortDirection);
  const sortField = useSelector(getSortField);
  const activeFilters: DealFilters = {
    projectDetails: useSelector(getFilterByProjectDetails),
    statusApplication: useSelector(getFilterByStatusApplication),
    voi: useSelector(getFilterByVoi),
  };

  const [filters, setFilters] = useState<DealFilters>(activeFilters);

  const handleReset = () => {
    setFilters(emptyFilters);
    dispatch(
      setTableOptions({
        [TableType.SortField]: 'createdAt',
        [TableType.SortDirection]: 'desc',
        [TableType.ProjectDetails]: null,
        [TableType.Voi]: null,
        [TableType.StatusApplication]: [],
        [TableType.PageIndex]: 0,
      }),
    );
    fetchData({ sortField: 'createdAt', sortDirection: 'desc', search: searchValue });
    onClose();
  };

  const handleApply = () => {
    if (
      activeFilters.statusApplication === filters.statusApplication &&
      activeFilters.voi === filters.voi &&
      activeFilters.projectDetails === filters.projectDetails
    ) {
      onClose();
      return;
    }
    fetchData({ filters, sortField, sortDirection, search: searchValue });
    dispatch(
      setTableOptions({
        [TableType.StatusApplication]: filters.statusApplication,
        [TableType.Voi]: filters.voi,
        [TableType.ProjectDetails]: filters.projectDetails,
        [TableType.PageIndex]: 0,
      }),
    );
    onClose();
  };

  const handleClose = () => {
    setFilters(activeFilters || emptyFilters);
    onClose();
  };

  const handleChange = (e: React.FormEvent) => {
    const target = e.target as any;
    if (target.type === 'radio') {
      setFilters({
        ...filters,
        [target.name]: target.value,
      });
    } else if (target.type === 'checkbox') {
      const isOldStatus = filters.statusApplication.some((status) => status === target.value);
      setFilters({
        ...filters,
        statusApplication: isOldStatus
          ? filters.statusApplication.filter((status) => status !== target.value)
          : filters.statusApplication.concat([target.value]),
      });
    }
  };

  const renderRadioFilter = (fieldName: string, inputName: 'voi' | 'projectDetails') => (
    <>
      <p className={styles.groupLabel}>{fieldName}</p>
      <RadioButton
        id={`needed${inputName}`}
        label="Needed"
        name={inputName}
        value="needed"
        defaultChecked={filters[inputName] === 'needed'}
      />
      <RadioButton
        containerClassName={styles.secondOption}
        id={`completed${inputName}`}
        label="Completed"
        name={inputName}
        value="completed"
        defaultChecked={filters[inputName] === 'completed'}
      />
    </>
  );

  const isApplyEnabled = () => Object.values(filters).some((value) => value !== null && value.length !== 0);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={handleClose}
      classes={{
        paperAnchorLeft: styles.filtersContainer,
        root: styles.rootContainer,
      }}
    >
      <div className={styles.filtersHeader}>
        <div className={styles.filtersTitle}>Filters</div>
        <CloseIcon className={styles.closeIcon} onClick={handleClose} />
      </div>
      <div className={styles.filtersContent} onChange={handleChange}>
        {renderRadioFilter('Project details', 'projectDetails')}
        <p className={styles.statusLabel}>Status application</p>
        <ul className={styles.statusList}>
          {ApplicationFriendlyStatuses.map((status) => (
            <li key={status.id} className={styles.chip}>
              <Chip
                label={status.label}
                id={status.id}
                value={status.value}
                defaultChecked={filters.statusApplication.includes(status.value)}
              />
            </li>
          ))}
        </ul>
        {renderRadioFilter('VOI', 'voi')}

        <Button
          onClick={handleApply}
          className={cx(styles.button, styles.applyButton)}
          title="Apply"
          variant={ButtonVariant.Primary}
          disabled={!isApplyEnabled()}
        />
        <Button
          onClick={handleReset}
          className={cx(styles.button, styles.resetButton)}
          type="reset"
          title="Reset all"
          disabled={!isApplyEnabled()}
          variant={ButtonVariant.Text}
        />
      </div>
    </Drawer>
  );
};

export default DealsFilters;
