import { createAsyncThunk } from '@reduxjs/toolkit';

import DataTreeApi from 'api/DataTreeApi';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { setCommonState } from 'handlers/common';
import { CommonTypes } from 'handlers/common/types';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface DataTreeProps {
  street: string;
  state: string;
  zipCode: string;
}

export const createGetPropertyOwnersThunk = (estatedApi: DataTreeApi) =>
  createAsyncThunk('data-tree/get', async ({ street, state, zipCode }: DataTreeProps, { dispatch }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
      const result = await estatedApi.getPropertyOwners(street, state, zipCode);
      dispatch(
        setApplicationData({
          [ApplicationVariables.PropertyOwner1]: result.owners[0] || '',
          [ApplicationVariables.PropertyOwner2]: result.owners[1] || '',
          [ApplicationVariables.PropertyOwner3]: result.owners[2] || '',
          propertyAddress: result.address,
          [ApplicationVariables.ParcelNumber]: result.parcelNumber,
          [ApplicationVariables.AbbreviatedLegalDescription]: `${result.legalDescription}, ${result.subdivision}, ${result.lotNumber}, ${result.sectionTownshipRange}, ${result.municipality}`,
          [ApplicationVariables.PropertyCounty]: result.countyName,
          [ApplicationVariables.FipsCode]: result.fipsCode,
          [ApplicationVariables.PropertyType]: result.propertyType,
          [ApplicationVariables.LandUseDescription]: result.landUseDescription,
        }),
      );
      dispatch(
        setCommonState({
          [CommonTypes.DataTreeLink]: result.fileLink,
        }),
      );
      return result.propertyType;
    } catch (error) {
      return null;
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
    }
  });
