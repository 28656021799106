import React, { useEffect } from 'react';

import classNames from 'classnames/bind';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import RadioButton from 'common/components/RadioButton/RadioButton';
import { SearchLocationInput } from 'common/components/SearchLocationInput';
import InputErrorMessage from 'common/components/TextInput/InputErrorMessage';
import useScript from 'common/hooks/useScript';
import DropDown from 'components/DropDown';
import { OptionType } from 'components/DropDown/DropDown';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { getMessageForInvalidField, getRequiredErrorMessage } from 'errors/errors';
import { backStep } from 'handlers/actions';
import { ApplicationVariables, OwnershipStatus } from 'handlers/application/types';
import { setCoborrowerData } from 'handlers/coborrower';
import { CoborrowerInfoKeys } from 'handlers/coborrower/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import styles from 'pages/pages.module.scss';
import {
  getBorrowerContactCity,
  getBorrowerContactState,
  getBorrowerContactStreetAddress,
  getBorrowerContactZipCode,
  getBorrowerOwnershipStatus,
  getCoborrowerCity,
  getCoborrowerLives,
  getCoborrowerOwnership,
  getCoborrowerState,
  getCoborrowerStreetAddress,
  getCoborrowerZipCode,
  getIsLoading,
  getPropertyOwner1,
} from 'selectors';
import { useAppDispatch } from 'store';
import { runStrategy } from 'thunks';
import { StrategyName } from 'types';
import { getOptionObject } from 'utils/getOptionObject';
import { getPatterns, isExistErrors } from 'utils/validationHelper';

export const ownershipList = [
  { value: OwnershipStatus.OwnOutright, label: OwnershipStatus.OwnOutright },
  { value: OwnershipStatus.OwnWithMortgage, label: OwnershipStatus.OwnWithMortgage },
  { value: OwnershipStatus.ReverseMortage, label: OwnershipStatus.ReverseMortage },
  { value: OwnershipStatus.NotAnOwner, label: OwnershipStatus.NotAnOwner },
];

const cx = classNames.bind(styles);

const CoborrowerLives = () => {
  const dispatch = useAppDispatch();
  const [loaded] = useScript(
    `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places`,
  );

  const initialCoborrowerLives = useSelector(getCoborrowerLives);
  const initialOwnershipStatus = useSelector(getCoborrowerOwnership);
  const borrowerOwnership = useSelector(getBorrowerOwnershipStatus);
  const loading = useSelector(getIsLoading);

  const borrowerContactStreetAddress = useSelector(getBorrowerContactStreetAddress);
  const borrowerContactCity = useSelector(getBorrowerContactCity);
  const borrowerContactZipCode = useSelector(getBorrowerContactZipCode);
  const borrowerContactState = useSelector(getBorrowerContactState);
  const propertyOwner = useSelector(getPropertyOwner1);

  const handleLivesAtInstallAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value === 'true';
    setValue(CoborrowerInfoKeys.CoborrowerLives, newValue);
    if (newValue) {
      setValue(CoborrowerInfoKeys.StreetAddress, borrowerContactStreetAddress);
      setValue(CoborrowerInfoKeys.State, borrowerContactState);
      setValue(CoborrowerInfoKeys.City, borrowerContactCity);
      setValue(CoborrowerInfoKeys.ZipOrPostalCode, borrowerContactZipCode);
    } else {
      setValue(CoborrowerInfoKeys.StreetAddress, '');
      setValue(CoborrowerInfoKeys.State, '');
      setValue(CoborrowerInfoKeys.City, '');
      setValue(CoborrowerInfoKeys.ZipOrPostalCode, '');
    }
  };

  const {
    handleSubmit,
    watch,
    control,
    trigger,
    setValue,
    register,
    clearErrors,
    formState: { errors, isSubmitted },
  } = useForm<FieldValues>({
    defaultValues: {
      [CoborrowerInfoKeys.CoborrowerLives]: initialCoborrowerLives,
      [CoborrowerInfoKeys.CoborrowerOwnership]: getOptionObject(
        ownershipList,
        initialOwnershipStatus as OwnershipStatus,
      ),
      [CoborrowerInfoKeys.StreetAddress]: useSelector(getCoborrowerStreetAddress),
      [CoborrowerInfoKeys.City]: useSelector(getCoborrowerCity),
      [CoborrowerInfoKeys.State]: useSelector(getCoborrowerState),
      [CoborrowerInfoKeys.ZipOrPostalCode]: useSelector(getCoborrowerZipCode),
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const watcher = watch();

  useEffect(() => {
    register(CoborrowerInfoKeys.CoborrowerOwnership, {
      required: watcher[CoborrowerInfoKeys.CoborrowerLives] ? undefined : getRequiredErrorMessage('Ownership status'),
    });
    register(CoborrowerInfoKeys.StreetAddress, {
      required: getRequiredErrorMessage('Contact street address'),
    });
    register(CoborrowerInfoKeys.City, { required: getRequiredErrorMessage('City') });
    register(CoborrowerInfoKeys.ZipOrPostalCode, {
      required: getRequiredErrorMessage('Zip code'),
      pattern: {
        value: getPatterns().zipcode,
        message: getMessageForInvalidField('Zip code'),
      },
    });
    register(CoborrowerInfoKeys.State, { required: getRequiredErrorMessage('State') });
  }, [register, watcher[CoborrowerInfoKeys.CoborrowerLives]]);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    dispatch(
      setCoborrowerData({
        [CoborrowerInfoKeys.CoborrowerLives]: data[CoborrowerInfoKeys.CoborrowerLives],
        [CoborrowerInfoKeys.CoborrowerOwnership]: data[CoborrowerInfoKeys.CoborrowerOwnership]?.value || '',
        [CoborrowerInfoKeys.StreetAddress]: data[CoborrowerInfoKeys.StreetAddress],
        [CoborrowerInfoKeys.City]: data[CoborrowerInfoKeys.City],
        [CoborrowerInfoKeys.State]: data[CoborrowerInfoKeys.State],
        [CoborrowerInfoKeys.ZipOrPostalCode]: data[CoborrowerInfoKeys.ZipOrPostalCode],
      }),
    );

    const strategyResult = await dispatch(
      runStrategy({
        strategyName: StrategyName.EligibilityCheck2,
        strategyInputVariables: {
          [CoborrowerInfoKeys.CoborrowerLives]: data[CoborrowerInfoKeys.CoborrowerLives],
          [CoborrowerInfoKeys.CoborrowerOwnership]: data[CoborrowerInfoKeys.CoborrowerOwnership]?.value || null,
          [ApplicationVariables.BorrowerOwnership]: borrowerOwnership,
          [ApplicationVariables.CoborrowerApplication]: true,
        },
      }),
    ).unwrap();

    if (strategyResult.declined) return;

    dispatch(setCurrentStage({ stage: propertyOwner ? StagesType.OwnersCheck : StagesType.Employment }));
  };

  const isSubmitInactive = () => {
    if (watcher[CoborrowerInfoKeys.CoborrowerLives]) return loading;
    return (
      !watcher[CoborrowerInfoKeys.CoborrowerOwnership] ||
      !watcher[CoborrowerInfoKeys.StreetAddress] ||
      !watcher[CoborrowerInfoKeys.City] ||
      !watcher[CoborrowerInfoKeys.State] ||
      !watcher[CoborrowerInfoKeys.ZipOrPostalCode] ||
      (isSubmitted && isExistErrors(errors))
    );
  };

  const handleBackClick = () => dispatch(backStep());

  const handleContactAddressChange = async (name: string, value: string) => {
    setValue(name, value);
    if (errors[name]?.message) clearErrors(name);
    return trigger(name);
  };

  const handleOwnershipChange = async (value: OptionType) => {
    setValue(CoborrowerInfoKeys.CoborrowerOwnership, value);
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.textInputLabel}>Does the co-borrower live at the install address?</div>
      <RadioButton
        containerClassName={styles.radioButtonWithMargin}
        label="Yes"
        onChange={handleLivesAtInstallAddressChange}
        name={CoborrowerInfoKeys.CoborrowerLives}
        id="coborrowerLives"
        value="true"
        checked={!!watcher[CoborrowerInfoKeys.CoborrowerLives]}
      />
      <div className={styles.inputContainer}>
        <RadioButton
          label="No"
          onChange={handleLivesAtInstallAddressChange}
          name={CoborrowerInfoKeys.CoborrowerLives}
          id="coborrowerDoesntLive"
          value="false"
          checked={watcher[CoborrowerInfoKeys.CoborrowerLives] === false}
        />
      </div>
      {watcher[CoborrowerInfoKeys.CoborrowerLives] === false && (
        <>
          <div className={styles.textInputLabel}>What is the co-borrower's ownership status?</div>
          <div className={cx(styles.dropdownContainer, styles.inputContainer)}>
            <DropDown
              control={control}
              className={styles.inputChildContainer}
              name={CoborrowerInfoKeys.CoborrowerOwnership}
              onValuePicked={handleOwnershipChange}
              selectedOption={watcher[CoborrowerInfoKeys.CoborrowerOwnership]}
              options={ownershipList}
              placeholder="Ownership status"
              invalid={!!errors[CoborrowerInfoKeys.CoborrowerOwnership]?.message}
              isSearchable={false}
              onChange={() => clearErrors(CoborrowerInfoKeys.CoborrowerOwnership)}
            />
            {!!errors[CoborrowerInfoKeys.CoborrowerOwnership]?.message && (
              <InputErrorMessage>{errors[CoborrowerInfoKeys.CoborrowerOwnership]?.message}</InputErrorMessage>
            )}
          </div>

          <div className={styles.textInputLabel}>What is the co-borrower's contact address?</div>
          {loaded && (
            <div className={styles.inputContainer}>
              <SearchLocationInput
                control={control}
                addressValue={{
                  street_address: watcher[CoborrowerInfoKeys.StreetAddress],
                  city: watcher[CoborrowerInfoKeys.City],
                  zip: watcher[CoborrowerInfoKeys.ZipOrPostalCode],
                  state: watcher[CoborrowerInfoKeys.State],
                }}
                addressLabel="Contact street address"
                containerClassName={styles.inputChildContainer}
                errors={isSubmitted ? errors : []}
                handleAddressChange={handleContactAddressChange}
                inputNames={{
                  street_address: CoborrowerInfoKeys.StreetAddress,
                  zip: CoborrowerInfoKeys.ZipOrPostalCode,
                  city: CoborrowerInfoKeys.City,
                  state: CoborrowerInfoKeys.State,
                }}
              />
            </div>
          )}
        </>
      )}
      <NavigationButtonWrapper>
        <BackButton onClick={handleBackClick} />
        <NextButton type="submit" loading={loading} inactive={isSubmitInactive()} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default CoborrowerLives;
