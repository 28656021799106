import axios, { AxiosInstance, AxiosResponse, AxiosRequestConfig, AxiosError } from 'axios';

import { UserType } from 'enums/UserType';

import { CONTRACTOR_LOGIN_URL, LOGIN_URL } from './AccountApi';

declare module 'axios' {
  interface AxiosResponse<T = any> extends Promise<T> {}
}

let isRefreshTokenRequestInProcess = false;
let requestsQueueForRefreshTokenProcess: any[] = [];

export abstract class HttpClient {
  protected readonly instance: AxiosInstance;

  protected isRefreshTokenRequest = false;

  public constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
    });

    this.initializeResponseInterceptor();
  }

  private initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(this.handleResponse, this.handleResponseError);
    this.instance.interceptors.request.use(this.handleRequest, this.handleRequestError);
  };

  private handleRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
    const userType = sessionStorage.getItem('userType');
    const accessToken =
      userType === UserType.Borrower ? localStorage.getItem('borrowerToken') : localStorage.getItem('contractorToken');

    if (userType && accessToken) {
      (config.headers || {}).authorization = `Bearer ${accessToken}`;
    }

    return config;
  };

  private handleRequestError = (error: AxiosError): Promise<AxiosError> => Promise.reject(error);

  private handleResponse = (response: AxiosResponse) => response;

  private processRequestsQueueForRefreshTokenProcess = () => {
    requestsQueueForRefreshTokenProcess.forEach((request) => {
      request.resolve();
    });
  };

  private handleResponseError = async (error: any) => {
    const originalRequest = error.config;

    if (originalRequest.url === LOGIN_URL || originalRequest.url === CONTRACTOR_LOGIN_URL) {
      return Promise.reject(error.response.data);
    }
    const userType = sessionStorage.getItem('userType');

    if (error.response.status === 401 && !this.isRefreshTokenRequest) {
      if (isRefreshTokenRequestInProcess) {
        // While refresh token request is in process, other requests should wait it and should be added in a queue
        // otherwise each request with expired token will make refresh token call and we don't need that.
        return new Promise((resolve, reject) => {
          requestsQueueForRefreshTokenProcess.push({ resolve, reject });
        }).then(() => this.instance(originalRequest));
      }
      this.isRefreshTokenRequest = true;
      isRefreshTokenRequestInProcess = true;

      const refreshToken =
        userType === UserType.Borrower
          ? localStorage.getItem('borrowerRefreshToken')!
          : localStorage.getItem('contractorRefreshToken')!;
      const token =
        userType === UserType.Borrower
          ? localStorage.getItem('borrowerToken')!
          : localStorage.getItem('contractorToken')!;
      if (token) {
        const loginUrl = userType === UserType.Borrower ? LOGIN_URL : CONTRACTOR_LOGIN_URL;

        const loginResult = await this.instance
          .post(`${process.env.REACT_APP_API_URL}${loginUrl}`, { refreshToken })
          .then((response: AxiosResponse<any>) => response.data);

        localStorage.setItem(
          userType === UserType.Borrower ? 'borrowerToken' : 'contractorToken',
          loginResult!.accessToken,
        );
        localStorage.setItem(
          userType === UserType.Borrower ? 'borrowerRefreshToken' : 'contractorRefreshToken',
          loginResult!.refreshToken,
        );
        isRefreshTokenRequestInProcess = false;
        this.processRequestsQueueForRefreshTokenProcess();
        requestsQueueForRefreshTokenProcess = [];

        return this.instance(originalRequest);
      }
    }

    if (error.response.status === 401 && this.isRefreshTokenRequest) {
      isRefreshTokenRequestInProcess = false;
      requestsQueueForRefreshTokenProcess = [];
      if (userType === UserType.Borrower) {
        localStorage.removeItem('borrowerToken');
        localStorage.removeItem('borrowerRefreshToken');
      } else {
        localStorage.removeItem('contractorToken');
        localStorage.removeItem('contractorRefreshToken');
      }
      return Promise.reject(new Error('logout'));
    }

    return Promise.reject(error.response.data);
  };
}
