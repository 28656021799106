import { toast, ToastOptions } from 'react-toastify';

const NotifyPosition: ToastOptions = { position: 'bottom-center' };

const whiteBackgroundColor = { style: { backgroundColor: '#ffffff' } };

interface INotify {
  notification: string | JSX.Element;
  additionalNotification?: string | JSX.Element;
  whiteBackground?: boolean;
}

export const notify = ({ notification, additionalNotification, whiteBackground = false }: INotify) => {
  if (whiteBackground) Object.assign(NotifyPosition, whiteBackgroundColor);
  dismissNotifications();
  toast(notification, NotifyPosition);
  if (additionalNotification) toast(additionalNotification, NotifyPosition);
};

export const dismissNotifications = () => toast.dismiss();
