import React from 'react';

export const specialCharactersiInputProhibition = (e: React.KeyboardEvent<HTMLDivElement>) => {
  const hasLatinLetterAndNumberPattern = /[a-z\sA-Z0-9'-]+$/;
  let checkIfNotSpecialCharacters;
  if (e.key !== undefined) {
    checkIfNotSpecialCharacters = hasLatinLetterAndNumberPattern.test(e.key);
  }
  return !checkIfNotSpecialCharacters && e.preventDefault();
};
