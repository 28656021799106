import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import NavigationButtonWrapper, { AlignmentType } from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import OffersMobile from 'components/OffersTable/OffersMobile';
import OffersTable, { ITableRow } from 'components/OffersTable/OffersTable';
import { MobileScreenWidth } from 'enums/MobileScreenWidth';
import { RootState } from 'handlers';
import { ApplicationState } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { Offer, OffersColumns } from 'pages/Offers/Offers';
import pages from 'pages/pages.module.scss';
import { getApplicationId, getIsLoading, getLoanAmount, getLoanTermList } from 'selectors';
import { useAppDispatch } from 'store';
import { getApplication, updateApplication } from 'thunks';
import { Routes, VariableValue } from 'types';
import { loanOffersToViewModel } from 'utils/loanOffersToViewModel';
import { notify } from 'utils/notificationHelper';

import { NotifyTypes } from './types';

const PAYMENT_OPTION_SUCCESSFULLY_CHANGED = 'Rate and payment option has been successfully changed';

interface ILocationState {
  coborrowerAdded: NotifyTypes.CoborrowerAdded;
}

const ChangeOffer = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const location = useLocation<ILocationState>();
  const [rows, setRows] = useState<ITableRow[]>([]);

  const loading = useSelector(getIsLoading);
  const applicationId = useSelector(getApplicationId);
  const loanTerm = useSelector(getLoanTermList);
  const loanAmount = useSelector(getLoanAmount);

  const application = useSelector((state: RootState) => state.application);

  const [offer, setOffer] = useState<Offer | null>(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobileScreen = screenWidth < MobileScreenWidth.Middle;

  const isRedirectedFromAddCoborrower = location.state?.coborrowerAdded;

  const handleWindowSizeChange = () => setScreenWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const getOffers = async () => {
    const offersState = {
      applicationVariables: application,
      selectedOffer: loanTerm as string,
      loanAmount: loanAmount as number,
    };

    if (isRedirectedFromAddCoborrower) {
      const currentApplication = await dispatch(
        getApplication({
          id: applicationId,
        }),
      ).unwrap();

      if (
        currentApplication.variables[ApplicationVariables.InterestRate5YearFixed] !==
          application[ApplicationVariables.InterestRate] &&
        currentApplication.variables[ApplicationVariables.InterestRate7YearFixed] !==
          application[ApplicationVariables.InterestRate] &&
        currentApplication.variables[ApplicationVariables.InterestRate10YearFixed] !==
          application[ApplicationVariables.InterestRate] &&
        currentApplication.variables[ApplicationVariables.InterestRate12YearFixed] !==
          application[ApplicationVariables.InterestRate] &&
        currentApplication.variables[ApplicationVariables.InterestRate15YearFixed] !==
          application[ApplicationVariables.InterestRate] &&
        currentApplication.variables[ApplicationVariables.InterestRate20YearFixed] !==
          application[ApplicationVariables.InterestRate] &&
        currentApplication.variables[ApplicationVariables.InterestRate21YearFixed] !==
          application[ApplicationVariables.InterestRate]
      ) {
        offersState.selectedOffer = '';
      }

      offersState.applicationVariables = currentApplication.variables as ApplicationState;
    }

    const loanOffers = loanOffersToViewModel(offersState);
    setRows(loanOffers);
  };

  useEffect(() => {
    getOffers();
  }, []);

  useEffect(() => {
    if (isRedirectedFromAddCoborrower) notify({ notification: NotifyTypes.CoborrowerAdded });
  }, [location]);

  const onChangeOffers = (row: ITableRow | null) => {
    const loanDurationInYears = row?.months ? row.months / 12 : null;
    if (row && loanDurationInYears) {
      setOffer({
        months: row.months,
        rate: (application as any)[`interest_rate_${loanDurationInYears}_year_fixed`] as number,
        monthlyPayment: (application as any)[`monthly_payment_${loanDurationInYears}_year_fixed`],
      });
    } else {
      setOffer(null);
    }
  };

  const handleBackClick = () => history.push(Routes.BorrowerDetails);

  const handleSubmit = async () => {
    const applicationVariables: Record<string, VariableValue> = {
      [ApplicationVariables.LoanTermList]: offer?.months.toString() as string,
      [ApplicationVariables.InterestRate]: offer?.rate as number,
      [ApplicationVariables.MonthlyPayment]: offer?.monthlyPayment as number,
    };
    await dispatch(
      updateApplication({
        applicationId,
        applicationVariables,
      }),
    );

    notify({
      notification: PAYMENT_OPTION_SUCCESSFULLY_CHANGED,
    });
    history.push(Routes.BorrowerDashboard);
  };

  const isDisabled = () => !offer || (!isRedirectedFromAddCoborrower && offer.months === Number(loanTerm));

  return (
    <>
      <div className={pages.tableContainer}>
        {isMobileScreen ? (
          <OffersMobile rows={rows} onChange={onChangeOffers} shownAmount={7} isCollapsible={false} />
        ) : (
          <OffersTable
            columns={OffersColumns}
            rows={rows}
            onChange={onChangeOffers}
            shownAmount={7}
            isCollapsible={false}
          />
        )}
      </div>
      <NavigationButtonWrapper alignment={isRedirectedFromAddCoborrower && AlignmentType.Right}>
        {!isRedirectedFromAddCoborrower && (
          <BackButton onClick={handleBackClick} className={commonStyles.portalBackButton} />
        )}
        <NextButton title="Submit" onClick={handleSubmit} loading={loading} disabled={isDisabled()} />
      </NavigationButtonWrapper>
    </>
  );
};

export default withPortalLayout(ChangeOffer);
