import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { backStep } from 'handlers/actions';
import { setCurrentStage, StageActionPayload } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';

import {
  BasicInformationSteps,
  CoborrowerInfoSteps,
  EmploymentSteps,
  InstallationSteps,
  OwnersCheckSteps,
  PhoneVerificationSteps,
  Step,
  UploadDocumentsSteps,
} from './types';

const basicInformationSteps = [
  BasicInformationSteps.LoanPurpose,
  BasicInformationSteps.LoanAmount,
  BasicInformationSteps.BorrowerInfo,
  BasicInformationSteps.Contacts,
];

const installationSteps = [
  InstallationSteps.AddressInfo,
  InstallationSteps.OwnershipTerm,
  InstallationSteps.CoborrowerLives,
];

const coborrowerInfoSteps = [
  CoborrowerInfoSteps.NumberOfBorrowers,
  CoborrowerInfoSteps.CoborrowerPersonalInfo,
  CoborrowerInfoSteps.CoborrowerContactInfo,
];
const phoneVerificationSteps = [PhoneVerificationSteps.PhoneVerification];
const ownersCheckSteps = [OwnersCheckSteps.OwnersCheck];
const employmentSteps = [EmploymentSteps.BorrowerEmploymentInfo, EmploymentSteps.BorrowerIncome];
const coborrowerEmploymentSteps = [EmploymentSteps.CoborrowerEmploymentInfo, EmploymentSteps.CoborrowerIncome];
const uploadDocumentsSteps = [UploadDocumentsSteps.BorrowerIdentity];

export interface Steps {
  currentStep: Step;
  steps: Step[];
}

const initialState: Steps = {
  currentStep: BasicInformationSteps.LoanPurpose,
  steps: basicInformationSteps,
};

const steps = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    setStep: (state: Steps, { payload }: PayloadAction<Step>) => {
      state.currentStep = payload;
    },

    setSteps: (state: Steps, { payload }: PayloadAction<Step[]>) => {
      state.steps = payload;
    },

    nextStep: (state: Steps) => {
      const currentIndex = state.steps.indexOf(state.currentStep);
      const nextIndex = currentIndex + 1;

      if (nextIndex < state.steps.length) {
        state.currentStep = state.steps[nextIndex];
      }

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(backStep, (state: Steps) => {
      const currentIndex = state.steps.indexOf(state.currentStep);
      const backIndex = currentIndex - 1;

      if (backIndex >= 0) {
        state.currentStep = state.steps[backIndex];
      }

      return state;
    });
    builder.addCase(setCurrentStage, (state: Steps, { payload }: PayloadAction<StageActionPayload>) => {
      switch (payload.stage) {
        case StagesType.BasicInformation:
          state.steps = basicInformationSteps;
          state.currentStep = payload.step || basicInformationSteps[0];
          break;
        case StagesType.PhoneVerification:
          state.steps = phoneVerificationSteps;
          state.currentStep = payload.step || phoneVerificationSteps[0];
          break;
        case StagesType.Installation:
          state.steps = installationSteps;
          state.currentStep = payload.step || installationSteps[0];
          break;
        case StagesType.CoborrowerInfo:
          state.steps = coborrowerInfoSteps;
          state.currentStep = payload.step || coborrowerInfoSteps[0];
          break;
        case StagesType.OwnersCheck:
          state.steps = ownersCheckSteps;
          state.currentStep = payload.step || ownersCheckSteps[0];
          break;
        case StagesType.Employment:
          state.steps = employmentSteps;
          state.currentStep = payload.step || employmentSteps[0];
          break;
        case StagesType.CoborrowerEmployment:
          state.steps = coborrowerEmploymentSteps;
          state.currentStep = payload.step || coborrowerEmploymentSteps[0];
          break;
        case StagesType.UploadDocuments:
          state.steps = uploadDocumentsSteps;
          state.currentStep = payload.step || uploadDocumentsSteps[0];
          break;
        default:
          break;
      }
    });
  },
});

export const { setStep, setSteps, nextStep } = steps.actions;

export default steps.reducer;
