import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';
import { UserType } from 'enums/UserType';
import { setIntermediaryData } from 'handlers/intermediary';
import { LoadingType, setLoadingState } from 'handlers/loading';

import { LoginProps } from './loginBorrower';

export const createLoginContractorThunk = (accountApi: AccountApi) =>
  createAsyncThunk(
    'account/contractorLogin',
    async ({ email, password }: LoginProps, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        const result = await accountApi.loginContractor(email, password);
        sessionStorage.setItem('userType', UserType.Contractor);
        localStorage.setItem('contractorToken', result!.accessToken);
        localStorage.setItem('contractorRefreshToken', result!.refreshToken);
        dispatch(
          setIntermediaryData({
            intermediary_email: email,
            isMfaIncomplete: result.isMfaIncomplete,
            isEmailNotVerified: result.isEmailNotVerified,
          }),
        );
        return result;
      } catch (e) {
        rejectWithValue(null);
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
