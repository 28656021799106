import { createAsyncThunk } from '@reduxjs/toolkit';

import ApplicationApi from 'api/ApplicationApi';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface SendLockedProps {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

export const createSendLockedThunk = (applicationApi: ApplicationApi) =>
  createAsyncThunk(
    'submissions/locked',
    async ({ firstName, lastName, email, phone }: SendLockedProps, { dispatch }) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        applicationApi.sendLockedNotifications(firstName, lastName, email, phone);
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
