import React, { FC } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ProgressBar from 'common/components/ProgressBar';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { getCurrentStageIndex, getProgressStages } from 'selectors';

import PageLayout from './PageLayout';

interface IWithProgressBarProps {
  title: string;
}

const WithProgressBar: FC<IWithProgressBarProps> = ({ children, title }) => {
  const dispatch = useDispatch();
  const currentStageIndex = useSelector(getCurrentStageIndex);
  const progressStages = useSelector(getProgressStages);

  const handleProgressBarClick = (stage: StagesType) => {
    dispatch(setCurrentStage({ stage }));
  };

  return (
    <PageLayout
      title={title}
      top={<ProgressBar currentIndex={currentStageIndex} stages={progressStages} onClick={handleProgressBarClick} />}
    >
      {children}
    </PageLayout>
  );
};

export default WithProgressBar;
