import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';
import { logout } from 'handlers/actions';

export interface LogoutProps {
  token?: string;
}

export const createLogoutBorrowerThunk = (accountApi: AccountApi) =>
  createAsyncThunk(
    'account/borrowerLogout',
    // eslint-disable-next-line no-empty-pattern
    async ({}: LogoutProps, { dispatch }) => {
      try {
        await accountApi.logoutBorrower();
      } finally {
        dispatch(logout());
      }
    },
  );
