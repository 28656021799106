import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';

export interface ForgotPasswordProps {
  email: string;
}

export const createForgotBorrowerPasswordThunk = (accountApi: AccountApi) =>
  createAsyncThunk('account/borrowerForgot', async ({ email }: ForgotPasswordProps, { rejectWithValue }) => {
    try {
      const result = await accountApi.requestBorrowerResetPassword(email);
      if (result.success) {
        return true;
      }
      rejectWithValue(null);
    } catch (e) {
      rejectWithValue(null);
    }
  });
