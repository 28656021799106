import {
  Action,
  AnyAction,
  configureStore,
  Dispatch,
  getDefaultMiddleware,
  Middleware,
  ThunkAction,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import rootReducer, { RootState } from 'handlers';
import { dismissNotifications } from 'utils/notificationHelper';

import applicationMiddleware from './applicationMiddleware';

const dissmissNotificationMiddleware: Middleware = () => (next: Dispatch<AnyAction>) => (action) => {
  dismissNotifications();
  return next(action);
};

const initialState = sessionStorage.getItem('state') ? JSON.parse(sessionStorage.getItem('state')!) : {};

const store = configureStore({
  reducer: rootReducer,
  middleware: [
    ...getDefaultMiddleware({ serializableCheck: false }),
    dissmissNotificationMiddleware,
    thunk as ThunkMiddleware,
    applicationMiddleware,
  ],
  preloadedState: initialState,
});

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

export default store;
