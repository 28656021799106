import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import Button, { ButtonVariant } from 'common/components/Button/Button';
import Link from 'common/components/Link';
import Header from 'components/Header';
import OffersMobile from 'components/OffersTable/OffersMobile';
import OffersTable, { ITableRow } from 'components/OffersTable/OffersTable';
import PageLoader from 'components/PageLoader';
import { MobileScreenWidth } from 'enums/MobileScreenWidth';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import pages from 'pages/pages.module.scss';
import {
  getAchDiscount,
  getAdjustedApplicationMonthlyDebtPayments,
  getAdjustedApplicationMonthlyIncome,
  getApplicationFicoScore,
  getApplicationId,
  getCoborrowerExists,
  getDischargedBankruptcyAlert,
  getDischargedBankruptcyAlertCoborrower,
  getIsLoading,
  getLoanAmount,
  getPotentialOffersDisplayed,
  getSoftPullResult,
} from 'selectors';
import { useAppDispatch } from 'store';
import { runStrategy, updateApplication } from 'thunks';
import { SoftPullStatus, StrategyName, VariableValue } from 'types';
import { convertMonetary } from 'utils/formats';
import { loanOffersToViewModel } from 'utils/loanOffersToViewModel';

import styles from './Offers.module.scss';

export const OffersColumns = [
  {
    field: 'product',
    headerName: 'Product',
  },
  {
    field: 'months',
    headerName: 'Months',
  },
  {
    field: 'rate',
    headerName: 'Rate',
  },
  {
    field: 'monthlyPayment',
    headerName: 'Monthly payment',
  },
];
export interface Offer {
  months: number;
  rate: number;
  monthlyPayment: number;
}

let initialOffers: Record<string, any>;

const DeclinePage = () => {
  const dispatch = useAppDispatch();

  const loanAmount = useSelector(getLoanAmount) || 0;
  const applicationId = useSelector(getApplicationId);
  const isJointApplication = useSelector(getCoborrowerExists);
  const applicationFicoScore = useSelector(getApplicationFicoScore);
  const adjustedApplicationMonthlyDebtPayments = useSelector(getAdjustedApplicationMonthlyDebtPayments);
  const adjustedApplicationMonthlyIncomeAmount = useSelector(getAdjustedApplicationMonthlyIncome);
  const achDiscount = useSelector(getAchDiscount);
  const dischargedBankruptcyAlert = useSelector(getDischargedBankruptcyAlert);
  const dischargedBankruptcyAlertCoborrower = useSelector(getDischargedBankruptcyAlertCoborrower);
  const [potentialOffersDisplayed, setPotentialOffersDisplayed] = useState(!!useSelector(getPotentialOffersDisplayed));
  const softPullResult = useSelector(getSoftPullResult);
  const [offer, setOffer] = useState<Offer | null>(null);
  const [offers, setOffers] = useState<ITableRow[]>([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const loading = useSelector(getIsLoading);

  const isMobileScreen = screenWidth < MobileScreenWidth.Middle;

  const handleWindowSizeChange = () => setScreenWidth(window.innerWidth);

  const getOffersTitle = () => {
    if (potentialOffersDisplayed) return 'Potential rate and payment options';
    return softPullResult === SoftPullStatus.Approved
      ? 'Your application is pre-approved!'
      : 'Choose your rate and payment option!';
  };

  const getOffersDescription = () => {
    if (potentialOffersDisplayed) {
      return 'Rate and payment options below contingent upon verification of income, credit approval and other factors';
    }
    return softPullResult === SoftPullStatus.Approved
      ? 'Pre-approved offers below contingent upon verification of identity, ownership, and other factors'
      : 'Pre-qualified offers below contingent upon credit and project approval';
  };

  useEffect(() => {
    document.body.classList.add('offersPageBody');
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      document.body.classList.remove('offersPageBody');
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const loadOffers = async () => {
    const strategyVariables = {
      [ApplicationVariables.LoanAmount]: loanAmount,
      [ApplicationVariables.ApplicationFicoScore]: applicationFicoScore,
      [ApplicationVariables.AdjustedApplicationMonthlyDebtPayments]: adjustedApplicationMonthlyDebtPayments,
      [ApplicationVariables.AdjustedApplicationMonthlyIncomeAmount]: adjustedApplicationMonthlyIncomeAmount,
      [ApplicationVariables.ACHDiscount]: achDiscount,
      [ApplicationVariables.DischargedBankruptcyAlert]: dischargedBankruptcyAlert,
      [ApplicationVariables.CoborrowerApplication]: !!isJointApplication,
      [ApplicationVariables.DischargedBankruptcyAlertCoborrower]: dischargedBankruptcyAlertCoborrower,
    };

    initialOffers = await dispatch(
      runStrategy({
        strategyName: StrategyName.OffersCalculation,
        applicationId,
        strategyInputVariables: strategyVariables,
      }),
    ).unwrap();

    dispatch(
      updateApplication({
        applicationId,
        applicationVariables: initialOffers.outputVariables,
        skipAuth: true,
      }),
    );
    setPotentialOffersDisplayed(initialOffers.outputVariables.potential_offers_displayed);
    const loanOffers = loanOffersToViewModel({
      applicationVariables: initialOffers.outputVariables,
      loanAmount: loanAmount as number,
    });
    setOffers(loanOffers);
  };

  useEffect(() => {
    loadOffers();
  }, []);

  const handleBack = () => {
    dispatch(
      setApplicationData({
        applicationId: undefined,
      }),
    );
  };

  const handleOfferChange = (row: ITableRow | null) => {
    const loanDurationInYears = row?.months ? row.months / 12 : null;
    if (row && loanDurationInYears) {
      setOffer({
        months: row.months,
        rate: initialOffers.outputVariables[`interest_rate_${loanDurationInYears}_year_fixed`] as number,
        monthlyPayment: initialOffers.outputVariables[`monthly_payment_${loanDurationInYears}_year_fixed`],
      });
    } else {
      setOffer(null);
    }
  };

  const handleContinue = async () => {
    const applicationVariables: Record<string, VariableValue> = {
      [ApplicationVariables.LoanTermList]: offer?.months.toString() as string,
      [ApplicationVariables.InterestRate]: offer?.rate as number,
      [ApplicationVariables.MonthlyPayment]: offer?.monthlyPayment as number,
    };
    await dispatch(
      updateApplication({
        applicationId,
        applicationVariables,
        skipAuth: true,
      }),
    );

    dispatch(setCurrentStage({ stage: StagesType.SCICode }));
  };

  const isDisabledNextButton = () => !potentialOffersDisplayed && !offer;

  return (
    <>
      {loading && <PageLoader />}
      <div className={pages.externalPage!}>
        <div className={pages.externalPageContent}>
          <Header external />
          <div className={styles.offersBodyWrapper}>
            <h1 className={pages.externalPageTitle}>{getOffersTitle()}</h1>
            <p className={styles.offersTitleNote}>{getOffersDescription()}</p>
            <p className={styles.loanLabel}>Requested loan amount</p>
            <strong className={styles.loanAmount}>{convertMonetary(loanAmount)}</strong>
            {!isMobileScreen && (
              <>
                <OffersTable
                  columns={OffersColumns}
                  rows={offers}
                  onChange={handleOfferChange}
                  shownAmount={3}
                  disabled={!!potentialOffersDisplayed}
                  isCollapsible={offers ? !!offers.length : !potentialOffersDisplayed}
                />
                <Button
                  title="Continue"
                  disabled={isDisabledNextButton()}
                  className={styles.continueButton}
                  onClick={handleContinue}
                />
              </>
            )}
          </div>
        </div>
        {isMobileScreen && (
          <>
            <OffersMobile
              rows={offers}
              onChange={handleOfferChange}
              shownAmount={3}
              disabled={!!potentialOffersDisplayed}
              isCollapsible={offers ? !!offers.length : !potentialOffersDisplayed}
            />
            <Button
              title="Continue"
              disabled={isDisabledNextButton()}
              className={styles.continueButton}
              onClick={handleContinue}
            />
          </>
        )}
        <Link href="https://ezsolarloan.com/" className={styles.link}>
          <BackButton className={styles.backButton} title="Not now" onClick={handleBack} variant={ButtonVariant.Text} />
        </Link>
      </div>
    </>
  );
};

export default DeclinePage;
