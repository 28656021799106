import React, { useEffect, useRef, useState } from 'react';

import { ClickAwayListener, Fade, Popper } from '@mui/material';
import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';

import { setTableOptions, TableType } from 'handlers/table';
import { getSortDirection, getSortField } from 'selectors';

import { ReactComponent as Arrow } from './icons/Arrow.svg';
import { ReactComponent as ArrowSort } from './icons/SortArrow.svg';
import styles from './SortButton.module.scss';

const cx = classNames.bind(styles);

interface ISortButton {
  options: Array<Column<any>>;
}

export enum Option {
  DESC = 'desc',
  ASC = 'asc',
}

const getArrowIcon = (value: Option | undefined) => {
  if (!value) return value;
  return <Arrow className={cx({ [styles.arrowIconDown]: value === Option.DESC })} />;
};

const SortButton = ({ options }: ISortButton) => {
  const dispatch = useDispatch();
  const sortDirection = useSelector(getSortDirection);
  const sortField = useSelector(getSortField);

  const [open, setOpen] = useState(false);
  const [sortFieldValue, setSortFieldValue] = useState<string | undefined>(sortField);
  const [sortDirectionValue, setSortDirectionValue] = useState<Option | undefined>(sortDirection as Option);
  const anchorRef = useRef<any>(null);

  const onSorted = (value: string | undefined) => {
    let field;
    let direction;
    if (value === sortFieldValue) {
      if (sortDirectionValue === Option.DESC) {
        field = undefined;
        direction = undefined;
      } else {
        field = value;
        direction = sortDirectionValue === Option.ASC ? Option.DESC : Option.ASC;
      }
    } else {
      field = value;
      direction = Option.ASC;
    }
    dispatch(
      setTableOptions({
        [TableType.SortField]: field,
        [TableType.SortDirection]: direction,
        [TableType.PageIndex]: 0,
      }),
    );
  };

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) return;
    setOpen(false);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) anchorRef.current.focus();
    prevOpen.current = open;
  }, []);

  useEffect(() => {
    setSortFieldValue(sortField);
    setSortDirectionValue(sortDirection as Option);
  }, [sortField, sortDirection]);

  const handleToggle = () => setOpen((prevOpen) => !prevOpen);

  const prevOpen = React.useRef(open);

  return (
    <>
      <button
        className={styles.button}
        type="button"
        ref={anchorRef}
        aria-controls={open ? 'menu-list' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <ArrowSort />
        Sort by
      </button>
      <Popper open={open} anchorEl={anchorRef.current} transition className={styles.popperContainer}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={200}>
              <div className={styles.menuContainer}>
                <ul className={styles.menuList} id="menu-list" onKeyDown={handleListKeyDown}>
                  {options.map(
                    (option) =>
                      option.disableSortBy ?? (
                        <li
                          className={cx(styles.menuItem, {
                            [styles.menuItemSelected]: option.id === sortFieldValue && sortDirectionValue,
                          })}
                          onClick={() => onSorted(option.id ?? undefined)}
                          key={option.id}
                        >
                          {option.Header} {option.id === sortFieldValue && getArrowIcon(sortDirectionValue)}
                        </li>
                      ),
                  )}
                </ul>
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default SortButton;
