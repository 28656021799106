export type VariableValue = string | string[] | boolean | number | null;

export enum StrategyName {
  EligibilityCheck1 = 'eligibility_check_1',
  EligibilityCheck2 = 'eligibility_check_2',
  OffersCalculation = 'offers_calculation',
  HardCreditPull = 'hard_credit_pull',
  AutomatedFinalCheck = 'automated_final_check',
  VOINeeded = 'voi_needed',
  AutomatedProjectCheck = 'automated_project_check',
  BorrowerIdVerification = 'borrower_id_verification',
  CoborrowerIdVerification = 'coborrower_id_verification',
  SoftCreditPull = 'soft_credit_pull',
  EstimatedUCCFilingCostCalculation = 'estimated_ucc_filing_cost_calculation',
}

export enum ApplicationStatus {
  PreQualified = 'Pre-qualified',
  CompletingVerification = 'Completing Verification',
  ManualProcessorReview = 'Manual Processor Review',
  ManualUnderwritingReview = 'Manual Underwriting Review',
  FinishingApplication = 'Finishing Application',
  ExceptionProcessing = 'Exception Processing',
  SigningDocuments = 'Signing Documents',
  BookedToKeystone = 'Booked to Keystone',
  ContractReview = 'Contract Review',
  ContractorPayments = 'Contractor Payments',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum SoftPullStatus {
  Received = 'Received',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum HardPullStatus {
  Received = 'Received',
  Approved = 'Approved',
  Rejected = 'Rejected',
}

export enum BorrowerType {
  Person = 'person',
  Company = 'company',
}

export interface UserShortInfo {
  id: string;
  firstName: string;
  lastName: string;
  imageId?: string;
}

export interface Application {
  id: string;
  displayId: number;
  variables: { [key: string]: any };
  status: {
    id: string;
    name: string;
  };
  borrowerId: string;
  coborrowerIds: string[];
  intermediaryId?: string;
  declineReasons?: string[];
  teamMembers: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
    avatarUrl?: string;
  }[];
  labels: {
    id: string;
    name: string;
    color: string;
  }[];
  borrowerType: BorrowerType;
  coborrowerTypes: BorrowerType[];
  product: {
    id: string;
    name: string;
    organizationId: string;
    borrowerType: BorrowerType;
  };
  createdBy?: UserShortInfo | null;
  updatedBy?: UserShortInfo | null;
  createdAt: Date;
  updatedAt: Date;
  approvedAt?: Date;
  rejectedAt?: Date;
}

export interface Borrower {
  id: string;
  variables: { [key: string]: any };
  locked: boolean;
  organizationId: string;
  type: BorrowerType;
  createdBy?: UserShortInfo | null;
  updatedBy?: UserShortInfo | null;
  createdAt: Date;
  updatedAt: Date;
}

export interface Intermediary {
  id: string;
  organizationId: string;
  variables: Record<string, VariableValue>;
  createdAt: Date;
  updatedAt: Date;
  createdBy?: UserShortInfo | null;
  updatedBy?: UserShortInfo | null;
}

export interface IntermediaryShort {
  id: string;
  intermediary_name: string;
}

export enum Routes {
  Apply = '/apply',
  BorrowerLogin = '/dashboard/login',
  BorrowerForgotPassword = '/dashboard/login/forgot',
  BorrowerNewPassword = '/dashboard/login/new-password/:code',
  BorrowerDashboard = '/dashboard/view',
  BorrowerDetails = '/dashboard/details',
  BorrowerProject = '/dashboard/project',
  BorrowerSalesperson = '/dashboard/salesperson',
  BorrowerPaymentRate = '/dashboard/paymentRate',
  BorrowerPayments = '/dashboard/payments',
  BorrowerSigning = '/dashboard/signing',
  BorrowerCoborrower = '/dashboard/coborrower',
  BorrowerDocuments = '/dashboard/documents',
  BorrowerConfirmEmail = '/dashboard/confirmEmail',
  BorrowerConfirmPhone = '/dashboard/confirmPhone',
  BorrowerNoActiveApplication = '/dashboard/no-active-application',
  BorrowerContactUs = '/dashboard/contact-us',
  ContractorLogin = '/portal/login',
  ContractorForgotPassword = '/portal/login/forgot',
  ContractorNewPassword = '/portal/login/new-password/:code',
  ContractorConfirmEmail = '/portal/confirmEmail',
  ContractorConfirmPhone = '/portal/confirmPhone',
  ContractorDashboard = '/portal/view',
  ContractorDeals = '/portal/deals',
  ContractorInvite = '/portal/invite',
  ContractorManageDeal = '/portal/deals/:id',
  ContractorProject = '/portal/deals/:id/project',
  ContractorViewProject = '/portal/deals/:id/view-project',
  ContractorDocuments = '/portal/deals/:id/documents',
  ContractorPaymentRate = '/portal/deals/:id/paymentRate',
  ContractorRequestFunds = '/portal/deals/:id/requestFunds',
  ContractorSignUp = '/portal/signup/:token',
}
