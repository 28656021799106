import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';
import { setCustomerData } from 'handlers/customer';
import { setIntermediaryData } from 'handlers/intermediary';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface PhoneCodeProps {
  code: string;
}

export const createVerifyPhoneForBorrowerThunk = (accountApi: AccountApi) =>
  createAsyncThunk('account/borrower/verifyPhone', async ({ code }: PhoneCodeProps, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
      const result = await accountApi.verifyPhoneCode(code);

      if (result.success) {
        dispatch(
          setCustomerData({
            isMfaIncomplete: null,
          }),
        );
        return true;
      }
      rejectWithValue(false);
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
    }
  });

export const createVerifyPhoneForContractorThunk = (accountApi: AccountApi) =>
  createAsyncThunk(
    'account/intermediary/verifyPhone',
    async ({ code }: PhoneCodeProps, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        const result = await accountApi.verifyPhoneCodeForContractor(code);

        if (result.success) {
          dispatch(
            setIntermediaryData({
              isMfaIncomplete: null,
            }),
          );
          return true;
        }
        rejectWithValue(false);
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
