import { OwnerNameKey } from 'enums/DataTree';

interface ICheckOwnersData {
  borrowerFirstName: string;
  borrowerLastName: string;
  coborrowerFirstName: string;
  coborrowerLastName: string;
  propertyOwner1: string;
  propertyOwner2: string;
  propertyOwner3: string;
}

export const checkOwnerName = (owner: string) =>
  owner.includes(OwnerNameKey.CORP) ||
  owner.includes(OwnerNameKey.CORPORATION) ||
  owner.includes(OwnerNameKey.LLC) ||
  owner.includes(OwnerNameKey.TTE) ||
  owner.includes(OwnerNameKey.TRUST);

const sliceMiddleName = (name: string) =>
  name.split(' ').length - 1 > 1 ? name.slice(0, name.lastIndexOf(' ')) : name;

export const checkOwners = (checkOwnersData: ICheckOwnersData) => {
  if (checkOwnerName(checkOwnersData.propertyOwner1)) {
    return false;
  }
  const owners = [sliceMiddleName(checkOwnersData.propertyOwner1)];

  if (checkOwnersData.propertyOwner2) {
    if (checkOwnerName(checkOwnersData.propertyOwner2)) {
      return false;
    }

    owners.push(sliceMiddleName(checkOwnersData.propertyOwner2));
  }
  if (checkOwnersData.propertyOwner3) {
    if (checkOwnerName(checkOwnersData.propertyOwner2)) {
      return false;
    }

    owners.push(sliceMiddleName(checkOwnersData.propertyOwner3));
  }

  const borrower = `${checkOwnersData.borrowerLastName.toUpperCase()} ${sliceMiddleName(
    checkOwnersData.borrowerFirstName.toUpperCase(),
  )}`;
  const coBorrower = `${checkOwnersData.coborrowerLastName.toUpperCase()} ${sliceMiddleName(
    checkOwnersData.coborrowerFirstName.toUpperCase(),
  )}`;

  return owners.every((owner) => owner === borrower || owner === coBorrower);
};
