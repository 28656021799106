import React, { useEffect } from 'react';

import classNames from 'classnames/bind';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import TextInput from 'common/components/TextInput';
import InputErrorMessage from 'common/components/TextInput/InputErrorMessage';
import DropDown from 'components/DropDown';
import { OptionType } from 'components/DropDown/DropDown';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { getRequiredErrorMessage } from 'errors/errors';
import { RootState } from 'handlers';
import { backStep } from 'handlers/actions';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables, EmploymentType } from 'handlers/application/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { nextStep, setSteps } from 'handlers/steps';
import { EmploymentSteps, InstallationSteps, Step } from 'handlers/steps/types';
import { EmploymentDuration, EmploymentPageType } from 'pages/Employment/types';
import styles from 'pages/pages.module.scss';
import {
  getBorrowerCompanyName,
  getBorrowerEmploymentDuration,
  getBorrowerEmploymentType,
  getBorrowerJobTitle,
  getBorrowerPreviousCompanyName,
  getBorrowerPreviousEmploymentType,
  getBorrowerPreviousJobTitle,
  getCoborrowerCompanyName,
  getCoborrowerEmploymentDuration,
  getCoborrowerEmploymentType,
  getCoborrowerExists,
  getCoborrowerJobTitle,
  getCoborrowerPreviousCompanyName,
  getCoborrowerPreviousEmploymentType,
  getCoborrowerPreviousJobTitle,
  getPreviousBorrowerEmploymentDuration,
  getPreviousCoborrowerEmploymentDuration,
  getPropertyOwner1,
} from 'selectors';
import { deleteSpaceInStart } from 'utils/formats';
import { getOptionObject } from 'utils/getOptionObject';
import { specialCharactersiInputProhibition } from 'utils/inputProhibition';
import { isRecentEmployment } from 'utils/isRecentEmployment';

type FormValues = {
  employmentType: OptionType;
  jobTitle: string;
  companyTitle: string;
  employmentDuration: OptionType;
};

export const employmentTypes = [
  { value: EmploymentType.Employed, label: EmploymentType.Employed },
  { value: EmploymentType.Homemaker, label: EmploymentType.Homemaker },
  { value: EmploymentType.Retired, label: EmploymentType.Retired },
  { value: EmploymentType.SelfEmployed, label: EmploymentType.SelfEmployed },
  { value: EmploymentType.BusinessOwner, label: EmploymentType.BusinessOwner },
  { value: EmploymentType.Student, label: EmploymentType.Student },
  { value: EmploymentType.Unemployed, label: EmploymentType.Unemployed },
];

export const employmentDurations = [
  { value: EmploymentDuration.Less1, label: EmploymentDuration.Less1 },
  { value: EmploymentDuration.Between1And2, label: EmploymentDuration.Between1And2 },
  { value: EmploymentDuration.More2, label: EmploymentDuration.More2 },
];

interface EmploymentInfoProps {
  type: EmploymentPageType;
}

enum EmploymentTypeLabel {
  Borrower = 'Which best describes your type of employment?',
  Coborrower = "Which best describes the co-borrower's type of employment?",
  PreviousBorrower = 'Which best describes your most recent previous type of employment?',
  PreviousCoborrower = "Which best describes the co-borrower's most recent previous type of employment?",
}

enum CompanyTitleLabel {
  Borrower = 'What is the name of your employer/company?',
  Coborrower = "What is the name of the co-borrower's employer / company?",
  PreviousBorrower = 'What was your most recent previous employer / company?',
  PreviousCoborrower = "What was the co-borrower's most recent previous employer / company?",
}

enum JobTitleLabel {
  Borrower = 'What is your job title?',
  Coborrower = "What is the co-borrower's job title?",
  PreviousBorrower = 'What was your most recent previous job title?',
  PreviousCoborrower = "What was the co-borrower's most recent previous job title?",
}

enum EmploymentDurationLabel {
  Borrower = 'How long have you been with your current employer/company?',
  Coborrower = 'How long has the co-borrower been with their current employer/company?',
  PreviousBorrower = 'How long have you been with your previous employer/company?',
  PreviousCoborrower = 'How long has the co-borrower been with their previous employer/company?',
}

const getInitialValuesObject = (type: EmploymentPageType) => {
  switch (type) {
    case EmploymentPageType.Borrower:
      return {
        employmentType: getOptionObject(employmentTypes, useSelector(getBorrowerEmploymentType) as EmploymentType),
        companyTitle: useSelector(getBorrowerCompanyName),
        jobTitle: useSelector(getBorrowerJobTitle),
        employmentDuration: getOptionObject(
          employmentDurations,
          useSelector(getBorrowerEmploymentDuration) as EmploymentDuration,
        ),
      };
    case EmploymentPageType.PreviousBorrower:
      return {
        employmentType: getOptionObject(
          employmentTypes,
          useSelector(getBorrowerPreviousEmploymentType) as EmploymentType,
        ),
        companyTitle: useSelector(getBorrowerPreviousCompanyName),
        jobTitle: useSelector(getBorrowerPreviousJobTitle),
        employmentDuration: getOptionObject(
          employmentDurations,
          useSelector(getPreviousBorrowerEmploymentDuration) as EmploymentDuration,
        ),
      };
    case EmploymentPageType.Coborrower:
      return {
        employmentType: getOptionObject(employmentTypes, useSelector(getCoborrowerEmploymentType) as EmploymentType),
        companyTitle: useSelector(getCoborrowerCompanyName),
        jobTitle: useSelector(getCoborrowerJobTitle),
        employmentDuration: getOptionObject(
          employmentDurations,
          useSelector(getCoborrowerEmploymentDuration) as EmploymentDuration,
        ),
      };
    case EmploymentPageType.PreviousCoborrower:
      return {
        employmentType: getOptionObject(
          employmentTypes,
          useSelector(getCoborrowerPreviousEmploymentType) as EmploymentType,
        ),
        companyTitle: useSelector(getCoborrowerPreviousCompanyName),
        jobTitle: useSelector(getCoborrowerPreviousJobTitle),
        employmentDuration: getOptionObject(
          employmentDurations,
          useSelector(getPreviousCoborrowerEmploymentDuration) as EmploymentDuration,
        ),
      };
    default:
  }
};

const getDataToSubmit = (data: FormValues, type: EmploymentPageType) => {
  switch (type) {
    case EmploymentPageType.Borrower:
      return {
        [ApplicationVariables.BorrowerEmploymentType]: data.employmentType.value as EmploymentType,
        [ApplicationVariables.BorrowerJobTitle]: data.jobTitle,
        [ApplicationVariables.BorrowerCompanyName]: data.companyTitle,
        [ApplicationVariables.BorrowerLengthOfEmployment]: data.employmentDuration?.value as EmploymentDuration,
      };
    case EmploymentPageType.PreviousBorrower:
      return {
        [ApplicationVariables.BorrowerPreviousEmploymentType]: data.employmentType.value as EmploymentType,
        [ApplicationVariables.BorrowerPreviousJobTitle]: data.jobTitle,
        [ApplicationVariables.BorrowerPreviousCompanyName]: data.companyTitle,
        [ApplicationVariables.BorrowerPreviousLengthOfEmployment]: data.employmentDuration?.value as EmploymentDuration,
      };
    case EmploymentPageType.Coborrower:
      return {
        [ApplicationVariables.CoborrowerEmploymentType]: data.employmentType.value as EmploymentType,
        [ApplicationVariables.CoborrowerJobTitle]: data.jobTitle,
        [ApplicationVariables.CoborrowerCompanyName]: data.companyTitle,
        [ApplicationVariables.CoborrowerLengthOfEmployment]: data.employmentDuration?.value as EmploymentDuration,
      };
    case EmploymentPageType.PreviousCoborrower:
      return {
        [ApplicationVariables.CoborrowerPreviousEmploymentType]: data.employmentType.value as EmploymentType,
        [ApplicationVariables.CoborrowerPreviousJobTitle]: data.jobTitle,
        [ApplicationVariables.CoborrowerPreviousCompanyName]: data.companyTitle,
        [ApplicationVariables.CoborrowerPreviousLengthOfEmployment]: data.employmentDuration
          ?.value as EmploymentDuration,
      };
    default:
      return {
        [ApplicationVariables.BorrowerEmploymentType]: data.employmentType.value as EmploymentType,
        [ApplicationVariables.BorrowerJobTitle]: data.jobTitle,
        [ApplicationVariables.BorrowerCompanyName]: data.companyTitle,
        [ApplicationVariables.BorrowerLengthOfEmployment]: data.employmentDuration?.value as EmploymentDuration,
      };
  }
};

const getSteps = (employmentDuration: EmploymentDuration, type: EmploymentPageType, currentSteps: Step[]) => {
  switch (type) {
    case EmploymentPageType.Borrower:
      if (isRecentEmployment(employmentDuration)) {
        return [...currentSteps, EmploymentSteps.BorrowerPreviousEmployment, EmploymentSteps.BorrowerPreviousIncome];
      }
      break;
    case EmploymentPageType.Coborrower:
      if (isRecentEmployment(employmentDuration)) {
        return [
          ...currentSteps,
          EmploymentSteps.CoborrowerPreviousEmployment,
          EmploymentSteps.CoborrowerPreviousIncome,
        ];
      }
      break;
    default:
  }
};

const getBackClickAction = (
  type: EmploymentPageType,
  coborrowerExist: boolean | null,
  borrowerEmploymentDuration: EmploymentDuration | null,
  isExistPropertyOwner: boolean,
) => {
  switch (type) {
    case EmploymentPageType.Borrower:
      if (coborrowerExist) {
        return setCurrentStage(
          isExistPropertyOwner
            ? { stage: StagesType.OwnersCheck }
            : { stage: StagesType.Installation, step: InstallationSteps.CoborrowerLives },
        );
      }
      return setCurrentStage(
        isExistPropertyOwner
          ? { stage: StagesType.OwnersCheck }
          : { stage: StagesType.Installation, step: InstallationSteps.OwnershipTerm },
      );
    case EmploymentPageType.PreviousBorrower:
      return backStep();
    case EmploymentPageType.Coborrower:
      return setCurrentStage({
        stage: StagesType.Employment,
        step: isRecentEmployment(borrowerEmploymentDuration)
          ? EmploymentSteps.BorrowerPreviousIncome
          : EmploymentSteps.BorrowerIncome,
      });
    case EmploymentPageType.PreviousCoborrower:
      return backStep();
    default:
      return {
        stage: StagesType.Employment,
      };
  }
};

const cx = classNames.bind(styles);

const EmploymentInfo = ({ type }: EmploymentInfoProps) => {
  const dispatch = useDispatch();

  const coborrowerExists = useSelector(getCoborrowerExists);
  const borrowerEmploymentDuration = useSelector(getBorrowerEmploymentDuration);
  const currentSteps = useSelector((state: RootState) => state.steps.steps);
  const propertyOwner = useSelector(getPropertyOwner1);

  const {
    register,
    handleSubmit,
    setValue,
    watch,
    clearErrors,
    control,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: getInitialValuesObject(type),
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });
  const watcher = watch();

  useEffect(() => {
    register('employmentType', { required: getRequiredErrorMessage('Employment type') });
    register(
      'employmentDuration',
      getValidationEmploymentDurationConfig(watcher.employmentType?.value, 'Employment duration'),
    );
  }, [register, watcher.employmentType]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    dispatch(setApplicationData(getDataToSubmit(data, type)));
    const newSteps = getSteps(data.employmentDuration?.value as EmploymentDuration, type, currentSteps);
    if (newSteps) {
      dispatch(setSteps(newSteps));
    }
    dispatch(nextStep());
  };

  const isEmploymentTypeRequireFields = (employmentType: string) => {
    const requireFieldTypes = [EmploymentType.Employed, EmploymentType.BusinessOwner, EmploymentType.SelfEmployed];
    return requireFieldTypes.includes(employmentType as EmploymentType);
  };

  const getValidationEmploymentDurationConfig = (employmentType: string, inputLabel: string) =>
    getValidationObj(employmentType, inputLabel);

  const getValidationObj = (employmentType: string, inputLabel: string) => {
    if (isEmploymentTypeRequireFields(employmentType)) return { required: getRequiredErrorMessage(inputLabel) };
    return { required: false };
  };

  const isSubmitInactive = () => {
    if (isEmploymentTypeRequireFields(watcher.employmentType?.value)) {
      return (
        !watcher.employmentType || !watcher.jobTitle || !watcher.companyTitle || !watcher.employmentDuration?.value
      );
    }
    return !watcher.employmentType;
  };

  const handleBackClick = () => {
    dispatch(getBackClickAction(type, coborrowerExists, borrowerEmploymentDuration, !!propertyOwner));
    if (isRecentEmployment(borrowerEmploymentDuration) && type === EmploymentPageType.Coborrower) {
      dispatch(
        setSteps([
          EmploymentSteps.BorrowerEmploymentInfo,
          EmploymentSteps.BorrowerIncome,
          EmploymentSteps.BorrowerPreviousEmployment,
          EmploymentSteps.BorrowerPreviousIncome,
        ]),
      );
    }
  };

  const handleEmploymentTypeChange = (value: OptionType) => {
    clearErrors();
    setValue('employmentType', value);
  };

  const handleEmploymentDurationChange = (value: OptionType) => {
    setValue('employmentDuration', value);
    clearErrors('employmentDuration');
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.textInputLabel}>{EmploymentTypeLabel[type]}</div>
      <div className={cx(styles.dropdownContainer, styles.inputContainer)}>
        <DropDown
          control={control}
          className={styles.inputChildContainer}
          name="employmentType"
          onValuePicked={handleEmploymentTypeChange}
          selectedOption={watcher.employmentType}
          options={employmentTypes}
          placeholder="Employment type"
          isSearchable={false}
          invalid={!watcher.employmentType && errors.employmentType?.message}
        />
        {errors.employmentType?.message && <InputErrorMessage>{errors.employmentType?.message}</InputErrorMessage>}
      </div>
      <div className={styles.textInputLabel}>{JobTitleLabel[type]}</div>
      <div className={styles.inputContainer}>
        <TextInput
          {...register('jobTitle', getValidationObj(watcher.employmentType?.value, 'Job title'))}
          label="Job title"
          value={deleteSpaceInStart(watcher.jobTitle)}
          errorMessage={errors.jobTitle?.message}
          maxLength={100}
          required={false}
          onKeyDown={specialCharactersiInputProhibition}
        />
      </div>
      <div className={styles.textInputLabel}>{CompanyTitleLabel[type]}</div>
      <div className={styles.inputContainer}>
        <TextInput
          {...register('companyTitle', getValidationObj(watcher.employmentType?.value, 'Employer / company'))}
          label="Employer / company"
          value={deleteSpaceInStart(watcher.companyTitle)}
          errorMessage={errors.companyTitle?.message}
          maxLength={100}
          required={false}
          onKeyDown={specialCharactersiInputProhibition}
        />
      </div>
      <div className={styles.textInputLabel}>{EmploymentDurationLabel[type]}</div>
      <div className={cx(styles.dropdownContainer, styles.inputContainer)}>
        <DropDown
          control={control}
          className={styles.inputChildContainer}
          name="employmentDuration"
          onValuePicked={handleEmploymentDurationChange}
          selectedOption={watcher.employmentDuration}
          options={employmentDurations}
          placeholder="Length of employment"
          isSearchable={false}
          invalid={!watcher.employmentDuration && errors.employmentDuration?.message}
        />
        {errors.employmentDuration?.message && (
          <InputErrorMessage>{errors.employmentDuration?.message}</InputErrorMessage>
        )}
      </div>
      <NavigationButtonWrapper>
        <BackButton onClick={handleBackClick} />
        <NextButton type="submit" inactive={isSubmitInactive()} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default EmploymentInfo;
