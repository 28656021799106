import React from 'react';

import classNames from 'classnames/bind';
import { Controller, Control } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import TextInput, { InputType, TextInputProps } from 'common/components/TextInput/TextInput';
import styles from 'common/components/TextInput/TextInput.module.scss';
import { getRequiredErrorMessage } from 'errors/errors';

const cx = classNames.bind(styles);

interface INumberInputProps extends TextInputProps {
  control: Control<any>;
  name: string;
  label: string;
  handleBlur?: () => void;
  handleFocus?: () => void;
  handleChange?: (value: string) => void;
  required?: boolean;
  inputType?: 'defaultInput' | 'smallInput';
  allowLeadingZeros?: boolean;
}

const defaultInput = 'defaultInput';

const NumberInput = ({
  control,
  name,
  label,
  value,
  errorMessage,
  handleBlur,
  handleFocus,
  handleChange,
  disabled,
  required = true,
  className,
  inputType = defaultInput,
  minLength,
  maxLength,
  allowLeadingZeros = false,
  ...restProps
}: INumberInputProps) => (
  <Controller
    control={control}
    name={name}
    rules={{
      required: {
        value: required,
        message: getRequiredErrorMessage(label),
      },
    }}
    render={({ field: { onChange, ref } }) => (
      <TextInput
        label={label}
        errorMessage={errorMessage}
        value={value}
        inputContainerClassName={className}
        inputType={inputType}
        disabled={disabled}
        {...restProps}
      >
        <NumberFormat
          allowLeadingZeros={allowLeadingZeros}
          getInputRef={ref}
          value={value}
          disabled={disabled}
          inputMode="decimal"
          allowNegative={false}
          defaultValue={value}
          onValueChange={(e) => {
            onChange(e.value);
            handleChange?.(e.value);
          }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          minLength={minLength}
          maxLength={maxLength}
          className={cx(styles[inputType], {
            [styles[inputType !== defaultInput ? InputType.hasValueSmallInput : InputType.hasValueDefaultInput]]:
              !!value,
          })}
        />
      </TextInput>
    )}
  />
);

export default NumberInput;
