import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import CodeVerification from 'components/CodeVerification';
import { LoginLayout } from 'layouts';
import { PortalType } from 'layouts/withPortalLayout';
import {
  getApplicationId,
  getBorrowerEmail,
  getBorrowerFirstName,
  getBorrowerIsLocked,
  getBorrowerLastName,
  getBorrowerMfaIncomplete,
  getBorrowerPhone,
  getIsLoading,
} from 'selectors';
import { useAppDispatch } from 'store';
import {
  requestPhoneVerificationForBorrower,
  requestPhoneVerificationForContractor,
  sendLockedNotifications,
  verifyPhoneForBorrower,
  verifyPhoneForContractor,
} from 'thunks';
import { Routes } from 'types';

import { ILoginProps, Resume } from './Login';
import styles from './Login.module.scss';

export const PhoneAuthentication = ({ type }: ILoginProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { state } = useLocation<Resume>();
  const loading = useSelector(getIsLoading);
  const [isCodeValid, setIsCodeValid] = useState<boolean | null>(null);
  const isMfaIncomplete = useSelector(getBorrowerMfaIncomplete);
  const applicationId = useSelector(getApplicationId);
  const borrowerIsLocked = useSelector(getBorrowerIsLocked);
  const borrowerEmail = useSelector(getBorrowerEmail);
  const borrowerFirstName = useSelector(getBorrowerFirstName);
  const borrowerLastName = useSelector(getBorrowerLastName);
  const borrowerPhone = useSelector(getBorrowerPhone);

  const { control } = useForm();

  useEffect(() => {
    async function requestPhoneCode() {
      if (type === PortalType.Contractor) {
        dispatch(requestPhoneVerificationForContractor({}));
      } else if (type === PortalType.Borrower && isMfaIncomplete) {
        dispatch(requestPhoneVerificationForBorrower({}));
      }
    }
    requestPhoneCode();
  }, [isMfaIncomplete]);

  const handleResendCode = async () => {
    if (type === PortalType.Contractor) {
      return dispatch(requestPhoneVerificationForContractor({}));
    }
    if (type === PortalType.Borrower) {
      return dispatch(requestPhoneVerificationForBorrower({}));
    }
  };

  const handleCodeEntered = async (value: string) => {
    if (type === PortalType.Contractor) {
      const result = await dispatch(
        verifyPhoneForContractor({
          code: value,
        }),
      ).unwrap();
      setIsCodeValid(!!result);
    } else if (type === PortalType.Borrower) {
      const result = await dispatch(
        verifyPhoneForBorrower({
          code: value,
        }),
      ).unwrap();
      setIsCodeValid(!!result);
    }
  };

  useEffect(() => {
    if (isCodeValid && !loading) {
      let route: string | Routes = Routes.BorrowerNoActiveApplication;

      if (type === PortalType.Contractor) {
        route = Routes.ContractorDashboard;
      } else if (type === PortalType.Borrower) {
        if (state?.applicationId) {
          route = `${Routes.Apply}?applicationId=${state?.applicationId}`;
        } else if (state?.verificationDocumentsNeededLink) {
          route = Routes.BorrowerDocuments;
        } else if (applicationId) {
          route = Routes.BorrowerDashboard;
        } else if (borrowerIsLocked) {
          dispatch(
            sendLockedNotifications({
              firstName: borrowerFirstName,
              lastName: borrowerLastName,
              email: borrowerEmail,
              phone: borrowerPhone,
            }),
          );
          route = Routes.BorrowerContactUs;
        }
      }
      history.replace(route);
    }
  }, [isCodeValid, loading]);

  return (
    <LoginLayout title="Secure phone authentication">
      <p className={styles.codeVerificationLabel}>Please enter the code sent to your phone</p>
      <CodeVerification
        control={control}
        loading={loading}
        onChange={handleCodeEntered}
        isCodeValid={!!isCodeValid}
        resendCode={handleResendCode}
      />
    </LoginLayout>
  );
};
