import React, { useEffect } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { Column, useAsyncDebounce, usePagination, useSortBy, useTable } from 'react-table';

import { ReactComponent as ArrowDown } from 'assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/ArrowUp.svg';
import Pagination from 'common/components/TablePagination';
import { setTableOptions, TableType } from 'handlers/table';
import {
  getFilterByProjectDetails,
  getFilterByStatusApplication,
  getFilterByVoi,
  getIsApplicationFetch,
  getPageIndex,
  getSearchValue,
  getSortDirection,
  getSortField,
} from 'selectors';
import { Routes } from 'types';

import styles from './Table.module.scss';
import TableNoResult from './TableNoResult';

interface TableProps {
  columns: Array<Column<any>>;
  data: Array<any>;
  className?: string;
  fetchData: (...args: any[]) => any;
  pageCount: number;
  totalCount: number;
}

interface IRow {
  displayId: string;
}

const columnsIdsWithTooltip = ['borrower_last_name', 'installAddress', 'install_city'];

const cx = classNames.bind(styles);

export const Table = ({
  columns,
  data,
  className,
  fetchData,
  pageCount: controlledPageCount,
  totalCount,
}: TableProps) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const searchValue = useSelector(getSearchValue);
  const sortDirection = useSelector(getSortDirection) === 'desc';
  const loading = useSelector(getIsApplicationFetch);
  const sortField = useSelector(getSortField);
  const currentPageIndex = useSelector(getPageIndex);
  const activeFilters = {
    projectDetails: useSelector(getFilterByProjectDetails),
    statusApplication: useSelector(getFilterByStatusApplication),
    voi: useSelector(getFilterByVoi),
  };

  const {
    getTableProps,
    getTableBodyProps,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    gotoPage,
    nextPage,
    setSortBy,
    previousPage,
    state: { pageIndex, pageSize, sortBy },
    headers,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPageIndex, pageSize: 20, sortBy: [{ id: sortField, desc: sortDirection }] },
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
    },
    useSortBy,
    usePagination,
  );
  const onFetchDataDebounced = useAsyncDebounce(fetchData, 100);

  useEffect(() => {
    if (sortBy[0]) {
      onFetchDataDebounced({
        pageIndex,
        pageSize,
        search: searchValue,
        sortField: sortBy[0].id,
        sortDirection: sortBy[0].desc ? 'desc' : 'asc',
        filters: activeFilters,
      });
      dispatch(
        setTableOptions({
          [TableType.SortField]: sortBy[0].id,
          [TableType.SortDirection]: sortBy[0].desc ? 'desc' : 'asc',
          [TableType.PageIndex]: pageIndex,
        }),
      );
    } else {
      onFetchDataDebounced({ pageIndex, pageSize, filters: activeFilters, search: searchValue });
      dispatch(
        setTableOptions({
          [TableType.PageIndex]: pageIndex,
          [TableType.SortField]: '',
          [TableType.SortDirection]: 'desc',
        }),
      );
    }
  }, [onFetchDataDebounced, pageIndex, sortBy]);

  const onClickRow = (row: IRow) => history.push(generatePath(Routes.ContractorManageDeal, { id: row.displayId }));

  const onClickHeaderRow = () => {
    dispatch(
      setTableOptions({
        [TableType.PageIndex]: 0,
      }),
    );
  };

  useEffect(() => {
    if (currentPageIndex === 0) gotoPage(currentPageIndex);
  }, [currentPageIndex]);

  useEffect(() => {
    setSortBy([{ id: sortField, desc: sortDirection }]);
  }, [sortDirection, sortField]);

  return (
    <>
      {loading || page.length ? (
        <>
          <table {...getTableProps()} className={cx(styles.table, className)}>
            <thead>
              <tr className={styles.headerRow} onClick={onClickHeaderRow}>
                {headers.map((header) => (
                  <th
                    {...header.getHeaderProps([
                      {
                        className: cx(styles.headerCell, {
                          [styles.sortedHeaderCell]: header.isSorted,
                        }),
                      },
                      header.getSortByToggleProps({ title: undefined }),
                    ])}
                  >
                    <div className={cx(styles.headerCellWrapper, styles[(header as any).className])}>
                      {header.render('Header')}
                      {!header.disableSortBy &&
                        (header.isSortedDesc ? (
                          <ArrowDown width={16} height={16} className={styles.sortIcon} />
                        ) : (
                          <ArrowUp width={16} height={16} className={styles.sortIcon} />
                        ))}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr
                    onClick={() => onClickRow(row.original as IRow)}
                    {...row.getRowProps([
                      {
                        className: styles.bodyRow,
                      },
                    ])}
                  >
                    {row.cells.map((cell) => (
                      <td
                        {...cell.getCellProps([
                          {
                            className: cx(styles.bodyCell, styles[(cell.column as any).className]),
                          },
                        ])}
                      >
                        <div className={styles.cell}>
                          {cell.render('Cell')}
                          {(columnsIdsWithTooltip.includes(cell.column.id) ||
                            (cell.column.id === 'coborrower_last_name' &&
                              cell.row.values.coborrower_last_name !== '-')) && (
                            <span className={styles.tooltipActive}>{cell.render('Cell')}</span>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Pagination
            pages={pageOptions}
            pageIndex={pageIndex}
            disabledNextPage={!canNextPage}
            disabledPrevPage={!canPreviousPage}
            onClickNextPage={nextPage}
            onClickPrevPage={previousPage}
            onClickPage={gotoPage}
            pageSize={pageSize}
            totalCount={totalCount}
            screenType="desktop"
          />
        </>
      ) : (
        <TableNoResult />
      )}
    </>
  );
};
