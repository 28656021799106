import React, { FC } from 'react';

import classNames from 'classnames/bind';

import styles from './Link.module.scss';

const cx = classNames.bind(styles);

export interface LinkProps {
  href?: string;
  className?: string;
  target?: string;
}

const Link: FC<LinkProps> = ({ href, className, children, target, ...props }) => (
  <a className={cx(styles.link, className)} href={href} target={target} {...props}>
    {children}
  </a>
);

export default Link;
