export enum ApplicationVariables {
  PrimaryLoanPurpose = 'primary_loan_purpose',
  SecondaryLoanPurpose = 'secondary_loan_purpose',
  LoanAmount = 'loan_amount',
  InstallationStreetAddress = 'install_street_address',
  InstallationZipCode = 'install_zip_or_postal_code',
  IntermediaryInvited = 'intermediary_invited',
  InstallationState = 'install_state_or_province',
  InstallationCity = 'install_city',
  BorrowerOwnership = 'borrower_ownership',
  BorrowerContactAddressSameAsInstall = 'borrower_contact_address_same_as_install',
  BorrowerContactStreetAddress = 'borrower_contact_street_address',
  BorrowerContactZipCode = 'borrower_contact_zip_or_postal_code',
  BorrowerContactState = 'borrower_contact_state_or_province',
  BorrowerContactCity = 'borrower_contact_city',
  BorrowerEmail = 'borrower_email',
  BorrowerPhone = 'borrower_phone',
  CoborrowerContactStreetAddress = 'coborrower_contact_street_address',
  CoborrowerContactZipCode = 'coborrower_contact_zip_or_postal_code',
  CoborrowerContactState = 'coborrower_contact_state_or_province',
  CoborrowerContactCity = 'coborrower_contact_city',
  OwnershipTermLessOneYear = 'ownership_term_less_1_year',
  BorrowersEqualsOwners = 'borrowers_equals_owners',
  CoborrowerApplication = 'coborrower_application',
  BorrowerPreviousGrossIncome = 'borrower_previous_gross_annual_income',
  BorrowerPreviousCompanyName = 'borrower_previous_employment_company_name',
  BorrowerPreviousJobTitle = 'borrower_previous_job_title',
  BorrowerPreviousEmploymentType = 'borrower_previous_employment_type',
  BorrowerGrossIncome = 'borrower_gross_annual_income',
  BorrowerLengthOfEmployment = 'borrower_length_of_employment',
  BorrowerJobTitle = 'borrower_job_title',
  BorrowerEmploymentType = 'borrower_employment_type',
  BorrowerCompanyName = 'borrower_employment_company_name',
  CoborrowerPreviousGrossIncome = 'coborrower_previous_gross_annual_income',
  CoborrowerPreviousCompanyName = 'coborrower_previous_employment_company_name',
  CoborrowerPreviousJobTitle = 'coborrower_previous_job_title',
  CoborrowerPreviousEmploymentType = 'coborrower_previous_employment_type',
  CoborrowerGrossIncome = 'coborrower_gross_annual_income',
  CoborrowerLengthOfEmployment = 'coborrower_length_of_employment',
  CoborrowerJobTitle = 'coborrower_job_title',
  CoborrowerEmploymentType = 'coborrower_employment_type',
  CoborrowerCompanyName = 'coborrower_employment_company_name',
  ACHDiscount = 'ach_discount',
  LoanTermList = 'loan_term_list',
  InterestRate = 'interest_rate',
  MonthlyPayment = 'monthly_payment',
  IntermediaryId = 'intermediary_id_number',
  IntermediaryEmail = 'intermediary_email',
  IntermediaryPhone = 'intermediary_phone',
  IntermdediaryName = 'intermediary_name',
  AdditionalSalespersonExists = 'additional_salesperson_exists',
  AdditionalSalespersonName = 'additional_salesperson_name',
  AdditionalSalespersonPhone = 'additional_salesperson_phone',
  AdditionalSalespersonEmail = 'additional_salesperson_email',
  ConfirmProjectDetails = 'project_and_contract_confirmed',
  ConfirmProjectDetailsDate = 'project_and_contract_confirmed_date',
  OwnerInformationCorrect = 'owner_information_correct',
  CoborrowerAuthorization = 'coborrower_authorization',
  CoborrowerFicoScore = 'coborrower_fico_score',
  BorrowerFicoScore = 'borrower_fico_score',
  ApplicationFicoScore = 'application_fico_score',
  SoftPullResult = 'soft_pull_result',
  UploadBorrowerVoiComplete = 'upload_borrower_voi_complete',
  PropertyOwner1 = 'property_owner_1',
  PropertyOwner2 = 'property_owner_2',
  PropertyOwner3 = 'property_owner_3',
  First50Requested = 'first_50_requested',
  First50RequestedDate = 'first_50_requested_date',
  Final50Requested = 'final_50_requested',
  Final50RequestedDate = 'final_50_requested_date',
  FullAmountRequested = 'full_amount_requested',
  FullAmountRequestedDate = 'full_amount_requested_date',
  First50Authorized = 'first_50_authorized',
  First50AuthorizedDate = 'first_50_authorized_date',
  Final50Authorized = 'final_50_authorized',
  Final50AuthorizedDate = 'final_50_authorized_date',
  FullAmountAuthorized = 'full_amount_authorized',
  FullAmountAuthorizedDate = 'full_amount_authorized_date',
  AgreedToFundingTermsStatement = 'agreed_to_funding_terms_statement',
  RequestFundsAgreementDate = 'request_funds_agreement_date',
  AutomatedBorrowerIdVerificationPassed = 'automated_borrower_id_verification_passed',
  AutomatedCoborrowerIdVerificationPassed = 'automated_coborrower_id_verification_passed',
  UploadCoborrowerVoiComplete = 'upload_coborrower_voi_complete',
  ProjectCheckConfirmed = 'project_check_confirmed',
  ApplicationWithdrawn = 'application_withdrawn',
  ContractManualReview = 'contract_manual_review',
  MonthlyMortgagePayment = 'monthly_mortgage_payment',
  VoiNeeded = 'voi_needed',
  PvKilowatts = 'pv_kilowatts',
  BatterySize = 'battery_size',
  RoofingRepairsDescription = 'roofing_repairs_description',
  MakeAndModel = 'heating_and_cooling_upgrades',
  OtherEnergyEfficiencyDescription = 'other_energy_efficiency_description',
  SolarPanelsCost = 'solar_panels_cost',
  EnergyStorageCost = 'energy_storage_cost',
  RoofingRepairsCost = 'roofing_repairs_cost',
  HeatingAndCoolingCost = 'heating_and_cooling_cost',
  OtherEnergyEfficiencyCost = 'other_energy_efficiency_cost',
  OutsideAmount = 'outside_amount',
  ProjectDetailsAndSignedContractCompleteDate = 'project_details_and_signed_contract_complete_date',
  ProjectDetailsAndSignedContractComplete = 'project_details_and_signed_contract_complete',
  Total = 'total_project_cost',
  TotalMinusOutsideAmount = 'total_project_cost_minus_outside_amount',
  RequestFundsAgreement = 'request_funds_agreement',
  InstallerRequestFundsAgreementDate = 'installer_request_funds_agreement_date',
  IntermediaryPrepaidEligible = 'intermediary_prepaid_eligible',
  UploadBorrowerVoiCompleteDate = 'upload_borrower_voi_complete_date',
  UploadBorrowerIdComplete = 'upload_borrower_id_complete',
  UploadBorrowerIdCompleteDate = 'upload_borrower_id_complete_date',
  UploadAdditionalDocumentsComplete = 'upload_additional_documents_complete',
  UploadAdditionalDocumentsCompleteDate = 'upload_additional_documents_complete_date',
  UploadCoborrowerIdComplete = 'upload_coborrower_id_complete',
  UploadCoborrowerIdCompleteDate = 'upload_coborrower_id_complete_date',
  UploadCoborrowerVoiCompleteDate = 'upload_coborrower_voi_complete_date',
  IntermediaryConfirmed = 'intermediary_confirmed',
  ContractManualReviewConfirmed = 'contract_manual_review_confirmed',
  BorrowerCviScore = 'borrower_cvi_score',
  BorrowerRiskIndicatorLn = 'borrower_risk_indicator_ln',
  CoborrowerCviScore = 'coborrower_cvi_score',
  CoborrowerRiskIndicatorLn = 'coborrower_risk_indicator_ln',
  InterestRate21YearFixed = 'interest_rate_21_year_fixed',
  InterestRate20YearFixed = 'interest_rate_20_year_fixed',
  InterestRate15YearFixed = 'interest_rate_15_year_fixed',
  InterestRate12YearFixed = 'interest_rate_12_year_fixed',
  InterestRate10YearFixed = 'interest_rate_10_year_fixed',
  InterestRate7YearFixed = 'interest_rate_7_year_fixed',
  InterestRate5YearFixed = 'interest_rate_5_year_fixed',
  MonthlyPayment21YearFixed = 'monthly_payment_21_year_fixed',
  MonthlyPayment20YearFixed = 'monthly_payment_20_year_fixed',
  MonthlyPayment15YearFixed = 'monthly_payment_15_year_fixed',
  MonthlyPayment12YearFixed = 'monthly_payment_12_year_fixed',
  MonthlyPayment10YearFixed = 'monthly_payment_10_year_fixed',
  MonthlyPayment7YearFixed = 'monthly_payment_7_year_fixed',
  MonthlyPayment5YearFixed = 'monthly_payment_5_year_fixed',
  MonthsIn21Years = 'months_in_21_years',
  MonthsIn20Years = 'months_in_20_years',
  MonthsIn15Years = 'months_in_15_years',
  MonthsIn12Years = 'months_in_12_years',
  MonthsIn10Years = 'months_in_10_years',
  MonthsIn7Years = 'months_in_7_years',
  MonthsIn5Years = 'months_in_5_years',
  DisplayOffer21YearFixed = 'display_offer_21_year_fixed',
  DisplayOffer20YearFixed = 'display_offer_20_year_fixed',
  DisplayOffer15YearFixed = 'display_offer_15_year_fixed',
  DisplayOffer12YearFixed = 'display_offer_12_year_fixed',
  DisplayOffer10YearFixed = 'display_offer_10_year_fixed',
  DisplayOffer7YearFixed = 'display_offer_7_year_fixed',
  DisplayOffer5YearFixed = 'display_offer_5_year_fixed',
  PotentialOffersDisplayed = 'potential_offers_displayed',
  UploadVerificationOwnershipComplete = 'upload_verification_of_ownership_complete',
  UploadVerificationOwnershipCompleteDate = 'upload_verification_of_ownership_complete_date',
  RequestLoanDocuments = 'request_loan_documents',
  AgreedRunHardPull = 'agreed_to_run_hard_pull',
  AgreedToAccountDisclosures = 'agreed_to_account_disclosures',
  HardPullResult = 'hard_pull_result',
  JoinSunComplete = 'join_sun_complete',
  BankName = 'bank_name',
  RoutingNumber = 'borrower_routing_number',
  AccountNumber = 'borrower_account_number',
  AccountType = 'account_type',
  JoinSunCompleteDate = 'join_sun_complete_date',
  ApplicationDti = 'application_dti',
  ApplicationMonthlyDebtPayments = 'application_monthly_debt_payments',
  ApplicationMonthlyIncomeAmount = 'application_monthly_income_amount',
  BorrowerUnsecuredDebtPercentage = 'borrower_unsecured_debt_percentage',
  CoborrowerUnsecuredDebtPercentage = 'coborrower_unsecured_debt_percentage',
  AdjustedApplicationMonthlyDebtPayments = 'adjusted_application_monthly_debt_payments',
  AdjustedApplicationMonthlyIncomeAmount = 'adjusted_application_monthly_income_amount',
  CheckRateAndPaymentsAgreement = 'check_rate_and_payments_agreement',
  BorrowerManualSSN = 'borrower_manual_ssn',
  BorrowerSigningDocumentsComplete = 'borrower_signing_documents_complete',
  BorrowerSigningDocumentsCompleteDate = 'borrower_signing_documents_complete_date',
  CoborrowerSigningDocumentsComplete = 'coborrower_signing_documents_complete',
  ProjectCheckConfirmedDate = 'project_check_confirmed_date',
  PvCostPerWatt = 'pv_cost_per_watt',
  RoofingPercentage = 'roofing_percentage',
  AccountCreationComplete = 'account_creation_complete',
  AccountCreationCompleteDate = 'account_creation_complete_date',
  EzsolarLoanFinal50Disbursed = 'ezsolarloan_final_50_disbursed',
  EzsolarLoanFullAmountDisbursed = 'ezsolarloan_full_amount_disbursed',
  ApplicationCreateDate = 'application_create_date',
  ApplicationDisplayId = 'application_display_id',
  ParcelNumber = 'parcel_number',
  AbbreviatedLegalDescription = 'abbreviated_legal_description',
  PropertyCounty = 'property_county',
  FipsCode = 'fips_code',
  UnqualifiedIncome = 'unqualified_income',
  ApplicationMortgageAlert = 'application_mortgage_alert',
  UnsecuredDebtPercentage = 'unsecured_debt_percentage',
  UCCFilingCost = 'ucc_filing_cost',
  TransferToServicingSystemComplete = 'transfer_to_servicing_system_complete',
  PropertyType = 'property_type',
  LandUseDescription = 'land_use_description',
  MonthlyDebtPaymentsAdjustment1 = 'monthly_debt_payments_adjustment_1',
  MonthlyDebtPaymentsAdjustment2 = 'monthly_debt_payments_adjustment_2',
  MonthlyDebtPaymentsAdjustment3 = 'monthly_debt_payments_adjustment_3',
  MonthlyDebtPaymentsAdjustment4 = 'monthly_debt_payments_adjustment_4',
  MonthlyDebtPaymentsAdjustment5 = 'monthly_debt_payments_adjustment_5',
  AvailableRevolving = 'available_revolving',
  AddedCoborrowerThroughDashboard = 'added_coborrower_through_dashboard',
  BorrowerSoftPullPdfReport = 'borrower_credit_report',
  CoborrowerSoftPullPdfReport = 'coborrower_credit_report',
  InstitutionName = 'institution_name',
  AuthorizedSignerAccount = 'authorized_signer_on_account',
  ReasonCode1BorrowerDescription = 'reason_code_1_description',
  ReasonCode2BorrowerDescription = 'reason_code_2_description',
  ReasonCode3BorrowerDescription = 'reason_code_3_description',
  ReasonCode4BorrowerDescription = 'reason_code_4_description',
  ReasonCode1CoborrowerDescription = 'reason_code_1_coborrower_description',
  ReasonCode2CoborrowerDescription = 'reason_code_2_coborrower_description',
  ReasonCode3CoborrowerDescription = 'reason_code_3_coborrower_description',
  ReasonCode4CoborrowerDescription = 'reason_code_4_coborrower_description',
  InstantDeclineReason1 = 'instant_decline_reason_1',
  InstantDeclineReason2 = 'instant_decline_reason_2',
  InstantDeclineReason3 = 'instant_decline_reason_3',
  InstantDeclineReason4 = 'instant_decline_reason_4',
  InstantDeclineReason5 = 'instant_decline_reason_5',
  InstantDeclineReason6 = 'instant_decline_reason_6',
  InstantDeclineReason7 = 'instant_decline_reason_7',
  InstantDeclineReason8 = 'instant_decline_reason_8',
  ManualReviewReason1 = 'manual_review_reason_1',
  ManualReviewReason2 = 'manual_review_reason_2',
  ManualReviewReason3 = 'manual_review_reason_3',
  ManualReviewReason4 = 'manual_review_reason_4',
  ManualReviewReason5 = 'manual_review_reason_5',
  ManualReviewReason6 = 'manual_review_reason_6',
  ManualReviewReason7 = 'manual_review_reason_7',
  ManualReviewReason8 = 'manual_review_reason_8',
  ManualReviewReason9 = 'manual_review_reason_9',
  ManualReviewReason10 = 'manual_review_reason_10',
  ManualReviewReason11 = 'manual_review_reason_11',
  ManualReviewReason12 = 'manual_review_reason_12',
  ManualReviewReason13 = 'manual_review_reason_13',
  PropertyTypeCheckRequired = 'property_type_check_required',
  BorrowerPreviousLengthOfEmployment = 'borrower_previous_length_of_employment',
  CoborrowerPreviousLengthOfEmployment = 'coborrower_previous_length_of_employment',
  HardCreditPullOverride = 'hard_credit_pull_override',
  BorrowerRiskIndicatorCodesDescription = 'borrower_risk_indicator_codes_description',
  CoborrowerRiskIndicatorCodesDescription = 'coborrower_risk_indicator_codes_description',
  TotalUnsecuredDebt = 'total_unsecured_debt',
  Borrower4LastDigitsSSN = 'borrower_4_last_digits_of_ssn',
  Coborrower4LastDigitsSSN = 'coborrower_4_last_digits_of_ssn',
  DischargedBankruptcyAlert = 'discharged_bankruptcy_alert',
  DischargedBankruptcyAlertCoborrower = 'discharged_bankruptcy_alert_coborrower',
}

export enum OwnershipStatus {
  OwnOutright = 'Own outright',
  OwnWithMortgage = 'Own with mortgage',
  ReverseMortage = 'Reverse mortgage',
  NotAnOwner = 'Not an owner',
}

export enum EmploymentType {
  Employed = 'Employed',
  Homemaker = 'Homemaker',
  Retired = 'Retired',
  SelfEmployed = 'Self Employed',
  BusinessOwner = 'Business Owner',
  Student = 'Student',
  Unemployed = 'Unemployed',
}
