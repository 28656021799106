import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';

interface SendPhoneCodeProps {
  phone?: string;
}

export const createRequestPhoneVerificationForBorrowerThunk = (accountApi: AccountApi) =>
  createAsyncThunk('account/borrower/sendPhoneCode', async ({ phone }: SendPhoneCodeProps, { rejectWithValue }) => {
    try {
      return await accountApi.sendPhoneCode(phone);
    } catch (e) {
      rejectWithValue(null);
    }
  });

export const createRequestPhoneVerificationForContractorThunk = (accountApi: AccountApi) =>
  createAsyncThunk('account/intermediary/sendPhoneCode', async ({ phone }: SendPhoneCodeProps, { rejectWithValue }) => {
    try {
      return await accountApi.sendPhoneCodeForContractor(phone);
    } catch (e) {
      rejectWithValue(null);
    }
  });
