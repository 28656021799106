import { createAsyncThunk } from '@reduxjs/toolkit';

import BorrowerApi from 'api/BorrowerApi';
import { setCustomerData } from 'handlers/customer';
import { UserInformationKeys } from 'handlers/customer/types';
import { LoadingType, setLoadingState } from 'handlers/loading';

export const createGetBorrowerThunk = (borrowerApi: BorrowerApi) =>
  createAsyncThunk('borrowers/get', async (_, { dispatch }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: true }));
      const borrower = await borrowerApi.getBorrower();
      dispatch(
        setCustomerData({
          customerId: borrower.id,
          isLocked: borrower.locked,
          [UserInformationKeys.Email]: borrower.variables[UserInformationKeys.Email],
          [UserInformationKeys.PhoneNumber]: borrower.variables[UserInformationKeys.PhoneNumber],
          [UserInformationKeys.FirstName]: borrower.variables[UserInformationKeys.FirstName],
          [UserInformationKeys.LastName]: borrower.variables[UserInformationKeys.LastName],
          [UserInformationKeys.DateOfBirth]: borrower.variables[UserInformationKeys.DateOfBirth],
          ...borrower.variables,
        }),
      );
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: false }));
    }
  });
