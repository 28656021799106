import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { ApplicationVariables } from 'handlers/application/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { SalespersonForm } from 'pages/NotificationsSalesperson';
import {
  getAdditionalSalespersonEmail,
  getAdditionalSalespersonName,
  getAdditionalSalespersonPhone,
  getApplicationId,
  getIsLoading,
} from 'selectors';
import { updateApplication } from 'thunks';
import { Routes, VariableValue } from 'types';
import { deleteDoubleSpace } from 'utils/formats';
import { notify } from 'utils/notificationHelper';
import { isExistErrors } from 'utils/validationHelper';

const SUCCESS_UPDATE = 'Salesperson info has been successfully updated';

const UpdateSalesperson = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const phone = useSelector(getAdditionalSalespersonPhone);
  const name = useSelector(getAdditionalSalespersonName);
  const email = useSelector(getAdditionalSalespersonEmail);

  const methods = useForm({
    defaultValues: {
      phone,
      name,
      email,
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const watcher = methods.watch();

  const applicationId = useSelector(getApplicationId);
  const loading = useSelector(getIsLoading);

  const isInactive = () =>
    !watcher.name ||
    !watcher.email ||
    !watcher.phone ||
    (methods.formState.isSubmitted && isExistErrors(methods.formState.errors));

  const isDisabled = () => watcher.name === name && watcher.phone === phone && watcher.email === email;

  const onSubmit = async () => {
    const applicationVariables: Record<string, VariableValue> = {
      [ApplicationVariables.AdditionalSalespersonExists]: true,
      [ApplicationVariables.AdditionalSalespersonPhone]: watcher.phone,
      [ApplicationVariables.AdditionalSalespersonName]: deleteDoubleSpace(watcher.name),
      [ApplicationVariables.AdditionalSalespersonEmail]: watcher.email,
    };
    await dispatch(
      updateApplication({
        applicationId,
        applicationVariables,
      }),
    );
    notify({ notification: SUCCESS_UPDATE });
    history.push(Routes.BorrowerDashboard);
  };

  const handleClickBack = () => {
    history.push(Routes.BorrowerDetails);
  };

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={methods.handleSubmit(onSubmit)}>
        <SalespersonForm errorBackgroundType="portalErrorBackground" />
        <NavigationButtonWrapper>
          <BackButton className={commonStyles.portalBackButton} onClick={handleClickBack} />
          <NextButton title="Submit" inactive={isInactive()} disabled={isDisabled()} loading={loading} type="submit" />
        </NavigationButtonWrapper>
      </FormContainer>
    </FormProvider>
  );
};

export default withPortalLayout(UpdateSalesperson);
