import { createAsyncThunk } from '@reduxjs/toolkit';

import DataTreeApi from 'api/DataTreeApi';
import { LoadingType, setLoadingState } from 'handlers/loading';

export const createDownloadDataTreeThunk = (dataTreeApi: DataTreeApi) =>
  createAsyncThunk('data-tree/download-document', async (link: string, { dispatch }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
      return await dataTreeApi.downloadDocument(link);
    } catch (error) {
      return null;
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
    }
  });
