import React, { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { useFormContext } from 'react-hook-form';

import { ReactComponent as ClosedEyeIcon } from 'assets/icons/ClosedEye.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/Eye.svg';
import TextInput from 'common/components/TextInput';
import { getRequiredErrorMessage } from 'errors/errors';

import styles from './Login.module.scss';

const cx = classNames.bind(styles);

export const ConfirmPasswordForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [passwordRules, setPasswordRules] = useState({
    atLeast8Characters: false,
    atLeast1Number: false,
    atLeast1Symbol: false,
    atLeast1Capital: false,
    atLeast1Lowercase: false,
  });

  const passwordPattern = new RegExp('^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  const hasNumberPattern = new RegExp('(?=.*[0-9])');
  const hasSymbolPattern = new RegExp('(?=.*[!@#$%^&*])');
  const hasCharactersPattern = new RegExp('(?=.{8,})');
  const hasCapitalLetterPattern = new RegExp('[A-Z]');
  const hasLowercaseLetterPattern = new RegExp('[a-z]');

  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();

  const watcher = watch();

  useEffect(() => {
    setPasswordRules({
      atLeast1Number: hasNumberPattern.test(watcher.password),
      atLeast1Symbol: hasSymbolPattern.test(watcher.password),
      atLeast8Characters: hasCharactersPattern.test(watcher.password),
      atLeast1Capital: hasCapitalLetterPattern.test(watcher.password),
      atLeast1Lowercase: hasLowercaseLetterPattern.test(watcher.password),
    });
  }, [watcher.password]);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const renderAdornmentForPassword = () =>
    showPassword ? (
      <EyeIcon onClick={togglePasswordVisibility} />
    ) : (
      <ClosedEyeIcon onClick={togglePasswordVisibility} />
    );
  const renderAdornmentForConfirmationPassword = () =>
    showConfirmPassword ? (
      <EyeIcon onClick={toggleConfirmPasswordVisibility} />
    ) : (
      <ClosedEyeIcon onClick={toggleConfirmPasswordVisibility} />
    );

  return (
    <>
      <div className={styles.inputContainer}>
        <TextInput
          {...register('password', { required: getRequiredErrorMessage('Password'), pattern: passwordPattern })}
          type={showPassword ? 'text' : 'password'}
          label="Password"
          errorMessage={errors.password?.message}
          value={watcher.password}
          autoComplete="off"
          endAdornment={renderAdornmentForPassword}
        />
      </div>
      <div className={styles.inputContainer}>
        <TextInput
          {...register('confirmPassword', {
            required: getRequiredErrorMessage('Password'),
            validate: (v) => (v === watcher.password ? true : 'Passwords need to match.'),
          })}
          type={showConfirmPassword ? 'text' : 'password'}
          label="Confirm password"
          errorMessage={errors.confirmPassword?.message}
          value={watcher.confirmPassword}
          autoComplete="off"
          endAdornment={renderAdornmentForConfirmationPassword}
          referenceInfo={
            <ul className={styles.passwordRuleList}>
              <li
                className={cx(styles.passwordRuleItem, {
                  [styles.validRuleItem]: passwordRules.atLeast8Characters,
                })}
              >
                At least 8 characters
              </li>
              <li
                className={cx(styles.passwordRuleItem, {
                  [styles.validRuleItem]: passwordRules.atLeast1Number,
                })}
              >
                At least 1 number
              </li>
              <li
                className={cx(styles.passwordRuleItem, {
                  [styles.validRuleItem]: passwordRules.atLeast1Symbol,
                })}
              >
                At least 1 symbol
              </li>
              <li
                className={cx(styles.passwordRuleItem, {
                  [styles.validRuleItem]: passwordRules.atLeast1Capital,
                })}
              >
                At least 1 capital letter
              </li>
              <li
                className={cx(styles.passwordRuleItem, {
                  [styles.validRuleItem]: passwordRules.atLeast1Lowercase,
                })}
              >
                At least 1 lowercase letter
              </li>
            </ul>
          }
        />
      </div>
    </>
  );
};
