import React from 'react';

import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import RadioButton from 'common/components/RadioButton/RadioButton';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { ApplicationVariables } from 'handlers/application/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import PageLayout from 'layouts';
import styles from 'pages/pages.module.scss';
import {
  getAdditionalSalespersonEmail,
  getAdditionalSalespersonExists,
  getAdditionalSalespersonName,
  getAdditionalSalespersonPhone,
  getApplicationId,
  getBorrowerEmail,
  getBorrowerFicoScore,
  getCoborrowerExists,
  getCoborrowerFicoScore,
  getIntermediaryName,
  getIntermediaryPrimaryContactFirstName,
  getIntermediaryPrimaryContactLastName,
  getIsLoading,
  getLoanAmount,
  getPotentialOffersDisplayed,
  getSoftPullResult,
  getUploadBorrowerVoiComplete,
  getUploadCoborrowerVoiComplete,
} from 'selectors';
import { useAppDispatch } from 'store';
import { getBorrowerAccount, runApplicationDecision, runStrategy, updateApplication } from 'thunks';
import { Routes, StrategyName, VariableValue } from 'types';
import { decodeRiskIndicators } from 'utils/decodeRiskIndicators';
import { deleteDoubleSpace } from 'utils/formats';
import { isExistErrors } from 'utils/validationHelper';

import { SalespersonForm } from './SalespersonForm';

const NotificationsSalesperson = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const methods = useForm({
    defaultValues: {
      salespersonExist: useSelector(getAdditionalSalespersonExists),
      phone: useSelector(getAdditionalSalespersonPhone),
      name: useSelector(getAdditionalSalespersonName),
      email: useSelector(getAdditionalSalespersonEmail),
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const watcher = methods.watch();

  const intermediaryName = useSelector(getIntermediaryName);
  const email = useSelector(getBorrowerEmail);
  const primaryContactFirstName = useSelector(getIntermediaryPrimaryContactFirstName);
  const primaryContactLastName = useSelector(getIntermediaryPrimaryContactLastName);
  const applicationId = useSelector(getApplicationId);
  const loading = useSelector(getIsLoading);
  const borrowerFicoScore = useSelector(getBorrowerFicoScore);
  const coborrowerFicoScore = useSelector(getCoborrowerFicoScore);
  const softPullResult = useSelector(getSoftPullResult);
  const uploadBorrowerVoiComplete = useSelector(getUploadBorrowerVoiComplete);
  const isCoborrowerExist = useSelector(getCoborrowerExists);
  const uploadCoborrowerVoiComplete = useSelector(getUploadCoborrowerVoiComplete);
  const loanAmount = useSelector(getLoanAmount);
  const potentialOffersDisplayed = useSelector(getPotentialOffersDisplayed);

  const isSubmitInactive = () => {
    let isValid = !!(watcher.name && watcher.email && watcher.phone);

    if (watcher.salespersonExist === false) isValid = !watcher.salespersonExist;
    return isValid || (methods.formState.isSubmitted && isExistErrors(methods.formState.errors));
  };

  const handleHaveAdditionalSalesperson = (e: React.ChangeEvent<HTMLInputElement>) => {
    methods.setValue('salespersonExist', e.target.value === 'true');
  };

  const onSubmit = async () => {
    const applicationVariables: Record<string, VariableValue> = {
      [ApplicationVariables.AdditionalSalespersonExists]: watcher.salespersonExist as boolean,
    };
    if (watcher.salespersonExist) {
      Object.assign(applicationVariables, {
        [ApplicationVariables.AdditionalSalespersonPhone]: watcher.phone,
        [ApplicationVariables.AdditionalSalespersonName]: deleteDoubleSpace(watcher.name),
        [ApplicationVariables.AdditionalSalespersonEmail]: watcher.email,
      });
    }
    await dispatch(
      updateApplication({
        applicationId,
        applicationVariables,
        skipAuth: true,
      }),
    );

    const voiNeededResult = await dispatch(
      runStrategy({
        strategyName: StrategyName.VOINeeded,
        applicationId,
        strategyInputVariables: {
          [ApplicationVariables.BorrowerFicoScore]: borrowerFicoScore,
          [ApplicationVariables.CoborrowerFicoScore]: coborrowerFicoScore,
          [ApplicationVariables.SoftPullResult]: softPullResult,
          [ApplicationVariables.UploadBorrowerVoiComplete]: uploadBorrowerVoiComplete,
          [ApplicationVariables.UploadCoborrowerVoiComplete]: uploadCoborrowerVoiComplete,
          [ApplicationVariables.CoborrowerApplication]: isCoborrowerExist,
          [ApplicationVariables.LoanAmount]: loanAmount,
          [ApplicationVariables.PotentialOffersDisplayed]: potentialOffersDisplayed,
        },
        skipDecline: true,
      }),
    ).unwrap();

    const borrowerIdVerificationResult = await dispatch(
      runApplicationDecision({
        applicationId,
        strategyName: StrategyName.BorrowerIdVerification,
        skipDecline: true,
      }),
    ).unwrap();

    let coborrowerIdVerificationResult;

    if (isCoborrowerExist) {
      coborrowerIdVerificationResult = await dispatch(
        runApplicationDecision({
          strategyName: StrategyName.CoborrowerIdVerification,
          applicationId,
          skipDecline: true,
        }),
      ).unwrap();
    }

    await dispatch(
      updateApplication({
        applicationId,
        skipAuth: true,
        applicationVariables: {
          [ApplicationVariables.BorrowerRiskIndicatorCodesDescription]: decodeRiskIndicators(
            borrowerIdVerificationResult.outputVariables.borrower_risk_indicator_ln,
          ),
          [ApplicationVariables.CoborrowerRiskIndicatorCodesDescription]: coborrowerIdVerificationResult
            ? decodeRiskIndicators(coborrowerIdVerificationResult.outputVariables.coborrower_risk_indicator_ln)
            : '',
          [ApplicationVariables.VoiNeeded]: !voiNeededResult.declined,
          [ApplicationVariables.AutomatedBorrowerIdVerificationPassed]: borrowerIdVerificationResult.passed,
          [ApplicationVariables.BorrowerCviScore]:
            borrowerIdVerificationResult.outputVariables[ApplicationVariables.BorrowerCviScore],
          [ApplicationVariables.BorrowerRiskIndicatorLn]:
            borrowerIdVerificationResult.outputVariables[ApplicationVariables.BorrowerRiskIndicatorLn],
          ...(coborrowerIdVerificationResult && {
            [ApplicationVariables.AutomatedCoborrowerIdVerificationPassed]: coborrowerIdVerificationResult.passed,
            [ApplicationVariables.CoborrowerCviScore]:
              coborrowerIdVerificationResult.outputVariables[ApplicationVariables.CoborrowerCviScore],
            [ApplicationVariables.CoborrowerRiskIndicatorLn]:
              coborrowerIdVerificationResult.outputVariables[ApplicationVariables.CoborrowerRiskIndicatorLn],
          }),
        },
      }),
    );
    const account = await dispatch(
      getBorrowerAccount({
        email,
      }),
    ).unwrap();

    const authToken = localStorage.getItem('borrowerToken');

    if (account) {
      if (authToken) {
        const parsedJwt = JSON.parse(atob(authToken.split('.')[1]));
        if (account.borrowerId === parsedJwt.borrowerId) {
          dispatch(setCurrentStage({ stage: StagesType.UploadDocuments }));
          return;
        }
      } else {
        history.push(Routes.BorrowerLogin);
      }
    }

    dispatch(setCurrentStage({ stage: StagesType.CreateAccount }));
  };

  const handleBackClick = () => dispatch(setCurrentStage({ stage: StagesType.SCICode }));

  return (
    <PageLayout title="Notifications to salesperson">
      <FormProvider {...methods}>
        <FormContainer onSubmit={methods.handleSubmit(onSubmit)}>
          <div className={styles.textInputLabel}>
            For {intermediaryName}, the primary contact is listed as {primaryContactFirstName} {primaryContactLastName}.
            Would you like to add an additional salesperson or representative to keep up to date on your application’s
            progress?
          </div>
          <RadioButton
            containerClassName={styles.radioButtonWithMargin}
            label="Add an additional salesperson or representative to keep up to date on my application progress"
            onChange={handleHaveAdditionalSalesperson}
            id="salespersonExist"
            value="true"
            checked={!!watcher.salespersonExist}
          />
          {watcher.salespersonExist && <SalespersonForm />}
          <RadioButton
            label="No, I do not have another person to add"
            onChange={handleHaveAdditionalSalesperson}
            checked={watcher.salespersonExist === false}
            id="salespersonNotExist"
            value="false"
          />
          <NavigationButtonWrapper>
            <BackButton onClick={handleBackClick} />
            <NextButton type="submit" disabled={!isSubmitInactive()} loading={loading} />
          </NavigationButtonWrapper>
        </FormContainer>
      </FormProvider>
    </PageLayout>
  );
};

export default NotificationsSalesperson;
