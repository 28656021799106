import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import PriceSlider from 'components/PriceSlider';
import { backStep } from 'handlers/actions';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { nextStep } from 'handlers/steps';
import { getLoanAmount } from 'selectors';
import { notify } from 'utils/notificationHelper';

const LOAN_AMOUNT_MIN_VALUE = 5000;
const LOAN_AMOUNT_MAX_VALUE = 80000;
const LESS_THAN_NOTIFICATION = "We're sorry, the minimum loan request for ezSolarLoan is $5,000.";
const OVER_THAN_NOTIFICATION = "We're sorry, the maximum loan request for ezSolarLoan is $80,000.";

const LoanAmountPage = () => {
  const dispatch = useDispatch();
  const sliderValue = useSelector(getLoanAmount);
  const [value, setValue] = useState<number | number[]>(40000 || sliderValue);

  const isValid = () => value <= LOAN_AMOUNT_MAX_VALUE && value >= LOAN_AMOUNT_MIN_VALUE;

  const handleBackClick = () => {
    dispatch(backStep());
  };

  const handleChange = (newValue: number | number[]) => setValue(newValue);

  const handleBlur = () => {
    if (value < LOAN_AMOUNT_MIN_VALUE) notify({ notification: LESS_THAN_NOTIFICATION });
    if (value > LOAN_AMOUNT_MAX_VALUE) notify({ notification: OVER_THAN_NOTIFICATION });
  };

  const handleSubmit = () => {
    dispatch(
      setApplicationData({
        [ApplicationVariables.LoanAmount]: value as number,
      }),
    );
    dispatch(nextStep());
  };

  return (
    <FormContainer title="What loan amount are you requesting?">
      <PriceSlider value={value} onChange={handleChange} onBlur={handleBlur} />
      <NavigationButtonWrapper>
        <BackButton onClick={handleBackClick} />
        <NextButton onClick={handleSubmit} disabled={!isValid()} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default LoanAmountPage;
