import React, { FC } from 'react';

import classNames from 'classnames/bind';

import styles from './NavigationButtonWrapper.module.scss';

export enum AlignmentType {
  Center = 'Center',
  Right = 'Rigth',
  Left = 'Left',
}

interface INavigationButtonWrapperProps extends React.ButtonHTMLAttributes<HTMLDivElement> {
  alignment?: AlignmentType;
}

const cx = classNames.bind(styles);

const NavigationButtonWrapper: FC<INavigationButtonWrapperProps> = ({ alignment, children }) => (
  <div
    className={cx({
      [styles.center]: alignment === AlignmentType.Center,
      [styles.right]: alignment === AlignmentType.Right,
      [styles.left]: alignment === AlignmentType.Left,
      [styles.default]: !alignment,
    })}
  >
    {children}
  </div>
);
export default NavigationButtonWrapper;
