import React from 'react';

import classnames from 'classnames/bind';

import LogoIcon from 'components/Logo';
import { AlignmentType } from 'components/Logo/LogoIcon';

import styles from './Header.module.scss';

interface HeaderProps {
  external?: boolean;
  portal?: boolean;
}

const cx = classnames.bind(styles);

const Header = ({ external, portal }: HeaderProps) => (
  <div
    className={cx(styles.header, {
      [styles.externalHeader]: external,
      [styles.portalHeader]: portal,
    })}
  >
    <LogoIcon alignment={external ? AlignmentType.Center : AlignmentType.Left} />
  </div>
);

export default Header;
