import React, { useEffect, useState } from 'react';

import classnames from 'classnames/bind';

import PageLoader from 'components/PageLoader';
import { useAppDispatch } from 'store';
import { downloadDocument } from 'thunks';
import { convertMonetary } from 'utils/formats';
import { notify } from 'utils/notificationHelper';

import styles from './ViewContract.module.scss';

const cx = classnames.bind(styles);

interface IViewContractProps {
  loanAmount: string;
  totalProjectCost: number | null;
  amountPaidOutside: number | null;
  className?: string;
  viewTitle: string;
  applicationId: string;
}
interface IFile {
  data: Blob;
  extension: string;
}

const ViewContract = ({
  loanAmount,
  totalProjectCost,
  amountPaidOutside,
  className,
  viewTitle,
  applicationId,
}: IViewContractProps) => {
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [file, setFile] = useState<IFile | null | undefined>(null);
  const downloadSignedContract = async () => {
    setFile(await dispatch(downloadDocument({ applicationId, documentName: 'Signed Contract' })).unwrap());
  };
  const isPdfExtension = file?.extension === 'pdf';

  const handleOpenDocument = () => {
    const url = URL.createObjectURL(
      new Blob([file?.data as Blob], isPdfExtension ? { type: 'application/pdf' } : undefined),
    );
    const newWin = window.open(isPdfExtension ? url : 'signed-contract') as Window;
    if (!isPdfExtension && !!newWin) {
      newWin.onload = () => {
        const { body } = newWin.document;
        const img = newWin.document.createElement('img');
        img.src = url;
        body.insertBefore(img, body.firstChild);
      };
    }
    setOpen(false);
  };

  useEffect(() => {
    if (file === undefined && open) notify({ notification: 'Document was not found' });
    if (file && open) handleOpenDocument();
  }, [file, open]);

  useEffect(() => {
    if (applicationId) downloadSignedContract();
    return () => setFile(null);
  }, [applicationId]);

  return (
    <>
      {file === null && open && <PageLoader />}
      <div className={cx(styles.contractCardContainer, className)}>
        <div className={styles.contractView}>
          <span className={styles.viewPDF} onClick={() => setOpen(true)}>
            View
          </span>{' '}
          {viewTitle}
        </div>
        <div className={styles.contractList}>
          <dl>
            <dt className={styles.contractProjectDetail}>ezSolarLoan application amount</dt>
            <dd className={styles.contractProjectValue}>{convertMonetary(loanAmount || 0)}</dd>
            <dt className={styles.contractProjectDetail}>Total project amount</dt>
            <dd className={styles.contractProjectValue}>{convertMonetary(totalProjectCost || 0)}</dd>
            <dt className={styles.contractProjectDetail}>Amount to be paid outside ezSolarLoan</dt>
            <dd className={styles.contractProjectValue}>{convertMonetary(amountPaidOutside || 0)}</dd>
            <dt className={styles.contractProjectDetail}>ezSolarLoan final amount</dt>
            <dd className={styles.contractFinalAmount}>
              {convertMonetary(totalProjectCost ? Number(totalProjectCost) - Number(amountPaidOutside) : 0)}
            </dd>
          </dl>
        </div>
      </div>
    </>
  );
};

export default ViewContract;
