import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface LoadingState {
  [key: string]: boolean;
}

export enum LoadingType {
  ApplicationUpdate = 'ApplicationUpdate',
  ApplicationFetch = 'ApplicationFetch',
}

const initialState: LoadingState = {
  ApplicationUpdate: false,
  ApplicationFetch: false,
};

const loading = createSlice({
  name: 'loading',
  initialState,
  reducers: {
    setLoadingState(state: LoadingState, { payload }: PayloadAction<{ type: string; loading: boolean }>) {
      state[payload.type] = payload.loading;
    },
  },
});

export const { setLoadingState } = loading.actions;

export default loading.reducer;
