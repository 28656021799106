import React, { useEffect, useState } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Link from 'common/components/Link';
import CodeVerification from 'components/CodeVerification';
import { LoginLayout } from 'layouts';
import { PortalType } from 'layouts/withPortalLayout';
import { getBorrowerEmail, getBorrowerMfaIncomplete, getIntermediaryEmail, getIsLoading } from 'selectors';
import { useAppDispatch } from 'store';
import {
  resendEmailCodeForBorrower,
  resendEmailCodeForContractor,
  verifyEmailForBorrower,
  verifyEmailForContractor,
} from 'thunks';
import { Routes } from 'types';

import { ILoginProps, Resume } from './Login';
import styles from './Login.module.scss';

export const EmailConfirmation = ({ type }: ILoginProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const loading = useSelector(getIsLoading);
  const { state } = useLocation<Resume>();
  const [isCodeValid, setIsCodeValid] = useState<boolean | null>(null);
  const borrowerMfaIncomplete = useSelector(getBorrowerMfaIncomplete);
  const borrowerEmail = useSelector(getBorrowerEmail);
  const contractorEmail = useSelector(getIntermediaryEmail);

  const [email] = useState(type === PortalType.Contractor ? contractorEmail : borrowerEmail);

  const { control } = useForm();

  useEffect(() => {
    if (type === PortalType.Contractor) {
      dispatch(resendEmailCodeForContractor({}));
    } else if (type === PortalType.Borrower) {
      dispatch(resendEmailCodeForBorrower({}));
    }
  }, []);

  const handleResendCode = async () => {
    if (type === PortalType.Contractor) {
      return dispatch(resendEmailCodeForContractor({}));
    }
    if (type === PortalType.Borrower) {
      return dispatch(resendEmailCodeForBorrower({}));
    }
  };

  const handleCodeEntered = async (value: string) => {
    if (type === PortalType.Contractor) {
      const result = await dispatch(
        verifyEmailForContractor({
          code: value,
        }),
      ).unwrap();
      setIsCodeValid(!!result);
    } else if (type === PortalType.Borrower) {
      const result = await dispatch(
        verifyEmailForBorrower({
          code: value,
        }),
      ).unwrap();
      setIsCodeValid(!!result);
    }
  };

  useEffect(() => {
    if (isCodeValid && !loading) {
      if (type === PortalType.Contractor) {
        history.replace(Routes.ContractorDashboard);
      } else if (type === PortalType.Borrower) {
        if (borrowerMfaIncomplete) {
          history.replace(Routes.BorrowerConfirmPhone, { applicationId: state?.applicationId });
        } else {
          history.replace(Routes.BorrowerDashboard);
        }
      }
    }
  }, [isCodeValid, loading]);

  return (
    <LoginLayout title="Secure email authentication">
      <p className={styles.codeVerificationLabel}>
        Please enter the code sent <Link href={`mailto:${email}`}>{email}</Link>
      </p>
      <CodeVerification
        control={control}
        loading={loading}
        onChange={handleCodeEntered}
        isCodeValid={!!isCodeValid}
        resendCode={handleResendCode}
      />
    </LoginLayout>
  );
};
