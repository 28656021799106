import React, { FC, useState, useEffect } from 'react';

import { Tooltip } from '@mui/material';
import classNames from 'classnames/bind';

import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import Link from 'common/components/Link';

import styles from './OffersTable.module.scss';

export interface ITableRow {
  product: string;
  months: number;
  rate: string;
  monthlyPayment: string;
  selected?: boolean;
  tooltip?: string;
}

export interface ITableColumn {
  field: string;
  headerName: string;
}

interface IDataTable {
  columns: ITableColumn[];
  rows: ITableRow[];
  shownAmount?: number;
  onChange?: (row: ITableRow | null) => void;
  isCollapsible?: boolean;
  disabled?: boolean;
}

enum Key {
  product = 'product',
  months = 'months',
  rate = 'rate',
  monthlyPayment = 'monthlyPayment',
}

const cx = classNames.bind(styles);

const DataTable: FC<IDataTable> = ({ columns, rows, shownAmount = 3, onChange, isCollapsible = true, disabled }) => {
  const [showAll, setShowAll] = useState(false);
  const [selected, setSelected] = useState<ITableRow | null>(null);
  const isSelected = (row: ITableRow) => {
    if (disabled) return row.selected;
    return !selected && row.selected ? row.selected : selected?.product === row.product;
  };

  const toggleSelected = (row: ITableRow) => {
    if (isSelected(row) && !disabled) {
      row.selected = false;
      setSelected(null);
    } else setSelected(row);
  };
  const toggleShowAll = () => setShowAll(!showAll);

  useEffect(() => {
    if (!disabled) onChange?.(selected);
  }, [selected]);

  useEffect(() => {
    setSelected(rows.filter((row) => row.selected)[0]);
  }, [rows]);

  const numberOfItems = showAll ? rows.length : shownAmount;

  return (
    <>
      <table className={styles.table}>
        <thead className={styles.tableHeader}>
          <tr>
            {columns.map((column) => (
              <th
                key={column.field}
                className={cx(styles.tableHeaderCell, {
                  [styles.productCell]: column.field === Key.product,
                  [styles.monthlyPaymentCell]: column.field === Key.monthlyPayment,
                })}
              >
                {column.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.slice(0, numberOfItems).map((row) => (
            <tr
              key={row.product}
              className={cx(styles.tableBody, { [styles.tableDisabledBody]: disabled })}
              data-selected={isSelected(row)}
              onClick={() => toggleSelected(row)}
            >
              <td
                className={cx(styles.tableBodyCell, styles.productCell, {
                  [styles.tableCursor]: !disabled,
                })}
              >
                {row.product}
                {row.tooltip && (
                  <Tooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                    placement="right-start"
                    title={
                      <>
                        {row.tooltip}{' '}
                        <Link target="_blank" href="https://ezsolarloan.com/homeowners/#jackpot">
                          View more details
                        </Link>
                      </>
                    }
                  >
                    <InfoIcon className={styles.infoIcon} />
                  </Tooltip>
                )}
              </td>
              <td className={cx(styles.tableBodyCell, { [styles.tableCursor]: !disabled })}>{row.months}</td>
              <td className={cx(styles.tableBodyCell, { [styles.tableCursor]: !disabled })}>{row.rate}</td>
              <td
                className={cx(styles.tableBodyCell, styles.monthlyPaymentBodyCell, { [styles.tableCursor]: !disabled })}
              >
                {row.monthlyPayment}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {rows.length !== shownAmount && isCollapsible && (
        <div className={cx(styles.showMoreButton, styles.tableCursor)} onClick={() => toggleShowAll()}>
          {showAll ? 'Hide shorter term options' : 'Show shorter term options'}
        </div>
      )}
    </>
  );
};

export default DataTable;
