import { createAsyncThunk } from '@reduxjs/toolkit';

import StrategiesRestApi from 'api/StrategiesApi';
import { setDeclineState } from 'handlers/decline';
import { DeclineTypes } from 'handlers/decline/types';
import { LoadingType, setLoadingState } from 'handlers/loading';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { StrategyName, VariableValue } from 'types';

interface RunStrategyProps {
  strategyName: StrategyName;
  strategyInputVariables: Record<string, VariableValue>;
  status?: string;
  applicationId?: string;
  skipDecline?: boolean;
  skipDeclinePage?: boolean;
}

export const createRunStrategyThunk = (strategiesApi: StrategiesRestApi) =>
  createAsyncThunk(
    'strategies/run',
    async (
      {
        strategyInputVariables,
        strategyName,
        skipDecline = false,
        skipDeclinePage = false,
        applicationId,
        status = process.env.REACT_APP_STRATEGY_STATUS as string,
      }: RunStrategyProps,
      { dispatch },
    ) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        const { declined, declineReasons, outputVariables, passed, decisionId } = await strategiesApi.run(
          applicationId || null,
          strategyInputVariables,
          strategyName,
          status,
        );

        if (!passed && !declineReasons.length) {
          dispatch(
            setDeclineState({
              [DeclineTypes.IsPassedStrategy]: false,
            }),
          );
        }

        if (!skipDecline && declined) {
          dispatch(
            setDeclineState({
              [DeclineTypes.Reasons]: declineReasons as string[],
            }),
          );
          if (!skipDeclinePage) {
            dispatch(setCurrentStage({ stage: StagesType.Decline }));
          }
        }
        return { outputVariables, declined, declineReasons, passed, decisionId };
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
