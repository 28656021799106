import React, { FC, useEffect, useState } from 'react';

import { Tooltip } from '@mui/material';
import classNames from 'classnames/bind';

import { ReactComponent as InfoIcon } from 'assets/icons/Info.svg';
import Link from 'common/components/Link';

import styles from './OffersMobile.module.scss';
import { ITableRow } from './OffersTable';

const cx = classNames.bind(styles);

interface IOffersMobileProps {
  rows: ITableRow[];
  shownAmount?: number;
  onChange?: (row: ITableRow | null) => void;
  isCollapsible?: boolean;
  disabled?: boolean;
}

const OffersMobile: FC<IOffersMobileProps> = ({ rows, shownAmount = 3, onChange, isCollapsible = true, disabled }) => {
  const [selected, setSelected] = useState<ITableRow | null>(null);
  const [showAll, setShowAll] = useState(false);

  const isSelected = (row: ITableRow) => {
    if (disabled) return row.selected;
    return !selected && row.selected ? row.selected : selected?.product === row.product;
  };

  const toggleSelected = (row: ITableRow) => {
    if (isSelected(row) && !disabled) {
      row.selected = false;
      setSelected(null);
    } else setSelected(row);
  };

  const numberOfItems = showAll ? rows.length : shownAmount;

  const toggleShowAll = () => setShowAll(!showAll);

  useEffect(() => {
    if (!disabled) onChange?.(selected);
  }, [selected]);

  useEffect(() => {
    setSelected(rows.filter((row) => row.selected)[0]);
  }, [rows]);

  return (
    <>
      {rows.slice(0, numberOfItems).map((row) => (
        <div
          className={styles.mobileCardContainer}
          key={row.product}
          onClick={() => toggleSelected(row)}
          data-selected={isSelected(row)}
        >
          <div className={styles.mobileCard}>
            <div>
              <div className={styles.label}>Product</div>
              <div className={styles.item}>
                {row.product}
                {row.tooltip && (
                  <Tooltip
                    enterTouchDelay={0}
                    leaveTouchDelay={5000}
                    title={
                      <>
                        {row.tooltip}{' '}
                        <Link target="_blank" href="https://ezsolarloan.com/homeowners/#jackpot">
                          View more details
                        </Link>
                      </>
                    }
                  >
                    <InfoIcon className={styles.infoIcon} />
                  </Tooltip>
                )}
              </div>
            </div>
            <div className={styles.cardBody}>
              <div>
                <div className={styles.label}>Months</div>
                <div className={styles.item}>{row.months}</div>
              </div>
              <div>
                <div className={styles.label}>Rate</div>
                <div className={styles.item}>{row.rate}</div>
              </div>
              <div className={styles.monthlyPaymentCell}>
                <div className={styles.label}>Monthly payment</div>
                <div className={styles.item}>{row.monthlyPayment}</div>
              </div>
            </div>
          </div>
        </div>
      ))}
      {rows.length !== shownAmount && isCollapsible && (
        <div className={cx(styles.showMoreButton)} onClick={() => toggleShowAll()}>
          {showAll ? 'Hide shorter term options' : 'Show shorter term options'}
        </div>
      )}
    </>
  );
};

export default OffersMobile;
