import { createAsyncThunk } from '@reduxjs/toolkit';

import DocusignApi from 'api/DocusignApi';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface SignLoanDocumentsProps {
  applicationId: string;
}

export const createSignLoanDocumentsThunk = (docusignApi: DocusignApi) =>
  createAsyncThunk('docusign/getSignUrl', async ({ applicationId }: SignLoanDocumentsProps, { dispatch }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
      return await docusignApi.getSignUrl(applicationId);
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
    }
  });
