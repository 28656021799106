import React from 'react';

import cnBind from 'classnames/bind';

import styles from './DatePicker.module.scss';

const cx = cnBind.bind(styles);

interface DatePickerProps {
  selectedDay: number | string;
  month: number | string;
  onDatePicked: (value: number) => void;
}

const getDaysInMonth = (month: number, year = 2020) => new Date(year, month, 0).getDate();

const DayPicker = ({ selectedDay, month, onDatePicked }: DatePickerProps) => {
  const numberOfDaysInMonth = getDaysInMonth(Number(month));
  const days = Array.from({ length: numberOfDaysInMonth }).map((_, index) => index + 1);

  return (
    <>
      <div className={styles.dayWrapper}>
        {days.map((day) => (
          <div className={styles.selectButtonWrapper} key={day}>
            <div
              className={cx(styles.selectButton, {
                [styles.selectButtonActive]: Number(selectedDay) === day,
              })}
              onClick={() => onDatePicked(day)}
            >
              {day < 10 ? `0${day}` : day}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default DayPicker;
