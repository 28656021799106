import React, { useState } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import Button from 'common/components/Button/Button';
import NextButton from 'common/components/Button/NextButton';
import RadioButton from 'common/components/RadioButton/RadioButton';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { CoborrowerInfoSteps, InstallationSteps } from 'handlers/steps/types';
import PageLayout from 'layouts';
import pages from 'pages/pages.module.scss';
import {
  getCoborrowerExists,
  getOwnerInformationCorrect,
  getPropertyAddress,
  getPropertyOwner1,
  getPropertyOwner2,
  getPropertyOwner3,
} from 'selectors';

import styles from './OwnersPage.module.scss';

const cx = classNames.bind(pages);

const hideOwner = (owner: string) =>
  owner.replace(/\b(\w{2})(\w+)(\w)\b/g, (_, first, middle, last) => `${first}${'*'.repeat(middle.length)}${last}`);

const InstallationAddressOwnershipCheck = () => {
  const dispatch = useDispatch();
  const isCoborrowerExist = useSelector(getCoborrowerExists);
  const [allOwnersAdded, setAllOwnersAdded] = useState<boolean | null>(useSelector(getOwnerInformationCorrect));
  const address = useSelector(getPropertyAddress);

  const propertyOwner1 = useSelector(getPropertyOwner1);
  const propertyOwner2 = useSelector(getPropertyOwner2);
  const propertyOwner3 = useSelector(getPropertyOwner3);

  const handleAllOwnersAddedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAllOwnersAdded(e.target.value === 'true');
  };

  const handleAddOrUpdateCoborrower = () => {
    dispatch(setCurrentStage({ stage: StagesType.CoborrowerInfo, step: CoborrowerInfoSteps.NumberOfBorrowers }));
  };

  const handleBackClick = () => {
    if (isCoborrowerExist) {
      dispatch(setCurrentStage({ stage: StagesType.Installation, step: InstallationSteps.CoborrowerLives }));
    } else {
      dispatch(setCurrentStage({ stage: StagesType.Installation, step: InstallationSteps.OwnershipTerm }));
    }
  };

  const isValid = () => typeof allOwnersAdded === 'boolean';

  const handleNext = () => {
    dispatch(
      setApplicationData({
        [ApplicationVariables.OwnerInformationCorrect]: allOwnersAdded,
      }),
    );
    dispatch(setCurrentStage({ stage: StagesType.Employment }));
  };

  return (
    <>
      <PageLayout title="Installation address ownership check">
        <p className={pages.paragraph}>Address found:</p>
        <p>{`${address.street} ${address.city} ${address.state} ${address.zipCode}`}</p>
        <p className={pages.paragraph}>Owners found:</p>
        {propertyOwner1 && <p className={pages.paragraphHighlighted}>{hideOwner(propertyOwner1)}</p>}
        {propertyOwner2 && <p className={pages.paragraphHighlighted}>{hideOwner(propertyOwner2)}</p>}
        {propertyOwner3 && <p className={pages.paragraphHighlighted}>{hideOwner(propertyOwner3)}</p>}
        <p className={cx(pages.textInputLabel, pages.paragraph)}>Is the owner information above correct?</p>
        <RadioButton
          containerClassName={pages.radioButtonWithMargin}
          label="Yes"
          onChange={handleAllOwnersAddedChange}
          name={ApplicationVariables.BorrowersEqualsOwners}
          value="true"
          id="borrowersEqualsOwners"
          checked={!!allOwnersAdded}
        />
        <RadioButton
          label="No"
          onChange={handleAllOwnersAddedChange}
          name={ApplicationVariables.BorrowersEqualsOwners}
          id="borrowersNotOwners"
          value="false"
          checked={allOwnersAdded === false}
        />
        <p className={pages.paragraph}>
          If the property ownership above is not correct, you will be asked to upload verification of ownership before a
          loan decision can be made.
        </p>
        <div className={styles.reminderContainer}>
          <span className={cx(styles.reminderText)}>Reminder</span>
          <p>All owners of the property installation address are required to apply.</p>
          <Button
            title="Add or update co-borrower"
            className={styles.addCoborrowerButton}
            type="button"
            onClick={handleAddOrUpdateCoborrower}
          />
        </div>
        <p className={pages.paragraph}>
          **If there are three or more property owners, please complete this online loan application for two
          individuals, then contact ezSolarLoan for further instructions**
        </p>
      </PageLayout>
      <NavigationButtonWrapper>
        <BackButton onClick={handleBackClick} />
        <NextButton onClick={handleNext} disabled={!isValid()} />
      </NavigationButtonWrapper>
    </>
  );
};

export default InstallationAddressOwnershipCheck;
