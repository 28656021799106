import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';
import { setCustomerData } from 'handlers/customer';
import { setIntermediaryData } from 'handlers/intermediary';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface VerifyCodeProps {
  code: string;
}

export const createVerifyEmailForBorrowerThunk = (accountApi: AccountApi) =>
  createAsyncThunk('account/borrower/verifyEmail', async ({ code }: VerifyCodeProps, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
      const result = await accountApi.verifyEmailCode(code);

      if (result.success) {
        dispatch(
          setCustomerData({
            isEmailNotVerified: null,
          }),
        );
        return true;
      }
      rejectWithValue(false);
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
    }
  });

export const createVerifyEmailForContractorThunk = (accountApi: AccountApi) =>
  createAsyncThunk(
    'account/contractor/verifyEmail',
    async ({ code }: VerifyCodeProps, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        const result = await accountApi.verifyEmailCodeForContractor(code);

        if (result.success) {
          dispatch(
            setIntermediaryData({
              isEmailNotVerified: null,
            }),
          );
          return true;
        }
        rejectWithValue(false);
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
