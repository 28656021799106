export enum PropertyType {
  Apartments = 'apartments',
  Commercial = 'commercial',
  Retail = 'retail',
  Condo = 'condo',
  Townhouse = 'townhouse',
  TownhouseRowhouse = 'townhouse/rowhouse',
  Rowhouse = 'rowhouse',
  MultiFamily = 'multi family',
  MobileHome = 'mobile home',
  ManufacturedHome = 'manufactured home',
  OfficeBuilding = 'office building',
  Supermarket = 'supermarket',
  SupermarketWithSpace = 'super market',
}

export enum OwnerNameKey {
  LLC = 'LLC',
  TRUST = 'TRUST',
  TTE = 'TTE',
  CORPORATION = 'CORPORATION',
  CORP = 'CORP',
}
