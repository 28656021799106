import { createAsyncThunk } from '@reduxjs/toolkit';

import SunApi from 'api/SunApi';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface ICreateSun {
  email: string;
  firstName: string;
  lastName: string;
  mailingStreet: string;
  mailingCity: string;
  mailingState: string;
  mailingPostalCode: string;
  phone: string;
}

export const createSunThunk = (sunApi: SunApi) =>
  createAsyncThunk(
    'sun/create',
    async (
      { email, firstName, lastName, mailingCity, mailingStreet, mailingState, mailingPostalCode, phone }: ICreateSun,
      { dispatch },
    ) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        return await sunApi.create(
          email,
          firstName,
          lastName,
          mailingStreet,
          mailingCity,
          mailingState,
          mailingPostalCode,
          phone,
        );
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: false }));
      }
    },
  );
