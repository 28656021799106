import React, { FC } from 'react';

import classNames from 'classnames/bind';

import styles from './TextInput.module.scss';

const cx = classNames.bind(styles);

interface IErrorMessageProps {
  backgroundColor?: 'flowErrorBackground' | 'portalErrorBackground';
  errorType?: 'smallInputErrorMessage' | 'defaultInputErrorMessage';
}

const InputErrorMessage: FC<IErrorMessageProps> = ({
  children,
  errorType = 'defaultInputErrorMessage',
  backgroundColor = 'flowErrorBackground',
}) => <div className={cx(styles[errorType], styles[backgroundColor])}>{children}</div>;

export default InputErrorMessage;
