import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import RadioButton from 'common/components/RadioButton/RadioButton';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { backStep } from 'handlers/actions';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { nextStep } from 'handlers/steps';
import styles from 'pages/pages.module.scss';
import {
  getBorrowerFirstName,
  getBorrowerLastName,
  getBorrowerOwnershipTerm,
  getCoborrowerExists,
  getCoborrowerFirstName,
  getCoborrowerLastName,
  getIsLoading,
  getPropertyOwner1,
  getPropertyOwner2,
  getPropertyOwner3,
} from 'selectors';
import { checkOwners } from 'utils/checkOwners';

const BorrowerOwnership = () => {
  const dispatch = useDispatch();

  const initialOwnershipTerm = useSelector(getBorrowerOwnershipTerm);
  const coborrowerApplication = useSelector(getCoborrowerExists);
  const loading = useSelector(getIsLoading);
  const borrowerFirstName = useSelector(getBorrowerFirstName);
  const borrowerLastName = useSelector(getBorrowerLastName);
  const coborrowerFirstName = useSelector(getCoborrowerFirstName);
  const coborrowerLastName = useSelector(getCoborrowerLastName);
  const propertyOwner1 = useSelector(getPropertyOwner1);
  const propertyOwner2 = useSelector(getPropertyOwner2);
  const propertyOwner3 = useSelector(getPropertyOwner3);

  const [ownershipTerm, setOwnershipTerm] = useState<boolean | null>(initialOwnershipTerm);

  const handleSubmit = async () => {
    const borrowersEqualsOwners = checkOwners({
      borrowerFirstName,
      borrowerLastName,
      coborrowerFirstName,
      coborrowerLastName,
      propertyOwner1,
      propertyOwner2,
      propertyOwner3,
    });

    dispatch(
      setApplicationData({
        [ApplicationVariables.OwnershipTermLessOneYear]: ownershipTerm,
        [ApplicationVariables.BorrowersEqualsOwners]: borrowersEqualsOwners,
      }),
    );
    if (coborrowerApplication) dispatch(nextStep());
    else dispatch(setCurrentStage({ stage: propertyOwner1 ? StagesType.OwnersCheck : StagesType.Employment }));
  };

  const isValid = () => ownershipTerm != null;

  const handleBackClick = () => dispatch(backStep());

  const handleOwnershipTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOwnershipTerm(e.target.value === 'true');
  };

  return (
    <FormContainer>
      <div className={styles.textInputLabel}>Have you owned the home less than 1 year?</div>
      <RadioButton
        containerClassName={styles.radioButtonWithMargin}
        label="Yes"
        onChange={handleOwnershipTermChange}
        name={ApplicationVariables.OwnershipTermLessOneYear}
        id="ownershipLessOneYear"
        value="true"
        checked={!!ownershipTerm}
      />
      <RadioButton
        label="No"
        onChange={handleOwnershipTermChange}
        name={ApplicationVariables.OwnershipTermLessOneYear}
        id="ownershipMoreOneYear"
        value="false"
        checked={ownershipTerm === false}
      />
      <NavigationButtonWrapper>
        <BackButton onClick={handleBackClick} />
        <NextButton type="button" onClick={handleSubmit} disabled={!isValid()} loading={loading} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default BorrowerOwnership;
