import { convertPercent } from './formats';

function getOfferItem(
  product: string,
  months: number,
  rate: number,
  monthlyPayment: number,
  visible: boolean,
  tooltip = '',
  selectedOffer: string,
) {
  return {
    product,
    months,
    rate: convertPercent((rate * 100).toFixed(2)),
    monthlyPayment: `$${monthlyPayment.toFixed(0)}`,
    visible,
    tooltip,
    selected: months === parseInt(selectedOffer, 10),
  };
}

interface ILoanOffersToViewModel {
  applicationVariables: { [key: string]: any };
  selectedOffer?: string;
  loanAmount?: number;
}

const MIN_LOAN_AMOUNT = 10000;

export function loanOffersToViewModel({
  applicationVariables,
  loanAmount = 0,
  selectedOffer = '',
}: ILoanOffersToViewModel) {
  if (Object.keys(applicationVariables).length) {
    const offers = [
      getOfferItem(
        '10-year fixed',
        applicationVariables.months_in_10_years,
        applicationVariables.interest_rate_10_year_fixed,
        applicationVariables.monthly_payment_10_year_fixed,
        applicationVariables.display_offer_10_year_fixed,
        undefined,
        selectedOffer,
      ),
      getOfferItem(
        '7-year fixed',
        applicationVariables.months_in_7_years,
        applicationVariables.interest_rate_7_year_fixed,
        applicationVariables.monthly_payment_7_year_fixed,
        applicationVariables.display_offer_7_year_fixed,
        undefined,
        selectedOffer,
      ),
      getOfferItem(
        '5-year fixed',
        applicationVariables.months_in_5_years,
        applicationVariables.interest_rate_5_year_fixed,
        applicationVariables.monthly_payment_5_year_fixed,
        applicationVariables.display_offer_5_year_fixed,
        undefined,
        selectedOffer,
      ),
    ];
    if (loanAmount >= MIN_LOAN_AMOUNT)
      offers.unshift(
        getOfferItem(
          '21-year Jackpot',
          applicationVariables.months_in_21_years,
          applicationVariables.interest_rate_21_year_fixed,
          applicationVariables.monthly_payment_21_year_fixed,
          applicationVariables.display_offer_21_year_fixed,
          'Rate shown is starting rate and may change once at 7 years and once again at 14 years.',
          selectedOffer,
        ),
        getOfferItem(
          '20-year fixed',
          applicationVariables.months_in_20_years,
          applicationVariables.interest_rate_20_year_fixed,
          applicationVariables.monthly_payment_20_year_fixed,
          applicationVariables.display_offer_20_year_fixed,
          undefined,
          selectedOffer,
        ),
        getOfferItem(
          '15-year fixed',
          applicationVariables.months_in_15_years,
          applicationVariables.interest_rate_15_year_fixed,
          applicationVariables.monthly_payment_15_year_fixed,
          applicationVariables.display_offer_15_year_fixed,
          undefined,
          selectedOffer,
        ),
        getOfferItem(
          '12-year fixed',
          applicationVariables.months_in_12_years,
          applicationVariables.interest_rate_12_year_fixed,
          applicationVariables.monthly_payment_12_year_fixed,
          applicationVariables.display_offer_12_year_fixed,
          undefined,
          selectedOffer,
        ),
      );
    return offers.filter((x) => x.visible);
  }
  return [];
}
