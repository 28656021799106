import React from 'react';

import { FieldValues, Control } from 'react-hook-form';

import InputErrorMessage from 'common/components/TextInput/InputErrorMessage';
import DropDown from 'components/DropDown';
import { OptionType } from 'components/DropDown/DropDown';
import { getOptionObject } from 'utils/getOptionObject';

interface CitizenshipStatusProps {
  handleValuePicked: (option: OptionType) => void;
  errorMessage?: string;
  control: Control<FieldValues>;
  selectedOption: string;
}

export enum CitizenshipType {
  Citizen = 'U.S. citizen',
  PermanentResident = 'Permanent resident',
  NonPermanentResident = 'Non-permanent resident',
}

export const citizenshipData = [
  { value: CitizenshipType.Citizen, label: CitizenshipType.Citizen },
  { value: CitizenshipType.PermanentResident, label: CitizenshipType.PermanentResident },
  { value: CitizenshipType.NonPermanentResident, label: CitizenshipType.NonPermanentResident },
];

export const CitizenshipStatus = ({
  errorMessage,
  control,
  selectedOption,
  handleValuePicked,
}: CitizenshipStatusProps) => (
  <>
    <DropDown
      control={control}
      name="citizenshipStatus"
      onValuePicked={handleValuePicked}
      selectedOption={getOptionObject(citizenshipData, selectedOption)}
      options={citizenshipData}
      placeholder="Citizenship status"
      isSearchable={false}
      invalid={!!errorMessage}
    />
    {!!errorMessage && <InputErrorMessage>{errorMessage}</InputErrorMessage>}
  </>
);
