import React, { FC, useEffect, useState } from 'react';

import cnBind from 'classnames/bind';
import useOnclickOutside from 'react-cool-onclickoutside';
import { Control, Controller, FieldValues } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import { ReactComponent as CalendarIcon } from 'assets/icons/Calendar.svg';
import TextInput, { TextInputProps } from 'common/components/TextInput/TextInput';
import { isFullDateLength } from 'utils/validationHelper';

import styles from './DatePicker.module.scss';
import DayPicker from './DayPicker';
import MonthPicker from './MonthPicker';
import YearPicker from './YearPicker';

const cx = cnBind.bind(styles);

export enum PickerType {
  Default = 'Default',
  BirthDayPicker = 'BirthDayPicker',
  LeaseStartPicker = 'LeaseStartPicker',
  LeaseTermPicker = 'LeaseTermPicker',
}

interface DatePickerProps extends TextInputProps {
  name: string;
  label: string;
  control: Control<FieldValues>;
  type?: PickerType;
  className?: string;
  handleChange?: (date: string) => void;
  handleBlur?: (date?: string) => void;
  invalid?: boolean;
}

enum PickerStageType {
  Month = 'Month',
  Day = 'Day',
  Year = 'Year',
}

export interface PickerDateState {
  month: number | string;
  day: number | string;
  year: number | string;
}

enum MaskPartType {
  Month = 'MM',
  Day = 'DD',
  Year = 'YYYY',
  MaxDayLength = 2,
  MaxMonthLength = 2,
  MaxYearLength = 4,
}

const getCurrentDateValue = (date: PickerDateState) => `${date.month}/${date.day}/${date.year}`;

const DatePicker: FC<DatePickerProps> = ({
  name,
  value,
  control,
  type = PickerType.Default,
  className,
  label,
  handleChange,
  handleBlur,
  errorMessage,
  disabled,
  invalid,
  errorBackgroundType,
  ...restProps
}) => {
  const [stage, setStage] = useState<PickerStageType>(PickerStageType.Month);
  const [date, setDate] = useState<PickerDateState>({
    month: MaskPartType.Month,
    day: MaskPartType.Day,
    year: MaskPartType.Year,
  });
  const [open, setOpen] = useState(false);
  const [changeMode, setChangeMode] = useState(false);

  const handleBlurDefault = (currentDateValue: string) => {
    if (currentDateValue) {
      const [month, day, year] = currentDateValue.split('/');
      setDate({ month, day, year });
    }
  };

  const handleOnClick = () => {
    setOpen(!open);
    if (isFullDateLength(date)) return setChangeMode(true);
    setStage(getCurrentStage(date));
  };

  const handleClickOutside = () => {
    if (open) {
      setOpen(!open);
      handleBlurDefault(value || '');
      handleBlur?.(value || '');
    }
  };

  const datePickerRef = useOnclickOutside(handleClickOutside);

  const isMaskHidden =
    !open && date.day === MaskPartType.Day && date.month === MaskPartType.Month && date.year === MaskPartType.Year;

  const getCurrentStage = (newDate: PickerDateState) => {
    if (!parseInt(newDate.month.toString(), 10)) return PickerStageType.Month;
    if (!parseInt(newDate.day.toString(), 10)) return PickerStageType.Day;
    return PickerStageType.Year;
  };

  const handleDatePartSelection = (newDate: PickerDateState, nextStage: PickerStageType) => {
    setDate(newDate);
    handleChange?.(getCurrentDateValue(newDate));

    if (!changeMode) {
      setStage(nextStage);

      if (nextStage === PickerStageType.Month) {
        setOpen(!open);
        handleBlurDefault(getCurrentDateValue(newDate));
        handleBlur?.(value || '');
      }
    }
  };

  const handleMonth = (month: number) => {
    const newDate = { ...date, month: month.toString().padStart(MaskPartType.MaxMonthLength, '0') };

    return handleDatePartSelection(newDate, PickerStageType.Day);
  };

  const handleDay = (day: number) => {
    const newDate = { ...date, day: day.toString().padStart(MaskPartType.MaxDayLength, '0') };

    return handleDatePartSelection(newDate, PickerStageType.Year);
  };

  const handleYear = (year: number) => {
    const newDate = { ...date, year };

    return handleDatePartSelection(newDate, PickerStageType.Month);
  };

  const handleSetInputDate = (dateType: string, newDate: PickerDateState, nextStage: PickerStageType) => {
    setDate(newDate);
    handleChange?.(getCurrentDateValue(newDate));

    if (nextStage !== PickerStageType.Month && dateType.length === MaskPartType.MaxDayLength) setStage(nextStage);
    if (nextStage === PickerStageType.Month && dateType.length === MaskPartType.MaxYearLength) {
      if (!changeMode) setOpen(!open);
      handleBlurDefault(getCurrentDateValue(newDate));
      handleBlur?.(value || '');
    }
  };

  const handleInputMonth = (dateValue: string) => {
    if (date.month !== MaskPartType.Month && String(date.month).length === MaskPartType.MaxMonthLength) {
      setStage(PickerStageType.Day);
      return handleInputDay(dateValue);
    }
    const newDate =
      String(date.month) === MaskPartType.Month
        ? { ...date, month: `${dateValue}` }
        : { ...date, month: `${String(date.month)[0]}${dateValue}` };

    return handleSetInputDate(newDate.month, newDate, PickerStageType.Day);
  };
  const handleInputDay = (dateValue: string) => {
    if (date.day !== MaskPartType.Day && String(date.day).length === MaskPartType.MaxDayLength) {
      setStage(PickerStageType.Year);
      return handleInputYear(dateValue);
    }
    const newDate =
      String(date.day) === MaskPartType.Day
        ? { ...date, day: `${dateValue}` }
        : { ...date, day: `${String(date.day)[0]}${dateValue}` };

    return handleSetInputDate(newDate.day, newDate, PickerStageType.Year);
  };
  const handleInputYear = (dateValue: string) => {
    if (date.year !== MaskPartType.Year && String(date.year).length === MaskPartType.MaxYearLength) return;

    const newDate =
      String(date.year) === MaskPartType.Year
        ? { ...date, year: `${dateValue}` }
        : { ...date, year: `${date.year}${dateValue}` };

    return handleSetInputDate(newDate.year, newDate, PickerStageType.Month);
  };

  const onInputDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    if (Number.isNaN(parseFloat(inputValue)) && !Number.isFinite(inputValue)) return;
    if (!open) return event.target.blur();
    switch (stage) {
      case PickerStageType.Month:
        return handleInputMonth(inputValue);
      case PickerStageType.Day:
        return handleInputDay(inputValue);
      case PickerStageType.Year:
        return handleInputYear(inputValue);
      default:
        break;
    }
  };

  const deleteDate = (dateValue: PickerDateState, prevStage: PickerStageType) => {
    setDate(dateValue);
    handleChange?.(getCurrentDateValue(dateValue));
    setStage(prevStage);
  };

  const handleDeleteInputDate = (dateType: string, newDate: PickerDateState, prevStage: PickerStageType) => {
    setDate(newDate);
    handleChange?.(getCurrentDateValue(newDate));
    if (dateType.length) return;
    switch (stage) {
      case PickerStageType.Year:
        return deleteDate({ ...date, year: MaskPartType.Year }, prevStage);
      case PickerStageType.Month:
        return deleteDate({ ...date, month: MaskPartType.Month }, prevStage);
      case PickerStageType.Day:
        return deleteDate({ ...date, day: MaskPartType.Day }, prevStage);
      default:
        break;
    }
  };

  const handleDeleteMonth = () => {
    if (date.month === MaskPartType.Month) return;
    const newDate = { ...date, month: `${String(date.month).slice(0, -1)}` };
    return handleDeleteInputDate(newDate.month, newDate, PickerStageType.Month);
  };
  const handleDeleteDay = () => {
    if (date.day === MaskPartType.Day) {
      setStage(PickerStageType.Month);
      return handleDeleteMonth();
    }
    const newDate = { ...date, day: `${String(date.day).slice(0, -1)}` };
    return handleDeleteInputDate(newDate.day, newDate, PickerStageType.Month);
  };
  const handleDeleteYear = () => {
    if (date.year === MaskPartType.Year) {
      setStage(PickerStageType.Day);
      return handleDeleteDay();
    }
    const newDate = { ...date, year: `${String(date.year).slice(0, -1)}` };
    return handleDeleteInputDate(
      newDate.year,
      newDate,
      date.day === MaskPartType.Day && date.month === MaskPartType.Month ? PickerStageType.Month : PickerStageType.Day,
    );
  };

  const onDeleteDate = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace') {
      switch (stage) {
        case PickerStageType.Day:
          return handleDeleteDay();
        case PickerStageType.Month:
          return handleDeleteMonth();
        case PickerStageType.Year:
          return handleDeleteYear();
        default:
          break;
      }
    }
  };

  const handleOnBlur = () => {
    if (date.day === '0') return setDate({ ...date, day: MaskPartType.Day });
    if (date.month === '0') return setDate({ ...date, month: MaskPartType.Month });
    if (String(date.day).length < MaskPartType.MaxDayLength) return setDate({ ...date, day: `${0}${date.day}` });
    if (String(date.month).length < MaskPartType.MaxMonthLength)
      return setDate({ ...date, month: `${0}${date.month}` });
  };

  const setFocusOnInput = () => {
    const datePicker = document.getElementById('datePicker');
    if (datePicker) datePicker.focus();
  };

  const selectDate = (currentDate: string | number, prevDate: string | number, dateType: PickerStageType) => {
    if (disabled) return;
    if (parseInt(String(currentDate), 10)) setStage(dateType);
    if (!parseInt(String(prevDate), 10)) return;
    setStage(dateType);
  };

  useEffect(() => {
    if (value) {
      const [month, day, year] = value.split('/');
      setDate({ month, day, year });
    }
  }, [value]);

  const renderAdornment = () => <CalendarIcon className={cx(styles.calendar)} onClick={handleOnClick} />;

  const renderMask = () => (
    <div
      className={cx(styles.maskContainer, { [styles.maskContainerHidden]: isMaskHidden })}
      onClick={() => {
        setFocusOnInput();
        setOpen(true);
      }}
    >
      <span
        onClick={() => setStage(PickerStageType.Month)}
        className={cx({
          [styles.maskPartActive]: stage === PickerStageType.Month && open,
          [styles.inputNoInteraction]: disabled,
        })}
      >
        {date.month || MaskPartType.Month}
      </span>
      <span className={cx({ [styles.inputNoInteraction]: disabled })}>/</span>
      <span
        onClick={() => selectDate(date.day, date.month, PickerStageType.Day)}
        className={cx({
          [styles.maskPartActive]: stage === PickerStageType.Day && open,
          [styles.inputNoInteraction]: disabled,
        })}
      >
        {date.day || MaskPartType.Day}
      </span>
      <span className={cx({ [styles.inputNoInteraction]: disabled })}>/</span>
      <span
        onClick={() => selectDate(date.year, date.day, PickerStageType.Year)}
        className={cx({
          [styles.maskPartActive]: stage === PickerStageType.Year && open,
          [styles.inputNoInteraction]: disabled,
        })}
      >
        {date.year || MaskPartType.Year}
      </span>
    </div>
  );

  return (
    <div ref={datePickerRef} style={{ width: '100%' }}>
      <div className={cx(styles.wrapper, className, { [styles.wrapperNoInteraction]: disabled })}>
        {renderMask()}
        <Controller
          name={name}
          control={control}
          render={({ field: { ref } }) => (
            <TextInput
              value={value}
              isActive={!isMaskHidden}
              errorBackgroundType={errorBackgroundType}
              name={name}
              label={label}
              disabled={disabled}
              errorMessage={errorMessage}
              endAdornment={renderAdornment}
              {...restProps}
            >
              <NumberFormat
                getInputRef={ref}
                name={name}
                id="datePicker"
                inputMode="decimal"
                value=""
                onBlur={handleOnBlur}
                onChange={onInputDate}
                onKeyDown={onDeleteDate}
                onPaste={(event) => event.preventDefault()}
                onCut={(event) => event.preventDefault()}
                onCopy={(event) => event.preventDefault()}
                onClick={handleOnClick}
                className={cx(styles.input, {
                  [styles.inputActive]: open,
                  [styles.inputNoInteraction]: disabled,
                })}
              />
            </TextInput>
          )}
        />
        {open && (
          <div className={styles.datepickerContainer} onClick={() => setFocusOnInput()}>
            <div className={styles.dateTabContainer}>
              <div
                className={cx(styles.dateTab, {
                  [styles.dateTabActive]: stage === PickerStageType.Month,
                })}
                onClick={() => setStage(PickerStageType.Month)}
              >
                Month
              </div>
              <div
                className={cx(styles.dateTab, {
                  [styles.dateTabActive]: stage === PickerStageType.Day,
                  [styles.dateTabDisabled]: !parseInt(date.month.toString(), 10) && !parseInt(date.day.toString(), 10),
                })}
                onClick={() => selectDate(date.day, date.month, PickerStageType.Day)}
              >
                Date
              </div>
              <div
                className={cx(styles.dateTab, {
                  [styles.dateTabActive]: stage === PickerStageType.Year,
                  [styles.dateTabDisabled]: !parseInt(date.day.toString(), 10) && !parseInt(date.year.toString(), 10),
                })}
                onClick={() => selectDate(date.year, date.day, PickerStageType.Year)}
              >
                Year
              </div>
            </div>
            {stage === PickerStageType.Month && <MonthPicker selectedMonth={date.month} onMonthPicked={handleMonth} />}
            {stage === PickerStageType.Day && (
              <DayPicker month={date.month} selectedDay={date.day} onDatePicked={handleDay} />
            )}
            {stage === PickerStageType.Year && (
              <YearPicker type={type} selectedYear={date.year} onYearPicked={handleYear} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default DatePicker;
