import { createAsyncThunk } from '@reduxjs/toolkit';

import ApplicationApi from 'api/ApplicationApi';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface GetApplicationsProps {
  search?: string;
  sortField?: string;
  sortDirection?: string;
  offset?: number;
  count?: number;
  borrowerId?: string;
  intermediaryId?: string;
  projectDetails?: string;
  statusApplication?: string[];
  voi?: string;
}

export const createGetApplicationsThunk = (applicationApi: ApplicationApi) =>
  createAsyncThunk(
    'applications/get',
    async (
      {
        search,
        sortField,
        sortDirection,
        offset,
        count,
        borrowerId,
        intermediaryId,
        projectDetails,
        statusApplication,
        voi,
      }: GetApplicationsProps,
      { dispatch },
    ) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: true }));
        return await applicationApi.getApplications({
          search,
          sortField,
          sortDirection,
          offset,
          count,
          borrowerId,
          intermediaryId,
          projectDetails,
          statusApplication,
          voi,
        });
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: false }));
      }
    },
  );
