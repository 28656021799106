import { StylesConfig } from 'react-select';
// common variables from common/styles
// since react-select works with styles object we have to use this approach
// maybe refactor later once solution is found.
const variables = {
  body1FontSize: '20px',
  body1LineHeight: '28px',
  fontFamilyRedHatDisplayRegular: 'RedHatDisplay-Regular',
  subtitle2FontSize: '20px',
  subtitle2LineHeight: '28px',
  fontFamilyRedHatDisplay700: 'RedHatDisplay-700',
  body2FontSize: '18px',
  body2LineHeight: '26px',
};

const fonts = {
  primary: `${variables.body1FontSize}/${variables.body1LineHeight} ${variables.fontFamilyRedHatDisplayRegular}, sans-serif`,
  subtitle2: `${variables.subtitle2FontSize}/${variables.subtitle2LineHeight} ${variables.fontFamilyRedHatDisplay700}, sans-serif`,
  body2: `${variables.body2FontSize}/${variables.body2LineHeight} ${variables.fontFamilyRedHatDisplayRegular}, sans-serif`,
};

const colorPalette = {
  black100: '#000000',
  black60: '#8c8c8c',
  black40: '#B3B3B3',
  black20: '#D9D9D9',
  yellow100: '#FEC50D',
  yellow90: '#FED13E',
  black5: '#f5f5f5',
};

// end of common variables

type MyOptionType = {
  label: string;
  value: string;
};

type IsMulti = false;

interface OptionsState {
  isDisabled?: boolean;
  isSelected?: boolean;
  isFocused?: boolean;
}

const getBackgroundColor = (options: OptionsState) => {
  if (options.isFocused) {
    return colorPalette.yellow90;
  }
  return 'white';
};

const customStyles: StylesConfig<MyOptionType, IsMulti> = {
  menu: () => ({
    position: 'absolute',
    width: '100%',
    zIndex: 998,
    boxSizing: 'border-box',
    boxShadow: '0px 1px 20px rgba(27, 24, 24, 0.15)',
    border: '1px solid #D9D9D9',
    borderRadius: '4px',
    font: fonts.primary,
    marginTop: 2,
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    '::-webkit-scrollbar': {
      width: '7px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: colorPalette.black20,
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: colorPalette.black60,
      borderRadius: '4px',
    },
  }),

  option: (provided, { data, isDisabled, isFocused, isSelected }) => ({
    ...provided,
    height: '64px',
    backgroundColor: getBackgroundColor({ isDisabled, isFocused, isSelected }),
    color: 'black',
    font: (isSelected && fonts.subtitle2) || undefined,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
    cursor: 'pointer',

    ':active': {
      backgroundColor: !isDisabled && data.color,
    },

    svg: {
      display: isSelected ? 'block' : 'none',
    },
  }),

  control: (provided, { isFocused, isDisabled }) => {
    const styles = {
      ...provided,
      alignItems: 'stretch',
      cursor: 'pointer',
      height: '64px',
      outline: isFocused ? 'none' : 'none',
      boxShadow: isFocused ? 'none' : `none`,
      borderColor: isFocused ? colorPalette.black20 : colorPalette.black20,
      borderRadius: '4px',
      borderBottom: isFocused ? `2px solid ${colorPalette.yellow100}` : `1px solid ${colorPalette.black20}`,
      borderBottomLeftRadius: isFocused ? '0px' : '4px',
      borderBottomRightRadius: isFocused ? '0px' : '4px',

      ':hover': {
        border: `1px solid ${colorPalette.black20}`,
        borderBottom: isFocused ? `2px solid ${colorPalette.yellow100}` : `1px solid ${colorPalette.black20}`,
        boxShadow: '0px 1px 20px rgba(27, 24, 24, 0.15)',
      },
    };

    if (isDisabled) {
      styles.border = 'none';
      styles.backgroundColor = colorPalette.black5;
    }

    return styles;
  },

  indicatorsContainer: (provided) => ({
    ...provided,
    alignSelf: 'center',
    width: 24,
    height: 24,
    marginRight: 20,
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    top: '40px',
    color: state.selectProps.menuIsOpen ? colorPalette.black40 : colorPalette.black100,
  }),

  valueContainer: (provided) => ({
    ...provided,
    alignItems: 'stretch',
    overflow: 'visible',
    font: fonts.body2,
    padding: '0 0 8px 24px',
  }),

  input: (provided) => ({
    ...provided,
    position: 'absolute',
    left: '24px',
    top: '20px',
  }),

  placeholder: () => ({
    display: 'none',
  }),
};

export default customStyles;
