import React, { ForwardedRef } from 'react';

import classNames from 'classnames/bind';

import InputErrorMessage from './InputErrorMessage';
import InputLabel from './InputLabel';
import styles from './TextInput.module.scss';

const cx = classNames.bind(styles);

const defaultInput = 'defaultInput';

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string;
  label?: string | JSX.Element;
  errorMessage?: string;
  errorBackgroundType?: 'flowErrorBackground' | 'portalErrorBackground';
  endAdornment?: () => JSX.Element;
  handleFocus?: () => void;
  handleBlur?: () => void;
  isActive?: boolean;
  invalid?: boolean;
  referenceInfo?: string | JSX.Element;
  icon?: string;
  inputContainerClassName?: string;
  inputType?: 'defaultInput' | 'smallInput';
}

export enum InputType {
  defaultInputLabel = 'defaultInputLabel',
  smallInputLabel = 'smallInputLabel',
  hasValueSmallInput = 'hasValueSmallInput',
  hasValueDefaultInput = 'hasValueDefaultInput',
  defaultInputErrorMessage = 'defaultInputErrorMessage',
  smallInputErrorMessage = 'smallInputErrorMessage',
}

const TextInput = React.forwardRef(
  (
    {
      value,
      label,
      errorMessage,
      children,
      onChange,
      onBlur,
      handleFocus,
      handleBlur,
      onFocus,
      isActive,
      name,
      endAdornment,
      referenceInfo,
      inputContainerClassName,
      disabled,
      inputType = defaultInput,
      invalid,
      errorBackgroundType,
      ...restProps
    }: React.PropsWithChildren<TextInputProps>,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    const renderInput = () => {
      if (children) {
        return children;
      }

      return (
        <input
          className={cx(styles[inputType], {
            [styles[inputType !== defaultInput ? InputType.hasValueSmallInput : InputType.hasValueDefaultInput]]:
              !!value,
          })}
          ref={ref}
          type="text"
          value={value}
          onChange={onChange}
          onBlur={(e) => {
            onBlur?.(e);
            handleBlur?.();
          }}
          onFocus={(e) => {
            onFocus?.(e);
            handleFocus?.();
          }}
          name={name}
          autoComplete="off"
          disabled={disabled}
          required
          {...restProps}
        />
      );
    };

    return (
      <div className={cx(styles.textInputContainer, inputContainerClassName)}>
        {renderInput()}
        <InputLabel
          active={isActive}
          disabled={disabled}
          inputType={inputType !== defaultInput ? InputType.smallInputLabel : InputType.defaultInputLabel}
        >
          {label}
        </InputLabel>
        {endAdornment && (
          <div
            className={cx(styles.endAdornmentWrapper, {
              [styles.endAdornmentWrapperDisabled]: disabled,
            })}
          >
            {endAdornment()}
          </div>
        )}
        {errorMessage && (
          <InputErrorMessage
            backgroundColor={errorBackgroundType}
            errorType={
              inputType !== defaultInput ? InputType.smallInputErrorMessage : InputType.defaultInputErrorMessage
            }
          >
            {errorMessage}
          </InputErrorMessage>
        )}
        {referenceInfo && <div className={styles.referenceInfo}>{referenceInfo}</div>}
      </div>
    );
  },
);

TextInput.displayName = 'TextInput';

export default TextInput;
