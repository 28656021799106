import React, { FC } from 'react';

import { Dialog } from '@mui/material';
import cnBind from 'classnames/bind';

import styles from './ModalWindow.module.scss';

const cx = cnBind.bind(styles);

interface ModalWindowProps {
  isOpen: boolean;
  title?: JSX.Element | string;
  content?: JSX.Element | string;
  actions?: JSX.Element | string;
  actionsContainerClassname?: string;
}

const ModalWindow: FC<ModalWindowProps> = ({ title, content, actions, isOpen, actionsContainerClassname }) => {
  const body = (
    <div className={styles.body}>
      <div className={styles.title}>{title}</div>
      <div className={styles.content}>{content}</div>
      <div className={cx(styles.actions, actionsContainerClassname)}>{actions}</div>
    </div>
  );
  return (
    <Dialog open={isOpen} maxWidth={false}>
      {body}
    </Dialog>
  );
};

export default ModalWindow;
