import { AnyAction, combineReducers, Reducer } from '@reduxjs/toolkit';

import { UserType } from 'enums/UserType';

import application from './application';
import coborrower from './coborrower';
import common from './common';
import customer from './customer';
import decline from './decline';
import intermediary from './intermediary';
import loading from './loading';
import stages from './stages';
import steps from './steps';
import tableOptions from './table';

const appReducer = combineReducers({
  customer,
  application,
  coborrower,
  tableOptions,
  loading,
  steps,
  stages,
  decline,
  intermediary,
  common,
});

const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    sessionStorage.removeItem('state');
    const userType = sessionStorage.getItem('userType');
    if (userType === UserType.Borrower) {
      localStorage.removeItem('borrowerToken');
      localStorage.removeItem('borrowerRefreshToken');
    } else if (userType === UserType.Contractor) {
      localStorage.removeItem('contractorToken');
      localStorage.removeItem('contractorRefreshToken');
    }
    sessionStorage.removeItem('userType');
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;

export default rootReducer;
