import React from 'react';

import styles from './Chip.module.scss';

interface IChipProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const Chip = ({ label, id, disabled, ...restProps }: IChipProps) => (
  <>
    <input type="checkbox" className={styles.chipInput} disabled={disabled} {...restProps} id={id} />
    <label htmlFor={id} className={styles.chipLabel}>
      {label}
    </label>
  </>
);

export default Chip;
