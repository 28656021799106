import React, { FC } from 'react';

import classNames from 'classnames/bind';

import styles from './Button.module.scss';
import { ReactComponent as EllipseIcon } from './icons/ellipse.svg';

export enum AlignmentType {
  Center = 'Center',
  Right = 'Right',
  Left = 'Left',
}

export enum ButtonVariant {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Text = 'Text',
}

const cx = classNames.bind(styles);

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title?: string;
  variant?: ButtonVariant;
  icon?: JSX.Element;
  loading?: boolean;
  iconAlignment?: AlignmentType;
  disabled?: boolean;
  inactive?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
}

const Button: FC<ButtonProps> = ({
  variant,
  title,
  icon,
  iconAlignment,
  disabled,
  inactive,
  type,
  className,
  onClick,
  loading,
}) => (
  <button
    // eslint-disable-next-line react/button-has-type
    type={type}
    className={cx(
      {
        [styles.primaryButton]: variant === ButtonVariant.Primary,
        [styles.secondaryButton]: variant === ButtonVariant.Secondary,
        [styles.textButton]: variant === ButtonVariant.Text,
        [styles.mainButton]: !variant,
        [styles.rightIcon]: iconAlignment === AlignmentType.Right,
        [styles.leftIcon]: iconAlignment === AlignmentType.Left,
        [styles.inactive]: inactive,
        [styles.loadingButton]: loading,
      },
      className,
    )}
    disabled={disabled}
    onClick={onClick}
  >
    {iconAlignment === AlignmentType.Left && icon}
    {iconAlignment === AlignmentType.Center && icon}
    {title}
    {iconAlignment === AlignmentType.Right && icon}
    {loading && <EllipseIcon />}
  </button>
);
Button.defaultProps = {
  type: 'button',
};

export default Button;
