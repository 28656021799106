import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as BatteryIcon } from 'assets/icons/Battery.svg';
import { ReactComponent as HeatingAndCoolingIcon } from 'assets/icons/HeatingAndCooling.svg';
import { ReactComponent as LightningIcon } from 'assets/icons/Lightning.svg';
import { ReactComponent as RoofIcon } from 'assets/icons/Roof.svg';
import { ReactComponent as SolarPanelIcon } from 'assets/icons/Solar-panel.svg';
import { ReactComponent as SunIcon } from 'assets/icons/Sun.svg';
import NextButton from 'common/components/Button/NextButton';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper, { AlignmentType } from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import SelectionPanel from 'components/SelectionPanel';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { nextStep } from 'handlers/steps';
import styles from 'pages/pages.module.scss';
import { getAdditionalLoanPurpose, getLoanPurpose } from 'selectors';

enum LoanPurposeKeys {
  SolarPanels = 'Solar system',
  EnergyStorage = 'Energy storage',
  SolarPanelsEnergyStorage = 'Solar system + Energy storage',
  RoofingRepairs = 'Roofing repairs',
  HeatingCoolingUpgrades = 'Heating and cooling upgrades',
  OtherEnergyEfficiency = 'Other energy efficiency',
}

export const LoanPurposesPrimary = [
  {
    title: 'Solar system',
    type: LoanPurposeKeys.SolarPanels,
    icon: <SolarPanelIcon />,
  },
  {
    title: 'Energy storage',
    type: LoanPurposeKeys.EnergyStorage,
    icon: <BatteryIcon />,
  },
  {
    title: 'Solar system + Energy storage',
    type: LoanPurposeKeys.SolarPanelsEnergyStorage,
    icon: <SunIcon />,
  },
];

export const LoanPurposesSecondary = [
  {
    title: 'Roofing repairs',
    type: LoanPurposeKeys.RoofingRepairs,
    icon: <RoofIcon />,
  },
  {
    title: 'Heating and cooling upgrades',
    type: LoanPurposeKeys.HeatingCoolingUpgrades,
    icon: <HeatingAndCoolingIcon />,
  },
  {
    title: 'Other energy efficiency',
    type: LoanPurposeKeys.OtherEnergyEfficiency,
    icon: <LightningIcon />,
  },
];

const LoanPurpose = () => {
  const dispatch = useDispatch();

  const loanPurpose = useSelector(getLoanPurpose);
  const additionalLoanPurpose = useSelector(getAdditionalLoanPurpose);

  const [purposeState, setPurpose] = useState([loanPurpose]);
  const [additionalPurposeState, setAdditionalPurpose] = useState<string[]>(
    additionalLoanPurpose ? additionalLoanPurpose.split(', ') : [],
  );

  const isFormValid = () => !!purposeState[0];

  const handleSubmit = () => {
    dispatch(
      setApplicationData({
        [ApplicationVariables.PrimaryLoanPurpose]: purposeState[0],
        [ApplicationVariables.SecondaryLoanPurpose]: additionalPurposeState.join(', '),
      }),
    );
    dispatch(nextStep());
  };

  return (
    <FormContainer title="What is your loan purpose?">
      <div className={styles.selectionPanelWrapper}>
        <SelectionPanel
          buttons={LoanPurposesPrimary}
          setCheckedOptions={(option: string[]) => setPurpose(option)}
          selected={purposeState}
          singleSelection
        />
        <SelectionPanel
          buttons={LoanPurposesSecondary}
          setCheckedOptions={(selected: string[]) => setAdditionalPurpose(selected)}
          selected={purposeState ? additionalPurposeState : []}
          disableButtons={!purposeState[0]}
        />
      </div>
      <NavigationButtonWrapper alignment={AlignmentType.Right}>
        <NextButton disabled={!isFormValid()} onClick={handleSubmit} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default LoanPurpose;
