import React, { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import Checkbox from 'common/components/Checkbox';
import NumberInput from 'common/components/NumberInput';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { DeclineReasons } from 'enums/DeclineReasons';
import { getInvalidSSNLength, getRequiredErrorMessage } from 'errors/errors';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { CoborrowerInfoKeys } from 'handlers/coborrower/types';
import { CommonTypes } from 'handlers/common/types';
import { UserInformationKeys } from 'handlers/customer/types';
import { DeclineTypes } from 'handlers/decline/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { setSteps } from 'handlers/steps';
import { EmploymentSteps } from 'handlers/steps/types';
import PageLayout from 'layouts';
import pages from 'pages/pages.module.scss';
import {
  getAchDiscount,
  getBorrowerEmploymentDuration,
  getCoborrowerEmploymentDuration,
  getCoborrowerExists,
  getIntermediarySci,
  getIsLoading,
  getRootState,
} from 'selectors';
import { useAppDispatch } from 'store';
import { downloadDataTreeDocument, runStrategy, submitApplication, uploadDocuments } from 'thunks';
import { Routes, StrategyName, VariableValue } from 'types';
import { createNewPdfFromBlob } from 'utils/createNewPdf';
import { getCurrentDate } from 'utils/dateHelper';
import { isRecentEmployment } from 'utils/isRecentEmployment';
import { isExistErrors } from 'utils/validationHelper';

import styles from './CheckRateAndPayment.module.scss';

const cx = classNames.bind(pages);

const APPLICATION_CREATE_DATE = getCurrentDate();
const SSN_LENGTH = 4;

type FormValues = {
  borrowerSSN: string;
  coborrowerSSN: string;
};

const CheckRateAndPayments = () => {
  const dispatch = useAppDispatch();
  const coborrowerExists = useSelector(getCoborrowerExists);
  const borrowerEmploymentDuration = useSelector(getBorrowerEmploymentDuration);
  const borrowerRecentEmployment = isRecentEmployment(borrowerEmploymentDuration);
  const coborrowerEmploymentDuration = useSelector(getCoborrowerEmploymentDuration);
  const sci = useSelector(getIntermediarySci);
  const coborrowerRecentEmployment = isRecentEmployment(coborrowerEmploymentDuration);
  const { application, customer, coborrower, common } = useSelector(getRootState);
  const loading = useSelector(getIsLoading);
  const history = useHistory();

  const [checkedAgreement, setCheckedAgreement] = useState<boolean>(useSelector(getAchDiscount));

  const {
    register,
    watch,
    handleSubmit,
    control,
    formState: { errors, isSubmitted },
  } = useForm<FormValues>({
    defaultValues: {
      borrowerSSN: '',
      coborrowerSSN: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const borrowerManualSSN = watch('borrowerSSN');
  const coborrowerManualSSN = watch('coborrowerSSN');

  const handleBack = () => {
    if (coborrowerExists) {
      dispatch(
        setCurrentStage({
          stage: StagesType.CoborrowerEmployment,
          step: coborrowerRecentEmployment
            ? EmploymentSteps.CoborrowerPreviousIncome
            : EmploymentSteps.CoborrowerIncome,
        }),
      );
      if (coborrowerRecentEmployment) {
        dispatch(
          setSteps([
            EmploymentSteps.CoborrowerEmploymentInfo,
            EmploymentSteps.CoborrowerIncome,
            EmploymentSteps.CoborrowerPreviousEmployment,
            EmploymentSteps.CoborrowerPreviousIncome,
          ]),
        );
      }
    } else {
      dispatch(
        setCurrentStage({
          stage: StagesType.Employment,
          step: borrowerRecentEmployment ? EmploymentSteps.BorrowerPreviousIncome : EmploymentSteps.BorrowerIncome,
        }),
      );
      if (borrowerRecentEmployment) {
        dispatch(
          setSteps([
            EmploymentSteps.BorrowerEmploymentInfo,
            EmploymentSteps.BorrowerIncome,
            EmploymentSteps.BorrowerPreviousEmployment,
            EmploymentSteps.BorrowerPreviousIncome,
          ]),
        );
      }
    }
  };

  const handleNext = async (data: FormValues) => {
    const softPull = await dispatch(
      runStrategy({
        strategyName: StrategyName.SoftCreditPull,
        strategyInputVariables: {
          [ApplicationVariables.Borrower4LastDigitsSSN]: `00000${data.borrowerSSN}`,
          [ApplicationVariables.Coborrower4LastDigitsSSN]: `00000${data.coborrowerSSN}`,
          [UserInformationKeys.FirstName]: customer.borrower_first_name,
          [UserInformationKeys.LastName]: customer.borrower_last_name,
          [CoborrowerInfoKeys.FirstName]: coborrower.coborrower_first_name,
          [CoborrowerInfoKeys.LastName]: coborrower.coborrower_last_name,
          [ApplicationVariables.CoborrowerApplication]: !!application.coborrower_application,
          [UserInformationKeys.DateOfBirth]: customer.borrower_date_of_birth,
          [CoborrowerInfoKeys.DateOfBirth]: coborrower.coborrower_date_of_birth,
          [ApplicationVariables.ApplicationCreateDate]: APPLICATION_CREATE_DATE,
          [ApplicationVariables.ApplicationDisplayId]: '99',
          [ApplicationVariables.PrimaryLoanPurpose]: application.primary_loan_purpose,
          [ApplicationVariables.SecondaryLoanPurpose]: application.secondary_loan_purpose,
          [ApplicationVariables.InstallationStreetAddress]: application.install_street_address,
          [ApplicationVariables.InstallationState]: application.install_state_or_province,
          [ApplicationVariables.InstallationCity]: application.install_city,
          [ApplicationVariables.InstallationZipCode]: application.install_zip_or_postal_code,
          [ApplicationVariables.MonthlyMortgagePayment]: application.monthly_mortgage_payment,
          [ApplicationVariables.LoanAmount]: application.loan_amount,
          [UserInformationKeys.Email]: customer?.borrower_email?.toLocaleLowerCase(),
          [UserInformationKeys.PhoneNumber]: customer.borrower_phone,
          [UserInformationKeys.StreetAddress]: application.borrower_contact_street_address,
          [UserInformationKeys.State]: application.borrower_contact_state_or_province,
          [UserInformationKeys.City]: application.borrower_contact_city,
          [UserInformationKeys.ZipOrPostalCode]: application.borrower_contact_zip_or_postal_code,
          [ApplicationVariables.BorrowerContactAddressSameAsInstall]:
            application.borrower_contact_address_same_as_install,
          [UserInformationKeys.OwnershipStatus]: application.borrower_ownership,
          [UserInformationKeys.CitizenshipStatus]: customer.borrower_citizenship_status,
          [ApplicationVariables.OwnershipTermLessOneYear]: application.ownership_term_less_1_year,
          [ApplicationVariables.BorrowerLengthOfEmployment]: application.borrower_length_of_employment,
          [ApplicationVariables.BorrowerEmploymentType]: application.borrower_employment_type,
          [ApplicationVariables.BorrowerJobTitle]: application.borrower_job_title,
          [ApplicationVariables.BorrowerCompanyName]: application.borrower_employment_company_name,
          [ApplicationVariables.BorrowerGrossIncome]: application.borrower_gross_annual_income,
          [ApplicationVariables.BorrowerPreviousGrossIncome]: application.borrower_previous_gross_annual_income,
          [ApplicationVariables.BorrowerPreviousEmploymentType]: application.borrower_previous_employment_type,
          [ApplicationVariables.BorrowerPreviousJobTitle]: application.borrower_previous_job_title,
          [ApplicationVariables.BorrowerPreviousCompanyName]: application.borrower_previous_employment_company_name,
          [CoborrowerInfoKeys.Email]: coborrower?.coborrower_email?.toLocaleLowerCase(),
          [CoborrowerInfoKeys.PhoneNumber]: coborrower.coborrower_phone,
          [CoborrowerInfoKeys.StreetAddress]: coborrower.coborrower_street_address,
          [CoborrowerInfoKeys.State]: coborrower.coborrower_state_or_province,
          [CoborrowerInfoKeys.City]: coborrower.coborrower_city,
          [CoborrowerInfoKeys.ZipOrPostalCode]: coborrower.coborrower_zip_or_postal_code,
          [CoborrowerInfoKeys.CoborrowerLives]: coborrower.coborrower_lives_at_the_install_address,
          [CoborrowerInfoKeys.CoborrowerOwnership]: coborrower.coborrower_ownership,
          [CoborrowerInfoKeys.CitizenshipStatus]: coborrower.coborrower_citizenship_status,
          [ApplicationVariables.CoborrowerLengthOfEmployment]: application.coborrower_length_of_employment,
          [ApplicationVariables.CoborrowerEmploymentType]: application.coborrower_employment_type,
          [ApplicationVariables.CoborrowerJobTitle]: application.coborrower_job_title,
          [ApplicationVariables.CoborrowerCompanyName]: application.coborrower_employment_company_name,
          [ApplicationVariables.CoborrowerGrossIncome]: application.coborrower_gross_annual_income,
          [ApplicationVariables.CoborrowerPreviousGrossIncome]: application.coborrower_previous_gross_annual_income,
          [ApplicationVariables.CoborrowerPreviousEmploymentType]: application.coborrower_previous_employment_type,
          [ApplicationVariables.CoborrowerPreviousJobTitle]: application.coborrower_previous_job_title,
          [ApplicationVariables.CoborrowerPreviousCompanyName]: application.coborrower_previous_employment_company_name,
        },
        skipDeclinePage: true,
      }),
    ).unwrap();
    if (
      softPull.declineReasons.includes(DeclineReasons.SoftPullIncorrectData) ||
      softPull.declineReasons.includes(DeclineReasons.BorrowerCreditReportIsFrozen) ||
      softPull.declineReasons.includes(DeclineReasons.CoborrowerCreditReportIsFrozen) ||
      (!softPull.passed && !softPull.declineReasons.length)
    ) {
      dispatch(setCurrentStage({ stage: StagesType.Decline }));
      return;
    }

    if (softPull.declined) {
      dispatch(
        setApplicationData({
          [ApplicationVariables.SoftPullResult]: softPull.outputVariables[ApplicationVariables.SoftPullResult],
        }),
      );

      if (!softPull.declined && !softPull.passed) {
        dispatch(setCurrentStage({ stage: StagesType.Decline }));
      }
    }

    dispatch(
      setApplicationData({
        [ApplicationVariables.ACHDiscount]: checkedAgreement,
        [ApplicationVariables.DischargedBankruptcyAlert]:
          softPull.outputVariables[ApplicationVariables.DischargedBankruptcyAlert],
        [ApplicationVariables.DischargedBankruptcyAlertCoborrower]:
          softPull.outputVariables[ApplicationVariables.DischargedBankruptcyAlertCoborrower] || '',
      }),
    );

    let coBorrowerVariables;
    const applicationVariables: Record<string, VariableValue> = {
      [ApplicationVariables.DischargedBankruptcyAlert]:
        softPull.outputVariables[ApplicationVariables.DischargedBankruptcyAlert],
      [ApplicationVariables.DischargedBankruptcyAlertCoborrower]:
      softPull.outputVariables[ApplicationVariables.DischargedBankruptcyAlertCoborrower] || '',
      [ApplicationVariables.Borrower4LastDigitsSSN]: `00000${data.borrowerSSN}`,
      [ApplicationVariables.TotalUnsecuredDebt]: softPull.outputVariables[ApplicationVariables.TotalUnsecuredDebt],
      [ApplicationVariables.PropertyTypeCheckRequired]: application.property_type_check_required,
      [ApplicationVariables.CheckRateAndPaymentsAgreement]: true,
      [ApplicationVariables.LoanAmount]: application.loan_amount,
      [ApplicationVariables.PrimaryLoanPurpose]: application.primary_loan_purpose,
      [ApplicationVariables.SecondaryLoanPurpose]: application.secondary_loan_purpose,
      [ApplicationVariables.InstallationStreetAddress]: application.install_street_address,
      [ApplicationVariables.InstallationState]: application.install_state_or_province,
      [ApplicationVariables.InstallationCity]: application.install_city,
      [ApplicationVariables.InstallationZipCode]: application.install_zip_or_postal_code,
      [ApplicationVariables.BorrowerContactAddressSameAsInstall]: application.borrower_contact_address_same_as_install,
      [ApplicationVariables.BorrowerContactStreetAddress]: application.borrower_contact_street_address,
      [ApplicationVariables.BorrowerContactState]: application.borrower_contact_state_or_province,
      [ApplicationVariables.BorrowerContactCity]: application.borrower_contact_city,
      [ApplicationVariables.BorrowerContactZipCode]: application.borrower_contact_zip_or_postal_code,
      [ApplicationVariables.BorrowerOwnership]: application.borrower_ownership,
      [UserInformationKeys.CitizenshipStatus]: customer.borrower_citizenship_status,
      [ApplicationVariables.OwnershipTermLessOneYear]: application.ownership_term_less_1_year,
      [ApplicationVariables.BorrowersEqualsOwners]: application.borrowers_equals_owners,
      [ApplicationVariables.OwnerInformationCorrect]: application.owner_information_correct,
      [ApplicationVariables.PropertyOwner1]: application.property_owner_1,
      [ApplicationVariables.PropertyOwner2]: application.property_owner_2,
      [ApplicationVariables.PropertyOwner3]: application.property_owner_3,
      [ApplicationVariables.BorrowerEmploymentType]: application.borrower_employment_type,
      [ApplicationVariables.BorrowerJobTitle]: application.borrower_job_title,
      [ApplicationVariables.BorrowerCompanyName]: application.borrower_employment_company_name,
      [ApplicationVariables.BorrowerGrossIncome]: application.borrower_gross_annual_income,
      [ApplicationVariables.BorrowerLengthOfEmployment]: application.borrower_length_of_employment,
      [ApplicationVariables.BorrowerPreviousEmploymentType]: application.borrower_previous_employment_type,
      [ApplicationVariables.BorrowerPreviousCompanyName]: application.borrower_previous_employment_company_name,
      [ApplicationVariables.BorrowerPreviousJobTitle]: application.borrower_previous_job_title,
      [ApplicationVariables.BorrowerPreviousLengthOfEmployment]: application.borrower_previous_length_of_employment,
      [ApplicationVariables.BorrowerPreviousGrossIncome]: application.borrower_previous_gross_annual_income,
      [ApplicationVariables.ACHDiscount]: checkedAgreement,
      [ApplicationVariables.MonthlyMortgagePayment]: application.monthly_mortgage_payment,
      [ApplicationVariables.SoftPullResult]: softPull.outputVariables[ApplicationVariables.SoftPullResult],
      [ApplicationVariables.ApplicationDti]: softPull.outputVariables[ApplicationVariables.ApplicationDti],
      [ApplicationVariables.ApplicationMonthlyDebtPayments]:
        softPull.outputVariables[ApplicationVariables.ApplicationMonthlyDebtPayments],
      [ApplicationVariables.ApplicationMonthlyIncomeAmount]:
        softPull.outputVariables[ApplicationVariables.ApplicationMonthlyIncomeAmount],
      [ApplicationVariables.BorrowerFicoScore]: softPull.outputVariables[ApplicationVariables.BorrowerFicoScore],
      [ApplicationVariables.BorrowerUnsecuredDebtPercentage]:
        softPull.outputVariables[ApplicationVariables.BorrowerUnsecuredDebtPercentage],
      [ApplicationVariables.AccountCreationComplete]: localStorage.getItem('borrowerToken') ? true : null,
      [ApplicationVariables.ParcelNumber]: application.parcel_number,
      [ApplicationVariables.AbbreviatedLegalDescription]: application.abbreviated_legal_description,
      [ApplicationVariables.PropertyCounty]: application.property_county,
      [ApplicationVariables.FipsCode]: application.fips_code,
      [ApplicationVariables.UCCFilingCost]: application.ucc_filing_cost,
      [ApplicationVariables.UnqualifiedIncome]: softPull.outputVariables[ApplicationVariables.UnqualifiedIncome],
      [ApplicationVariables.UnsecuredDebtPercentage]:
        softPull.outputVariables[ApplicationVariables.UnsecuredDebtPercentage],
      [ApplicationVariables.ApplicationMortgageAlert]:
        softPull.outputVariables[ApplicationVariables.ApplicationMortgageAlert],
      [ApplicationVariables.AvailableRevolving]: softPull.outputVariables[ApplicationVariables.AvailableRevolving],
      [ApplicationVariables.PropertyType]: application.property_type,
      [ApplicationVariables.LandUseDescription]: application.land_use_description,
      [ApplicationVariables.ApplicationCreateDate]: APPLICATION_CREATE_DATE,
      [ApplicationVariables.InstantDeclineReason1]:
        softPull.outputVariables[ApplicationVariables.InstantDeclineReason1],
      [ApplicationVariables.InstantDeclineReason2]:
        softPull.outputVariables[ApplicationVariables.InstantDeclineReason2],
      [ApplicationVariables.InstantDeclineReason3]:
        softPull.outputVariables[ApplicationVariables.InstantDeclineReason3],
      [ApplicationVariables.InstantDeclineReason4]:
        softPull.outputVariables[ApplicationVariables.InstantDeclineReason4],
      [ApplicationVariables.InstantDeclineReason5]:
        softPull.outputVariables[ApplicationVariables.InstantDeclineReason5],
      [ApplicationVariables.InstantDeclineReason6]:
        softPull.outputVariables[ApplicationVariables.InstantDeclineReason6],
      [ApplicationVariables.InstantDeclineReason7]:
        softPull.outputVariables[ApplicationVariables.InstantDeclineReason7],
      [ApplicationVariables.InstantDeclineReason8]:
        softPull.outputVariables[ApplicationVariables.InstantDeclineReason8],
      [ApplicationVariables.ManualReviewReason1]: softPull.outputVariables[ApplicationVariables.ManualReviewReason1],
      [ApplicationVariables.ManualReviewReason2]: softPull.outputVariables[ApplicationVariables.ManualReviewReason2],
      [ApplicationVariables.ManualReviewReason3]: softPull.outputVariables[ApplicationVariables.ManualReviewReason3],
      [ApplicationVariables.ManualReviewReason4]: softPull.outputVariables[ApplicationVariables.ManualReviewReason4],
      [ApplicationVariables.ManualReviewReason5]: softPull.outputVariables[ApplicationVariables.ManualReviewReason5],
      [ApplicationVariables.ManualReviewReason6]: softPull.outputVariables[ApplicationVariables.ManualReviewReason6],
      [ApplicationVariables.ManualReviewReason7]: softPull.outputVariables[ApplicationVariables.ManualReviewReason7],
      [ApplicationVariables.ManualReviewReason8]: softPull.outputVariables[ApplicationVariables.ManualReviewReason8],
      [ApplicationVariables.ManualReviewReason9]: softPull.outputVariables[ApplicationVariables.ManualReviewReason9],
      [ApplicationVariables.ManualReviewReason10]: softPull.outputVariables[ApplicationVariables.ManualReviewReason10],
      [ApplicationVariables.ManualReviewReason11]: softPull.outputVariables[ApplicationVariables.ManualReviewReason11],
      [ApplicationVariables.ManualReviewReason12]: softPull.outputVariables[ApplicationVariables.ManualReviewReason12],
      [ApplicationVariables.ManualReviewReason13]: softPull.outputVariables[ApplicationVariables.ManualReviewReason13],
      [ApplicationVariables.ReasonCode1BorrowerDescription]:
        softPull.outputVariables[ApplicationVariables.ReasonCode1BorrowerDescription],
      [ApplicationVariables.ReasonCode2BorrowerDescription]:
        softPull.outputVariables[ApplicationVariables.ReasonCode2BorrowerDescription],
      [ApplicationVariables.ReasonCode3BorrowerDescription]:
        softPull.outputVariables[ApplicationVariables.ReasonCode3BorrowerDescription],
      [ApplicationVariables.ReasonCode4BorrowerDescription]:
        softPull.outputVariables[ApplicationVariables.ReasonCode4BorrowerDescription],
    };
    if (application.coborrower_application) {
      Object.assign(applicationVariables, {
        [ApplicationVariables.Coborrower4LastDigitsSSN]: `00000${data.coborrowerSSN}`,
        [CoborrowerInfoKeys.CoborrowerOwnership]: coborrower.coborrower_ownership,
        [CoborrowerInfoKeys.CitizenshipStatus]: coborrower.coborrower_citizenship_status,
        [CoborrowerInfoKeys.CoborrowerLives]: coborrower.coborrower_lives_at_the_install_address,
        [ApplicationVariables.CoborrowerEmploymentType]: application.coborrower_employment_type,
        [ApplicationVariables.CoborrowerJobTitle]: application.coborrower_job_title,
        [ApplicationVariables.CoborrowerCompanyName]: application.coborrower_employment_company_name,
        [ApplicationVariables.CoborrowerGrossIncome]: application.coborrower_gross_annual_income,
        [ApplicationVariables.CoborrowerLengthOfEmployment]: application.coborrower_length_of_employment,
        [ApplicationVariables.CoborrowerPreviousEmploymentType]: application.coborrower_previous_employment_type,
        [ApplicationVariables.CoborrowerPreviousCompanyName]: application.coborrower_previous_employment_company_name,
        [ApplicationVariables.CoborrowerPreviousJobTitle]: application.coborrower_previous_job_title,
        [ApplicationVariables.CoborrowerPreviousLengthOfEmployment]:
          application.coborrower_previous_length_of_employment,
        [ApplicationVariables.CoborrowerPreviousGrossIncome]: application.coborrower_previous_gross_annual_income,
        [ApplicationVariables.CoborrowerAuthorization]: application.coborrower_authorization,
        [ApplicationVariables.CoborrowerFicoScore]: softPull.outputVariables[ApplicationVariables.CoborrowerFicoScore],
        [ApplicationVariables.CoborrowerUnsecuredDebtPercentage]:
          softPull.outputVariables[ApplicationVariables.CoborrowerUnsecuredDebtPercentage],
        [ApplicationVariables.ReasonCode1CoborrowerDescription]:
          softPull.outputVariables[ApplicationVariables.ReasonCode1CoborrowerDescription],
        [ApplicationVariables.ReasonCode2CoborrowerDescription]:
          softPull.outputVariables[ApplicationVariables.ReasonCode2CoborrowerDescription],
        [ApplicationVariables.ReasonCode3CoborrowerDescription]:
          softPull.outputVariables[ApplicationVariables.ReasonCode3CoborrowerDescription],
        [ApplicationVariables.ReasonCode4CoborrowerDescription]:
          softPull.outputVariables[ApplicationVariables.ReasonCode4CoborrowerDescription],
      });
      coBorrowerVariables = {
        [UserInformationKeys.FirstName]: coborrower.coborrower_first_name,
        [UserInformationKeys.LastName]: coborrower.coborrower_last_name,
        [UserInformationKeys.Email]: coborrower.coborrower_email.toLocaleLowerCase(),
        [UserInformationKeys.PhoneNumber]: coborrower.coborrower_phone,
        [UserInformationKeys.DateOfBirth]: coborrower.coborrower_date_of_birth,
        [UserInformationKeys.StreetAddress]: coborrower.coborrower_street_address,
        [UserInformationKeys.State]: coborrower.coborrower_state_or_province,
        [UserInformationKeys.City]: coborrower.coborrower_city,
        [UserInformationKeys.ZipOrPostalCode]: coborrower.coborrower_zip_or_postal_code,
      };
    }

    const result = await dispatch(
      submitApplication({
        isRejected: softPull.declined,
        borrowerVariables: {
          [UserInformationKeys.FirstName]: customer.borrower_first_name,
          [UserInformationKeys.LastName]: customer.borrower_last_name,
          [UserInformationKeys.Email]: customer.borrower_email.toLocaleLowerCase(),
          [UserInformationKeys.PhoneNumber]: customer.borrower_phone,
          [UserInformationKeys.DateOfBirth]: customer.borrower_date_of_birth,
          [UserInformationKeys.StreetAddress]: application.borrower_contact_street_address,
          [UserInformationKeys.State]: application.borrower_contact_state_or_province,
          [UserInformationKeys.City]: application.borrower_contact_city,
          [UserInformationKeys.ZipOrPostalCode]: application.borrower_contact_zip_or_postal_code,
        },
        sci: sci || undefined,
        coBorrowerVariables,
        applicationVariables,
        softPullDecisionId: softPull.decisionId,
      }),
    ).unwrap();

    const files = [];

    if (common[CommonTypes.DataTreeLink]) {
      const dataTreeDocument = await dispatch(downloadDataTreeDocument(common[CommonTypes.DataTreeLink])).unwrap();
      if (dataTreeDocument) {
        files.push(createNewPdfFromBlob(dataTreeDocument, 'Data_Tree_Document'));
      }
    }

    if (
      result &&
      (result[DeclineTypes.BorrowerInvalidEmailForSSN] ||
        result[DeclineTypes.CoborrowerInvalidEmailForSSN] ||
        result[DeclineTypes.BorrowerEmailAlreadyInUse] ||
        result[DeclineTypes.CoborrowerEmailAlreadyInUse])
    ) {
      await dispatch(setCurrentStage({ stage: StagesType.Decline }));
      return;
    }

    if (result?.locked) {
      await dispatch(setCurrentStage({ stage: StagesType.ContactUs }));
      return;
    }

    if (result && result.success === false) {
      history.replace(`${Routes.Apply}?applicationId=${result.applicationId}`);
      return;
    }

    await dispatch(
      uploadDocuments({
        applicationId: result?.applicationId,
        isPdfFile: true,
        files,
      }),
    );

    if (softPull.declined) {
      await dispatch(setCurrentStage({ stage: StagesType.Decline }));
      return;
    }
    await dispatch(setCurrentStage({ stage: StagesType.Offers }));
  };

  const isDisabled =
    loading ||
    !borrowerManualSSN ||
    (!!coborrowerExists && !coborrowerManualSSN) ||
    (isSubmitted && isExistErrors(errors));

  useEffect(() => {
    register('borrowerSSN', {
      required: getRequiredErrorMessage('Borrower SSN'),
      minLength: {
        value: SSN_LENGTH,
        message: getInvalidSSNLength('Borrower SSN'),
      },
    });
    if (coborrowerExists) {
      register('coborrowerSSN', {
        required: getRequiredErrorMessage('Co-borrower SSN'),
        minLength: {
          value: SSN_LENGTH,
          message: getInvalidSSNLength('Co-borrower SSN'),
        },
      });
    }
  }, [borrowerManualSSN, coborrowerManualSSN]);

  return (
    <PageLayout title="Check your rate and payment options">
      <FormContainer onSubmit={handleSubmit(handleNext)}>
        <p>
          Ready to see what you qualify for, in terms of interest rates and estimated monthly payments? Click the “Check
          rate and payments” button below, and don’t worry... this will not affect your credit score!
        </p>
        <div className={pages.textInputLabel}>
          For verification, please provide last 4 digits of Social Security Number.
        </div>
        <div className={cx(pages.flexInputContainer, pages.inputContainer)}>
          <NumberInput
            name="borrowerSSN"
            control={control}
            inputContainerClassName={pages.inputChildContainer}
            label="Borrower last 4 digits SSN"
            value={borrowerManualSSN}
            errorMessage={errors.borrowerSSN?.message}
            maxLength={SSN_LENGTH}
            allowLeadingZeros
          />
          {coborrowerExists && (
            <NumberInput
              name="coborrowerSSN"
              control={control}
              inputContainerClassName={pages.inputChildContainer}
              label="Co-borrower last 4 digits SSN"
              value={coborrowerManualSSN}
              errorMessage={errors.coborrowerSSN?.message}
              maxLength={SSN_LENGTH}
              allowLeadingZeros
            />
          )}
        </div>
        <p className={styles.agreementNote}>
          By clicking the “Check rate and payments” button and continuing with the application, you understand and agree
          that this constitutes “written instructions” to Community 1st Credit Union as described in the Fair Credit
          Reporting Act. You thereby authorize Community 1st Credit Union to obtain your personal credit and other
          information from consumer reporting agencies or other third parties. You further authorize Community 1st
          Credit Union to attempt to pre-qualify you for any loan products available through ezSolarLoan. Clicking the
          button below will initiate a “soft credit pull” and not affect your credit score, but if you follow through
          with getting a loan, there will be a “hard credit pull” inquiry prior to finalization.
        </p>
        <Checkbox
          checked={checkedAgreement}
          onChange={() => setCheckedAgreement(!checkedAgreement)}
          label="I agree to set up ACH electronic automatic payments in order to enjoy a 0.25% discount on the interest rate"
        />
        <NavigationButtonWrapper>
          <BackButton onClick={handleBack} />
          <NextButton
            type="submit"
            title="Check Rate and Payments"
            inactive={isDisabled}
            className={styles.nextButton}
            loading={loading}
          />
        </NavigationButtonWrapper>
      </FormContainer>
    </PageLayout>
  );
};
export default CheckRateAndPayments;
