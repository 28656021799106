import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import NavigationCards from 'components/NavigationCards';
import { NavigationCard } from 'components/NavigationCards/NavigationCards';
import PageLoader from 'components/PageLoader';
import { ApplicationState } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import {
  getApplicationId,
  getApplicationStatus,
  getApplicationWithdrawn,
  getBorrowerSigningDocumentsComplete,
  getCoborrowerExists,
  getCoborrowerSigningDocumentsComplete,
  getConfirmProjectDetails,
  getEzsolarLoanFinal50Disbursed,
  getEzsolarLoanFullAmountDisbursed,
  getFinal50Authorized,
  getFinal50Requested,
  getFirst50Authorized,
  getFirst50Requested,
  getFullAmountAuthorized,
  getFullAmountRequested,
  getIsApplicationFetch,
  getLoanTermList,
  getProjectDetailsAndSignedContractComplete,
  getSoftPullResult,
} from 'selectors';
import { getApplication, updateApplication } from 'thunks';
import { ApplicationStatus, Routes } from 'types';
import { getCurrentDate } from 'utils/dateHelper';
import { getFriendlyStatus } from 'utils/getFriendlyStatus';
import { notify } from 'utils/notificationHelper';

import styles from './BorrowerDashboard.module.scss';
import { NotifyTypes } from './types';

const getBorrowerDashboardCards = (
  isProjectConfirmed: boolean,
  isPaymentsDisabled: boolean,
  isDocumentsDisabled: boolean,
): NavigationCard[] => [
  {
    title: 'Request and sign your loan documents',
    description: 'Review and sign your loan documents electronically',
    url: Routes.BorrowerSigning,
    disabled: isDocumentsDisabled,
  },
  {
    title: 'Update your information',
    description: 'Finish your application, upload requested documents or make any changes that you need',
    url: Routes.BorrowerDetails,
  },
  {
    title: 'Confirm solar project and contract',
    description:
      'Before any payments can be sent to your solar company, you must confirm the project details and sales contract',
    url: Routes.BorrowerProject,
    disabled: isProjectConfirmed,
  },
  {
    title: 'Authorize payments to solar company',
    description: 'Review and authorize requests for payment submitted by your solar company',
    url: Routes.BorrowerPayments,
    disabled: isPaymentsDisabled,
  },
];

const getSubTitle = (friendlyStatus: string, projectAndContract: boolean | null) => {
  switch (friendlyStatus) {
    case 'Received':
      return 'We have received your application! Click the card below if you need to update your information, upload documents such as Verification of Income or make any changes.  Stay tuned to your email and text messages for next steps and more information.';
    case 'Pre-approved':
      return 'Your application is pre-approved! We may need to confirm a couple of things before approving it fully.  Stay tuned to your email and text messages for next steps and more informaiton.  In the meantime, you may use the card below if you need to make any updates or changes or upload any documents.';
    case 'Approved':
      return 'Your application is approved! To move forward, use the cards below to request and sign your loan documents or make updates to your information in the application.';
    case 'Signed':
      return projectAndContract
        ? 'Your loan documents are signed, thanks for doing that, and for confirming the details of your solar project.   Use the cards below to update your information and to authorize payment requests from your solar company.'
        : 'Your loan documents are signed, thanks for doing that. Use the cards below to update your information, confirm the details of your solar project, and to authorize payment requests from your solar company.';
    case 'Funding':
      return 'Your application is in the funding stage. Use the cards below to update your information and to authorize payment requests from your solar company.';
    default:
      break;
  }
};

const BorrowerDashboard = () => {
  const dispatch = useDispatch();
  const applicationStatus = useSelector(getApplicationStatus);
  const first50Requested = useSelector(getFirst50Requested);
  const final50Requested = useSelector(getFinal50Requested);
  const isFetchApplication = useSelector(getIsApplicationFetch);
  const coborrowerSigningDocumentsComplete = useSelector(getCoborrowerSigningDocumentsComplete);
  const borrowerSigningDocumentsComplete = useSelector(getBorrowerSigningDocumentsComplete);
  const fullAmountRequested = useSelector(getFullAmountRequested);
  const first50Authorized = useSelector(getFirst50Authorized);
  const final50Authorized = useSelector(getFinal50Authorized);
  const fullAmountAuthorized = useSelector(getFullAmountAuthorized);
  const projectAndContractConfirmed = useSelector(getConfirmProjectDetails);
  const jointApplication = useSelector(getCoborrowerExists);
  const loanTermList = useSelector(getLoanTermList);
  const applicationId = useSelector(getApplicationId);

  const location = useLocation();
  const application = {
    status: applicationStatus,
    coborrower_application: useSelector(getCoborrowerExists),
    [ApplicationVariables.ApplicationWithdrawn]: useSelector(getApplicationWithdrawn),
    [ApplicationVariables.Final50Authorized]: final50Authorized,
    [ApplicationVariables.FullAmountAuthorized]: fullAmountAuthorized,
    [ApplicationVariables.First50Authorized]: first50Authorized,
    [ApplicationVariables.BorrowerSigningDocumentsComplete]: borrowerSigningDocumentsComplete,
    [ApplicationVariables.CoborrowerSigningDocumentsComplete]: coborrowerSigningDocumentsComplete,
    [ApplicationVariables.SoftPullResult]: useSelector(getSoftPullResult),
    [ApplicationVariables.EzsolarLoanFinal50Disbursed]: useSelector(getEzsolarLoanFinal50Disbursed),
    [ApplicationVariables.EzsolarLoanFullAmountDisbursed]: useSelector(getEzsolarLoanFullAmountDisbursed),
  };

  const isDocumentsDisabled =
    !loanTermList ||
    !!borrowerSigningDocumentsComplete ||
    (applicationStatus !== ApplicationStatus.FinishingApplication &&
      applicationStatus !== ApplicationStatus.SigningDocuments);

  const isProjectConfirmed =
    !useSelector(getProjectDetailsAndSignedContractComplete) ||
    projectAndContractConfirmed !== null ||
    !borrowerSigningDocumentsComplete ||
    (!!jointApplication && !coborrowerSigningDocumentsComplete);

  const isPaymentsDisabled = (() => {
    if (!projectAndContractConfirmed) return true;
    if (fullAmountAuthorized) return true;
    if (first50Requested && !final50Requested) return !!first50Authorized;
    if (first50Requested && final50Requested) return !!final50Authorized;
    return !first50Requested && !fullAmountRequested;
  })();

  const getApplicationById = async () => {
    const params = new URLSearchParams(location.search);
    if (params.get('event') === 'signing_complete' && !borrowerSigningDocumentsComplete) {
      await dispatch(
        updateApplication({
          applicationId,
          applicationVariables: {
            [ApplicationVariables.BorrowerSigningDocumentsComplete]: true,
            [ApplicationVariables.BorrowerSigningDocumentsCompleteDate]: getCurrentDate(),
          },
        }),
      );
      await dispatch(
        getApplication({
          id: applicationId,
        }),
      );
      notify({ notification: NotifyTypes.DocumentsSigned });
    }
  };

  useEffect(() => {
    getApplicationById();
  }, [location]);

  return (
    <>
      {isFetchApplication && <PageLoader />}
      <p className={styles.subTitle}>
        {getSubTitle(getFriendlyStatus(application as ApplicationState), projectAndContractConfirmed)}
      </p>
      <NavigationCards items={getBorrowerDashboardCards(isProjectConfirmed, isPaymentsDisabled, isDocumentsDisabled)} />
    </>
  );
};

export default withPortalLayout(BorrowerDashboard);
