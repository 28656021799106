export enum QuestionType {
  Yes = 'yes',
  No = 'no',
}

export enum DateType {
  MonthYear = 'month-year',
  MonthDayYear = 'month-day-year',
}

export enum MaskedMaxLengthType {
  Phone = 16,
  SocialSecurityNumber = 11,
  Rate = 4,
}

const FULL_DATE_LENGTH = 8;
const MONTH_YEAR_LENGTH = 6;

const DEMICAL_PLACE = 3;

export const PHONE_LENGTH = 10;
export const SOCIAL_SECURITY_LENGTH = 9;
export const PERCENTAGE_RATE_LENGTH = 5;

export const convertMonetary = (value: string | number | null) => {
  if (value === null) return '';
  const monetary = Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
  }).format(Number(value));
  return monetary[monetary.length - 1] === '0' && monetary[monetary.length - 2] === '0'
    ? monetary.slice(0, monetary.indexOf('.'))
    : monetary;
};

export const convertValue = (value: string | number | null): string => {
  if (value === null) {
    return '';
  }

  if (!value) {
    return '';
  }

  return `${!value.toString().replace('$', '') ? '' : '$'}${value
    .toString()
    .replace(/\D/g, '')
    .split('')
    .reverse()
    .map((digit, index) => (index !== 0 && index % DEMICAL_PLACE === 0 ? `${digit},` : digit))
    .reverse()
    .join('')}`;
};

export const convertPercent = (value: string | Number): string => {
  const maskedValue = value.toString().replace('%', '');

  if (!maskedValue) {
    return '';
  }

  return `${maskedValue}%`;
};

export const convertDate = (value: string, dateType: DateType): string => {
  const isMothYearType = dateType === DateType.MonthYear;
  const cleanValue = value.replace(/\D/g, '');
  const dateRegex = isMothYearType ? /(\d{0,2})(\d{0,4})/ : /(\d{0,2})(\d{0,2})(\d{0,4})/;
  const dateLength = isMothYearType ? MONTH_YEAR_LENGTH : FULL_DATE_LENGTH;

  return cleanValue.substr(0, dateLength).replace(dateRegex, (_match, month, day, year) => {
    const dateParts = year ? [month, day, year] : [month, day];

    return dateParts.filter((part) => !!part).join(' / ');
  });
};

export const convertPhone = (phone: string | undefined): string => {
  const phoneRegex = /(\d{3})(\d{0,3})(\d{0,4})/;

  if (!phone) {
    return '';
  }

  return phone
    .toString()
    .replace(phone[1], '')
    .replace(/\D/g, '')
    .substr(0, PHONE_LENGTH)
    .replace(phoneRegex, (_match, areaCode, firstPart, secondPart) => {
      const formattedAreaCode = firstPart ? `(${areaCode})` : areaCode;
      const phoneParts = [firstPart, secondPart].filter((part) => !!part).join(' - ');
      const result = [formattedAreaCode];

      if (phoneParts) {
        result.push(` ${phoneParts}`);
      }

      return result.join('');
    });
};

export const convertPhoneToNumber = (phone: string | number | null | undefined) => {
  if (!phone) {
    return null;
  }

  return Number(phone.toString().replace(/\D/g, '').substr(0, PHONE_LENGTH));
};

export const convertPercentValue = (value: string | Number): string =>
  `${value.toString().replace('%', '')}${value ? '%' : ''}`;

export const convertSocialSecurity = (value: string | number): string => {
  const socialSecurityRegex = /(\d{3})(\d{0,2})(\d{0,4})/;

  if (!value) {
    return '';
  }

  return value
    .toString()
    .replace(/\D/g, '')
    .substr(0, SOCIAL_SECURITY_LENGTH)
    .replace(socialSecurityRegex, (_match, firstPart, secondPart, thirdPart) => {
      const parts = [firstPart, secondPart, thirdPart];

      return parts.filter((part) => !!part).join('-');
    });
};

export const convertQuestionAnswerToBoolean = (answer: string | boolean | null) => {
  switch (answer) {
    case QuestionType.Yes:
      return true;
    case QuestionType.No:
      return false;
    default:
      return null;
  }
};

export const convertBooleanToQuestionType = (answer: boolean | undefined | null | string) => {
  switch (answer) {
    case true:
      return QuestionType.Yes;
    case false:
      return QuestionType.No;
    default:
      return null;
  }
};

export const deleteDoubleSpace = (value: string | number) => {
  if (!value) {
    return '';
  }

  return value
    .toString()
    .split(' ')
    .filter((item) => (item === '' ? null : item))
    .join(' ');
};

export const deleteSpaceInStart = (value: string | number | undefined | null) => {
  if (!value) {
    return '';
  }

  return value.toString().trimStart();
};

export const convertFileSize = (bytes: number, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
};

export default {
  deleteSpaceInStart,
  convertBooleanToQuestionType,
  convertQuestionAnswerToBoolean,
  convertSocialSecurity,
  convertPercentValue,
  convertPhoneToNumber,
  convertPhone,
  convertDate,
  convertPercent,
  convertMonetary,
  convertValue,
  convertFileSize,
};
