import React from 'react';

import styles from './PageLoader.module.scss';

const PageLoader = () => (
  <div className={styles.loaderWrapper}>
    <div className={styles.loaderContainer}>
      <div className={styles.loaderElement} />
      <div className={styles.loaderElement} />
      <div className={styles.loaderElement} />
      <div className={styles.loaderElement} />
    </div>
  </div>
);

export default PageLoader;
