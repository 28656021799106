import { VariableValue, StrategyName } from 'types';

import { HttpClient } from './Api';

export interface StrategiesApi {
  run(
    applicationId: string | null,
    inputVariables: Record<string, VariableValue>,
    strategyName: StrategyName,
    status: string,
  ): Promise<Record<string, any>>;
}

export default class StrategiesRestApi extends HttpClient implements StrategiesApi {
  public async run(
    applicationId: string | null,
    inputVariables: Record<string, VariableValue>,
    strategyName: StrategyName,
    status: string,
  ) {
    return this.instance
      .post<Record<string, any>>('/api/strategies/run', {
        applicationId,
        inputVariables,
        strategyName,
        status,
      })
      .then((res) => res.data);
  }
}
