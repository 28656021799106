/* eslint-disable no-console */
import AccountApi from 'api/AccountApi';
import ApplicationApi from 'api/ApplicationApi';
import BorrowerApi from 'api/BorrowerApi';
import DataTreeApi from 'api/DataTreeApi';
import DocusignApi from 'api/DocusignApi';
import IntermediaryApi from 'api/IntermediaryApi';
import StrategiesRestApi from 'api/StrategiesApi';
import SunApi from 'api/SunApi';

import { createAcceptInviteThunk } from './acceptInvite';
import { createCreateAccountThunk } from './createAcount';
import { createCreateOrUpdateCoborrowerThunk } from './createCoborrower';
import { createSunThunk } from './createSun';
import { createDownloadDataTreeThunk } from './downloadDataTreeDocument';
import { createDownloadDocumentThunk } from './downloadDocuments';
import { createFetchDataForBorrowerThunk } from './fetchDataForBorrower';
import { createForgotBorrowerPasswordThunk } from './forgotBorrowerPassword';
import { createForgotContractorPasswordThunk } from './forgotContractorPassword';
import { createGetApplicationThunk } from './getApplication';
import { createGetApplicationByDisplayIdThunk } from './getApplicationByDisplayId';
import { createGetApplicationsThunk } from './getApplications';
import { createGetBorrowerThunk } from './getBorrower';
import { createGetBorrowerAccountThunk } from './getBorrowerAccount';
import { createGetBorrowerByTokenThunk } from './getBorrowerAccountByToken';
import {
  createGetIntermediaryByIdThunk,
  createGetIntermediaryByEmailThunk,
  createGetIntermediaryBySciCodeThunk,
} from './getIntermediary';
import { createGetInviteDetailsThunk } from './getInviteDetails';
import { createGetPropertyOwnersThunk } from './getPropertyOwners';
import { createLoginBorrowerThunk } from './loginBorrower';
import { createLoginContractorThunk } from './loginContractor';
import { createLogoutBorrowerThunk } from './logoutBorrower';
import { createLogoutContractorThunk } from './logoutContractor';
import {
  createRequestEmailVerificationForBorrowerThunk,
  createRequestEmailVerificationForContractorThunk,
} from './requestEmailVerification';
import {
  createRequestPhoneVerificationForContractorThunk,
  createRequestPhoneVerificationForBorrowerThunk,
} from './requestPhoneVerification';
import { createResetBorrowerPasswordThunk } from './resetBorrowerPassword';
import { createResetContractorPasswordThunk } from './resetContractorPassword';
import { createRunApplicationDecisionThunk } from './runApplicationDecision';
import { createRunStrategyThunk } from './runStrategy';
import { createSendInviteThunk } from './sendInvite';
import { createSendLockedThunk } from './sendLockedNotification';
import { createSignLoanDocumentsThunk } from './signLoanDocuments';
import { createSubmitApplicationThunk } from './submitApplication';
import { createUpdateApplicationThunk } from './updateApplication';
import { createUploadDocumentsThunk } from './uploadDocuments';
import { createVerifyEmailForBorrowerThunk, createVerifyEmailForContractorThunk } from './verifyEmail';
import { createVerifyPhoneForBorrowerThunk, createVerifyPhoneForContractorThunk } from './verifyPhoneNumber';

const apiUrl = process.env.REACT_APP_API_URL as string;

const strategiesApi = new StrategiesRestApi(apiUrl);
const dataTreeApi = new DataTreeApi(apiUrl);
const applicationApi = new ApplicationApi(apiUrl);
const accountApi = new AccountApi(apiUrl);
const intermediaryApi = new IntermediaryApi(apiUrl);
const borrowerApi = new BorrowerApi(apiUrl);
const sunApi = new SunApi(apiUrl);
const docusignApi = new DocusignApi(apiUrl);

export const requestPhoneVerificationForBorrower = createRequestPhoneVerificationForBorrowerThunk(accountApi);
export const requestPhoneVerificationForContractor = createRequestPhoneVerificationForContractorThunk(accountApi);
export const verifyPhoneForBorrower = createVerifyPhoneForBorrowerThunk(accountApi);
export const verifyPhoneForContractor = createVerifyPhoneForContractorThunk(accountApi);
export const runStrategy = createRunStrategyThunk(strategiesApi);
export const runApplicationDecision = createRunApplicationDecisionThunk(applicationApi);
export const getPropertyOwners = createGetPropertyOwnersThunk(dataTreeApi);
export const submitApplication = createSubmitApplicationThunk(applicationApi);
export const updateApplication = createUpdateApplicationThunk(applicationApi);
export const createAccount = createCreateAccountThunk(accountApi);
export const verifyEmailForBorrower = createVerifyEmailForBorrowerThunk(accountApi);
export const verifyEmailForContractor = createVerifyEmailForContractorThunk(accountApi);
export const resendEmailCodeForBorrower = createRequestEmailVerificationForBorrowerThunk(accountApi);
export const resendEmailCodeForContractor = createRequestEmailVerificationForContractorThunk(accountApi);
export const getIntermediaryBySciCode = createGetIntermediaryBySciCodeThunk(intermediaryApi);
export const getIntermediaryById = createGetIntermediaryByIdThunk(intermediaryApi);
export const getIntermediaryByEmail = createGetIntermediaryByEmailThunk(intermediaryApi);
export const uploadDocuments = createUploadDocumentsThunk(applicationApi);
export const downloadDocument = createDownloadDocumentThunk(applicationApi);
export const getApplications = createGetApplicationsThunk(applicationApi);
export const getApplicationByDisplayId = createGetApplicationByDisplayIdThunk(applicationApi);
export const getApplication = createGetApplicationThunk(applicationApi);
export const sendInvite = createSendInviteThunk(applicationApi);
export const getBorrower = createGetBorrowerThunk(borrowerApi);
export const createOrUpdateCoborrower = createCreateOrUpdateCoborrowerThunk(borrowerApi);
export const fetchDataForBorrower = createFetchDataForBorrowerThunk(borrowerApi, applicationApi);
export const loginBorrower = createLoginBorrowerThunk(accountApi);
export const loginContractor = createLoginContractorThunk(accountApi);
export const logoutBorrower = createLogoutBorrowerThunk(accountApi);
export const logoutContractor = createLogoutContractorThunk(accountApi);
export const forgotBorrowerPassword = createForgotBorrowerPasswordThunk(accountApi);
export const forgotContractorPassword = createForgotContractorPasswordThunk(accountApi);
export const changeBorrowerPassword = createResetBorrowerPasswordThunk(accountApi);
export const changeContractorPassword = createResetContractorPasswordThunk(accountApi);
export const getBorrowerAccount = createGetBorrowerAccountThunk(accountApi);
export const createSun = createSunThunk(sunApi);
export const sendLockedNotifications = createSendLockedThunk(applicationApi);
export const signLoanDocuments = createSignLoanDocumentsThunk(docusignApi);
export const getInviteDetails = createGetInviteDetailsThunk(accountApi);
export const acceptInvite = createAcceptInviteThunk(accountApi);
export const downloadDataTreeDocument = createDownloadDataTreeThunk(dataTreeApi);
export const getBorrowerByToken = createGetBorrowerByTokenThunk(accountApi);
