import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';

import { ForgotPasswordProps } from './forgotBorrowerPassword';

export const createForgotContractorPasswordThunk = (accountApi: AccountApi) =>
  createAsyncThunk('account/contractorForgot', async ({ email }: ForgotPasswordProps, { rejectWithValue }) => {
    try {
      const result = await accountApi.requestContractorResetPassword(email);
      if (result.success) {
        return true;
      }
      rejectWithValue(null);
    } catch (e) {
      rejectWithValue(null);
    }
  });
