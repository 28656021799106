import { createAsyncThunk } from '@reduxjs/toolkit';

import ApplicationApi from 'api/ApplicationApi';
import { setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { setCoborrowerData } from 'handlers/coborrower';
import { CoborrowerInfoKeys } from 'handlers/coborrower/types';
import { setCustomerData } from 'handlers/customer';
import { UserInformationKeys } from 'handlers/customer/types';
import { setIntermediaryData } from 'handlers/intermediary';
import { LoadingType, setLoadingState } from 'handlers/loading';
import { ApplicationStatus } from 'types';

interface GetApplicationProps {
  id: string;
}

export const createGetApplicationThunk = (applicationApi: ApplicationApi) =>
  createAsyncThunk('application/get', async ({ id }: GetApplicationProps, { dispatch }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: true }));
      const application = await applicationApi.getApplicationById(id);
      dispatch(
        setApplicationData({
          applicationId: id,
          status: application.status.name as ApplicationStatus,
          coborrowerId: application.coborrowerIds[0],
          ...application.variables,
        }),
      );
      dispatch(
        setIntermediaryData({
          intermediary_email: application.variables[ApplicationVariables.IntermediaryEmail] as string,
          intermediary_phone: application.variables[ApplicationVariables.IntermediaryPhone] as string,
          intermediary_name: application.variables[ApplicationVariables.IntermdediaryName] as string,
          intermediary_id_number: application.variables[ApplicationVariables.IntermediaryId] as string,
        }),
      );
      dispatch(
        setCustomerData({
          customerId: application.borrowerId,
          [UserInformationKeys.Email]: application.variables[UserInformationKeys.Email],
          [UserInformationKeys.PhoneNumber]: application.variables[UserInformationKeys.PhoneNumber],
          [UserInformationKeys.FirstName]: application.variables[UserInformationKeys.FirstName],
          [UserInformationKeys.LastName]: application.variables[UserInformationKeys.LastName],
          [UserInformationKeys.DateOfBirth]: application.variables[UserInformationKeys.DateOfBirth],
          [UserInformationKeys.CitizenshipStatus]: application.variables[UserInformationKeys.CitizenshipStatus],
          [UserInformationKeys.PrivacyAndTermsSigned]: application.variables[UserInformationKeys.PrivacyAndTermsSigned],
          [UserInformationKeys.PolicyAgreement]: application.variables[UserInformationKeys.PolicyAgreement],
          [UserInformationKeys.SSN]: application.variables[UserInformationKeys.SSN],
          [UserInformationKeys.ZipOrPostalCode]: application.variables[UserInformationKeys.ZipOrPostalCode],
          [UserInformationKeys.State]: application.variables[UserInformationKeys.State],
          [UserInformationKeys.City]: application.variables[UserInformationKeys.City],
          [UserInformationKeys.StreetAddress]: application.variables[UserInformationKeys.StreetAddress],
        }),
      );
      if (application.coborrowerIds[0]) {
        dispatch(
          setCoborrowerData({
            [CoborrowerInfoKeys.FirstName]: application.variables[CoborrowerInfoKeys.FirstName],
            [CoborrowerInfoKeys.LastName]: application.variables[CoborrowerInfoKeys.LastName],
            [CoborrowerInfoKeys.Email]: application.variables[CoborrowerInfoKeys.Email],
            [CoborrowerInfoKeys.PhoneNumber]: application.variables[CoborrowerInfoKeys.PhoneNumber],
            [CoborrowerInfoKeys.StreetAddress]: application.variables[CoborrowerInfoKeys.StreetAddress],
            [CoborrowerInfoKeys.City]: application.variables[CoborrowerInfoKeys.City],
            [CoborrowerInfoKeys.State]: application.variables[CoborrowerInfoKeys.State],
            [CoborrowerInfoKeys.ZipOrPostalCode]: application.variables[CoborrowerInfoKeys.ZipOrPostalCode],
            [CoborrowerInfoKeys.DateOfBirth]: application.variables[CoborrowerInfoKeys.DateOfBirth],
            [CoborrowerInfoKeys.CoborrowerLives]: application.variables[CoborrowerInfoKeys.CoborrowerLives],
            [CoborrowerInfoKeys.CoborrowerOwnership]: application.variables[CoborrowerInfoKeys.CoborrowerOwnership],
            [CoborrowerInfoKeys.CitizenshipStatus]: application.variables[CoborrowerInfoKeys.CitizenshipStatus],
            [CoborrowerInfoKeys.SSN]: application.variables[CoborrowerInfoKeys.SSN],
          }),
        );
      }
      return application;
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: false }));
    }
  });
