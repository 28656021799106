import React, { useEffect } from 'react';

import classNames from 'classnames/bind';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import Checkbox from 'common/components/Checkbox';
import Link from 'common/components/Link';
import TextInput from 'common/components/TextInput';
import InputErrorMessage from 'common/components/TextInput/InputErrorMessage';
import DatePicker from 'components/DatePicker';
import { OptionType } from 'components/DropDown/DropDown';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { getInvalidDateErrorMessage, getRequiredErrorMessage } from 'errors/errors';
import { backStep } from 'handlers/actions';
import { setCustomerData } from 'handlers/customer';
import { UserInformationKeys } from 'handlers/customer/types';
import { nextStep } from 'handlers/steps';
import { CitizenshipStatus } from 'layouts';
import styles from 'pages/pages.module.scss';
import {
  getBorrowerCitizenshipStatus,
  getBorrowerDateOfBirth,
  getBorrowerFirstName,
  getBorrowerLastName,
  getPrivacyAndTermsSigned,
} from 'selectors';
import { deleteDoubleSpace, deleteSpaceInStart } from 'utils/formats';
import { specialCharactersiInputProhibition } from 'utils/inputProhibition';
import { isExistErrors, isValidDate } from 'utils/validationHelper';

const cx = classNames.bind(styles);

const AgreementLabel = () => (
  <span>
    I agree to the{' '}
    <Link href="https://ezsolarloan.com/privacy-policy/" target="_blank">
      Privacy Policy
    </Link>
    , and{' '}
    <Link href="https://ezsolarloan.com/terms-and-conditions/" target="_blank">
      Terms of Use
    </Link>{' '}
    and{' '}
    <Link href="https://ezsolarloan.com/esign-consent/" target="_blank">
      E-Sign Consent
    </Link>
    .
  </span>
);

const BorrowerInfo = () => {
  const dispatch = useDispatch();

  const {
    register,
    watch,
    handleSubmit,
    clearErrors,
    setValue,
    trigger,
    control,
    formState: { errors, isSubmitted },
  } = useForm<FieldValues>({
    defaultValues: {
      firstName: useSelector(getBorrowerFirstName) || '',
      lastName: useSelector(getBorrowerLastName) || '',
      dateOfBirth: useSelector(getBorrowerDateOfBirth) || '',
      agreement: useSelector(getPrivacyAndTermsSigned) || false,
      citizenshipStatus: {
        value: useSelector(getBorrowerCitizenshipStatus) || '',
      },
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const dateOfBirth = watch('dateOfBirth');
  const agreement = watch('agreement');
  const citizenshipStatus = watch('citizenshipStatus');

  useEffect(() => {
    register('dateOfBirth', {
      required: getRequiredErrorMessage('Date of birth'),
      validate: (value) => (isValidDate(value) ? true : getInvalidDateErrorMessage()),
    });
    register('citizenshipStatus', {
      validate: (item) => (!item.value ? getRequiredErrorMessage('Citizenship status') : true),
    });
  }, [register]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    dispatch(
      setCustomerData({
        [UserInformationKeys.FirstName]: deleteDoubleSpace(data.firstName),
        [UserInformationKeys.LastName]: deleteDoubleSpace(data.lastName),
        [UserInformationKeys.DateOfBirth]: data.dateOfBirth,
        [UserInformationKeys.PrivacyAndTermsSigned]: data.agreement,
        [UserInformationKeys.CitizenshipStatus]: data.citizenshipStatus.value,
      }),
    );
    dispatch(nextStep());
  };

  const onChangeAgreement = () => {
    setValue('agreement', !agreement);
    if (errors.agreement?.message) clearErrors('agreement');
  };

  const onChangeDate = (date: string) => {
    setValue('dateOfBirth', date);
    if (errors.dateOfBirth?.message) clearErrors('dateOfBirth');
    return trigger('dateOfBirth');
  };

  const setCitizenshipStatus = (option: OptionType) => {
    setValue('citizenshipStatus', option);
    return errors.citizenshipStatus?.message ? clearErrors('citizenshipStatus') : null;
  };

  const isSubmitInactive = () =>
    !firstName ||
    !lastName ||
    !agreement ||
    !dateOfBirth ||
    !citizenshipStatus?.value ||
    (isSubmitted && isExistErrors(errors));

  const handleBackClick = () => dispatch(backStep());

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.textInputLabel}>What is your name?</div>
      <div className={cx(styles.flexInputContainer, styles.inputContainer)}>
        <TextInput
          inputContainerClassName={styles.inputChildContainer}
          {...register('firstName', { required: getRequiredErrorMessage('First name') })}
          label="First name"
          value={deleteSpaceInStart(firstName)}
          errorMessage={errors.firstName?.message}
          maxLength={100}
          onKeyDown={specialCharactersiInputProhibition}
        />
        <TextInput
          inputContainerClassName={styles.inputChildContainer}
          {...register('lastName', { required: getRequiredErrorMessage('Last name') })}
          label="Last name"
          value={deleteSpaceInStart(lastName)}
          errorMessage={errors.lastName?.message}
          maxLength={100}
          onKeyDown={specialCharactersiInputProhibition}
        />
      </div>
      <div className={styles.textInputLabel}>What is your date of birth?</div>
      <div className={styles.inputContainer}>
        <DatePicker
          control={control}
          handleChange={onChangeDate}
          handleBlur={() => trigger('dateOfBirth')}
          value={dateOfBirth}
          errorMessage={isSubmitted ? errors.dateOfBirth?.message : ''}
          label="Date of birth"
          name="dateOfBirth"
        />
      </div>
      <div className={styles.textInputLabel}>What is your citizenship status?</div>
      <div className={styles.inputContainer}>
        <CitizenshipStatus
          handleValuePicked={setCitizenshipStatus}
          selectedOption={citizenshipStatus.value}
          errorMessage={errors.citizenshipStatus?.message}
          control={control}
        />
      </div>
      <Checkbox
        {...register('agreement', { required: getRequiredErrorMessage('Agreement') })}
        className={styles.checkBox}
        checked={agreement}
        onChange={() => onChangeAgreement()}
        label={<AgreementLabel />}
      />
      {errors.agreement?.message && <InputErrorMessage>{errors.agreement.message}</InputErrorMessage>}
      <NavigationButtonWrapper>
        <BackButton onClick={handleBackClick} />
        <NextButton type="submit" inactive={isSubmitInactive()} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default BorrowerInfo;
