import { createAsyncThunk } from '@reduxjs/toolkit';

import ApplicationApi from 'api/ApplicationApi';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface SendInviteProps {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  sci: string;
  intermediaryName: string;
}

export const createSendInviteThunk = (applicationApi: ApplicationApi) =>
  createAsyncThunk(
    'submissions/invitation',
    async ({ firstName, lastName, phone, email, sci, intermediaryName }: SendInviteProps, { dispatch }) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        return await applicationApi.sendInvite(firstName, lastName, phone, email, sci, intermediaryName);
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
