import React, { Fragment } from 'react';

import classnames from 'classnames/bind';

import { ProgressStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';

import styles from './ProgressBar.module.scss';

interface IProgressBarProps {
  stages: ProgressStage[];
  currentIndex: number;
  onClick?: (stage: StagesType) => void;
}

const cx = classnames.bind(styles);

const ProgressBar = ({ stages, currentIndex, onClick }: IProgressBarProps) => (
  <ul className={styles.progressList}>
    {stages.map((stage, index) => {
      const isLastIndex = index === stages.length - 1;
      const renderedIndex = index + 1;

      switch (index === currentIndex) {
        case true:
          return (
            <Fragment key={`${stage}${index}`}>
              <li
                className={cx(styles.progressCircleBase, styles.activeProgressCircle)}
                data-stage-number={renderedIndex}
              />
              {!isLastIndex && <li className={styles.progressLine} />}
            </Fragment>
          );
        case false:
          if (stage.isPassed) {
            return (
              <Fragment key={`${stage}${index}`}>
                <li
                  className={cx(styles.progressCircleBase, styles.passedProgressCircle)}
                  data-stage-number={renderedIndex}
                  onClick={onClick && (() => onClick(stage.name))}
                />
                {!isLastIndex && <li className={styles.progressLine} />}
              </Fragment>
            );
          }
          return (
            <Fragment key={`${stage}${index}`}>
              <li className={styles.progressCircleBase} data-stage-number={renderedIndex} />
              {!isLastIndex && <li className={styles.progressLine} />}
            </Fragment>
          );
          break;
        default:
          return <></>;
      }
    })}
  </ul>
);

export default ProgressBar;
