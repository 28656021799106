import React from 'react';

import { FieldValues, FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'common/components/Button';
import FormContainer from 'components/FormContainer';
import { LoginLayout } from 'layouts';
import { PortalType } from 'layouts/withPortalLayout';
import { useAppDispatch } from 'store';
import { changeBorrowerPassword, changeContractorPassword } from 'thunks';
import { Routes } from 'types';
import { notify } from 'utils/notificationHelper';

import { ConfirmPasswordForm } from './ConfirmPassword';
import { ILoginProps } from './Login';
import styles from './Login.module.scss';

type FormValues = {
  password: string;
  confirmPassword: string;
};

export const NewPassword = ({ type }: ILoginProps) => {
  const history = useHistory();
  const { code } = useParams<{ code: string }>();
  const dispatch = useAppDispatch();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    if (type === PortalType.Borrower) {
      const result = await dispatch(
        changeBorrowerPassword({
          password: data.password,
          resetPasswordToken: code,
        }),
      ).unwrap();
      if (result) {
        notify({ notification: 'Password was successfully updated.', whiteBackground: true });
        history.push(Routes.BorrowerLogin);
      }
    } else if (type === PortalType.Contractor) {
      const result = await dispatch(
        changeContractorPassword({
          password: data.password,
          resetPasswordToken: code,
        }),
      ).unwrap();
      if (result) {
        notify({ notification: 'Password was successfully updated.', whiteBackground: true });
        history.push(Routes.ContractorLogin);
      }
    }
  };

  const isSubmitInactive = () => !formMethods.watch('password') || !formMethods.watch('confirmPassword');

  return (
    <LoginLayout title="Create new password">
      <FormProvider {...formMethods}>
        <FormContainer className={styles.loginForm} onSubmit={formMethods.handleSubmit(onSubmit)}>
          <ConfirmPasswordForm />
          <Button
            title="Confirm new password"
            inactive={isSubmitInactive()}
            className={styles.submitButton}
            type="submit"
          />
        </FormContainer>
      </FormProvider>
    </LoginLayout>
  );
};
