import React from 'react';

import { useHistory } from 'react-router-dom';

import NextButton from 'common/components/Button/NextButton';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { Routes } from 'types';

import styles from './NoActiveApplication.module.scss';

const NoActiveApplication = () => {
  const history = useHistory();

  const handleApply = () => history.push(Routes.Apply);

  return (
    <>
      <p className={styles.subTitle}>
        Currently you do not have an active application. Click the button below to create a new application.
      </p>
      <NavigationButtonWrapper>
        <NextButton title="apply now" onClick={handleApply} />
      </NavigationButtonWrapper>
    </>
  );
};

export default withPortalLayout(NoActiveApplication);
