export const getCurrentYear = () => new Date().getFullYear();

export const getFirstDayOfCurrentMonth = () => {
  const currentDate = new Date();

  return new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
};

export const getCurrentDate = () => {
  const dateInPacificTime = new Date().toLocaleString("en-US", {
    timeZone: "America/Los_Angeles"
  });

  const date = `${new Date(dateInPacificTime).getMonth() + 1}/${new Date(
    dateInPacificTime
  ).getDate()}/${new Date(dateInPacificTime).getFullYear()}`;

  const newDate = date
    .split("/")
    .map((item, index) =>
      index < 2 && Number(item) < 10 ? `0${item.trim()}` : item.trim()
    );

  return `${newDate[0]}/${newDate[1]}/${newDate[2]}`;
};
