import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import Checkbox from 'common/components/Checkbox';
import DropZone from 'components/DropZone';
import { IFileWithError } from 'components/DropZone/DropZone';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { RootState } from 'handlers';
import { backStep } from 'handlers/actions';
import { ApplicationVariables, EmploymentType } from 'handlers/application/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { nextStep, setSteps } from 'handlers/steps';
import { UploadDocumentsSteps } from 'handlers/steps/types';
import PageLayout from 'layouts';
import { CitizenshipType } from 'layouts/CitizenshipStatus';
import {
  getApplicationId,
  getBorrowerCitizenshipStatus,
  getBorrowerEmploymentType,
  getBorrowerGrossIncome,
  getCoborrowerCitizenshipStatus,
  getCoborrowerEmploymentType,
  getCoborrowerExists,
  getCoborrowerGrossIncome,
  getCurrentStep,
  getIsLoading,
  getPotentialOffersDisplayed,
  getVoiNeeded,
} from 'selectors';
import { updateApplication, uploadDocuments } from 'thunks';
import { VariableValue } from 'types';
import { getCurrentDate } from 'utils/dateHelper';

import styles from './UploadDocuments.module.scss';

enum UploadDocumentTitle {
  BorrowerIdentity = 'Borrower identity verification',
  CoborrowerIdentity = 'Co-borrower identity verification',
  BorrowerIncome = 'Borrower income verification',
  CoborrowerIncome = 'Co-borrower income verification',
}

enum UploadFileName {
  BorrowerIdentity = 'Borrower ID-from-Application',
  CoborrowerIdentity = 'Co-borrower ID-from-Application',
  BorrowerIncome = 'Borrower VOI-from-Application',
  CoborrowerIncome = 'Co-borrower VOI-from-Application',
}

const unEmploymentTypes = [EmploymentType.Student, EmploymentType.Homemaker, EmploymentType.Unemployed];
const citizenshipStatuses = [CitizenshipType.Citizen, CitizenshipType.NonPermanentResident];

const getCheckBoxLabel = (
  currentStep: UploadDocumentsSteps,
  borrowerEmploymentType: EmploymentType | null,
  coborrowerEmploymentType: EmploymentType | null,
) => {
  switch (currentStep) {
    case UploadDocumentsSteps.BorrowerIdentity:
      return 'Skip for now. I will upload it through my Borrower Dashboard or request my Solar Company transmit it. I understand my application will not continue until that is complete.';
    case UploadDocumentsSteps.CoborrowerIdentity:
      return 'Skip for now. I will upload it through my Borrower Dashboard or request my Solar Company transmit it. I understand my application will not continue until that is complete.';
    case UploadDocumentsSteps.BorrowerIncome:
      return unEmploymentTypes.includes(borrowerEmploymentType as EmploymentType)
        ? 'I do not have any personal income.'
        : 'Skip for now. I will upload it through my Borrower Dashboard or request my Solar Company transmit it. I understand my application will not continue until that is complete.';
    case UploadDocumentsSteps.CoborrowerIncome:
      return unEmploymentTypes.includes(coborrowerEmploymentType as EmploymentType)
        ? 'I do not have any personal income.'
        : 'Skip for now. I will upload it through my Borrower Dashboard or request my Solar Company transmit it. I understand my application will not continue until that is complete.';
    default:
      break;
  }
};

const getSubtitle = (
  step: UploadDocumentsSteps,
  borrowerEmploymentType: EmploymentType | null,
  coborrowerEmploymentType: EmploymentType | null,
  coborrowerCitizenshipStatus: string,
  borrowerCitizenshipStatus: string,
) => {
  switch (step) {
    case UploadDocumentsSteps.BorrowerIdentity:
      return citizenshipStatuses.includes(borrowerCitizenshipStatus as CitizenshipType)
        ? 'To verify your identity, please upload a valid, non-expired government-issued photo ID such as a Driver’s license or Passport.'
        : 'To verify your identity, please upload a valid, non-expired permanent resident card.';
    case UploadDocumentsSteps.CoborrowerIdentity:
      return citizenshipStatuses.includes(coborrowerCitizenshipStatus as CitizenshipType)
        ? 'To verify your identity, please upload a valid, non-expired government-issued photo ID such as a Driver’s license or Passport.'
        : 'To verify your identity, please upload a valid, non-expired permanent resident card.';
    case UploadDocumentsSteps.BorrowerIncome:
      if (borrowerEmploymentType === EmploymentType.Employed) {
        return 'Please provide your most recent paystub, which should be dated within the last 30 days. If you have included additional income through rentals or services, please also provide the relevant agreements or contracts';
      }
      if (
        borrowerEmploymentType === EmploymentType.SelfEmployed ||
        borrowerEmploymentType === EmploymentType.BusinessOwner
      ) {
        return 'Please upload the two most recent years of tax returns, both personal and business, including all schedules attached';
      }
      if (borrowerEmploymentType === EmploymentType.Retired) {
        return 'Please upload relevant statements of benefits, pensions, award letters, etc. If you have included additional income through rentals or services, please also provide the relevant agreements or contracts';
      }
      if (unEmploymentTypes.includes(borrowerEmploymentType as EmploymentType)) {
        return 'Please upload verification of any income you would like us to consider, such as rental agreements, part-time employment wages, pensions, social security, revenue from sales or services provided, etc.';
      }
      break;
    case UploadDocumentsSteps.CoborrowerIncome:
      if (coborrowerEmploymentType === EmploymentType.Employed) {
        return 'Please provide co-borrower’s most recent paystub, which should be dated within the last 30 days. If you have included additional income through rentals or services, please also provide the relevant agreements or contracts';
      }
      if (
        coborrowerEmploymentType === EmploymentType.SelfEmployed ||
        coborrowerEmploymentType === EmploymentType.BusinessOwner
      ) {
        return 'Please upload the two most recent years of tax returns, both personal and business, including all schedules attached';
      }
      if (coborrowerEmploymentType === EmploymentType.Retired) {
        return 'Please upload relevant statements of benefits, pensions, award letters, etc. If you have included additional income through rentals or services, please also provide the relevant agreements or contracts';
      }
      if (unEmploymentTypes.includes(coborrowerEmploymentType as EmploymentType)) {
        return 'Please upload verification of any income you would like us to consider, such as rental agreements, part-time employment wages, pensions, social security, revenue from sales or services provided, etc.';
      }
      break;
    default:
  }
};

const getApplicationData = (step: UploadDocumentsSteps): Record<string, VariableValue> => {
  const borrowerIdentityVariables = {
    [ApplicationVariables.UploadBorrowerIdComplete]: true,
    [ApplicationVariables.UploadBorrowerIdCompleteDate]: getCurrentDate(),
  };
  switch (step) {
    case UploadDocumentsSteps.BorrowerIdentity:
      return borrowerIdentityVariables;
    case UploadDocumentsSteps.CoborrowerIdentity:
      return {
        [ApplicationVariables.UploadCoborrowerIdComplete]: true,
        [ApplicationVariables.UploadCoborrowerIdCompleteDate]: getCurrentDate(),
      };
    case UploadDocumentsSteps.BorrowerIncome:
      return {
        [ApplicationVariables.UploadBorrowerVoiComplete]: true,
        [ApplicationVariables.UploadBorrowerVoiCompleteDate]: getCurrentDate(),
      };
    case UploadDocumentsSteps.CoborrowerIncome:
      return {
        [ApplicationVariables.UploadCoborrowerVoiComplete]: true,
        [ApplicationVariables.UploadCoborrowerVoiCompleteDate]: getCurrentDate(),
      };
    default:
      return borrowerIdentityVariables;
  }
};

const UploadDocuments = () => {
  const dispatch = useDispatch();
  const currentStep = useSelector(getCurrentStep) as UploadDocumentsSteps;
  const currentSteps = useSelector((state: RootState) => state.steps.steps);
  const applicationId = useSelector(getApplicationId);
  const loading = useSelector(getIsLoading);
  const potentialOffersDisplayed = useSelector(getPotentialOffersDisplayed);
  const coborrowerExist = useSelector(getCoborrowerExists);
  const [files, setFiles] = useState<IFileWithError[]>([]);
  const [isExistIncomeDocuments, setIsExistIncomeDocuments] = useState(false);
  const voiNeeded = useSelector(getVoiNeeded);
  const borrowerGrossIncome = useSelector(getBorrowerGrossIncome) || 0;
  const coborrowerGrossIncome = useSelector(getCoborrowerGrossIncome) || 0;

  const borrowerEmploymentType = useSelector(getBorrowerEmploymentType);
  const coborrowerEmploymentType = useSelector(getCoborrowerEmploymentType);
  const borrowerCitizenshipStatus = useSelector(getBorrowerCitizenshipStatus);
  const coborrowerCitizenshipStatus = useSelector(getCoborrowerCitizenshipStatus);

  useEffect(() => {
    const steps = [UploadDocumentsSteps.BorrowerIdentity];
    if (coborrowerExist) {
      steps.push(UploadDocumentsSteps.CoborrowerIdentity);
      if (voiNeeded || potentialOffersDisplayed) {
        if (borrowerGrossIncome > 0) {
          steps.push(UploadDocumentsSteps.BorrowerIncome);
        }
        if (coborrowerGrossIncome > 0) {
          steps.push(UploadDocumentsSteps.CoborrowerIncome);
        }
      }
    } else if ((voiNeeded || potentialOffersDisplayed) && borrowerGrossIncome > 0) {
      steps.push(UploadDocumentsSteps.BorrowerIncome);
    }
    dispatch(setSteps(steps));
  }, [coborrowerExist, voiNeeded]);

  const handleNext = async () => {
    if (!isExistIncomeDocuments) {
      await dispatch(
        uploadDocuments({
          applicationId,
          files,
        }),
      );
      await dispatch(
        updateApplication({
          applicationId,
          applicationVariables: getApplicationData(currentStep),
        }),
      );
      setFiles([]);
    }

    setIsExistIncomeDocuments(false);

    if (currentStep === currentSteps[currentSteps.length - 1]) {
      dispatch(setCurrentStage({ stage: StagesType.CreditApproval }));
    } else {
      dispatch(nextStep());
    }
  };

  const handleBack = () => {
    setIsExistIncomeDocuments(false);
    setFiles([]);
    if (currentStep === UploadDocumentsSteps.BorrowerIdentity) {
      dispatch(setCurrentStage({ stage: StagesType.SalespersonInfo }));
    } else {
      dispatch(backStep());
    }
  };
  const isDisabledNextButton = () =>
    (!isExistIncomeDocuments && !files.length) || !!files.filter((file) => file.error).length;

  return (
    <PageLayout title={UploadDocumentTitle[currentStep]}>
      <p>
        {getSubtitle(
          currentStep,
          borrowerEmploymentType,
          coborrowerEmploymentType,
          coborrowerCitizenshipStatus,
          borrowerCitizenshipStatus,
        )}
      </p>
      <DropZone
        files={files}
        setFiles={setFiles}
        dropZoneStyle={styles.marginTop}
        disabled={isExistIncomeDocuments}
        fileName={UploadFileName[currentStep]}
      />
      <Checkbox
        label={getCheckBoxLabel(currentStep, borrowerEmploymentType, coborrowerEmploymentType)}
        checked={isExistIncomeDocuments}
        onChange={() => setIsExistIncomeDocuments(!isExistIncomeDocuments)}
        className={styles.marginTop}
      />
      <NavigationButtonWrapper>
        <BackButton onClick={handleBack} />
        <NextButton onClick={handleNext} disabled={isDisabledNextButton()} loading={loading} />
      </NavigationButtonWrapper>
    </PageLayout>
  );
};

export default UploadDocuments;
