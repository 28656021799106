import React, { useEffect, useRef, useState } from 'react';

import { Popper, ClickAwayListener, Fade } from '@mui/material';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import { ReactComponent as ArrowDown } from 'assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/ArrowUp.svg';
import Link from 'common/components/Link';
import { MobileScreenWidth } from 'enums/MobileScreenWidth';
import { getApplicationFriendlyStatus, getBorrowerFirstName, getBorrowerLastName, getLoanAmount } from 'selectors';
import { useAppDispatch } from 'store';
import { logoutBorrower } from 'thunks';
import { Routes } from 'types';
import { convertMonetary } from 'utils/formats';

import styles from './HeaderAccountProfile.module.scss';

const cx = classNames.bind(styles);

const BorrowerAccountProfile = () => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const borrowerFirstName = useSelector(getBorrowerFirstName);
  const borrowerLastName = useSelector(getBorrowerLastName);
  const loanAmount = useSelector(getLoanAmount);
  const applicationStatus = useSelector(getApplicationFriendlyStatus);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const isMobileScreenMax = screenWidth < MobileScreenWidth.Max;
  const isMobileScreenMiddle = screenWidth < MobileScreenWidth.Middle;
  const isMobileScreenMin = screenWidth < 400;

  const convertedLoanAmount = loanAmount ? `${convertMonetary(loanAmount)}` : null;

  const handleWindowSizeChange = () => setScreenWidth(window.innerWidth);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = async () => {
    await dispatch(logoutBorrower({}));
    history.push(Routes.BorrowerLogin);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleFaq = () => {
    window.open('https://ezsolarloan.com/faq/', '_blank');
  };

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  return (
    <div className={styles.accountContainer}>
      {!isMobileScreenMax && <span className={cx(commonStyles[applicationStatus])}>{applicationStatus}</span>}
      <button
        className={cx(styles.menuTitle, styles.borrowerMenuTitle)}
        type="button"
        ref={anchorRef}
        aria-controls={open ? 'menu-list' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {!isMobileScreenMiddle && (
          <>
            {borrowerFirstName} {borrowerLastName} {convertedLoanAmount}
          </>
        )}
        {isMobileScreenMiddle && screenWidth > 400 && (
          <>
            {borrowerLastName} {convertedLoanAmount}
          </>
        )}
        {open ? <ArrowUp className={styles.arrowIcon} /> : <ArrowDown className={styles.arrowIcon} />}
      </button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} disablePortal placement="bottom-end" transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={200}>
              <div className={styles.menuContainer}>
                <ul className={styles.menuList} id="menu-list" onKeyDown={handleListKeyDown}>
                  {isMobileScreenMax && (applicationStatus || isMobileScreenMin) && (
                    <li className={styles.menuItem}>
                      {isMobileScreenMin && (
                        <span className={styles.menuInfoItem}>
                          {borrowerFirstName} {borrowerLastName} {convertedLoanAmount}
                        </span>
                      )}
                      <span className={cx(styles.menuStatusItem, commonStyles[applicationStatus])}>
                        {applicationStatus}
                      </span>
                    </li>
                  )}
                  <li className={styles.menuItem} onClick={handleFaq}>
                    FAQ’s
                  </li>
                  <li className={cx(styles.menuItem, styles.logoutItem)} onClick={handleLogout}>
                    Log out
                  </li>
                  <li className={styles.contactsMenuItem}>
                    <span className={styles.contactsTitle}>Contacts</span>
                    <span className={styles.contactsPhone}>800-493-1310</span>
                    <Link className={styles.contactsLink} href="mailto:applications@ezsolarloan.com">
                      applications@ezsolarloan.com
                    </Link>
                  </li>
                </ul>
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default BorrowerAccountProfile;
