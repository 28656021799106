export const getRequiredErrorMessage = (label: string) => `${label} is required`;

export const getInvalidEmailErrorMessage = () => 'Invalid email format';

export const getTooLongErrorMessage = (label: string) => `${label} is too long`;

export const getTooLowMonthlyRentErrorMessage = () => 'Must be greater than $100';

export const getInvalidSocialSecurityNumberErrorMessage = () => 'Invalid SSN format';

export const getInvalidPhoneNumberErrorMessage = () => 'Invalid phone number format';

export const getNotAValidUSNumberErrorMessage = () => 'Not a valid US phone number';

export const getTooYoungErrorMessage = () => 'Borrower must be at least 18 years old';

export const getInvalidDateErrorMessage = () => 'Invalid date';

export const getDifferentEmailRequiredErrorMessage = () => 'Must be different from the borrower email';

export const getEnterValidSSNErrorMessage = () => 'Please enter a valid Social Security Number';

export const getInvalidSSNLength = (field: string) => `Whoops! ${field} must be 4 digits long`;

export const getInvalidSciCodeMessage = () => 'Wrong length (should be 4 digits)';

export const getMessageForInvalidField = (label: string) => `${label} is invalid`;

export const getIncorrectSSNErrorMessage = () => 'SSN does not match data from soft credit pull';

export const getInvalidKilowattsErrorMessage = () => 'Solar panel size should be in kilowatts, and not exceed 100';

export const getInvalidBatteryErrorMessage = () => 'Battery size should be in kilowatt hours, and not exceed 100';

export const getInvalidTotalAmountErrorMessage = () => 'The project cost must be greater than zero';

export const getAuthorizedSignerAccountErrorMessage = () =>
  'Please only submit ACH information for an account for which you or the Co-borrower is a signer.';

export const getInvalidTotalMinusAmountErrorMessage = () =>
  'Total minus outside amount can not be negative and must be less than or equal to ezSolarLoan application amount';

export const getCustomError = (message: string) => ({
  type: 'manual',
  message,
});

export const SomethingWentWrong = 'Something went wrong. Please try again later.';

export const getNumberInputInvalidMessage = (minDigit: number, maxDigit?: number) =>
  maxDigit
    ? `Wrong length (should be ${minDigit} to ${maxDigit} digits)`
    : `Wrong length (should be ${minDigit} digits)`;
