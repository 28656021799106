import React from 'react';

import classNames from 'classnames/bind';

import styles from './Pagination.module.scss';

const cx = classNames.bind(styles);

interface IPaginationProps {
  pages: Array<any>;
  pageIndex: number;
  disabledNextPage: boolean;
  disabledPrevPage: boolean;
  onClickNextPage: () => void;
  onClickPrevPage: () => void;
  onClickPage: (page: number) => void;
  pageSize: number;
  totalCount: number;
  screenType: 'mobile' | 'desktop';
  displayPageNumber?: (page: number) => boolean;
  disablePageNumber?: (page: number) => boolean;
}

const Pagination = ({
  pageIndex,
  disabledNextPage,
  disabledPrevPage,
  onClickPage,
  onClickNextPage,
  onClickPrevPage,
  pageSize,
  totalCount,
  screenType,
  disablePageNumber,
  displayPageNumber,
  pages,
}: IPaginationProps) => {
  const isDesktop = screenType === 'desktop';
  return (
    <div className={styles.pagination}>
      <div>
        <button disabled={disabledPrevPage} onClick={onClickPrevPage} type="button" className={styles.pageLink}>
          {'< '}
          {isDesktop && 'Prev'}
        </button>
        {pages.map((_, i) => (
          <button
            key={i}
            type="button"
            onClick={() => onClickPage(i)}
            className={cx(styles.pageLink, styles.pageNumber, {
              [styles.notDisplayPageNum]: displayPageNumber?.(i),
              [styles.disablePageNum]: disablePageNumber?.(i),
              [styles.activePage]: pageIndex === i,
            })}
          >
            {disablePageNumber?.(i) ? '...' : i + 1}
          </button>
        ))}
        <button disabled={disabledNextPage} onClick={onClickNextPage} type="button" className={styles.pageLink}>
          {isDesktop && 'Next'}
          {' >'}
        </button>
      </div>
      <div className={styles.paginationStatus}>
        Results:
        {` ${pageIndex * pageSize + 1} - ${Math.min(totalCount, (pageIndex + 1) * pageSize)} of ${totalCount}`}
      </div>
    </div>
  );
};

export default Pagination;
