import React, { FC, LegacyRef, useState } from 'react';

import cnBind from 'classnames/bind';
import { Control, Controller } from 'react-hook-form';
import { isValidPhoneNumber, isPossiblePhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input/input';

import InputErrorMessage from 'common/components/TextInput/InputErrorMessage';
import { getInvalidPhoneNumberErrorMessage, getNotAValidUSNumberErrorMessage } from 'errors/errors';

import styles from './PhoneInput.module.scss';

const cx = cnBind.bind(styles);

interface PhoneNumberInputWrappedProps {
  name: string;
  control?: Control;
  errorBackgroundType?: 'flowErrorBackground' | 'portalErrorBackground';
  required?: boolean;
  label: string;
  className?: string;
  errorClassName?: string;
  customValidator?: (value: string) => boolean | string;
  disabled?: boolean;
  autofocus?: boolean;
}

const renderInputComponent = React.forwardRef((props, ref: LegacyRef<HTMLInputElement>) => (
  <input ref={ref} {...props} />
));

const InputPhone: FC<PhoneNumberInputWrappedProps> = ({
  name,
  control,
  required,
  label,
  className,
  customValidator,
  disabled,
  errorBackgroundType,
  autofocus = false,
}) => {
  const [focus, setFocus] = useState(true);
  const validatePhoneNumber = (currentValue: string) => {
    if (!isPossiblePhoneNumber(currentValue ?? '')) {
      return getInvalidPhoneNumberErrorMessage();
    }

    if (!isValidPhoneNumber(currentValue ?? '')) {
      return getNotAValidUSNumberErrorMessage();
    }

    if (customValidator) {
      return customValidator(currentValue);
    }

    return true;
  };

  return (
    <>
      <Controller
        name={name}
        control={control}
        rules={{ required, validate: validatePhoneNumber }}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <div className={cx(styles.textInputContainer, className)}>
            <PhoneInput
              inputComponent={error && autofocus ? renderInputComponent : undefined}
              autoFocus={error && focus && autofocus ? true : undefined}
              country="US"
              onBlur={() => {
                onBlur?.();
                setFocus(false);
              }}
              value={value}
              maxLength={16}
              className={cx(styles.input)}
              onChange={onChange}
              disabled={disabled}
              required
              inputMode="tel"
            />
            <label className={cx(styles.inputLabel, { [styles.errorInputLabel]: !!error })}>{label}</label>
            {!!error && <InputErrorMessage backgroundColor={errorBackgroundType}>{error?.message}</InputErrorMessage>}
          </div>
        )}
      />
    </>
  );
};

export default InputPhone;
