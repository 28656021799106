import React from 'react';

import BaseIcon, { BaseIconProps } from './BaseIcon';

const SearchIcon = ({ viewBox, ...restProps }: BaseIconProps) => (
  <BaseIcon {...restProps} viewBox={viewBox || '0 0 24 24'} fill="none">
    <circle cx="10.5" cy="10.5" r="6.75" stroke="#2C3D50" strokeWidth="1.5" />
    <line
      x1="16.3377"
      y1="15.1261"
      x2="20.9063"
      y2="18.9436"
      stroke="#2C3D50"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </BaseIcon>
);

export default SearchIcon;
