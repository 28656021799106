import React, { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { DateTime } from 'luxon';

import commonStyles from 'App.module.scss';
import TextLoader from 'components/TextLoader';
import { ApplicationState } from 'handlers/application';
import { ApplicationVariables, EmploymentType } from 'handlers/application/types';
import { CoborrowerInfoKeys } from 'handlers/coborrower/types';
import { UserInformationKeys } from 'handlers/customer/types';
import { Application, ApplicationStatus } from 'types';
import { convertMonetary } from 'utils/formats';
import { getFriendlyStatus } from 'utils/getFriendlyStatus';

import styles from './ViewDeal.module.scss';

const cx = classNames.bind(styles);

enum VerificationStatus {
  Needed = 'Needed',
  Completed = 'Completed',
}

interface IViewDealProps {
  application: Application | null;
}

interface IViewDealState {
  borrowerFirstName: string;
  borrowerLastName: string;
  borrowerEmploymentType: EmploymentType | null;
  borrowerGrossAnnualIncome: number;
  loanAmount: number;
  createdAt: string;
  coborrowerFirstName: string;
  coborrowerLastName: string;
  coborrowerApplication: boolean;
  coborrowerEmploymentType: EmploymentType | null;
  coborrowerGrossAnnualIncome: number;
  installationStreetAddress: string;
  installationZipCode: string;
  installationState: string;
  installationCity: string;
  projectAndContractConfirmed: boolean;
  uploadBorrowerVoiComplete: boolean;
  uploadCoborrowerVoiComplete: boolean;
  applicationStatus: string;
  voiNedeed: boolean;
  potentialOffersDisplayed: boolean | null;
}

const initialState: IViewDealState = {
  applicationStatus: '',
  borrowerFirstName: '',
  borrowerLastName: '',
  borrowerEmploymentType: null,
  borrowerGrossAnnualIncome: 0,
  coborrowerFirstName: '',
  coborrowerLastName: '',
  coborrowerApplication: false,
  coborrowerEmploymentType: null,
  coborrowerGrossAnnualIncome: 0,
  createdAt: '',
  installationStreetAddress: '',
  installationCity: '',
  installationState: '',
  installationZipCode: '',
  loanAmount: 0,
  projectAndContractConfirmed: false,
  uploadBorrowerVoiComplete: false,
  uploadCoborrowerVoiComplete: false,
  voiNedeed: false,
  potentialOffersDisplayed: null,
};

const isVoiRequired = (employmentType: EmploymentType) =>
  employmentType === EmploymentType.Employed ||
  employmentType === EmploymentType.SelfEmployed ||
  employmentType === EmploymentType.BusinessOwner ||
  employmentType === EmploymentType.Retired;

const ViewDeal = ({ application }: IViewDealProps) => {
  const [data, setData] = useState<IViewDealState>(initialState);

  const getVOIStatus = () => {
    const VoiStatusCondition =
      (data.voiNedeed || data.potentialOffersDisplayed) &&
      ((isVoiRequired(data.borrowerEmploymentType as EmploymentType) &&
        data.borrowerGrossAnnualIncome > 0 &&
        !data.uploadBorrowerVoiComplete) ||
        (data.coborrowerApplication &&
          isVoiRequired(data.coborrowerEmploymentType as EmploymentType) &&
          data.coborrowerGrossAnnualIncome > 0 &&
          !data.uploadCoborrowerVoiComplete));

    return VoiStatusCondition ? VerificationStatus.Needed : VerificationStatus.Completed;
  };

  const getProjectStatus = () => {
    if (data.projectAndContractConfirmed) return VerificationStatus.Completed;
    return VerificationStatus.Needed;
  };

  const getCoborrower = () => {
    if (data.coborrowerLastName && data.coborrowerFirstName)
      return `${data.coborrowerFirstName} ${data.coborrowerLastName}`;
    return '-';
  };

  const isLoading = (el: string | JSX.Element, width: string, maxWidth?: string) =>
    !application ? <TextLoader width={width} maxWidth={maxWidth} /> : el;

  useEffect(() => {
    if (application) {
      setData({
        createdAt: DateTime.fromJSDate(new Date(application.createdAt)).toLocaleString(DateTime.DATE_MED),
        applicationStatus: getFriendlyStatus({
          ...application.variables,
          status: application.status.name as ApplicationStatus,
        } as ApplicationState),
        borrowerFirstName: application.variables[UserInformationKeys.FirstName],
        borrowerLastName: application.variables[UserInformationKeys.LastName],
        borrowerEmploymentType: application.variables[ApplicationVariables.BorrowerEmploymentType],
        borrowerGrossAnnualIncome: application.variables[ApplicationVariables.BorrowerGrossIncome],
        loanAmount: application.variables[ApplicationVariables.LoanAmount],
        coborrowerFirstName: application.variables[CoborrowerInfoKeys.FirstName],
        coborrowerLastName: application.variables[CoborrowerInfoKeys.LastName],
        coborrowerApplication: application.variables[ApplicationVariables.CoborrowerApplication],
        coborrowerEmploymentType: application.variables[ApplicationVariables.CoborrowerEmploymentType],
        coborrowerGrossAnnualIncome: application.variables[ApplicationVariables.CoborrowerGrossIncome],
        installationStreetAddress: application.variables[ApplicationVariables.InstallationStreetAddress],
        installationZipCode: application.variables[ApplicationVariables.InstallationZipCode],
        installationState: application.variables[ApplicationVariables.InstallationState],
        installationCity: application.variables[ApplicationVariables.InstallationCity],
        projectAndContractConfirmed:
          application.variables[ApplicationVariables.ProjectDetailsAndSignedContractComplete],
        uploadBorrowerVoiComplete: application.variables[ApplicationVariables.UploadBorrowerVoiComplete],
        uploadCoborrowerVoiComplete: application.variables[ApplicationVariables.UploadCoborrowerVoiComplete],
        voiNedeed: application.variables[ApplicationVariables.VoiNeeded],
        potentialOffersDisplayed: application.variables[ApplicationVariables.PotentialOffersDisplayed],
      });
    }
  }, [application]);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div>
          <div className={styles.borrowerInfo}>
            <span className={styles.name}>
              {isLoading(`${data.borrowerFirstName} ${data.borrowerLastName}`, '120px')}
            </span>
            <span className={styles.sum}>{isLoading(`${convertMonetary(data.loanAmount)}`, '120px')}</span>
          </div>
          <div className={styles.createdAt}>{isLoading(`Created ${data.createdAt}`, '120px')}</div>
        </div>
        <div>
          {isLoading(<span className={commonStyles[data.applicationStatus]}>{data.applicationStatus}</span>, '130px')}
        </div>
      </div>
      <dl className={styles.body}>
        <div className={styles.list}>
          <dt className={styles.label}>{isLoading('Co-borrower', '81px')}</dt>
          <dd className={cx(styles.value, { [styles.loading]: !application })}>
            {isLoading(`${getCoborrower()}`, '100%', '250px')}
          </dd>
          <dt className={styles.label}>{isLoading('Address', '81px')}</dt>
          <dd className={cx(styles.value, { [styles.loading]: !application })}>
            {isLoading(
              `${data.installationStreetAddress}, ${data.installationCity}, ${data.installationState}, ${data.installationZipCode}`,
              '100%',
              '403px',
            )}
          </dd>
        </div>
        <div className={styles.list}>
          <dt className={styles.label}>{isLoading('VOI', '158px')}</dt>
          <dd className={cx(styles.value, styles.textEnd, { [styles.loading]: !application })}>
            {isLoading(`${getVOIStatus()}`, '100%', '80px')}
          </dd>
          <dt className={styles.label}>{isLoading('Project details / Contract', '158px')}</dt>
          <dd className={cx(styles.value, styles.textEnd, { [styles.loading]: !application })}>
            {isLoading(`${getProjectStatus()}`, '100%', '80px')}
          </dd>
        </div>
      </dl>
    </div>
  );
};

export default ViewDeal;
