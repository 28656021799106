import React, { useState } from 'react';

import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { ReactComponent as Checkmark } from 'assets/icons/Checkmark.svg';
import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg';

import styles from './PriceInput.module.scss';

const ENTER_KEY_CODE = 'Enter';

export interface InputProps {
  value: number | number[] | null;
  placeHolder?: string;
  maxLength: number;
  onChange: (value: number) => void;
  onBlur?: () => void;
}

const formatValue = (value: string) => {
  if (!Number(value)) return null;
  return value.indexOf('.') > 0 ? value : value.slice(0, 6);
};

export const PriceInput = ({ value, placeHolder, maxLength, onChange, onBlur }: InputProps) => {
  const inputRef = React.createRef<HTMLInputElement>();
  const [focused, setFocused] = useState(false);
  const [carriagePosition, setCarriagePosition] = useState<number>(0);
  const [formattedValue, setFormattedValue] = useState<string>(`$${value} `);

  const onPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const keyCode = event.key || event.code;

    if (keyCode === ENTER_KEY_CODE && onBlur) {
      event.preventDefault();
      inputRef.current?.blur();
    }
  };

  const getMaxInputLength = (position: number, currentValue: string) => {
    const indexPoint = currentValue.indexOf('.');
    const lengthBeforePoint = indexPoint > 0 ? currentValue.slice(0, indexPoint).length : 0;
    const lengthAfterPoint = currentValue.slice(indexPoint + 1, currentValue.length).length;

    if (indexPoint > 0) return position - 1 < indexPoint ? lengthAfterPoint + maxLength : lengthBeforePoint + 3;

    return maxLength;
  };

  const inputOnChange = (values: NumberFormatValues) => {
    onChange(Number(values.value));
    setFormattedValue(values.formattedValue);
  };

  const handleCarriagePosition = (event: React.MouseEvent<HTMLInputElement> | React.KeyboardEvent<HTMLInputElement>) =>
    setCarriagePosition(event.currentTarget.selectionStart as number);

  return (
    <div className={styles.wrapper}>
      <NumberFormat
        prefix="$"
        thousandSeparator
        className={styles.priceInput}
        placeholder={placeHolder}
        style={{
          width: `${(formattedValue.length - 1 || 0) + 3}ex`,
        }}
        allowNegative={false}
        value={formatValue(String(value))}
        getInputRef={inputRef}
        maxLength={getMaxInputLength(carriagePosition, formattedValue)}
        onValueChange={inputOnChange}
        onKeyUp={handleCarriagePosition}
        onMouseUp={handleCarriagePosition}
        onBlur={() => {
          setFocused(false);
          onBlur?.();
        }}
        onKeyPress={onPress}
        inputMode="decimal"
      />
      {!focused && (
        <EditIcon
          className={styles.priceInputIcon}
          onClick={() => {
            inputRef.current?.focus();
            setFocused(true);
          }}
        />
      )}
      {focused && <Checkmark className={styles.priceInputIcon} />}
    </div>
  );
};
export default PriceInput;
