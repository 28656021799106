import React, { ComponentType, FC, useState } from 'react';

import cnBind from 'classnames/bind';
import { Control, Controller } from 'react-hook-form';
import Select, { components, ControlProps, OptionProps } from 'react-select';
import { OptionsType, OptionTypeBase } from 'react-select/src/types';

import { ReactComponent as ArrowDown } from 'assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/ArrowUp.svg';
import { ReactComponent as CheckedIcon } from 'assets/icons/CheckedIcon.svg';

import customDropDownStyles from './customDropDownStyles';
import styles from './Dropdown.module.scss';
import SearchIcon from './Icons/SearchIcon';

const cx = cnBind.bind(styles);

export enum DropdownIndicatorTypes {
  Close = 'close',
  Open = 'open',
  Search = 'search',
}

export interface OptionType extends OptionTypeBase {
  label: string;
  value: string;
}

type IsMulti = false;

interface DropdownIndicatorProps {
  dropdownIndicatorType: DropdownIndicatorTypes;
}

interface DropDownProps {
  control: Control<any>;
  selectedOption: OptionType | null;
  onValuePicked: (value: OptionType) => void;
  options: OptionsType<OptionType>;
  placeholder: string;
  name: string;
  isSearchable?: boolean;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: () => void;
  invalid?: boolean;
  className?: string;
  disabled?: boolean;
}

const NewDropdownIndicator: FC<DropdownIndicatorProps> = ({ dropdownIndicatorType }) => (
  <>
    {dropdownIndicatorType === DropdownIndicatorTypes.Search && <SearchIcon />}
    {dropdownIndicatorType === DropdownIndicatorTypes.Close && <ArrowDown className={styles.arrowIcon} />}
    {dropdownIndicatorType === DropdownIndicatorTypes.Open && <ArrowUp className={styles.arrowIcon} />}
  </>
);

const NewOption: ComponentType<OptionProps<OptionType, IsMulti>> = ({ data, ...props }) => (
  <components.Option data={data} {...props}>
    {data.label}
    <CheckedIcon />
  </components.Option>
);

const NewControl: ComponentType<ControlProps<OptionType, IsMulti>> = ({ ...props }) => (
  <>
    <label
      className={cx(styles.label, {
        [styles.labelFloating]: props.isFocused || props.hasValue,
      })}
    >
      {props.selectProps.placeholder}
    </label>
    <components.Control {...props}>{props.children}</components.Control>
  </>
);

const DropDown: FC<DropDownProps> = ({
  options,
  selectedOption,
  placeholder,
  onValuePicked,
  isSearchable,
  onBlur,
  onFocus,
  name,
  invalid,
  className,
  disabled,
  control,
  onChange,
}) => {
  const [menuStatus, setMenuStatus] = useState(DropdownIndicatorTypes.Close);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { ref } }) => (
        <Select
          ref={ref}
          className={className}
          name={name}
          value={selectedOption}
          options={options}
          styles={customDropDownStyles}
          placeholder={placeholder}
          components={{
            DropdownIndicator: () => <NewDropdownIndicator dropdownIndicatorType={menuStatus} />,
            Option: NewOption,
            Control: NewControl,
          }}
          onMenuOpen={() => menuStatus === DropdownIndicatorTypes.Close && setMenuStatus(DropdownIndicatorTypes.Open)}
          onMenuClose={() => setMenuStatus(DropdownIndicatorTypes.Close)}
          onInputChange={(newValue) =>
            newValue ? setMenuStatus(DropdownIndicatorTypes.Search) : setMenuStatus(DropdownIndicatorTypes.Open)
          }
          onChange={(obj) => {
            onChange?.();
            return obj && onValuePicked(obj);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          isSearchable={isSearchable}
          invalid={invalid}
          isDisabled={disabled}
        />
      )}
    />
  );
};

export default DropDown;
