import { createAsyncThunk } from '@reduxjs/toolkit';

import IntermediaryApi from 'api/IntermediaryApi';
import { setIntermediaryData } from 'handlers/intermediary';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface GetIntermediaryBySciCodeProps {
  sci: string;
}

interface GetIntermediaryByIdProps {
  id: string;
}

export const createGetIntermediaryBySciCodeThunk = (intermediaryApi: IntermediaryApi) =>
  createAsyncThunk('intermediaries/getBySciCode', async ({ sci }: GetIntermediaryBySciCodeProps, { dispatch }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
      const result = await intermediaryApi.getIntermediaryBySciCode(sci);
      dispatch(
        setIntermediaryData({
          ...result,
          intermediaryNotFound: null,
        }),
      );
      return result;
    } catch (error) {
      dispatch(setIntermediaryData({ intermediaryNotFound: true }));
      return null;
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
    }
  });

export const createGetIntermediaryByIdThunk = (intermediaryApi: IntermediaryApi) =>
  createAsyncThunk('intermediaries/getById', async ({ id }: GetIntermediaryByIdProps, { dispatch }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
      return await intermediaryApi.getIntermediaryById(id);
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
    }
  });

export const createGetIntermediaryByEmailThunk = (intermediaryApi: IntermediaryApi) =>
  createAsyncThunk('intermediaries/getByEmail', async (_, { dispatch }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
      const result = await intermediaryApi.getIntermediaryByEmail();

      dispatch(
        setIntermediaryData({
          id: result.id,
          ...result.variables,
        }),
      );
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
    }
  });
