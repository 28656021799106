import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';
import { logout } from 'handlers/actions';

import { LogoutProps } from './logoutBorrower';

export const createLogoutContractorThunk = (accountApi: AccountApi) =>
  createAsyncThunk(
    'account/contractorLogout',
    // eslint-disable-next-line no-empty-pattern
    async ({}: LogoutProps, { dispatch }) => {
      try {
        await accountApi.logoutContractor();
      } finally {
        dispatch(logout());
      }
    },
  );
