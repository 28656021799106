import React, { useEffect } from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import Button from 'common/components/Button';
import TextInput from 'common/components/TextInput';
import FormContainer from 'components/FormContainer';
import { setIntermediaryData } from 'handlers/intermediary';
import { IntermediaryVariables } from 'handlers/intermediary/types';
import { LoginLayout } from 'layouts';
import { ConfirmPasswordForm } from 'pages/Login/ConfirmPassword';
import { useAppDispatch } from 'store';
import { acceptInvite, getIntermediaryById, getInviteDetails } from 'thunks';
import { Routes } from 'types';
import { deleteSpaceInStart } from 'utils/formats';
import { notify } from 'utils/notificationHelper';
import { getCorrectValueForEmail } from 'utils/validationHelper';

import styles from './Account.module.scss';

type FormValues = {
  email: string;
  password: string;
  confirmPassword: string;
};

const CreateIntermediaryAccount = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  const formMethods = useForm<FormValues>({
    defaultValues: {
      email: '',
      password: '',
      confirmPassword: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    const getDetails = async () => {
      if (token) {
        const invite = await dispatch(getInviteDetails({ token })).unwrap();
        if (invite) {
          const intermediary = await dispatch(getIntermediaryById({ id: invite.intermediaryId })).unwrap();
          formMethods.setValue('email', intermediary.variables[IntermediaryVariables.Email] as string);
          dispatch(
            setIntermediaryData({
              id: intermediary.id,
            }),
          );
        }
      }
    };

    getDetails();
  }, [token]);

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const account = await dispatch(
      acceptInvite({
        password: data.password,
        token,
      }),
    ).unwrap();
    if (!account) {
      notify({ notification: "Couldn't create an account. Please try again later." });
    } else {
      dispatch(
        setIntermediaryData({
          [IntermediaryVariables.Email]: data.email,
          isEmailNotVerified: false,
        }),
      );
      history.replace(Routes.ContractorDashboard);
    }
  };

  const isSubmitInactive = () =>
    !formMethods.watch('email') || !formMethods.watch('password') || !formMethods.watch('confirmPassword');

  const handleChangeEmail = (value: string) => {
    formMethods.setValue('email', getCorrectValueForEmail(value));
  };

  return (
    <LoginLayout title="Create solar company portal">
      <p className={styles.paragraph}>
        Your solar company portal will be used to invite customers to apply and to track your deals throughout the
        process. Please create a single login for your company representative(s) to use.
      </p>
      <FormProvider {...formMethods}>
        <FormContainer className={styles.formContainer} onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className={styles.inputContainer}>
            <TextInput
              {...formMethods.register('email')}
              onChange={(event) => handleChangeEmail(event.target.value)}
              label="Email"
              disabled
              value={deleteSpaceInStart(formMethods.watch('email'))}
              errorMessage={formMethods.formState.errors.email?.message}
              inputMode="email"
            />
          </div>
          <ConfirmPasswordForm />
          <Button title="Submit" inactive={isSubmitInactive()} className={styles.submitButton} type="submit" />
        </FormContainer>
      </FormProvider>
    </LoginLayout>
  );
};

export default CreateIntermediaryAccount;
