import React, { useState, useEffect } from 'react';

import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Link from 'common/components/Link';
import CodeVerification from 'components/CodeVerification/CodeVerification';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import PageLayout from 'layouts';
import styles from 'pages/pages.module.scss';
import { getBorrowerEmail, getIsLoading } from 'selectors';
import { useAppDispatch } from 'store';
import { resendEmailCodeForBorrower, verifyEmailForBorrower } from 'thunks';

const EmailVerificationPage = () => {
  const dispatch = useAppDispatch();
  const borrowerEmail = useSelector(getBorrowerEmail);
  const loading = useSelector(getIsLoading);
  const [isEmailCodeValid, setIsEmailCodeValid] = useState<boolean | null>(null);

  const { control } = useForm();

  useEffect(() => {
    dispatch(resendEmailCodeForBorrower({}));
  }, []);

  const handleResendCode = async () => dispatch(resendEmailCodeForBorrower({}));

  const handleCodeEntered = async (value: string) => {
    const result = await dispatch(
      verifyEmailForBorrower({
        code: value,
      }),
    ).unwrap();
    setIsEmailCodeValid(!!result);
  };

  useEffect(() => {
    if (isEmailCodeValid && !loading) {
      dispatch(setCurrentStage({ stage: StagesType.PhoneVerification }));
    }
  }, [isEmailCodeValid, loading]);

  return (
    <PageLayout title="Email verification">
      <div className={styles.codeVerificationLabel}>
        <p>
          We will send you a 6-digit verification code to <Link href={`mailto:${borrowerEmail}`}>{borrowerEmail}</Link>
        </p>
      </div>
      <CodeVerification
        control={control}
        loading={loading}
        notificationWhiteBackground={false}
        onChange={handleCodeEntered}
        isCodeValid={!!isEmailCodeValid}
        resendCode={handleResendCode}
        caption="If you are unable to verify the email address above, please contact us at 800-493-1310."
      />
    </PageLayout>
  );
};

export default EmailVerificationPage;
