import React, { useState } from 'react';

import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';

import Button from 'common/components/Button';
import Link from 'common/components/Link';
import TextInput from 'common/components/TextInput';
import FormContainer from 'components/FormContainer';
import { getInvalidEmailErrorMessage, getRequiredErrorMessage, SomethingWentWrong } from 'errors/errors';
import { LoginLayout } from 'layouts';
import { PortalType } from 'layouts/withPortalLayout';
import { useAppDispatch } from 'store';
import { forgotBorrowerPassword, forgotContractorPassword } from 'thunks';
import { notify } from 'utils/notificationHelper';
import { getCorrectValueForEmail, getPatterns } from 'utils/validationHelper';

import { ILoginProps } from './Login';
import styles from './Login.module.scss';

export const ForgotPassword = ({ type }: ILoginProps) => {
  const [emailSent, setEmailSent] = useState(false);
  const dispatch = useAppDispatch();

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const email = watch('email');

  const onSubmit: SubmitHandler<FieldValues> = async () => {
    if (type === PortalType.Borrower) {
      const result = await dispatch(
        forgotBorrowerPassword({
          email,
        }),
      ).unwrap();
      if (result) {
        setEmailSent(true);
      } else {
        notify({ notification: SomethingWentWrong, whiteBackground: true });
      }
    } else if (type === PortalType.Contractor) {
      const result = await dispatch(
        forgotContractorPassword({
          email,
        }),
      ).unwrap();
      if (result) {
        setEmailSent(true);
      } else {
        notify({ notification: SomethingWentWrong, whiteBackground: true });
      }
    }
  };

  const handleTryAnotherEmail = () => {
    reset({
      email: '',
    });
    setEmailSent(false);
  };

  const handleChangeEmail = (value: string) => {
    setValue('email', getCorrectValueForEmail(value));
  };

  return (
    <LoginLayout title="Reset password">
      {!emailSent && (
        <FormContainer className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
          <p className={styles.loginParagraph}>
            Enter the email associated with your account and we'll send an email with instructions to reset your
            password
          </p>
          <div className={styles.inputContainer}>
            <TextInput
              {...register('email', {
                required: getRequiredErrorMessage('Email'),
                pattern: {
                  value: getPatterns().email,
                  message: getInvalidEmailErrorMessage(),
                },
              })}
              onChange={(event) => handleChangeEmail(event.target.value)}
              label="Email"
              value={email}
              errorMessage={errors.email?.message}
              inputMode="email"
            />
          </div>
          <Button title="Confirm email" inactive={!email} className={styles.submitButton} type="submit" />
        </FormContainer>
      )}
      {emailSent && (
        <>
          <p className={styles.loginParagraph}>
            We have sent a password recover instructions to <Link href={`mailto:${email}`}>{email}</Link>
          </p>
          <p>
            Did not receive email? Check your spam filter, or{' '}
            <span className={styles.clickable} onClick={handleTryAnotherEmail}>
              try another email address
            </span>
          </p>
        </>
      )}
    </LoginLayout>
  );
};
