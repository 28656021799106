export enum IntermediaryVariables {
  Name = 'intermediary_name',
  Type = 'intermediary_type',
  Email = 'intermediary_email',
  Phone = 'intermediary_phone',
  Id = 'intermediary_id_number',
  PrimaryContactFirstName = 'intermediary_primary_contact_first_name',
  PrimaryContactLastName = 'intermediary_primary_contact_last_name',
  IntermediaryFirstPaymentPercentage = 'intermediary_first_payment_percentage',
  IntermediarySecondPaymentPercentage = 'intermediary_second_payment_percentage',
}
