import React from 'react';

import { Slider as MaterialSlider } from '@mui/material';

import styles from './PriceInput.module.scss';

export interface MarkProps {
  value: number;
  label?: string;
}

export interface SliderProps {
  min: number;
  max: number;
  marks: MarkProps[];
  value: number | number[];
  onChange: (event: Event, newValue: number | number[]) => void;
  step: number | null;
}

const Slider = ({ min, max, marks, step, value, onChange }: SliderProps) => {
  const handleChange = (event: Event, newValue: number | number[]) => {
    onChange(event, newValue);
  };
  return (
    <MaterialSlider
      classes={{
        root: styles.materialSliderRoot,
        rail: styles.materialSliderRail,
        track: styles.materialSliderTrack,
        thumb: styles.materialSliderThumb,
        mark: styles.materialSliderMark,
        markLabel: styles.materialSliderMarkLabel,
        markActive: styles.materialSliderMarkActive,
        active: styles.materialSliderMarkActive,
      }}
      min={min}
      max={max}
      marks={marks}
      track={false}
      step={step}
      value={value}
      onChange={handleChange}
    />
  );
};

export default Slider;
