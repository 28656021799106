import React, { FC, useCallback, useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { FileError, FileRejection, FileWithPath, useDropzone } from 'react-dropzone';

import { ReactComponent as DeleteIcon } from 'assets/icons/DeleteIcon.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/Trash.svg';
import { MobileScreenWidth } from 'enums/MobileScreenWidth';
import { convertFileSize } from 'utils/formats';
import { notify } from 'utils/notificationHelper';

import styles from './DropZone.module.scss';

const MAX_FILES = 50;
const DEFAULT_MAX_FILE_SIZE = 20971520;

const cx = classNames.bind(styles);

interface IDropZoneProps {
  files: IFileWithError[];
  setFiles: React.Dispatch<React.SetStateAction<IFileWithError[]>>;
  fileName: string;
  titleName?: string;
  titleStyle?: string;
  dropZoneStyle?: string;
  disabled?: boolean;
  description?: string;
  descriptionStyle?: string;
}

export interface IFileWithError extends FileWithPath {
  error?: string;
}

enum DisplayErrors {
  InvalidType = 'Wrong file format',
  FileTooLarge = 'File is too big',
  TooManyFiles = 'Whoops! Maximum number of files is',
}

enum ErrorCode {
  FileTooLarge = 'file-too-large',
  InvalidType = 'file-invalid-type',
}

const displayFileErrors = (error: string) => {
  const errors = error.split(',');
  return (
    <ul className={styles.fileError}>
      {errors.length > 1 ? (
        <>
          <li className={styles.errorsListItem}>{errors[0]}</li>
          <li className={styles.errorsListItem}>{errors[1]}</li>
        </>
      ) : (
        errors[0]
      )}
    </ul>
  );
};

const DropZone: FC<IDropZoneProps> = ({
  files,
  setFiles,
  titleStyle,
  titleName,
  dropZoneStyle,
  disabled,
  description,
  descriptionStyle,
  fileName,
}) => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const isMobileScreen = screenWidth < MobileScreenWidth.Middle;

  const handleWindowSizeChange = () => setScreenWidth(window.innerWidth);
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => window.removeEventListener('resize', handleWindowSizeChange);
  }, []);

  const removeFile = (index: number) => {
    setFiles(files.filter((x, i) => i !== index));
  };

  const renameFile = (filesWithPath: FileWithPath[], name: string) =>
    filesWithPath.map((file) => {
      const newFile: FileWithPath = new File([file], `${name}.${file.type.slice(file.type.indexOf('/') + 1)}`, {
        type: file.type,
      });
      Object.assign(newFile, {
        path: file.path,
      });
      return newFile;
    });

  const checkErrorType = (error: string[], errorCode: ErrorCode) => error[0] === errorCode || error[1] === errorCode;

  const getErrors = (error: string[], isOneFile: boolean) => {
    let errors;
    if (checkErrorType(error, ErrorCode.InvalidType)) {
      if (isOneFile) notify({ notification: DisplayErrors.InvalidType });
      else errors = DisplayErrors.InvalidType;
    }
    if (checkErrorType(error, ErrorCode.FileTooLarge)) {
      if (isOneFile) notify({ notification: DisplayErrors.FileTooLarge });
      else errors = errors ? `${errors}, ${DisplayErrors.FileTooLarge}` : DisplayErrors.FileTooLarge;
    }
    return errors;
  };

  const verifyFile = (file: File, error: string[], isOneFile: boolean) => {
    if (isOneFile) {
      getErrors(error, isOneFile);
      return;
    }
    return Object.assign(file, { error: getErrors(error, isOneFile) }) as IFileWithError;
  };

  const getFilesWithError = (rejectedFiles: FileRejection[], isOneFile: boolean) => {
    const filesWithError: IFileWithError[] = [];
    rejectedFiles.forEach((file: FileRejection) => {
      const errors = file.errors.map((e: FileError) => e.code);
      const result = verifyFile(file.file, errors, isOneFile);
      if (result) filesWithError.push(result);
    });

    return filesWithError;
  };

  const onDropAccepted = (acceptedFiles: IFileWithError[], rejectedFiles: FileRejection[]) => {
    const filesWithError = rejectedFiles.length
      ? getFilesWithError(rejectedFiles, acceptedFiles.length + rejectedFiles.length < 2)
      : [];
    return files.length + acceptedFiles.length + filesWithError.length > MAX_FILES
      ? notify({ notification: `${DisplayErrors.TooManyFiles} ${MAX_FILES}` })
      : setFiles([...filesWithError, ...files, ...renameFile(acceptedFiles, fileName)]);
  };

  const onDrop = useCallback(
    (acceptedFiles, rejectedFiles) => (disabled ? null : onDropAccepted(acceptedFiles, rejectedFiles)),
    [files, disabled, fileName],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: ['.pdf', '.jpg', '.jpeg', '.gif', '.png'],
    maxFiles: MAX_FILES,
    maxSize: DEFAULT_MAX_FILE_SIZE, // 10mb
  });

  const getCollapsedFileName = (uploadedFileName: string) => {
    const [name, extension] = uploadedFileName.split(/\.(?=[^.]+$)/);

    return (
      <div className={styles.uploadedFileNameContainer}>
        <p className={styles.uploadedFileName}>{name}</p>
        <p>{`.${extension}`}</p>
      </div>
    );
  };

  return (
    <>
      {!!titleName && <p className={classNames(styles.titleName, titleStyle)}>{titleName}</p>}
      {!!description && <p className={classNames(styles.description, descriptionStyle)}>{description}</p>}
      <div
        className={cx(styles.dropzone, dropZoneStyle, {
          [styles.draggingDropzone]: disabled ? false : isDragActive,
          [styles.disabledDropzone]: disabled,
        })}
        {...getRootProps()}
      >
        <input {...getInputProps()} disabled={disabled} />
        <p
          className={cx(styles.dropzoneTitle, {
            [styles.disabledTitle]: disabled,
          })}
        >
          {isMobileScreen ? (
            <span className={styles.browseText}>Take a photo or browse for files to upload</span>
          ) : (
            <>
              Drag and drop files here to upload Or <span className={styles.browseText}>browse for files</span>
            </>
          )}
        </p>
        <p className={styles.dropzoneNote}>
          Acceptable file formats are PDF, JPG, JPEG, GIF and PNG; maximum file size 20MB
        </p>
      </div>
      {!!files.length && (
        <aside className={styles.filesContainer}>
          <ul>
            {files.map((file, index) => (
              <li className={cx(styles[file.error ? 'rejectedFile' : 'acceptedFile'])} key={index}>
                <div className={styles.fileNameContainer}>
                  {file.path && getCollapsedFileName(file.path)}
                  <span className={styles.fileSize}>
                    {file.error ? displayFileErrors(file.error) : convertFileSize(file.size)}
                  </span>
                </div>
                {file.error ? (
                  <DeleteIcon
                    className={cx(styles.deleteIcon, styles.deleteRejectedIcon)}
                    onClick={() => removeFile(index)}
                  />
                ) : (
                  <TrashIcon className={styles.deleteIcon} onClick={() => removeFile(index)} />
                )}
              </li>
            ))}
          </ul>
        </aside>
      )}
    </>
  );
};

export default DropZone;
