import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CoborrowerInfoKeys } from './types';

interface CoborrowerState {
  [CoborrowerInfoKeys.Email]: string;
  [CoborrowerInfoKeys.PhoneNumber]: string;
  [CoborrowerInfoKeys.FirstName]: string;
  [CoborrowerInfoKeys.LastName]: string;
  [CoborrowerInfoKeys.DateOfBirth]: string;
  [CoborrowerInfoKeys.CitizenshipStatus]: string;
  [CoborrowerInfoKeys.CoborrowerLives]: boolean | null;
  [CoborrowerInfoKeys.CoborrowerOwnership]: string;
  [CoborrowerInfoKeys.SSN]: string;
  [CoborrowerInfoKeys.StreetAddress]: string;
  [CoborrowerInfoKeys.City]: string;
  [CoborrowerInfoKeys.ZipOrPostalCode]: string;
  [CoborrowerInfoKeys.State]: string;
}

const initialState: CoborrowerState = {
  [CoborrowerInfoKeys.Email]: '',
  [CoborrowerInfoKeys.PhoneNumber]: '',
  [CoborrowerInfoKeys.FirstName]: '',
  [CoborrowerInfoKeys.LastName]: '',
  [CoborrowerInfoKeys.DateOfBirth]: '',
  [CoborrowerInfoKeys.CitizenshipStatus]: '',
  [CoborrowerInfoKeys.CoborrowerLives]: null,
  [CoborrowerInfoKeys.CoborrowerOwnership]: '',
  [CoborrowerInfoKeys.SSN]: '',
  [CoborrowerInfoKeys.StreetAddress]: '',
  [CoborrowerInfoKeys.City]: '',
  [CoborrowerInfoKeys.ZipOrPostalCode]: '',
  [CoborrowerInfoKeys.State]: '',
};

const coborrower = createSlice({
  name: 'coborrower',
  initialState,
  reducers: {
    setCoborrowerData: (state: CoborrowerState, { payload }: PayloadAction<Partial<CoborrowerState>>) => {
      state = { ...state, ...payload };

      return state;
    },
  },
});

export const { setCoborrowerData } = coborrower.actions;

export default coborrower.reducer;
