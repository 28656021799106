import React from 'react';

import classNames from 'classnames/bind';

import styles from './TextLoader.module.scss';

const cx = classNames.bind(styles);

interface ITextLoaderProps {
  width: string;
  maxWidth?: string;
}

const TextLoader = ({ width, maxWidth }: ITextLoaderProps) => (
  <div style={{ width, maxWidth }} className={cx(styles.loader)} />
);

export default TextLoader;
