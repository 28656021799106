import React from 'react';

import classnames from 'classnames/bind';

import { MonthName } from 'enums/MonthName';

import styles from './DatePicker.module.scss';

interface MonthPickerProps {
  selectedMonth: number | string;
  onMonthPicked: (value: number) => void;
}

const cx = classnames.bind(styles);

const MonthPicker = ({ selectedMonth, onMonthPicked }: MonthPickerProps) => {
  const monthNames = Object.values(MonthName);

  return (
    <>
      <div className={styles.monthWrapper}>
        {monthNames.map((month, index) => (
          <div className={styles.selectButtonWrapper} key={month}>
            <div
              className={cx(styles.selectButton, {
                [styles.selectButtonActive]: Number(selectedMonth) - 1 === index,
              })}
              onClick={() => onMonthPicked(index + 1)}
            >
              {month}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default MonthPicker;
