import { HttpClient } from './Api';

interface CreateAccountResponse {
  accessToken: string;
  refreshToken: string;
  isMfaIncomplete: boolean | null;
  isEmailNotVerified: boolean | null;
}

interface InviteDetailsResponse {
  accountId: string;
  intermediaryId: string;
}

export interface AccountParams {
  createAccount(email: string, password: string, phone: string, borrowerId: string): Promise<CreateAccountResponse>;
  getBorrowerAccount(email: string): Promise<Record<string, any>>;
  verifyEmailCode(accessToken: string, code: string): Promise<{ success: boolean }>;
  verifyEmailCodeForContractor(accessToken: string, code: string): Promise<{ success: boolean }>;
  sendEmailCode(accessToken: string): Promise<{ success: boolean }>;
  sendEmailCodeForContractor(accessToken: string): Promise<{ success: boolean }>;
  sendPhoneCode(accessToken: string, phone: string): Promise<{ success: boolean }>;
  sendPhoneCodeForContractor(accessToken: string, phone: string): Promise<{ success: boolean }>;
  verifyPhoneCode(accessToken: string, code: string): Promise<{ success: boolean }>;
  verifyPhoneCodeForContractor(accessToken: string, code: string): Promise<{ success: boolean }>;
  loginBorrower(email: string, password: string): Promise<CreateAccountResponse>;
  loginContractor(email: string, password: string): Promise<CreateAccountResponse>;
  logoutBorrower(accessToken: string): Promise<{ success: boolean }>;
  logoutContractor(accessToken: string): Promise<{ success: boolean }>;
  requestBorrowerResetPassword(email: string): Promise<{ success: boolean }>;
  requestContractorResetPassword(email: string): Promise<{ success: boolean }>;
  resetBorrowerPassword(password: string, resetPasswordToken: string): Promise<{ success: boolean }>;
  resetContractorPassword(password: string, resetPasswordToken: string): Promise<{ success: boolean }>;
  getInviteDetails(token: string): Promise<InviteDetailsResponse>;
  acceptInvite(token: string, password: string): Promise<CreateAccountResponse>;
  getBorrowerAccountByToken(token: string): Promise<CreateAccountResponse>;
}

interface SuccessResponse {
  success: boolean;
}

export const LOGIN_URL = '/api/account/login';
export const CONTRACTOR_LOGIN_URL = '/api/account/intermediary/login';
export default class AccountApi extends HttpClient implements AccountParams {
  public async createAccount(email: string, password: string, phone: string, borrowerId: string) {
    return this.instance
      .post<CreateAccountResponse>('/api/account', {
        email,
        password,
        phone,
        borrowerId,
      })
      .then((res) => res.data);
  }

  public async createIntermediaryAccount(email: string, password: string, intermediaryId: string) {
    return this.instance
      .post<CreateAccountResponse>('/api/account/intermediary', {
        email,
        password,
        intermediaryId,
      })
      .then((res) => res.data);
  }

  public async verifyEmailCode(code: string) {
    return this.instance
      .post<SuccessResponse>('/api/account/verify-email', {
        code,
      })
      .then((res) => res.data);
  }

  public async verifyEmailCodeForContractor(code: string) {
    return this.instance
      .post<SuccessResponse>('/api/account/intermediary/verify-email', {
        code,
      })
      .then((res) => res.data);
  }

  public async sendEmailCode() {
    return this.instance.post<SuccessResponse>('/api/account/email-codes').then((res) => res.data);
  }

  public async sendEmailCodeForContractor() {
    return this.instance.post<SuccessResponse>('/api/account/intermediary/email-codes').then((res) => res.data);
  }

  public async sendPhoneCode(phone?: string) {
    return this.instance
      .post<SuccessResponse>('/api/account/mfa-codes', {
        phone,
      })
      .then((res) => res.data);
  }

  public async verifyPhoneCode(code: string) {
    return this.instance
      .post<SuccessResponse>('/api/account/verify-mfa-code', {
        code,
      })
      .then((res) => res.data);
  }

  public async sendPhoneCodeForContractor(phone?: string) {
    return this.instance
      .post<SuccessResponse>('/api/account/intermediary/mfa-codes', {
        phone,
      })
      .then((res) => res.data);
  }

  public async verifyPhoneCodeForContractor(code: string) {
    return this.instance
      .post<SuccessResponse>('/api/account/intermediary/verify-mfa-code', {
        code,
      })
      .then((res) => res.data);
  }

  public async loginBorrower(email: string, password: string) {
    return this.instance
      .post<CreateAccountResponse>(LOGIN_URL, {
        email,
        password,
      })
      .then((res) => res.data);
  }

  public async loginContractor(email: string, password: string) {
    return this.instance
      .post<CreateAccountResponse>(CONTRACTOR_LOGIN_URL, {
        email,
        password,
      })
      .then((res) => res.data);
  }

  public async logoutBorrower() {
    return this.instance.post<SuccessResponse>('/api/account/logout').then((res) => res.data);
  }

  public async logoutContractor() {
    return this.instance.post<SuccessResponse>('/api/account/intermediary/logout').then((res) => res.data);
  }

  public async requestBorrowerResetPassword(email: string) {
    return this.instance
      .post<SuccessResponse>('/api/account/request-reset-password', {
        email,
      })
      .then((res) => res.data);
  }

  public async resetBorrowerPassword(password: string, resetPasswordToken: string) {
    return this.instance
      .put<SuccessResponse>('/api/account/reset-password', {
        password,
        resetPasswordToken,
      })
      .then((res) => res.data);
  }

  public async requestContractorResetPassword(email: string) {
    return this.instance
      .post<SuccessResponse>('/api/account/intermediary/request-reset-password', {
        email,
      })
      .then((res) => res.data);
  }

  public async resetContractorPassword(password: string, resetPasswordToken: string) {
    return this.instance
      .put<SuccessResponse>('/api/account/intermediary/reset-password', {
        password,
        resetPasswordToken,
      })
      .then((res) => res.data);
  }

  public async getBorrowerAccount(email: string): Promise<Record<string, any>> {
    return this.instance
      .get<Record<string, any>>(`/api/account?email=${encodeURIComponent(email)}`)
      .then((res) => res.data);
  }

  public async getInviteDetails(token: string): Promise<InviteDetailsResponse> {
    return this.instance.get(`/api/account/intermediary/invite?token=${token}`).then((res) => res.data);
  }

  public async acceptInvite(token: string, password: string): Promise<CreateAccountResponse> {
    return this.instance
      .post('/api/account/intermediary/invite', {
        token,
        password,
      })
      .then((res) => res.data);
  }

  public async getBorrowerAccountByToken(token: string) {
    return this.instance
      .post('/api/account/borrower/get-by-token', {
        token,
      })
      .then((res) => res.data);
  }
}
