import React, { FC } from 'react';

import { getMarks } from 'utils/getSliderMarks';

import InputPrice from './PriceInput';
import Slider from './Slider';

enum SliderOptions {
  Max = 80000,
  Min = 5000,
}

const DEFAULT_SLIDER_VALUE = 1000;
const MAX_INPUT_LENGTH = 9;

export interface PriceSliderProps {
  value?: number | number[];
  onChange: (value: number | number[]) => void;
  onBlur?: (value: number | number[]) => void;
}

const PriceSlider: FC<PriceSliderProps> = ({ value = DEFAULT_SLIDER_VALUE, onChange, onBlur }) => (
  <>
    <InputPrice maxLength={MAX_INPUT_LENGTH} value={value} onChange={onChange} onBlur={() => onBlur?.(value)} />
    <Slider
      value={value}
      max={SliderOptions.Max}
      min={SliderOptions.Min}
      step={null}
      marks={getMarks()}
      onChange={(event: Event, sliderValue: number | number[]) => onChange(sliderValue)}
    />
  </>
);

export default PriceSlider;
