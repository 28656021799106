import React from 'react';

import { useSelector } from 'react-redux';

import { InstallationSteps, Step } from 'handlers/steps/types';
import { WithProgressBar } from 'layouts';
import { getCurrentStep } from 'selectors';

import BorrowerAddressInfo from './steps/BorrowerAddressInfo';
import BorrowerOwnership from './steps/BorrowerOwnership';
import CoborrowerLives from './steps/CoborrowerLives';

const getPageByStep = (step: Step | null) => {
  switch (step) {
    case InstallationSteps.AddressInfo:
      return <BorrowerAddressInfo />;
    case InstallationSteps.OwnershipTerm:
      return <BorrowerOwnership />;
    case InstallationSteps.CoborrowerLives:
      return <CoborrowerLives />;
    default:
      return <BorrowerAddressInfo />;
  }
};

const InstallationPage = () => {
  const currentBasicInformationStep = useSelector(getCurrentStep);
  return (
    <WithProgressBar title="Installation information">{getPageByStep(currentBasicInformationStep)}</WithProgressBar>
  );
};

export default InstallationPage;
