import React from 'react';

import classnames from 'classnames/bind';

import styles from './RadioButton.module.scss';

interface RadioButtonProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  containerClassName?: string;
  disabled?: boolean;
}

const cx = classnames.bind(styles);

const RadioButton = React.forwardRef<HTMLInputElement, RadioButtonProps>(
  ({ label, containerClassName, id, disabled, ...restProps }, ref) => (
    <>
      <input className={styles.radioInput} id={id} type="radio" {...restProps} ref={ref} disabled={disabled} />
      <label
        htmlFor={id}
        className={cx(styles.radioButtonContainer, { [styles.disabledText]: disabled }, containerClassName)}
      >
        {label}
      </label>
    </>
  ),
);

export default RadioButton;
