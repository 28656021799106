import { Intermediary, IntermediaryShort } from 'types';

import { HttpClient } from './Api';

export interface IntermediaryParams {
  getIntermediaryBySciCode(sci: string): Promise<IntermediaryShort>;
  getIntermediaryByEmail(email: string): Promise<Intermediary>;
}

export default class IntermediaryApi extends HttpClient implements IntermediaryParams {
  public async getIntermediaryBySciCode(sci: string) {
    return this.instance.get<IntermediaryShort>(`/api/intermediaries/sci?sci=${sci}`).then((res) => res.data);
  }

  public async getIntermediaryById(id: string) {
    return this.instance.get<Intermediary>(`/api/intermediaries?id=${id}`).then((res) => res.data);
  }

  public async getIntermediaryByEmail() {
    return this.instance.get<Intermediary>(`/api/intermediaries/email`).then((res) => res.data);
  }
}
