import { HttpClient } from './Api';

export interface PropertyOwnersResponse {
  owners: string[];
  address: {
    street: string;
    city: string;
    state: string;
    zipCode: string;
  };
  parcelNumber: string;
  legalDescription: string;
  subdivision: string;
  lotNumber: string;
  sectionTownshipRange: string;
  municipality: string;
  countyName: string;
  fipsCode: string;
  landUseDescription: string;
  propertyType: string;
  fileLink: string;
}

export default class DataTreeApi extends HttpClient {
  public async getPropertyOwners(street: string, state: string, zipCode: string) {
    return this.instance
      .get<PropertyOwnersResponse>(`/api/data-tree?street=${street}&state=${state}&zipCode=${zipCode}`)
      .then((res) => res.data);
  }

  public async downloadDocument(link: string) {
    return this.instance
      .get(`api/data-tree/download-document?link=${link}`, {
        headers: {
          Accept: '*/*',
        },
        responseType: 'blob',
      })
      .then((response) => response.data);
  }
}
