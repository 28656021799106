import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum TableType {
  SortField = 'sortField',
  SortDirection = 'sortDirection',
  SearchValue = 'searchValue',
  ProjectDetails = 'projectDetails',
  StatusApplication = 'statusApplication',
  Voi = 'voi',
  PageIndex = 'pageIndex',
}
interface ITableOptions {
  [TableType.SortField]: string;
  [TableType.SortDirection]: string;
  [TableType.SearchValue]: string;
  [TableType.ProjectDetails]: null | string;
  [TableType.Voi]: null | string;
  [TableType.StatusApplication]: string[];
  [TableType.PageIndex]: number;
}

const initialState: ITableOptions = {
  [TableType.SortField]: 'createdAt',
  [TableType.SortDirection]: 'desc',
  [TableType.SearchValue]: '',
  [TableType.ProjectDetails]: null,
  [TableType.Voi]: null,
  [TableType.StatusApplication]: [],
  [TableType.PageIndex]: 0,
};

const tableOptions = createSlice({
  name: 'table-options',
  initialState,
  reducers: {
    setTableOptions(state: ITableOptions, { payload }: PayloadAction<Partial<ITableOptions>>) {
      state = { ...state, ...payload };
      return state;
    },
  },
});

export const { setTableOptions } = tableOptions.actions;

export default tableOptions.reducer;
