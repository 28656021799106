import React, { useEffect } from 'react';

import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import MonetaryInput from 'common/components/MonetaryInput/MonetaryInput';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { getRequiredErrorMessage } from 'errors/errors';
import { backStep } from 'handlers/actions';
import { ApplicationState, setApplicationData } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { nextStep } from 'handlers/steps';
import { EmploymentPageType } from 'pages/Employment/types';
import styles from 'pages/pages.module.scss';
import {
  getBorrowerEmploymentDuration,
  getBorrowerGrossIncome,
  getBorrowerPreviousGrossIncome,
  getCoborrowerEmploymentDuration,
  getCoborrowerExists,
  getCoborrowerGrossIncome,
  getCoborrowerPreviousGrossIncome,
} from 'selectors';
import { isRecentEmployment } from 'utils/isRecentEmployment';

type FormValues = {
  grossIncome: number | null;
};

interface IncomeInfoProps {
  type: EmploymentPageType;
}

enum IncomeQuestion {
  Borrower = 'What is your individual annual gross income (before taxes)?',
  Coborrower = "What is the co-borrower's individual annual gross income (before taxes)?",
  PreviousBorrower = 'In your previous position, what was your individual annual gross income (before taxes)?',
  PreviousCoborrower = "In their previous position, what was the co-borrower's individual annual gross income (before taxes)?",
}

enum IncomeNote {
  Borrower = 'Please refer to your paystub records so that you can verify your income if asked to do so.',
  Coborrower = 'Please refer to their paystubs or other documents so that they can verify their income if asked to do so.',
  PreviousBorrower = 'Please refer to your paystub records so that you can verify your income if asked to do so.',
  PreviousCoborrower = 'Please refer to their paystubs or other documents so that they can verify their income if asked to do so.',
}

const getGrossIncome = (type: EmploymentPageType) => {
  switch (type) {
    case EmploymentPageType.Borrower:
      return useSelector(getBorrowerGrossIncome);
    case EmploymentPageType.Coborrower:
      return useSelector(getCoborrowerGrossIncome);
    case EmploymentPageType.PreviousBorrower:
      return useSelector(getBorrowerPreviousGrossIncome);
    case EmploymentPageType.PreviousCoborrower:
      return useSelector(getCoborrowerPreviousGrossIncome);
    default:
      return useSelector(getBorrowerGrossIncome);
  }
};

const IncomeInfo = ({ type }: IncomeInfoProps) => {
  const dispatch = useDispatch();

  const coborrowerExists = useSelector(getCoborrowerExists);
  let recentEmployment: boolean;
  if (type === EmploymentPageType.Borrower) {
    recentEmployment = isRecentEmployment(useSelector(getBorrowerEmploymentDuration));
  } else if (type === EmploymentPageType.Coborrower) {
    recentEmployment = isRecentEmployment(useSelector(getCoborrowerEmploymentDuration));
  }

  const {
    control,
    handleSubmit,
    watch,
    register,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      grossIncome: getGrossIncome(type),
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });
  const watcher = watch();

  useEffect(() => {
    register('grossIncome', { required: getRequiredErrorMessage('Gross income') });
  }, [register, watcher]);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    const grossIncome: Partial<ApplicationState> = {};
    let nextStage;
    switch (type) {
      case EmploymentPageType.Borrower:
        Object.assign(grossIncome, {
          [ApplicationVariables.BorrowerGrossIncome]: Number(data.grossIncome),
        });
        if (recentEmployment) {
          nextStage = null;
        } else if (coborrowerExists) {
          nextStage = StagesType.CoborrowerEmployment;
        } else {
          nextStage = StagesType.CheckRateAndPayments;
        }
        break;
      case EmploymentPageType.Coborrower:
        Object.assign(grossIncome, {
          [ApplicationVariables.CoborrowerGrossIncome]: Number(data.grossIncome),
        });
        nextStage = recentEmployment ? null : StagesType.CheckRateAndPayments;
        break;
      case EmploymentPageType.PreviousBorrower:
        Object.assign(grossIncome, {
          [ApplicationVariables.BorrowerPreviousGrossIncome]: Number(data.grossIncome),
        });
        nextStage = coborrowerExists ? StagesType.CoborrowerEmployment : StagesType.CheckRateAndPayments;
        break;
      case EmploymentPageType.PreviousCoborrower:
        Object.assign(grossIncome, {
          [ApplicationVariables.CoborrowerPreviousGrossIncome]: Number(data.grossIncome),
        });
        nextStage = StagesType.CheckRateAndPayments;
        break;
      default:
        break;
    }
    dispatch(setApplicationData(grossIncome));
    if (nextStage) {
      dispatch(setCurrentStage({ stage: nextStage }));
    } else {
      dispatch(nextStep());
    }
  };
  const isSubmitInactive = () => !watcher.grossIncome && watcher.grossIncome !== 0;

  const handleBackClick = () => {
    dispatch(backStep());
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.textInputLabel}>{IncomeQuestion[type]}</div>
      <p className={styles.inputLabelNote}>{IncomeNote[type]}</p>
      <div className={styles.inputContainer}>
        <MonetaryInput
          control={control}
          name="grossIncome"
          label="Gross income"
          value={watcher.grossIncome?.toString()}
          errorMessage={errors.grossIncome?.message}
          referenceInfo="Please include wages, commissions, bonuses and all other income"
        />
      </div>
      <NavigationButtonWrapper>
        <BackButton onClick={handleBackClick} />
        <NextButton type="submit" inactive={isSubmitInactive()} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default IncomeInfo;
