import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { setCoborrowerExists } from 'handlers/application';
import { Step } from 'handlers/steps/types';

import { StagesType } from './types';

export interface ProgressStage {
  name: StagesType;
  isPassed: boolean;
}

export interface StageActionPayload {
  stage: StagesType;
  step?: Step;
}

const progressStages: ProgressStage[] = [
  {
    name: StagesType.BasicInformation,
    isPassed: false,
  },
  {
    name: StagesType.CoborrowerInfo,
    isPassed: false,
  },
  {
    name: StagesType.Installation,
    isPassed: false,
  },
  {
    name: StagesType.Employment,
    isPassed: false,
  },
];

interface Stages {
  currentStage: StagesType;
  previousStage: StagesType | null;
  progressStages: ProgressStage[];
}

export type StagesState = Stages;

const initialState: StagesState = {
  currentStage: StagesType.BasicInformation,
  previousStage: null,
  progressStages,
};

const stages = createSlice({
  name: 'stages',
  initialState,
  reducers: {
    setCurrentStage: (state: StagesState, { payload }: PayloadAction<StageActionPayload>) => {
      state.progressStages = state.progressStages.map((stage) => {
        if (stage.name === state.currentStage)
          return {
            name: state.currentStage,
            isPassed: true,
          };
        return stage;
      });

      state.previousStage = state.currentStage;
      state.currentStage = payload.stage;

      return state;
    },
    setStages: (state: StagesState, { payload }: PayloadAction<ProgressStage[]>) => {
      state.progressStages = payload;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCoborrowerExists, (state: StagesState, { payload }: PayloadAction<boolean | null>) => {
      const coborrowerEmploymentStageExists = state.progressStages.some(
        (stage) => stage.name === StagesType.CoborrowerEmployment,
      );

      if (!payload) {
        if (coborrowerEmploymentStageExists) state.progressStages.pop();
        return state;
      }

      if (coborrowerEmploymentStageExists) return;

      state.progressStages.push({
        name: StagesType.CoborrowerEmployment,
        isPassed: false,
      });

      return state;
    });
  },
});

export const { setCurrentStage, setStages } = stages.actions;
export default stages.reducer;
