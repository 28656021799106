import { HttpClient } from './Api';

export default class SunApi extends HttpClient {
  public async create(
    Email: string,
    FirstName: string,
    LastName: string,
    MailingStreet: string,
    MailingCity: string,
    MailingState: string,
    MailingPostalCode: string,
    Phone: string,
  ) {
    return this.instance
      .post<{ success: boolean }>('/api/sun', {
        Email,
        FirstName,
        LastName,
        MailingStreet,
        MailingCity,
        MailingState,
        MailingPostalCode,
        Phone,
      })
      .then((res) => res.data);
  }
}
