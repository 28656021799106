import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';

export interface ResetPasswordProps {
  password: string;
  resetPasswordToken: string;
}

export const createResetBorrowerPasswordThunk = (accountApi: AccountApi) =>
  createAsyncThunk(
    'account/borrowerReset',
    async ({ password, resetPasswordToken }: ResetPasswordProps, { rejectWithValue }) => {
      try {
        const result = await accountApi.resetBorrowerPassword(password, resetPasswordToken);
        if (result.success) {
          return true;
        }
        rejectWithValue(null);
      } catch (e) {
        rejectWithValue(null);
      }
    },
  );
