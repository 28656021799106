import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';
import { UserType } from 'enums/UserType';

interface AcceptInviteProps {
  token: string;
  password: string;
}

export const createAcceptInviteThunk = (accountApi: AccountApi) =>
  createAsyncThunk('invite/accept', async ({ token, password }: AcceptInviteProps, { rejectWithValue }) => {
    try {
      const result = await accountApi.acceptInvite(token, password);
      sessionStorage.setItem('userType', UserType.Contractor);
      localStorage.setItem('contractorToken', result!.accessToken);
      localStorage.setItem('contractorRefreshToken', result!.refreshToken);

      return result;
    } catch (e) {
      rejectWithValue(null);
    }
  });
