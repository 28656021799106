import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import PageLoader from 'components/PageLoader';
import { UserType } from 'enums/UserType';
import { ApplicationVariables } from 'handlers/application/types';
import { setCustomerData } from 'handlers/customer';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { setSteps } from 'handlers/steps';
import { BasicInformationSteps, EmploymentSteps, Step, UploadDocumentsSteps } from 'handlers/steps/types';
import BasicInformation from 'pages/BasicInformation';
import { getApplicationId, getApplicationStatus, getCurrentStage, getCurrentStep } from 'selectors';
import { useAppDispatch } from 'store';
import { getApplication, getBorrowerAccount } from 'thunks';
import { Application, ApplicationStatus, Routes, SoftPullStatus } from 'types';

import CreateAccount from './Account';
import EmailVerificationPage from './Account/EmailVerification';
import PhoneVerificationPage from './Account/PhoneVerificationPage';
import CheckRateAndPayments from './CheckRateAndPayment';
import CoborrowerInfo from './CoborrowerInfo';
import CreditApproval from './CreditApproval';
import DeclinePage from './DeclinePage';
import EmploymentPage from './Employment/Employment';
import Installation from './Installation';
import NotificationsSalesperson from './NotificationsSalesperson';
import Offers from './Offers';
import OwnersCheck from './OwnersCheck';
import SCIPage from './SCI';
import UploadDocuments from './UploadDocuments';

interface RouteTypes {
  stage: StagesType;
  step?: Step;
}

const getEmploymentPageTitle = (step: Step) => {
  switch (step) {
    case EmploymentSteps.BorrowerPreviousEmployment:
    case EmploymentSteps.BorrowerPreviousIncome:
      return 'Previous employment information';
    case EmploymentSteps.CoborrowerPreviousEmployment:
    case EmploymentSteps.CoborrowerPreviousIncome:
      return 'Co-borrower previous employment information';
    case EmploymentSteps.CoborrowerEmploymentInfo:
    case EmploymentSteps.CoborrowerIncome:
      return 'Co-borrower employment information';
    default:
      return 'Employment information';
  }
};

const Stages = () => {
  const stage = useSelector(getCurrentStage);
  const currentStep = useSelector(getCurrentStep);
  const { search, pathname } = useLocation();
  const applicationId = new URLSearchParams(search).get('applicationId');
  const storedApplicationId = useSelector(getApplicationId);
  const applicationStatus = useSelector(getApplicationStatus);
  const authToken = localStorage.getItem('borrowerToken');
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();

  const resumeApplication = async (application: Application) => {
    const { variables, status, coborrowerIds } = application;

    const route: RouteTypes = { stage: StagesType.BasicInformation, step: BasicInformationSteps.LoanPurpose };
    const steps = [];
    const isJointApplication = coborrowerIds.length > 0;

    const setNewRoute = (currentRoute: RouteTypes, stageType: StagesType, step?: Step) => {
      currentRoute.stage = stageType;
      currentRoute.step = step;
    };

    if (status.name === ApplicationStatus.PreQualified) {
      if (
        (variables[ApplicationVariables.CheckRateAndPaymentsAgreement] &&
          variables[ApplicationVariables.LoanTermList] == null &&
          variables[ApplicationVariables.PotentialOffersDisplayed] === false &&
          (variables[ApplicationVariables.SoftPullResult] === SoftPullStatus.Approved ||
            variables[ApplicationVariables.SoftPullResult] === SoftPullStatus.Received)) ||
        (variables[ApplicationVariables.IntermediaryConfirmed] == null &&
          variables[ApplicationVariables.PotentialOffersDisplayed])
      ) {
        setNewRoute(route, StagesType.Offers);
      } else if (
        !variables[ApplicationVariables.IntermediaryConfirmed] &&
        !!variables[ApplicationVariables.LoanTermList]
      ) {
        setNewRoute(route, StagesType.SCICode);
      } else if (
        variables[ApplicationVariables.IntermediaryConfirmed] &&
        variables[ApplicationVariables.AdditionalSalespersonExists] == null
      ) {
        setNewRoute(route, StagesType.SalespersonInfo);
      }
    } else if (status.name === ApplicationStatus.CompletingVerification) {
      const borrowerAccount = await dispatch(
        getBorrowerAccount({
          email: variables[ApplicationVariables.BorrowerEmail],
        }),
      ).unwrap();
      if (!borrowerAccount) {
        dispatch(
          setCustomerData({
            borrower_email: variables[ApplicationVariables.BorrowerEmail],
            borrower_phone: variables[ApplicationVariables.BorrowerPhone],
          }),
        );
        setNewRoute(route, StagesType.CreateAccount);
      } else if (borrowerAccount.isEmailNotVerified) {
        setNewRoute(route, StagesType.EmailVerification);
      } else if (borrowerAccount.isMfaIncomplete) {
        setNewRoute(route, StagesType.PhoneVerification);
      } else if (!localStorage.getItem('borrowerToken')) {
        history.replace(Routes.BorrowerLogin);
      } else if (variables[ApplicationVariables.AccountCreationComplete]) {
        setNewRoute(route, StagesType.UploadDocuments);
        steps.push(UploadDocumentsSteps.BorrowerIdentity);
        if (isJointApplication) {
          steps.push(UploadDocumentsSteps.CoborrowerIdentity);
          if (variables[ApplicationVariables.VoiNeeded] || variables[ApplicationVariables.PotentialOffersDisplayed]) {
            steps.push(UploadDocumentsSteps.BorrowerIncome, UploadDocumentsSteps.CoborrowerIncome);
          }
        } else if (
          variables[ApplicationVariables.VoiNeeded] ||
          variables[ApplicationVariables.PotentialOffersDisplayed]
        ) {
          steps.push(UploadDocumentsSteps.BorrowerIncome);
        }
        if (!variables[ApplicationVariables.UploadBorrowerIdComplete]) {
          setNewRoute(route, StagesType.UploadDocuments, UploadDocumentsSteps.BorrowerIdentity);
        } else if (isJointApplication && !variables[ApplicationVariables.UploadCoborrowerIdComplete]) {
          setNewRoute(route, StagesType.UploadDocuments, UploadDocumentsSteps.CoborrowerIdentity);
        } else if (
          variables[ApplicationVariables.UploadBorrowerIdComplete] &&
          (variables[ApplicationVariables.VoiNeeded] || variables[ApplicationVariables.PotentialOffersDisplayed]) &&
          variables[ApplicationVariables.BorrowerGrossIncome] > 0 &&
          !variables[ApplicationVariables.UploadBorrowerVoiComplete]
        ) {
          setNewRoute(route, StagesType.UploadDocuments, UploadDocumentsSteps.BorrowerIncome);
        } else if (
          isJointApplication &&
          (variables[ApplicationVariables.UploadBorrowerVoiComplete] ||
            variables[ApplicationVariables.BorrowerGrossIncome] === 0) &&
          variables[ApplicationVariables.CoborrowerGrossIncome] > 0 &&
          !variables[ApplicationVariables.UploadCoborrowerVoiComplete]
        ) {
          setNewRoute(route, StagesType.UploadDocuments, UploadDocumentsSteps.CoborrowerIncome);
        }
      }
    } else {
      history.push(Routes.BorrowerLogin);
    }
    dispatch(setCurrentStage(route));
    if (steps.length) {
      dispatch(setSteps(steps));
    }
  };

  const render = () => {
    switch (stage) {
      case StagesType.BasicInformation:
        return <BasicInformation />;
      case StagesType.PhoneVerification:
        return <PhoneVerificationPage />;
      case StagesType.Installation:
        return <Installation />;
      case StagesType.CoborrowerInfo:
        return <CoborrowerInfo />;
      case StagesType.OwnersCheck:
        return <OwnersCheck />;
      case StagesType.Employment:
        return <EmploymentPage title={getEmploymentPageTitle(currentStep)} />;
      case StagesType.CoborrowerEmployment:
        return <EmploymentPage title={getEmploymentPageTitle(currentStep)} />;
      case StagesType.CheckRateAndPayments:
        return <CheckRateAndPayments />;
      case StagesType.Offers:
        return <Offers />;
      case StagesType.CreateAccount:
        return <CreateAccount />;
      case StagesType.EmailVerification:
        return <EmailVerificationPage />;
      case StagesType.SCICode:
        return <SCIPage />;
      case StagesType.UploadDocuments:
        return <UploadDocuments />;
      case StagesType.SalespersonInfo:
        return <NotificationsSalesperson />;
      case StagesType.Decline:
        return <DeclinePage title="We're sorry." />;
      case StagesType.ContactUs:
        return <DeclinePage title="Unfortunately, you can't apply for an ezSolarLoan at this time." contactUs />;
      case StagesType.CreditApproval:
        return <CreditApproval />;
      default:
        return <BasicInformation />;
    }
  };

  useEffect(() => {
    if (
      applicationStatus &&
      storedApplicationId &&
      authToken &&
      pathname.includes('apply') &&
      !pathname.includes('applicationId')
    ) {
      if (
        applicationStatus === ApplicationStatus.CompletingVerification ||
        applicationStatus === ApplicationStatus.PreQualified
      ) {
        history.replace(`${Routes.Apply}?applicationId=${storedApplicationId}`);
      } else {
        history.replace(Routes.BorrowerDashboard);
      }
    }
  }, [authToken, storedApplicationId]);

  useEffect(() => {
    async function fetchApplication() {
      setIsLoading(true);
      sessionStorage.setItem('userType', UserType.Borrower);
      const application = await dispatch(
        getApplication({
          id: applicationId as string,
        }),
      ).unwrap();
      await resumeApplication(application);
      setIsLoading(false);
    }
    if (applicationId) {
      fetchApplication();
    }
  }, [applicationId]);

  return <>{isLoading ? <PageLoader /> : render()}</>;
};

export default Stages;
