import React from 'react';

import classNames from 'classnames/bind';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import Link from 'common/components/Link';
import PhoneInput from 'common/components/PhoneInput';
import TextInput from 'common/components/TextInput';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { getRequiredErrorMessage, getInvalidEmailErrorMessage } from 'errors/errors';
import { withPortalLayout } from 'layouts/withPortalLayout';
import styles from 'pages/pages.module.scss';
import { getIntermediaryName, getIntermediarySci, getIsLoading } from 'selectors';
import { useAppDispatch } from 'store';
import { sendInvite } from 'thunks';
import { Routes } from 'types';
import { deleteDoubleSpace, deleteSpaceInStart } from 'utils/formats';
import { specialCharactersiInputProhibition } from 'utils/inputProhibition';
import { notify } from 'utils/notificationHelper';
import { getCorrectValueForEmail, getPatterns, isExistErrors } from 'utils/validationHelper';

const cx = classNames.bind(styles);

const Invite = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const loading = useSelector(getIsLoading);
  const sci = useSelector(getIntermediarySci);
  const intermediaryName = useSelector(getIntermediaryName);
  const {
    register,
    setValue,
    handleSubmit,
    watch,
    control,
    formState: { errors, isSubmitted },
  } = useForm<FieldValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const handleBackClick = () => {
    history.push(Routes.ContractorDashboard);
  };

  const watcher = watch();

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const result = await dispatch(
      sendInvite({
        firstName: deleteDoubleSpace(data.firstName),
        lastName: deleteDoubleSpace(data.lastName),
        phone: data.phone,
        email: data.email,
        sci,
        intermediaryName,
      }),
    ).unwrap();
    if (!result) {
      notify({ notification: "Couldn't send an invitation. Please try again later." });
      return;
    }
    notify({
      notification: (
        <>
          Invite has been successfully sent to <Link href={`mailto:${data.email}`}>{data.email}</Link>
        </>
      ),
    });
    history.push(Routes.ContractorDashboard);
  };

  const isSubmitInactive = () =>
    !watcher.firstName ||
    !watcher.lastName ||
    !watcher.phone ||
    !watcher.email ||
    (isSubmitted && isExistErrors(errors));

  const handleChangeEmail = (value: string) => {
    setValue('email', getCorrectValueForEmail(value));
  };

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.textInputLabel}>What is the customer name?</div>
      <div className={cx(styles.flexInputContainer, styles.inputContainer)}>
        <TextInput
          inputContainerClassName={styles.inputChildContainer}
          {...register('firstName', {
            required: getRequiredErrorMessage('First name'),
          })}
          label="First name"
          value={deleteSpaceInStart(watcher.firstName)}
          errorMessage={errors.firstName?.message}
          errorBackgroundType="portalErrorBackground"
          onKeyDown={specialCharactersiInputProhibition}
        />
        <TextInput
          inputContainerClassName={styles.inputChildContainer}
          {...register('lastName', {
            required: getRequiredErrorMessage('Last name'),
          })}
          label="Last name"
          value={deleteSpaceInStart(watcher.lastName)}
          errorMessage={errors.lastName?.message}
          errorBackgroundType="portalErrorBackground"
          onKeyDown={specialCharactersiInputProhibition}
        />
      </div>
      <div className={styles.textInputLabel}>What are the customer phone and email?</div>
      <div className={cx(styles.flexInputContainer, styles.inputContainer)}>
        <PhoneInput
          label="Phone"
          control={control}
          name="phone"
          className={styles.inputChildContainer}
          errorBackgroundType="portalErrorBackground"
        />
        <TextInput
          inputContainerClassName={styles.inputChildContainer}
          {...register('email', {
            required: getRequiredErrorMessage('Email'),
            pattern: {
              value: getPatterns().email,
              message: getInvalidEmailErrorMessage(),
            },
          })}
          onChange={(event) => handleChangeEmail(event.target.value)}
          label="Email"
          value={deleteSpaceInStart(watcher.email)}
          errorMessage={errors.email?.message}
          errorBackgroundType="portalErrorBackground"
          inputMode="email"
        />
      </div>
      <NavigationButtonWrapper>
        <BackButton className={commonStyles.portalBackButton} onClick={handleBackClick} />
        <NextButton type="submit" inactive={isSubmitInactive()} loading={loading} title="Submit" />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default withPortalLayout(Invite);
