import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';
import { UserType } from 'enums/UserType';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface CreateAccountProps {
  email: string;
  password: string;
  phone: string;
  borrowerId: string;
}

export const createCreateAccountThunk = (accountApi: AccountApi) =>
  createAsyncThunk(
    'account/create',
    async ({ email, password, phone, borrowerId }: CreateAccountProps, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        const result = await accountApi.createAccount(email, password, phone, borrowerId);
        sessionStorage.setItem('userType', UserType.Borrower);
        localStorage.setItem('borrowerToken', result!.accessToken);
        localStorage.setItem('borrowerRefreshToken', result!.refreshToken);
        return result;
      } catch (e) {
        rejectWithValue(null);
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
