import { createAsyncThunk } from '@reduxjs/toolkit';

import AccountApi from 'api/AccountApi';
import { LoadingType, setLoadingState } from 'handlers/loading';

interface GetBorrowerAccountProps {
  email: string;
}

export const createGetBorrowerAccountThunk = (accountApi: AccountApi) =>
  createAsyncThunk(
    'account/borrower/get',
    async ({ email }: GetBorrowerAccountProps, { dispatch, rejectWithValue }) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        return await accountApi.getBorrowerAccount(email);
      } catch (e) {
        rejectWithValue(null);
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
