import React from 'react';

import classNames from 'classnames/bind';
import { useHistory } from 'react-router-dom';

import styles from './NavigationCards.module.scss';

export type NavigationCard = {
  title: string;
  description: string;
  url: string;
  disabled?: boolean;
  className?: string;
};

interface NavigationCardsProps {
  items: NavigationCard[];
}

const cx = classNames.bind(styles);

const NavigationCards = ({ items }: NavigationCardsProps) => {
  const history = useHistory();

  const handleNavigation = (url: string) => {
    history.push(url);
  };

  return (
    <ul className={styles.cardsContainer}>
      {items.map((item, index) => (
        <li
          key={index}
          className={cx(
            styles.card,
            {
              [styles.disabledCard]: item.disabled,
            },
            item.className,
          )}
          onClick={() => handleNavigation(item.url)}
        >
          <div className={styles.cardTitle}>{item.title}</div>
          <p className={styles.cardDescription}>{item.description}</p>
        </li>
      ))}
    </ul>
  );
};

export default NavigationCards;
