import React, { useEffect, useState } from 'react';

import classNames from 'classnames/bind';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';

import Pagination from 'common/components/TablePagination';
import { setTableOptions, TableType } from 'handlers/table';
import {
  getFilterByProjectDetails,
  getFilterByStatusApplication,
  getFilterByVoi,
  getIsApplicationFetch,
  getPageIndex,
  getSearchValue,
  getSortDirection,
  getSortField,
} from 'selectors';
import { Routes } from 'types';

import styles from './MobileTable.module.scss';
import TableNoResult from './TableNoResult';

const cx = classNames.bind(styles);

const PAGE_SIZE = 10;

interface ICardProps {
  data: Array<any>;
  fetchData: (...args: any[]) => any;
  pageCount: number;
  totalCount: number;
}

interface IData {
  borrower: string;
  city: string;
  coborrower: string;
  createdAt: string;
  installAddress: string;
  loanAmount: number;
  state: string;
  status: string;
  displayId: string;
}

export const MobileTable = ({ data, fetchData, pageCount, totalCount }: ICardProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const sortDirection = useSelector(getSortDirection);
  const sortField = useSelector(getSortField);
  const loading = useSelector(getIsApplicationFetch);
  const searchValue = useSelector(getSearchValue);
  const page = useSelector(getPageIndex);
  const activeFilters = {
    projectDetails: useSelector(getFilterByProjectDetails),
    statusApplication: useSelector(getFilterByStatusApplication),
    voi: useSelector(getFilterByVoi),
  };
  const [pageIndex, setPageIndex] = useState(page);

  const notDisplayPage = (i: number) =>
    (pageCount > 4 && pageIndex - 1 > i) || (pageIndex + 2 < i && i + 1 !== pageCount);

  const disablePage = (i: number) =>
    pageCount > 4 && pageIndex + 3 !== pageCount - 1 && pageIndex + 1 < i && i + 1 !== pageCount;

  const onClickCard = (id: string) => history.push(generatePath(Routes.ContractorManageDeal, { id }));

  useEffect(() => {
    if (page === 0) setPageIndex(page);
  }, [page]);

  useEffect(() => {
    fetchData({
      pageSize: PAGE_SIZE,
      pageIndex,
      sortField,
      sortDirection,
      filters: activeFilters,
      search: searchValue,
    });
    dispatch(
      setTableOptions({
        [TableType.PageIndex]: pageIndex,
      }),
    );
  }, [pageIndex, sortField, sortDirection]);

  return (
    <div>
      {loading || data.length ? (
        <>
          {data.map((item: IData) => (
            <div className={styles.card} key={item.displayId} onClick={() => onClickCard(item.displayId)}>
              <div className={styles.body}>
                <div className={styles.row}>
                  <div className={styles.label}>Borrower</div>
                  <div className={styles.value}>
                    {item.borrower}
                    <span className={styles.tooltipActive}>{item.borrower}</span>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>Co-borrower</div>
                  <div className={cx(styles.value, styles.lastValue)}>
                    {item.coborrower}
                    <span className={styles.tooltipActive}>{item.coborrower}</span>
                  </div>
                </div>
              </div>
              <div className={styles.body}>
                <div className={styles.row}>
                  <div className={styles.label}>Install address</div>
                  <div className={styles.value}>
                    {item.installAddress}
                    <span className={styles.tooltipActive}>{item.installAddress}</span>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>City</div>
                  <div className={styles.value}>
                    {item.city}
                    <span className={styles.tooltipActive}>{item.city}</span>
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>State</div>
                  <div className={cx(styles.value, styles.lastValue)}>{item.state}</div>
                </div>
              </div>
              <div className={styles.body}>
                <div className={styles.row}>
                  <div className={styles.label}>App date</div>
                  <div className={styles.value}>{new Date(item.createdAt).toLocaleDateString()}</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>Status</div>
                  <div className={styles.value}>{item.status}</div>
                </div>
                <div className={styles.row}>
                  <div className={styles.label}>Loan amount</div>
                  <div className={cx(styles.value, styles.lastValue)}>{item.loanAmount}</div>
                </div>
              </div>
            </div>
          ))}
          <Pagination
            pages={new Array(pageCount).fill(0)}
            pageIndex={pageIndex}
            disabledNextPage={pageIndex + 1 === pageCount}
            disabledPrevPage={!pageIndex}
            onClickNextPage={() => setPageIndex(pageIndex + 1)}
            onClickPrevPage={() => setPageIndex(pageIndex - 1)}
            onClickPage={setPageIndex}
            pageSize={PAGE_SIZE}
            totalCount={totalCount}
            screenType="mobile"
            disablePageNumber={disablePage}
            displayPageNumber={notDisplayPage}
          />
        </>
      ) : (
        <TableNoResult />
      )}
    </div>
  );
};
