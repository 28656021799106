import React, { FC } from 'react';

import classNames from 'classnames/bind';

import Button, { AlignmentType, ButtonVariant } from './Button';
import styles from './Button.module.scss';
import { ReactComponent as BackArrowIcon } from './icons/BackArrowIcon.svg';

const cx = classNames.bind(styles);

interface BackButtonProps {
  disabled?: boolean;
  onClick?: () => void;
  title?: string;
  className?: string;
  variant?: ButtonVariant;
}

const BackButton: FC<BackButtonProps> = ({
  title = 'Back',
  variant = ButtonVariant.Secondary,
  className,
  ...props
}: BackButtonProps) => (
  <Button
    variant={variant}
    title={title}
    className={cx(styles.backButton, className)}
    icon={<BackArrowIcon />}
    iconAlignment={AlignmentType.Left}
    type="button"
    {...props}
  />
);

export default BackButton;
