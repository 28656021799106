import isDate from 'validator/lib/isDate';

const FULL_DATE_FORMAT = 'MM/DD/YYYY';
const MIN_YEAR = 1902;

export interface PickerDateState {
  month: number | string;
  day: number | string;
  year: number | string;
}

export const getPatterns = () => ({
  email: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
  socialSecurityNumber: /^\d{3}-\d{2}-\d{4}$/g,
  percentage: /^%(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/g,
  zipcode: /^\d{5}(?:[-\s]\d{4})?$/i,
});

export const isValidDate = (date: string) => {
  const year = Number(date.slice(-4));
  return isDate(date, { format: FULL_DATE_FORMAT }) && !(year < MIN_YEAR || year > new Date().getFullYear());
};

export const isFullDateLength = (date: PickerDateState) =>
  parseInt(date.day.toString(), 10) && parseInt(date.month.toString(), 10) && parseInt(date.year.toString(), 10);

export const isExistErrors = (errors: { [name: string]: any }) => !!Object.keys(errors).length;

// eslint-disable-next-line no-control-regex
export const getCorrectValueForEmail = (value: string) => value.replace(/[^\x00-\x7F]+/, '');
