import { createAsyncThunk } from '@reduxjs/toolkit';

import ApplicationApi from 'api/ApplicationApi';
import { setApplicationData } from 'handlers/application';
import { setCustomerData } from 'handlers/customer';
import { setDeclineState } from 'handlers/decline';
import { DeclineTypes } from 'handlers/decline/types';
import { setIntermediaryData } from 'handlers/intermediary';
import { LoadingType, setLoadingState } from 'handlers/loading';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { VariableValue } from 'types';

interface SubmitApplicationProps {
  isRejected: boolean;
  borrowerVariables: Record<string, VariableValue>;
  applicationVariables: Record<string, VariableValue>;
  softPullDecisionId: string;
  coBorrowerVariables?: Record<string, VariableValue>;
  declineReasons?: string[];
  sci?: string;
}

export const createSubmitApplicationThunk = (applicationApi: ApplicationApi) =>
  createAsyncThunk(
    'submissions/application',
    async (
      {
        isRejected,
        borrowerVariables,
        applicationVariables,
        coBorrowerVariables,
        declineReasons,
        sci,
        softPullDecisionId,
      }: SubmitApplicationProps,
      { dispatch },
    ) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        const result = await applicationApi.submitApplication(
          isRejected,
          borrowerVariables,
          applicationVariables,
          softPullDecisionId,
          coBorrowerVariables,
          declineReasons,
          sci,
        );
        dispatch(
          setDeclineState({
            [DeclineTypes.BorrowerEmailAlreadyInUse]: !!result[DeclineTypes.BorrowerEmailAlreadyInUse],
            [DeclineTypes.CoborrowerEmailAlreadyInUse]: !!result[DeclineTypes.CoborrowerEmailAlreadyInUse],
            [DeclineTypes.BorrowerInvalidEmailForSSN]: !!result[DeclineTypes.BorrowerInvalidEmailForSSN],
            [DeclineTypes.CoborrowerInvalidEmailForSSN]: !!result[DeclineTypes.CoborrowerInvalidEmailForSSN],
          }),
        );
        if (
          result.locked ||
          result.success === false ||
          result[DeclineTypes.BorrowerEmailAlreadyInUse] ||
          result[DeclineTypes.CoborrowerEmailAlreadyInUse] ||
          result[DeclineTypes.BorrowerInvalidEmailForSSN] ||
          result[DeclineTypes.CoborrowerInvalidEmailForSSN]
        ) {
          return result;
        }
        dispatch(
          setCustomerData({
            customerId: result.borrowerId as string,
          }),
        );
        if (sci) {
          dispatch(
            setIntermediaryData({
              id: result.intermediaryId,
            }),
          );
        }
        dispatch(
          setApplicationData({
            applicationId: result.applicationId as string,
            ...result.variables,
          }),
        );
        return result;
      } catch (error) {
        dispatch(setCurrentStage({ stage: StagesType.ContactUs }));
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
