import React, { FC } from 'react';

import Header from 'components/Header';
import Title from 'components/Title/Title';

interface PageLayoutProps {
  title: string;
  top?: JSX.Element;
  bottom?: JSX.Element;
}

const PageLayout: FC<PageLayoutProps> = ({ children, top, bottom, title }) => (
  <>
    <Header />
    {top}
    <Title>{title}</Title>
    {children}
    {bottom}
  </>
);

export default PageLayout;
