import { AnyAction, Dispatch, Middleware } from '@reduxjs/toolkit';

import { logout } from 'handlers/actions';
import { StagesType } from 'handlers/stages/types';

const applicationMiddleware: Middleware = (store) => (next: Dispatch<AnyAction>) => (action) => {
  const updatedState = JSON.parse(JSON.stringify(store.getState()));
  if (action.payload?.stage === StagesType.Decline || action.payload?.stage === StagesType.ContactUs) {
    updatedState.stages.previousStage = updatedState.stages.currentStage;
    updatedState.stages.currentStage = action.payload?.stage;
  }
  sessionStorage.setItem('state', JSON.stringify(updatedState));

  if (action.error?.message === 'logout') {
    return next(logout());
  }
  return next(action);
};

export default applicationMiddleware;
