import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import commonStyles from 'App.module.scss';
import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import RadioButton from 'common/components/RadioButton/RadioButton';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import ViewContract from 'components/ViewContract';
import { ApplicationVariables } from 'handlers/application/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import pages from 'pages/pages.module.scss';
import {
  getApplicationFriendlyStatus,
  getApplicationId,
  getBatterySize,
  getBorrowerFirstName,
  getBorrowerLastName,
  getConfirmProjectDetails,
  getIntermediaryName,
  getIsLoading,
  getLoanAmount,
  getMakeAndModel,
  getOtherEnergyEfficiencyDescription,
  getOutsideAmount,
  getPvKilowatts,
  getRoofingRepairsDescription,
  getTotalProjectCost,
} from 'selectors';
import { updateApplication } from 'thunks';
import { Routes, VariableValue } from 'types';
import { getCurrentDate } from 'utils/dateHelper';
import { convertMonetary } from 'utils/formats';
import { notify } from 'utils/notificationHelper';

import styles from './ConfirmProject.module.scss';

const ConfirmProject = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const confirmProjectDetails = useSelector(getConfirmProjectDetails);
  const applicationId = useSelector(getApplicationId);
  const borrowerFirstName = useSelector(getBorrowerFirstName);
  const borrowerLastName = useSelector(getBorrowerLastName);
  const loading = useSelector(getIsLoading);
  const loanAmount = useSelector(getLoanAmount);
  const totalProjectCost = useSelector(getTotalProjectCost);
  const pvKilowatts = useSelector(getPvKilowatts);
  const roofingRepairsDescription = useSelector(getRoofingRepairsDescription);
  const batterySize = useSelector(getBatterySize);
  const makeAndModel = useSelector(getMakeAndModel);
  const otherDescription = useSelector(getOtherEnergyEfficiencyDescription);
  const intermediaryName = useSelector(getIntermediaryName);

  const amountPaidOutside = useSelector(getOutsideAmount);

  const applicationStatus = useSelector(getApplicationFriendlyStatus);

  const [confirmProject, setConfirmProject] = useState<boolean | null>(confirmProjectDetails);
  const [isValid, setIsValid] = useState<boolean>(false);

  const handleConfirmProject = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmProject(e.target.value === 'true');
    setIsValid(true);
  };

  const handleSubmit = async () => {
    const applicationVariables: Record<string, VariableValue> = {
      [ApplicationVariables.ConfirmProjectDetails]: confirmProject as boolean,
      [ApplicationVariables.ConfirmProjectDetailsDate]: getCurrentDate(),
    };
    if (!confirmProject)
      Object.assign(applicationVariables, {
        [ApplicationVariables.ProjectDetailsAndSignedContractComplete]: null,
        [ApplicationVariables.ContractManualReviewConfirmed]: null,
        [ApplicationVariables.RequestFundsAgreement]: null,
        [ApplicationVariables.First50Requested]: null,
        [ApplicationVariables.FullAmountRequested]: null,
      });
    await dispatch(
      updateApplication({
        applicationId,
        applicationVariables,
      }),
    );
    if (confirmProject) {
      notify({ notification: 'Project and contract have been successfully confirmed' });
    } else {
      notify({ notification: 'The message to your solar company has been successfully sent' });
    }
    history.push(Routes.BorrowerDashboard);
  };

  const handleBackClick = () => {
    history.push(Routes.BorrowerDashboard);
  };

  return (
    <>
      <p>Finalize your ezSolarLoan amount</p>
      <div className={styles.borrowerCardContainer}>
        <div className={styles.borrowerCardHeader}>
          <div className={styles.aboutBorrower}>
            <div className={styles.borrower}>
              <span>
                {borrowerFirstName} {borrowerLastName}
              </span>
              <span className={styles.borrowerSum}>{convertMonetary(loanAmount)}</span>
            </div>
            <div className={styles.createdAt}>Created Apr 13, 2021</div>
          </div>
          <div>
            <span className={commonStyles[applicationStatus]}>{applicationStatus}</span>
          </div>
        </div>
        <dl className={styles.borrowerCardBody}>
          {pvKilowatts && (
            <>
              <dt className={styles.projectDetailLabel}>Solar system size in kilowatts</dt>
              <dd className={styles.projectDetailValue}>{pvKilowatts} kilowatts</dd>
            </>
          )}
          {roofingRepairsDescription && (
            <>
              <dt className={styles.projectDetailLabel}>Roofing repairs description</dt>
              <dd className={styles.projectDetailValue}>{roofingRepairsDescription}</dd>
            </>
          )}
          {batterySize && (
            <>
              <dt className={styles.projectDetailLabel}>Energy storage</dt>
              <dd className={styles.projectDetailValue}>{batterySize} kilowatt hours</dd>
            </>
          )}
          {makeAndModel && (
            <>
              <dt className={styles.projectDetailLabel}>Heating and cooling make and model</dt>
              <dd className={styles.projectDetailValue}>{makeAndModel}</dd>
            </>
          )}
          {otherDescription && (
            <>
              <dt className={styles.projectDetailLabel}>Other description</dt>
              <dd className={styles.projectDetailValue}>{otherDescription}</dd>
            </>
          )}
        </dl>
      </div>
      <ViewContract
        loanAmount={String(loanAmount)}
        totalProjectCost={totalProjectCost}
        amountPaidOutside={amountPaidOutside}
        viewTitle="signed contract submitted by Solar Company"
        applicationId={applicationId}
      />
      <RadioButton
        containerClassName={pages.radioButtonWithMargin}
        label={`I have reviewed and confirm the project details and signed contract provided by my Solar company, 
        ${intermediaryName}.`}
        value="true"
        id="agreeToProject"
        onChange={handleConfirmProject}
        checked={!!confirmProject}
      />
      <RadioButton
        label="The project details and/or signed contract were not what I expected.
        Please send an automated message to my solar company directing them to contact me to discuss."
        value="false"
        id="notAgreeToProject"
        onChange={handleConfirmProject}
        checked={confirmProject === false}
      />
      <NavigationButtonWrapper>
        <BackButton className={commonStyles.portalBackButton} onClick={handleBackClick} />
        <NextButton title="Submit" onClick={handleSubmit} disabled={!isValid} loading={loading} />
      </NavigationButtonWrapper>
    </>
  );
};

export default withPortalLayout(ConfirmProject);
