import { createAsyncThunk } from '@reduxjs/toolkit';

import ApplicationApi from 'api/ApplicationApi';
import { setApplicationData } from 'handlers/application';
import { LoadingType, setLoadingState } from 'handlers/loading';
import { ApplicationStatus, VariableValue } from 'types';

interface UpdateApplicationProps {
  applicationId: string;
  applicationVariables?: Record<string, VariableValue>;
  applicationStatus?: string;
  coborrowerId?: string;
  intermediaryId?: string;
  skipAuth?: boolean;
}

export const createUpdateApplicationThunk = (applicationApi: ApplicationApi) =>
  createAsyncThunk(
    'application/update',
    async (
      {
        applicationId,
        applicationVariables,
        applicationStatus,
        coborrowerId,
        intermediaryId,
        skipAuth,
      }: UpdateApplicationProps,
      { dispatch },
    ) => {
      try {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: true }));
        const result = await applicationApi.updateApplication(
          applicationId,
          applicationVariables,
          applicationStatus,
          coborrowerId,
          intermediaryId,
          skipAuth,
        );
        dispatch(
          setApplicationData({
            status: (result.application.status as any).name as ApplicationStatus,
            ...(result.application.variables as object),
          }),
        );
        return result;
      } finally {
        dispatch(setLoadingState({ type: LoadingType.ApplicationUpdate, loading: false }));
      }
    },
  );
