import React, { FC } from 'react';

import classsames from 'classnames/bind';

import Button, { AlignmentType, ButtonVariant } from './Button';
import styles from './Button.module.scss';
import { ReactComponent as EllipseIcon } from './icons/ellipse.svg';
import { ReactComponent as ForwardArrowIcon } from './icons/ForwardArrowIcon.svg';

interface NextButtonProps {
  inactive?: boolean;
  type?: 'button' | 'submit' | 'reset';
  onClick?: () => void;
  loading?: boolean;
  title?: string;
  className?: string;
  disabled?: boolean;
}

const cx = classsames.bind(styles);

const NextButton: FC<NextButtonProps> = ({ loading, title = 'Continue', className, inactive, disabled, ...props }) => (
  <Button
    variant={ButtonVariant.Primary}
    title={title}
    className={cx(className, {
      [styles.loadingButton]: loading,
      [styles.inactive]: inactive,
    })}
    disabled={disabled || loading}
    icon={loading ? <EllipseIcon /> : <ForwardArrowIcon />}
    iconAlignment={AlignmentType.Right}
    {...props}
  />
);

export default NextButton;
