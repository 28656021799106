import React from 'react';

import classNames from 'classnames/bind';
import { Controller, Control } from 'react-hook-form';
import NumberFormat from 'react-number-format';

import TextInput, { InputType, TextInputProps } from 'common/components/TextInput/TextInput';
import styles from 'common/components/TextInput/TextInput.module.scss';
import { getRequiredErrorMessage } from 'errors/errors';

interface MonetaryInputProps extends TextInputProps {
  control: Control<any>;
  name: string;
  allowNegative?: boolean;
  label: string;
  handleBlur?: () => void;
  handleFocus?: () => void;
  handleChange?: () => void;
  required?: boolean;
  inputType?: 'defaultInput' | 'smallInput';
}

const defaultInput = 'defaultInput';

const cx = classNames.bind(styles);

const MonetaryInput = ({
  control,
  name,
  label,
  value,
  errorMessage,
  handleBlur,
  handleFocus,
  handleChange,
  disabled,
  required,
  allowNegative,
  className,
  inputType = defaultInput,
  errorBackgroundType,
  ...restProps
}: MonetaryInputProps) => (
  <Controller
    control={control}
    name={name}
    rules={{
      required: {
        value: !!required,
        message: getRequiredErrorMessage(label),
      },
    }}
    render={({ field: { onChange, ref } }) => (
      <TextInput
        label={label}
        errorMessage={errorMessage}
        value={value}
        inputContainerClassName={className}
        inputType={inputType}
        disabled={disabled}
        errorBackgroundType={errorBackgroundType}
        {...restProps}
      >
        <NumberFormat
          getInputRef={ref}
          value={value}
          disabled={disabled}
          prefix="$"
          allowNegative={!!allowNegative}
          thousandSeparator
          inputMode="decimal"
          defaultValue={value}
          onValueChange={(e) => {
            onChange?.(e.value);
            handleChange?.();
          }}
          onBlur={handleBlur}
          onFocus={handleFocus}
          className={cx(styles[inputType], {
            [styles[inputType !== defaultInput ? InputType.hasValueSmallInput : InputType.hasValueDefaultInput]]:
              !!value,
          })}
        />
      </TextInput>
    )}
  />
);

export default MonetaryInput;
