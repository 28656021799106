import React, { useLayoutEffect } from 'react';

import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';

import Header from 'components/Header';
import BorrowerAccountProfile from 'components/HeaderAccountProfile/BorrowerAccountProfile';
import ContractorAccountProfile from 'components/HeaderAccountProfile/ContractorAccountProfile';
import PageLoader from 'components/PageLoader';
import Title from 'components/Title/Title';
import { getIsApplicationFetch, getIsLoading } from 'selectors';

import styles from './Portal.module.scss';

const cx = classNames.bind(styles);

export enum PortalType {
  Borrower = 'borrower',
  Contractor = 'contractor',
}

interface PortalLayoutProps {
  title: string;
  type: PortalType;
  className?: string;
}

export function withPortalLayout<T extends PortalLayoutProps>(WrappedComponent: React.ComponentType<T>) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

  const ComponentWithPortalLayout = ({ title, type, className, ...restProps }: T) => {
    useLayoutEffect(() => {
      document.body.classList.add('portalPageBody');

      return () => {
        document.body.classList.remove('portalPageBody');
      };
    });

    const loading = useSelector(getIsLoading);
    const isApplicationFetch = useSelector(getIsApplicationFetch);

    return (
      <>
        {loading || (isApplicationFetch && <PageLoader />)}
        <header className={styles.header}>
          <div className={styles.headerContent}>
            <Header portal />
            {type === PortalType.Borrower && <BorrowerAccountProfile />}
            {type === PortalType.Contractor && <ContractorAccountProfile />}
          </div>
        </header>
        <main className={cx(styles.mainContent, className)}>
          <Title>{title}</Title>
          <WrappedComponent {...(restProps as T)} />
        </main>
        <footer className={styles.footer}>
          ezsolarloan.com is a product of{' '}
          <a className={styles.noColorLink} href="https://bankhere.com" target="_blank" rel="noreferrer">
            bankhere
          </a>{' '}
          and funded by Community 1st Credit Union
        </footer>
      </>
    );
  };

  ComponentWithPortalLayout.displayName = `withPortalLayout(${displayName})`;

  return ComponentWithPortalLayout;
}
