import React, { useEffect, useRef, useState } from 'react';

import { Popper, ClickAwayListener, Fade } from '@mui/material';
import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ReactComponent as ArrowDown } from 'assets/icons/ArrowDown.svg';
import { ReactComponent as ArrowUp } from 'assets/icons/ArrowUp.svg';
import Link from 'common/components/Link';
import { getIntermediaryName, getIntermediarySci } from 'selectors';
import { useAppDispatch } from 'store';
import { logoutContractor } from 'thunks';
import { Routes } from 'types';

import styles from './HeaderAccountProfile.module.scss';

const cx = classNames.bind(styles);

const BorrowerAccountProfile = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);

  const intermediaryName = useSelector(getIntermediaryName);
  const sciCode = useSelector(getIntermediarySci);

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleLogout = async () => {
    await dispatch(logoutContractor({}));
    history.push(Routes.ContractorLogin);
  };

  const handleFaq = () => {
    window.open('https://ezsolarloan.com/faq-solar-companies', '_blank');
  };

  const handleViewDeals = () => {
    history.push(Routes.ContractorDeals);
  };

  function handleListKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false && anchorRef.current) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={styles.accountContainer}>
      <span className={styles.sciCode}>SCI code: {sciCode}</span>
      <button
        className={cx(styles.menuTitle, styles.contractorMenuTitle)}
        type="button"
        ref={anchorRef}
        aria-controls={open ? 'menu-list' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {intermediaryName}
        {open ? <ArrowUp className={styles.arrowIcon} /> : <ArrowDown className={styles.arrowIcon} />}
      </button>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} disablePortal placement="bottom-end" transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClose}>
            <Fade {...TransitionProps} timeout={200}>
              <div className={styles.menuContainer}>
                <ul className={styles.menuList} id="menu-list" onKeyDown={handleListKeyDown}>
                  <li className={cx(styles.menuItem, styles.sciCodeMobile)}>SCI code: {sciCode}</li>
                  <li className={styles.menuItem} onClick={handleViewDeals}>
                    View deals
                  </li>
                  <li className={styles.menuItem} onClick={handleFaq}>
                    FAQ’s
                  </li>
                  <li className={cx(styles.menuItem, styles.logoutItem)} onClick={handleLogout}>
                    Log out
                  </li>
                  <li className={styles.contactsMenuItem}>
                    <span className={styles.contactsTitle}>Contacts</span>
                    <span className={styles.contactsPhone}>800-493-1310</span>
                    <Link className={styles.contactsLink} href="mailto:applications@ezsolarloan.com">
                      applications@ezsolarloan.com
                    </Link>
                  </li>
                </ul>
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </div>
  );
};

export default BorrowerAccountProfile;
