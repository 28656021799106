import React from 'react';

import cnBind from 'classnames/bind';

import { getCurrentYear } from 'utils/dateHelper';

import { PickerType } from './DatePicker';
import styles from './DatePicker.module.scss';

const cx = cnBind.bind(styles);

interface YearPickerProps {
  type: PickerType;
  selectedYear: number | string;
  onYearPicked: (value: number) => void;
}

const getMaxYear = (type: PickerType) => {
  const currentYear = getCurrentYear();
  switch (type) {
    case PickerType.BirthDayPicker:
      return currentYear - 18;
    case PickerType.LeaseStartPicker:
      return currentYear + 1;
    case PickerType.LeaseTermPicker:
      return currentYear + 5;
    default:
      return currentYear;
  }
};

const getMinYear = () => {
  const currentYear = getCurrentYear();

  return currentYear - 120;
};

const YearPicker = ({ type, selectedYear, onYearPicked }: YearPickerProps) => {
  const pickYear = (year: number) => {
    onYearPicked(year);
  };

  const maxYear = getMaxYear(type);
  const minYear = getMinYear();
  const years = Array.from(new Array(maxYear - minYear + 1))
    .map((_, index) => minYear + index)
    .reverse();

  return (
    <>
      <div className={styles.yearWrapper}>
        {years.map((year) => (
          <div className={styles.selectButtonWrapper} key={year}>
            <div
              onClick={() => pickYear(year)}
              className={cx(styles.selectButton, {
                [styles.selectButtonActive]: year === Number(selectedYear),
              })}
            >
              {year}
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default YearPicker;
