import { createAsyncThunk } from '@reduxjs/toolkit';

import ApplicationApi from 'api/ApplicationApi';
import { LoadingType, setLoadingState } from 'handlers/loading';

export const createGetApplicationByDisplayIdThunk = (applicationApi: ApplicationApi) =>
  createAsyncThunk('application/get', async (displayId: string, { dispatch }) => {
    try {
      dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: true }));
      return await applicationApi.getApplicationByDisplayId(displayId);
    } finally {
      dispatch(setLoadingState({ type: LoadingType.ApplicationFetch, loading: false }));
    }
  });
