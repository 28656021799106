import { Borrower, VariableValue } from 'types';

import { HttpClient } from './Api';

export interface BorrowerRestApi {
  getBorrower(email: string): Promise<Borrower>;
  createOrUpdateCoborrower(CoborrowerVariables: Record<string, VariableValue>): Promise<Record<string, VariableValue>>;
}

export default class BorrowerApi extends HttpClient implements BorrowerRestApi {
  public async getBorrower() {
    return this.instance.get<Borrower>(`/api/borrowers`).then((res) => res.data);
  }

  public async createOrUpdateCoborrower(CoborrowerVariables: Record<string, VariableValue>) {
    return this.instance
      .post<Record<string, VariableValue>>('/api/borrowers', CoborrowerVariables)
      .then((res) => res.data);
  }
}
