import React, { useEffect } from 'react';

import classNames from 'classnames/bind';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import BackButton from 'common/components/Button/BackButton';
import NextButton from 'common/components/Button/NextButton';
import TextInput from 'common/components/TextInput';
import DatePicker from 'components/DatePicker';
import { OptionType } from 'components/DropDown/DropDown';
import FormContainer from 'components/FormContainer';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import { getInvalidDateErrorMessage, getRequiredErrorMessage } from 'errors/errors';
import { backStep } from 'handlers/actions';
import { ApplicationVariables } from 'handlers/application/types';
import { setCoborrowerData } from 'handlers/coborrower';
import { CoborrowerInfoKeys } from 'handlers/coborrower/types';
import { UserInformationKeys } from 'handlers/customer/types';
import { nextStep } from 'handlers/steps';
import { CitizenshipStatus } from 'layouts';
import styles from 'pages/pages.module.scss';
import {
  getBorrowerCitizenshipStatus,
  getBorrowerDateOfBirth,
  getCoborrowerCitizenshipStatus,
  getCoborrowerDateOfBirth,
  getCoborrowerFirstName,
  getCoborrowerLastName,
  getIsLoading,
} from 'selectors';
import { runStrategy } from 'thunks';
import { StrategyName } from 'types';
import { deleteDoubleSpace, deleteSpaceInStart } from 'utils/formats';
import { specialCharactersiInputProhibition } from 'utils/inputProhibition';
import { isExistErrors, isValidDate } from 'utils/validationHelper';

const cx = classNames.bind(styles);

const CoborrowerPersonalInfo = () => {
  const dispatch = useDispatch();

  const borrowerDateOfBirth = useSelector(getBorrowerDateOfBirth);
  const borrowerCitizenshipStatus = useSelector(getBorrowerCitizenshipStatus);
  const loading = useSelector(getIsLoading);

  const {
    register,
    watch,
    handleSubmit,
    clearErrors,
    control,
    trigger,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm<FieldValues>({
    defaultValues: {
      firstName: useSelector(getCoborrowerFirstName) || '',
      lastName: useSelector(getCoborrowerLastName) || '',
      dateOfBirth: useSelector(getCoborrowerDateOfBirth) || '',
      citizenshipStatus: {
        value: useSelector(getCoborrowerCitizenshipStatus) || '',
      },
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldFocusError: false,
  });

  const firstName = watch('firstName');
  const lastName = watch('lastName');
  const dateOfBirth = watch('dateOfBirth');
  const citizenshipStatus = watch('citizenshipStatus');

  useEffect(() => {
    register('dateOfBirth', {
      required: getRequiredErrorMessage('Date of birth'),
      validate: (value) => (isValidDate(value) ? true : getInvalidDateErrorMessage()),
    });
    register('citizenshipStatus', {
      validate: (item) => (!item.value ? getRequiredErrorMessage('Citizenship status') : true),
    });
  }, [register]);

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    dispatch(
      setCoborrowerData({
        [CoborrowerInfoKeys.FirstName]: deleteDoubleSpace(data.firstName),
        [CoborrowerInfoKeys.LastName]: deleteDoubleSpace(data.lastName),
        [CoborrowerInfoKeys.DateOfBirth]: data.dateOfBirth,
        [CoborrowerInfoKeys.CitizenshipStatus]: data.citizenshipStatus.value,
      }),
    );

    await dispatch(
      runStrategy({
        strategyName: StrategyName.EligibilityCheck1,
        strategyInputVariables: {
          [UserInformationKeys.CitizenshipStatus]: borrowerCitizenshipStatus,
          [UserInformationKeys.DateOfBirth]: borrowerDateOfBirth,
          [CoborrowerInfoKeys.DateOfBirth]: data.dateOfBirth,
          [CoborrowerInfoKeys.CitizenshipStatus]: data.citizenshipStatus.value,
          [ApplicationVariables.CoborrowerApplication]: true,
        },
      }),
    );

    dispatch(nextStep());
  };

  const onChangeDate = (date: string) => {
    setValue('dateOfBirth', date);
    if (errors.dateOfBirth?.message) clearErrors('dateOfBirth');
    return trigger('dateOfBirth');
  };

  const setCitizenshipStatus = (option: OptionType) => {
    setValue('citizenshipStatus', option);
    return errors.citizenshipStatus?.message ? clearErrors('citizenshipStatus') : null;
  };

  const isSubmitInactive = () =>
    !firstName || !lastName || !dateOfBirth || !citizenshipStatus?.value || (isSubmitted && isExistErrors(errors));

  const handleBackClick = () => dispatch(backStep());

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.textInputLabel}>What is the co-borrower's name?</div>
      <div className={cx(styles.inputContainer, styles.flexInputContainer)}>
        <TextInput
          inputContainerClassName={styles.inputChildContainer}
          {...register('firstName', { required: getRequiredErrorMessage('First name') })}
          label="First name"
          value={deleteSpaceInStart(firstName)}
          errorMessage={errors.firstName?.message}
          maxLength={100}
          onKeyDown={specialCharactersiInputProhibition}
        />
        <TextInput
          inputContainerClassName={styles.inputChildContainer}
          {...register('lastName', { required: getRequiredErrorMessage('Last name') })}
          label="Last name"
          value={deleteSpaceInStart(lastName)}
          errorMessage={errors.lastName?.message}
          maxLength={100}
          onKeyDown={specialCharactersiInputProhibition}
        />
      </div>
      <div className={styles.textInputLabel}>What is the co-borrower's date of birth?</div>
      <div className={styles.inputContainer}>
        <DatePicker
          control={control}
          handleChange={onChangeDate}
          handleBlur={() => trigger('dateOfBirth')}
          value={dateOfBirth}
          errorMessage={isSubmitted ? errors.dateOfBirth?.message : ''}
          label="Date of birth"
          name="dateOfBirth"
        />
      </div>
      <div className={styles.textInputLabel}>What is the co-borrower's citizenship status?</div>
      <div className={styles.inputContainer}>
        <CitizenshipStatus
          handleValuePicked={setCitizenshipStatus}
          selectedOption={citizenshipStatus.value}
          errorMessage={errors.citizenshipStatus?.message}
          control={control}
        />
      </div>
      <NavigationButtonWrapper>
        <BackButton onClick={handleBackClick} />
        <NextButton type="submit" inactive={isSubmitInactive()} loading={loading} />
      </NavigationButtonWrapper>
    </FormContainer>
  );
};

export default CoborrowerPersonalInfo;
