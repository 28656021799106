import React, { useEffect, useLayoutEffect, useState } from 'react';

import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { ReactComponent as ClosedEyeIcon } from 'assets/icons/ClosedEye.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/Eye.svg';
import Button from 'common/components/Button';
import Link from 'common/components/Link';
import TextInput from 'common/components/TextInput';
import FormContainer from 'components/FormContainer';
import { UserType } from 'enums/UserType';
import { getInvalidEmailErrorMessage, getRequiredErrorMessage } from 'errors/errors';
import { LoginLayout } from 'layouts';
import { PortalType } from 'layouts/withPortalLayout';
import {
  getBorrowerEmailNotVerified,
  getBorrowerMfaIncomplete,
  getContractorEmailNotVerified,
  getIsLoading,
} from 'selectors';
import { useAppDispatch } from 'store';
import { getBorrowerByToken, loginBorrower, loginContractor } from 'thunks';
import { Routes } from 'types';
import { getCorrectValueForEmail, getPatterns } from 'utils/validationHelper';

import styles from './Login.module.scss';

export interface ILoginProps {
  type: PortalType;
}

export type Resume = {
  applicationId?: string;
  verificationDocumentsNeededLink?: string;
};

const Login = ({ type }: ILoginProps) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userType = sessionStorage.getItem('userType');
  const loading = useSelector(getIsLoading);
  const contractorEmailNotVerified = useSelector(getContractorEmailNotVerified);
  const borrowerMfaIncomplete = useSelector(getBorrowerMfaIncomplete);
  const borrowerEmailNotVerified = useSelector(getBorrowerEmailNotVerified);
  const contractorToken = localStorage.getItem('contractorToken');
  const borrowerToken = localStorage.getItem('borrowerToken');
  const { search } = useLocation();
  const applicationId = new URLSearchParams(search).get('applicationId');
  const verificationDocumentsNeededLink = new URLSearchParams(search).get('documents');

  useLayoutEffect(() => {
    document.body.classList.add('loginPageBody');

    return () => {
      document.body.classList.remove('loginPageBody');
    };
  });
  const [showPassword, setShowPassword] = useState(false);
  const [emailInvalid, setEmailInvalid] = useState(false);

  const getBorrower = async () => {
    if (type === PortalType.Borrower && userType === UserType.Borrower && borrowerToken) {
      const { payload } = await dispatch(getBorrowerByToken(borrowerToken!));
      if (payload) {
        if (payload.isEmailNotVerified || borrowerEmailNotVerified) {
          history.replace(Routes.BorrowerConfirmEmail, { applicationId });
        } else if (payload.isMfaIncomplete || borrowerMfaIncomplete) {
          history.replace(Routes.BorrowerConfirmPhone, { applicationId, verificationDocumentsNeededLink });
        } else {
          history.replace(verificationDocumentsNeededLink ? Routes.BorrowerDocuments : Routes.BorrowerDashboard);
        }
      }
    } else if (type === PortalType.Contractor && userType === UserType.Contractor && contractorToken) {
      if (contractorEmailNotVerified) {
        history.replace(Routes.ContractorConfirmEmail);
      } else {
        history.replace(Routes.ContractorDashboard);
      }
    }
  };

  useEffect(() => {
    getBorrower();
  }, [
    userType,
    contractorEmailNotVerified,
    borrowerEmailNotVerified,
    borrowerMfaIncomplete,
    contractorToken,
    borrowerToken,
  ]);

  const {
    register,
    setValue,
    watch,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      email: '',
      password: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const watcher = watch();

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const renderAdornmentForPassword = () =>
    showPassword ? (
      <EyeIcon onClick={togglePasswordVisibility} />
    ) : (
      <ClosedEyeIcon onClick={togglePasswordVisibility} />
    );

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    setEmailInvalid(false);
    let result;
    if (type === PortalType.Borrower) {
      result = await dispatch(
        loginBorrower({
          email: data.email,
          password: data.password,
        }),
      ).unwrap();
    } else {
      result = await dispatch(
        loginContractor({
          email: data.email,
          password: data.password,
        }),
      ).unwrap();
    }
    if (!result) {
      setEmailInvalid(true);
      setError('password', { message: 'Incorrect password or email' });
    }
  };

  const handleChangeEmail = (value: string) => {
    setValue('email', getCorrectValueForEmail(value));
  };

  return (
    <LoginLayout title={type === PortalType.Borrower ? 'Log into dashboard' : 'Log into portal'}>
      <FormContainer className={styles.loginForm} onSubmit={handleSubmit(onSubmit)}>
        <input type="email" name="email" className={styles.hidden} inputMode="email" />
        <input type="password" name="password" className={styles.hidden} />
        <div className={styles.inputContainer}>
          <TextInput
            {...register('email', {
              required: getRequiredErrorMessage('Email'),
              pattern: {
                value: getPatterns().email,
                message: getInvalidEmailErrorMessage(),
              },
            })}
            onChange={(event) => handleChangeEmail(event.target.value)}
            label="Email"
            invalid={emailInvalid}
            value={watcher.email}
            errorMessage={errors.email?.message}
            inputMode="email"
          />
        </div>
        <div className={styles.inputContainer}>
          <TextInput
            {...register('password', { required: getRequiredErrorMessage('Password') })}
            type={showPassword ? 'text' : 'password'}
            label="Password"
            errorMessage={errors.password?.message}
            value={watcher.password}
            endAdornment={renderAdornmentForPassword}
            referenceInfo={
              <Link
                href={type === PortalType.Borrower ? Routes.BorrowerForgotPassword : Routes.ContractorForgotPassword}
              >
                Forgot your password?
              </Link>
            }
          />
        </div>
        <Button
          title="Log in"
          inactive={!watcher.password || !watcher.email}
          className={styles.submitButton}
          type="submit"
          loading={loading}
        />
      </FormContainer>
    </LoginLayout>
  );
};

export default Login;
