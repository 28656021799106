import React, { useLayoutEffect } from 'react';

import Header from 'components/Header';
import styles from 'pages/pages.module.scss';

interface ILoginLayoutProps {
  title: string;
  loading?: boolean;
}

export const LoginLayout = ({ title, children, loading }: React.PropsWithChildren<ILoginLayoutProps>) => {
  useLayoutEffect(() => {
    document.body.classList.add('loginPageBody');

    return () => {
      document.body.classList.remove('loginPageBody');
    };
  });

  return (
    <div className={styles.externalPage}>
      {loading ? (
        children
      ) : (
        <div className={styles.externalPageContent}>
          <Header external />
          <h1 className={styles.externalPageTitle}>{title}</h1>
          <div className={styles.externalPageMainContent}>{children}</div>
        </div>
      )}
    </div>
  );
};
