import React from 'react';

import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';

import Button from 'common/components/Button';
import TextInput from 'common/components/TextInput';
import FormContainer from 'components/FormContainer';
import { ApplicationVariables } from 'handlers/application/types';
import { setCurrentStage } from 'handlers/stages';
import { StagesType } from 'handlers/stages/types';
import { LoginLayout } from 'layouts';
import { ConfirmPasswordForm } from 'pages/Login/ConfirmPassword';
import { getApplicationId, getBorrowerEmail, getBorrowerId, getBorrowerPhone, getIsLoading } from 'selectors';
import { useAppDispatch } from 'store';
import { createAccount, updateApplication } from 'thunks';
import { getCurrentDate } from 'utils/dateHelper';
import { notify } from 'utils/notificationHelper';

import styles from './Account.module.scss';

type FormValues = {
  password: string;
  confirmPassword: string;
};

const CreateAccount = () => {
  const dispatch = useAppDispatch();
  const borrowerEmail = useSelector(getBorrowerEmail);
  const borrowerPhone = useSelector(getBorrowerPhone);
  const borrowerId = useSelector(getBorrowerId);
  const applicationId = useSelector(getApplicationId);
  const loading = useSelector(getIsLoading);

  const formMethods = useForm<FormValues>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    const account = await dispatch(
      createAccount({
        email: borrowerEmail,
        password: data.password,
        phone: borrowerPhone,
        borrowerId,
      }),
    ).unwrap();
    if (!account) {
      notify({ notification: "Couldn't create an account. Please try again later.", whiteBackground: true });
    } else {
      dispatch(
        updateApplication({
          applicationId,
          applicationVariables: {
            [ApplicationVariables.AccountCreationComplete]: true,
            [ApplicationVariables.AccountCreationCompleteDate]: getCurrentDate(),
          },
        }),
      );
      dispatch(setCurrentStage({ stage: StagesType.EmailVerification }));
    }
  };

  const isSubmitInactive = () => !formMethods.watch('password') || !formMethods.watch('confirmPassword');

  return (
    <LoginLayout title="Create borrower dashboard">
      <p className={styles.paragraph}>
        Your dashboard will be used to check loan status, upload requested items, sign loan documents and more!
      </p>
      <FormProvider {...formMethods}>
        <FormContainer className={styles.formContainer} onSubmit={formMethods.handleSubmit(onSubmit)}>
          <div className={styles.inputContainer}>
            <TextInput disabled label="Email" value={borrowerEmail} inputMode="email" />
          </div>
          <ConfirmPasswordForm />
          <Button
            title="Submit"
            inactive={isSubmitInactive()}
            className={styles.submitButton}
            type="submit"
            loading={loading}
          />
        </FormContainer>
      </FormProvider>
    </LoginLayout>
  );
};

export default CreateAccount;
