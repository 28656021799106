import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';

import commonStyles from 'App.module.scss';
import { ReactComponent as FiltersIcon } from 'assets/icons/Filters.svg';
import Button from 'common/components/Button';
import BackButton from 'common/components/Button/BackButton';
import { AlignmentType } from 'common/components/Button/Button';
import SearchInput from 'common/components/SearchInput';
import { Table } from 'common/components/Table';
import { MobileTable } from 'common/components/Table/MobileTable';
import DealsFilters from 'components/DealsFilters/DealsFilters';
import NavigationButtonWrapper from 'components/NavigationButtonWrapper/NavigationButtonWrapper';
import PageLoader from 'components/PageLoader';
import SortButton from 'components/SortButton';
import { MobileScreenWidth } from 'enums/MobileScreenWidth';
import { ApplicationState } from 'handlers/application';
import { ApplicationVariables } from 'handlers/application/types';
import { CoborrowerInfoKeys } from 'handlers/coborrower/types';
import { UserInformationKeys } from 'handlers/customer/types';
import { withPortalLayout } from 'layouts/withPortalLayout';
import { getIsApplicationFetch } from 'selectors';
import { useAppDispatch } from 'store';
import { getApplications } from 'thunks';
import { ApplicationStatus, Routes } from 'types';
import { convertMonetary } from 'utils/formats';
import { getFriendlyStatus } from 'utils/getFriendlyStatus';

import styles from './Deals.module.scss';

interface IDeal {
  borrower: string;
  coborrower: string;
  installAddress: string;
  city: string;
  state: string;
  createdAt: Date;
  status: string;
  loanAmount: number;
}

interface IFetchData {
  pageSize: number;
  pageIndex: number;
  search?: string;
  sortField?: string;
  sortDirection?: string;
  projectDetails?: string;
  statusApplication?: string[];
  voi?: string;
}

const Deals = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [open, setOpen] = useState(false);
  const [data, setData] = useState<Array<IDeal>>([]);
  const [pageCount, setPageCount] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const loading = useSelector(getIsApplicationFetch);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const isMobileScreen = screenWidth < MobileScreenWidth.Middle;

  const handleWindowSizeChange = () => setScreenWidth(window.innerWidth);

  const PAGE_SIZE = isMobileScreen ? 10 : 20;

  const getData = async ({
    pageSize,
    pageIndex,
    search,
    sortField,
    sortDirection,
    projectDetails,
    statusApplication,
    voi,
  }: IFetchData) => {
    const applications = await dispatch(
      getApplications({
        count: pageSize,
        offset: pageIndex * pageSize,
        sortField,
        sortDirection,
        search,
        projectDetails,
        statusApplication,
        voi,
      }),
    ).unwrap();
    setData(
      applications.items.map((item) => ({
        displayId: item.displayId,
        borrower: `${item.variables[UserInformationKeys.LastName]}, ${item.variables[UserInformationKeys.FirstName]}`,
        coborrower: item.variables[ApplicationVariables.CoborrowerApplication]
          ? `${item.variables[CoborrowerInfoKeys.LastName]}, ${item.variables[CoborrowerInfoKeys.FirstName]}`
          : '-',
        city: item.variables[ApplicationVariables.InstallationCity],
        state: item.variables[ApplicationVariables.InstallationState],
        installAddress: item.variables[ApplicationVariables.InstallationStreetAddress],
        createdAt: item.createdAt,
        status: getFriendlyStatus({
          ...item.variables,
          status: item.status.name as ApplicationStatus,
        } as ApplicationState),
        loanAmount: item.variables[ApplicationVariables.LoanAmount],
      })),
    );
    setPageCount(Math.ceil(applications.total / pageSize));
    setTotalCount(applications.total);
  };

  const fetchData = useCallback(({ pageIndex, pageSize, sortField, sortDirection, filters, search }) => {
    getData({
      pageSize: pageSize || PAGE_SIZE,
      pageIndex: pageIndex || 0,
      search: search || '',
      sortField: sortField || 'createdAt',
      sortDirection: sortDirection || 'desc',
      projectDetails: (filters?.projectDetails as string) || '',
      statusApplication: filters?.statusApplication || [],
      voi: (filters?.voi as string) || '',
    });
  }, []);

  const columns: Array<Column<IDeal>> = useMemo(
    () => [
      {
        Header: 'Borrower',
        accessor: 'borrower',
        id: 'borrower_last_name',
      },
      {
        Header: 'Co-borrower',
        accessor: 'coborrower',
        id: 'coborrower_last_name',
      },
      {
        Header: 'Install address',
        disableSortBy: true,
        accessor: 'installAddress',
      },
      {
        Header: 'City',
        accessor: 'city',
        id: 'install_city',
      },
      {
        Header: 'State',
        id: 'install_state_or_province',
        accessor: 'state',
      },
      {
        Header: 'App date',
        id: 'createdAt',
        accessor: (row) => new Date(row.createdAt).toLocaleDateString(),
      },
      {
        Header: 'Status',
        disableSortBy: true,
        accessor: 'status',
      },
      {
        Header: 'Loan amount',
        id: 'loan_amount',
        accessor: (row) => `${convertMonetary(row.loanAmount)}`,
        className: 'numberCell',
      },
    ],
    [],
  );

  const handleBackClick = () => {
    history.push(Routes.ContractorDashboard);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  return (
    <>
      {loading && <PageLoader />}
      <DealsFilters open={open} onClose={handleClose} fetchData={fetchData} />
      <p>Select a name from the list to manage deal</p>
      <div className={styles.controlsContainer}>
        <Button
          className={styles.filtersButton}
          title="Filters"
          onClick={() => {
            setOpen(true);
          }}
          icon={<FiltersIcon className={styles.filtersIcon} />}
          iconAlignment={isMobileScreen ? AlignmentType.Center : AlignmentType.Left}
        />
        {isMobileScreen ? (
          <SortButton options={columns} />
        ) : (
          <SearchInput placeholder="Search" fetchData={fetchData} className={styles.searchInput} />
        )}
      </div>
      {isMobileScreen && <SearchInput placeholder="Search" fetchData={fetchData} className={styles.searchInput} />}
      {isMobileScreen ? (
        <MobileTable data={data} fetchData={fetchData} pageCount={pageCount} totalCount={totalCount} />
      ) : (
        <Table
          className={styles.dealsTable}
          columns={columns}
          data={data}
          fetchData={fetchData}
          pageCount={pageCount}
          totalCount={totalCount}
        />
      )}
      <NavigationButtonWrapper>
        <BackButton className={commonStyles.portalBackButton} onClick={handleBackClick} />
      </NavigationButtonWrapper>
    </>
  );
};

export default withPortalLayout(Deals);
